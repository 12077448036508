import React, { useState, useEffect } from 'react';

import CourseService from "services/course/course.service";
import { logger } from 'hoc/logger';

import { CourseDto } from 'dto/course/course.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';
import CourseFilterInfo from 'components/course/course/components/coursefilter/CourseFilterInfo';
import CourseSettingsInfo from 'components/course/course/components/coursesettings/CourseSettingsInfo';
import CourseEnrollment from 'components/course/course/components/courseenrollment/CourseEnrollment';
import Lesson from 'components/course/course/components/lesson/Lesson';
import CoursePriceInfo from 'components/course/course/components/courseprice/CoursePriceInfo';
import CertificateInfo from 'components/course/course/components/certificate/CertificateInfo';
import LinkCourseCategoryInfo from 'components/course/course/components/linkcoursecategory/LinkCourseCategoryInfo';
import { RouteTools } from 'tools/routetools';

const courseService = new CourseService();


interface DetailCourseProps {
  children: any;
  currentRoute: any;
  _obj: CourseDto,
  mainObject: any;
}

const DetailCourse: React.FC<DetailCourseProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();
  const detailObj = _obj !== undefined ? _obj : undefined;
  const [loading, setLoading] = useState<boolean>(true);
  const [obj, setObj] = useState<CourseDto | undefined>(detailObj);
  const [id] = useState<string>(currentRoute._paths[1]);
  const editUrl = CommonTools.generateEditUrl(mainObject, id);


  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined) {
      courseService.get(id, loadObject, {});

    }
  };

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    if (obj === undefined) getObject();

  }, []);

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    { label: LL("crumb_Detail" + mainObject) },
  ];


  const [value, setValue] = React.useState(CommonTools.getAnchor(currentRoute, 'generalInfo'));

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
    RouteTools.setAnchor(newValue)
  };



  return !loading ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
        maxWidth="md"
      >
        <MyNavBt
          tabIndex={1}
          href={listUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />
        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {" "}
          {LL("DetailBlock")}{" "}
        </Typography>

        {obj ? (
          <MyNavBt
            tabIndex={0}
            href={editUrl}
            children={
              <IconButton className="editBtn">
                <EditIcon />
              </IconButton>
            }
            sx={{ ml: "auto" }}
            _hstate={CommonTools.prepareObjectForUpdate(obj, mainObject, value)}
            _mainurl={mainUrl}
          />
        ) : (
          <></>
        )}
      </Box>

      <Box>
        <Box className="tabs">
          <Tabs
            scrollButtons
            allowScrollButtonsMobile
            variant="scrollable"
            value={value}
            onChange={handleChange}
          >
            {obj ? <Tab label={LL("Course_main_Info")} value={'generalInfo'} /> : <></>}
            {obj ? <Tab label={LL("Course_Filter_Info")} value={'courseFilter'} /> : <></>}
            {obj ? <Tab label={LL("Course_Enrollment")} value={'courseEnrollment'} /> : <></>}
            {obj ? <Tab label={LL("Course_Lesson")} value={'courseLesson'} /> : <></>}
            {obj ? <Tab label={LL("Course_Price")} value={'coursePrice'} /> : <></>}
            {/* {obj ? <Tab label={LL("Course_Certificate")} value={6} /> : <></>} */}
            {obj ? (
              <Tab label={LL("Course_LinkCourseCategory")} value={'courseCategory'} />
            ) : (
              <></>
            )}
            {obj && obj.idcoursesettings ? (
              <Tab label={LL("Course_Settings")} value={'courseSettings'} />
            ) : (
              <></>
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={'generalInfo'}>
          <Box
            maxWidth="md"
            sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
          >
            <Grid spacing={2}>
              <Grid>
                <Typography color="text.disabled" variant="caption">
                  {LL("Name")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectField(obj, "name")}
              </Grid>

              <Grid mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("Status")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectField(obj, "status_name")}
              </Grid>
              <Grid mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("Description")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectField(obj, "description")}
              </Grid>

              <Grid mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("requirementstext")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectField(obj, "requirementstext")}
              </Grid>

              <Grid mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("whatwillyoulearntext")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectField(obj, "whatwillyoulearntext")}
              </Grid>

              <Grid mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("targetaudiencetext")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectField(obj, "targetaudiencetext")}
              </Grid>


              <Grid mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("shortdescription")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectField(obj, "shortdescription")}
              </Grid>


              <Grid mt={3}>

                <Typography color="text.disabled" variant="caption">
                  {LL("language")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectFieldOfObject(
                  obj,
                  "language",
                  "name"
                )}
              </Grid>

              <Grid mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("teacher")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectFieldOfObject(
                  obj,
                  "teacher",
                  "fullname"
                )}
              </Grid>
              <Grid mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("CourseDuration")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectFieldTimeStamp(
                  obj,
                  "courseduration"
                )}
              </Grid>
              <Grid mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("Numberoflessons")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectField(
                  obj,
                  "numberoflessons"
                )}
              </Grid>

              <Grid mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("PopularIdentifier")}
                </Typography>
              </Grid>
              <Grid>
                {ComponentCommonTools.processObjectField(
                  obj,
                  "popularidentifier"
                )}
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={'courseFilter'}>
          {obj ? <CourseFilterInfo currentRoute={currentRoute} /> : <></>}
        </TabPanel>

        <TabPanel value={value} index={'courseEnrollment'}>
          {obj ? (
            <CourseEnrollment
              mainObject={mainObject}
              currentRoute={currentRoute}
            />
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel value={value} index={'courseLesson'}>
          {obj ? (
            <Lesson mainObject={mainObject} currentRoute={currentRoute} />
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel value={value} index={'coursePrice'}>
          {obj && obj.courseprice ? (
            <CoursePriceInfo currentRoute={currentRoute} />
          ) : (
            <></>
          )}
        </TabPanel>
        {/* <TabPanel value={value} index={6}>
          {obj && obj.certificate ? (
            <CertificateInfo currentRoute={currentRoute} />
          ) : (
            <></>
          )} 
        </TabPanel>
        */}
        <TabPanel value={value} index={'courseCategory'}>
          {obj ? (
            <LinkCourseCategoryInfo currentRoute={currentRoute} />
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel value={value} index={'courseSettings'}>
          {obj && obj.idcoursesettings ? (
            <CourseSettingsInfo currentRoute={currentRoute} />
          ) : (
            <></>
          )}
        </TabPanel>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default DetailCourse;
