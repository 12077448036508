import React, { useState, useEffect } from "react";

import { logger } from "hoc/logger";
import useMyFormValidation from "hook/useMyFormValidation";

import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";

import CourseEnrollmentService from "services/course/courseenrollment.service";
import { StudentAutocomplete } from "components/elements/Autocomplete/StudentAutocomplete";
import MyTable from "components/elements/TabelTools/MyTable";
import ResultListDTO from "dto/app/resultlist.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import FilterTools from "tools/filtertools";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { FilterAutocomplete } from "components/elements/Autocomplete/FilterAutocomplete";

import { FilterDictionaryAutocomplete } from "components/elements/Autocomplete/FilterDictionaryAutocomplete";
import { StatusSelect } from "components/elements/Select/StatusSelect";
import { CheckBox } from "@mui/icons-material";
import { Status } from "tools/status";
import { AutocompleteAddStudent } from "components/student/components/AutocompleteAddStudent";

const courseEnrollmentService = new CourseEnrollmentService();

interface AddCourseEnrollmentProps {
  currentRoute: any;

  mainObject: any;
}

const AddCourseEnrollment: React.FC<AddCourseEnrollmentProps> = ({
  currentRoute,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [id, setId] = useState<string | undefined>(_id);

  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<CourseEnrollmentDto | undefined>(
    new CourseEnrollmentDto()
  );

  const [validators, setValidators] = useState<any>();
  const [vresults, setVResults] = useState<any>(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    // if (vresults) return;

    obj.idcourse = obj.idcourse !== undefined ? obj.idcourse : "";
    obj.idstudent = obj.idstudent !== undefined ? obj.idstudent : "";
    obj.status =
      obj.status !== undefined ? obj.status : Status.ENR_NEED_CHECKOUT;
    obj.paymentstatus =
      obj.paymentstatus !== undefined
        ? obj.paymentstatus
        : Status.PAYMENT_UNPAID;

    setObj(obj);

    // let t = validateField("value", obj.value);
  };

  // const validateField = (field: any, value: any, v?: any) => {

  //     v = (v) ? v : JSON.parse(JSON.stringify(vresults));
  //     if (v === false) v = {};
  //     v[field] = ValidateTools.validate(field, value, validators, vresults);

  //     // setIsFormDisabled(ValidateTools.vForm(v, field));
  //     setVResults(v);
  //     return v;
  // };

  // const checkIsFormDisabled = () => {
  //     var rez = ValidateTools.vForm(vresults, 'value');

  //     setIsFormDisabled(rez);
  // };
  // useEffect(() => {
  //     checkIsFormDisabled();
  // }, [vresults]);
  useEffect(() => {
    validateAll();
  }, [obj, validators]);
  // const processValidators = () => {
  //     let v: any = {
  //         value: [],

  //     };

  //     v.value.push(new RequiredValidator(LL('Required field value', LabelTypes.LABEL_VALIDATION), ''));

  //     setValidators(v);
  // };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (obj !== undefined && id !== undefined) {
      obj.idcourse = id;
      courseEnrollmentService.add(
        ComponentCommonTools.handleSuccessAddAndEdit,
        { mainObject, idRoute: id, anchor: "courseEnrollment" },
        obj
      );
    }
  };

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };

  return !loading && obj ? (
    <>
      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <form onSubmit={handleSubmit}>
          {/* <StudentAutocomplete
            
            id="idstudent"
            variant="outlined"
            color="primary"
            field="idstudent"
            size="small"
            setObj={setObj}
            obj={obj}
            setObjFieldGeneral={setObjField}
          /> */}
          <AutocompleteAddStudent
            object={obj}
            setObject={setObj}
            setObjField={setObjField}
          />
          <Box mt={3}>
            <StatusSelect
              tabIndex={2}
              id="status"
              name="status"
              variant="outlined"
              color="primary"
              value={obj.status}
              field="status"
              size="small"
              statusType="enrollment"
              setObj={setObjField}
              _defaultValue={obj.status}
            />
          </Box>
          <Box mt={3}>
            <StatusSelect
              tabIndex={3}
              id="paymentstatus"
              name="paymentstatus"
              variant="outlined"
              color="primary"
              value={obj.paymentstatus}
              field="paymentstatus"
              size="small"
              statusType="payment"
              setObj={setObjField}
              _defaultValue={obj.paymentstatus}
            />
          </Box>
          <MyButton
            tabIndex={3}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Submit")}
          />
        </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default AddCourseEnrollment;
