import React, { useState, useEffect } from 'react';

import LessonService from "services/course/lesson.service";
import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import { LessonDto } from 'dto/course/lesson.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { TeacherAutocomplete } from 'components/elements/Autocomplete/TeacherAutocomplete';
import { LessonTypeSelect } from 'components/elements/Select/LessonTypeSelect';
import MyCheckboxField from "components/form/mycheckboxfield";
import MyDateTimePicker from 'components/elements/MyDatePicker/MyDateTimePicker';
import DateTools from 'tools/date.tools';
import {Stack} from "@mui/material";
import { Status } from 'tools/status';
const lessonService = new LessonService();

interface EditLessonProps {
    children: any;
    currentRoute: any;
    _obj: LessonDto,
    mainObject: any;
}

const EditLesson: React.FC<EditLessonProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");
    const [addUrl, setAddUrl] = useState("");

    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const au = CommonTools.generateAddUrl(mainObject);
        setAddUrl(au);
        const lu = _getListUrl(mu);
        setListUrl(lu);
    }, [mainObject, currentRoute]);

    const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<LessonDto | undefined>()
    const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);

    const _courseUrl = obj ? CommonTools.generateDetailUrl('Course', obj.idcourse) : '';
    const mainUrlCourse = CommonTools.generateMainUrl('Course');
    const [courseDetailUrl, setCourseDetailUrl] = useState<string>(_courseUrl);

    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.name = obj.name !== undefined ? obj.name : '';
        obj.description = obj.description !== undefined ? obj.description : '';
        obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 1;
        obj.idtypelesson = obj.idtypelesson !== undefined ? obj.idtypelesson : '';
        obj.idcourse = obj.idcourse !== undefined ? obj.idcourse : '';

        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.lessonduration = obj.lessonduration !== undefined ? obj.lessonduration : 0;
        obj.islive = obj.islive !== undefined ? obj.islive : false;
        obj.startdate = obj.startdate !== undefined ? obj.startdate : undefined;
        obj.enddate = obj.enddate !== undefined ? obj.enddate : undefined;
        setObj(obj);

        let t = validateField("name", obj.name);
        t = validateField("description", obj.description, t);
        // t = validateField("lessonduration", obj.lessonduration,t);
        // t = validateField("ordercriteria", obj.ordercriteria, t);
    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            name: [],
            description: [],
            // lessonduration: [],
        };

        v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
        v.description.push(new RequiredValidator(LL('Required field description', LabelTypes.LABEL_VALIDATION), ''));
        // v.lessonduration.push(new RequiredValidator(LL('Required field lessonduration', LabelTypes.LABEL_VALIDATION), ''));
        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {

            lessonService.get(id, loadObject, {});
        }
    };

   
    const setObjDateField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = DateTools.getDateTimeStamp(value);
        setObj(t);
        checkIsFormDisabled();
    };

    useEffect(() => {
        processValidators();
        if (id === '') {
            if (obj !== undefined) {
                setId(obj.id)
            }
        }
    }, []);
    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'name');
        rez = ValidateTools.vForm(vresults, 'description', rez);
        // rez = ValidateTools.vForm(vresults, 'lessonduration',rez);
        if (obj && obj.startdate !== undefined && obj.enddate !== undefined) {
            if (obj.startdate > obj.enddate) {
                rez = true;
            }
        }
        setIsFormDisabled(rez);
    };

    useEffect(() => {
        validateAll();
        checkLoading();
        if (courseDetailUrl === '') {
            if (obj !== undefined) {
                setCourseDetailUrl(CommonTools.generateDetailUrl('Course', obj.idcourse));
            }
        }
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);

    useEffect(() => {
        if (obj === undefined) getObject();
    }, [obj]);


    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (id !== undefined) {
            lessonService.update(id, ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
        }
    };
    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrlCourse,
            label: LL("crumb_Course"),
        },
        {
            url: courseDetailUrl,
            label: LL("crumb_DetailCourse"),
        },
        {
            url: detailUrl,
            label: LL("crumb_Detail" + mainObject),
        },
        { label: LL("crumb_Edit" + mainObject) },
    ];
    const setCheckFixed = (value: any) => {
        let t = JSON.parse(JSON.stringify(obj));

        t['islive'] = value;
        setObj(t);

    };
    // const [value, setValue] = React.useState(1);

    // const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    //     setValue(newValue);
    // };

    return !loading && obj ? (
        <>

            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
            <Box sx={{ flexDirection: 'row', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                <MyNavBt
                    tabIndex={2}
                    href={detailUrl}
                    children={<IconButton color="primary"><ArrowBackIcon /></IconButton>}
                    size="large"
                    _hstate={{}}
                    _mainurl={courseDetailUrl}
                />
                <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
                    {LL("EditBlock")}
                </Typography>
            </Box>



            <>
                {/* <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                >
                    {obj ? <Tab label={LL("Edit_Lesson_main_Info")} value={1} /> : <></>}
                    {obj ? <Tab label={LL("Lesson_Settings_Info")} value={2} /> : <></>}

                </Tabs>
                <TabPanel value={value} index={1}> */}
                <Box
                    maxWidth="md"
                    sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
                >
                    <form onSubmit={handleSubmit}>
                        <MyTextField
                            tabIndex={0}
                            fullWidth
                            id="name"
                            name="name"
                            variant="outlined"
                            color="primary"
                            _label={LL("Name")}
                            size="small"
                            value={obj.name}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.name ? vresults.name : false
                            }
                        />
                        <Box sx={{ mt: 3 }}>
                            <MyTextField
                                tabIndex={1}
                                fullWidth
                                id="description"
                                name="description"
                                variant="outlined"
                                color="primary"
                                _label={LL("Description")}
                                size="small"
                                value={obj.description}
                                setObj={setObjField}
                                _vresults={
                                    vresults && vresults.description ? vresults.description : false
                                }
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <MyTextField
                                tabIndex={2}
                                fullWidth
                                id="ordercriteria"
                                name="ordercriteria"
                                variant="outlined"
                                color="primary"
                                _label={LL("Ordercriteria")}
                                size="small"
                                value={obj.ordercriteria}
                                setObj={setObjField}
                                _vresults={
                                    vresults && vresults.ordercriteria ? vresults.ordercriteria : false
                                }
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <LessonTypeSelect
                                tabIndex={3}
                                id="idtypelesson"
                                name="idtypelesson"
                                variant="outlined"
                                color="primary"
                                value={obj.idtypelesson}
                                field="idtypelesson"
                                size="small"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypelesson}
                            />
                        </Box>
                        <Box mt={3}>
                            <StatusSelect
                                tabIndex={2}
                                id="status"
                                name="status"
                                variant="outlined"
                                color="primary"
                                value={obj.status}
                                field="status"
                                size="small"
                                setObj={setObjField}
                                _defaultValue={obj.status}
                            />
                        </Box>
                        {/* <Box sx={{ mt: 3 }}>
                            <MyTextField
                                tabIndex={1}
                                fullWidth
                                id="lessonduration"
                                name="lessonduration"
                                variant="outlined"
                                color="primary"
                                _label={LL("lessonduration")}
                                size="small"
                                value={obj.lessonduration}
                                setObj={setObjField}
                                _vresults={false}
                            />
                        </Box> */}
                        <Box sx={{ mt: 3 }}>
                            <MyCheckboxField

                                tabIndex={1}
                                id="islive"
                                name="islive"
                                _label={LL("islive")}
                                setObj={setCheckFixed}
                                checked={obj.islive}
                                color="primary"
                                size="small"
                                _vresults={undefined}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                <Typography variant="h6" mb={1}> {LL('Seteaza data si ora lectiei')}</Typography>
                    <Stack direction='row' spacing={2}>
                    <MyDateTimePicker
                        className="form-picker"
                        field="startdate"
                        _defaultValue={DateTools.convertTimestampToDate(obj.startdate)}
                        LL={LL}
                        setObjField={setObjDateField}

                    />
                
                    <MyDateTimePicker
                        className="form-picker"
                        field="enddate"
                        _defaultValue={DateTools.convertTimestampToDate(obj.enddate)}
                        LL={LL}
                        setObjField={setObjDateField}
                    /></Stack>
                </Box>
                        <MyButton
                            tabIndex={3}
                            disabled={isFormDisabled}
                            color="primary"
                            fullWidth
                            variant="contained"
                            size="large"
                            type="submit"
                            sx={{ mt: 4 }}
                            children={LL("Bt_Submit")}
                        />
                    </form>
                </Box>
                {/* </TabPanel>
                <TabPanel value={value} index={2}>
                    {obj && obj.idlessonsettings ?
                        <EditLessonSettings mainObject={mainObject} currentRoute={currentRoute} />
                        : <AddLessonSettings mainObject={mainObject} currentRoute={currentRoute} />}
                </TabPanel> */}

            </>
        </>
    ) : (
        <Loading />
    );
};

export default EditLesson;