import React, { useState, useEffect } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";

import {
  DataGrid,
  GridSortItem,
  GridSortModel,
  GridFilterModel,
  GridToolbar,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridRowSelectionModel,
  GridColumnMenu,
  GridColumnMenuProps,
  GridCallbackDetails,
  useGridApiRef
} from "@mui/x-data-grid";

import RequestListDTO from "dto/app/requestlist.dto";
import { logger } from "hoc/logger";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Sort } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import DateTools from "tools/date.tools";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import { useResource } from "hook/useResource";
import LinearProgress from "@mui/material/LinearProgress";
import { TableToolbar } from "./TableToolbar";
import Box from "@mui/material/Box/Box";
import Divider from "@mui/material/Divider";
import { ComponentCommonTools } from "tools/componentcommontools";
import FilterTools from "tools/filtertools";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { MessagesContainerPopUpConfirmDelete } from "components/message/MessagesContainerPopUpConfirmDelete";
import { CommonTools } from "tools/commontools";

interface MyTableProps {
  _columns: any;
  _data: any;
  _reqList: RequestListDTO;
  _tbFilterList?: TbFilterListDTO;
  _total: number;
  _totalPage: number;
  setReqList: (value: RequestListDTO) => any;
  loading: boolean;
  currentRoute: any;
  onDelete?: (value: any) => any;
  mainObject: any;
  onRowClickCb?: (obj: any) => any;
  service?: any;
  fieldToShowOnDelete?: string;
  deleteOneObject?: any;
  setDeleteOneObject?: any
}

const MyTable: React.FC<MyTableProps> = ({
  _columns,
  _data,
  _reqList,
  _tbFilterList,
  _total,
  _totalPage,
  setReqList,
  loading,
  currentRoute,
  onDelete,
  mainObject,
  onRowClickCb,
  service,
  deleteOneObject,
  setDeleteOneObject,
  fieldToShowOnDelete,
  ...props
}) => {
  const { LL } = useResource();
  const [cookies, setCookies] = useCookies();
  const [columns, setColumns] = useState(_columns);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [total, setTotal] = React.useState(_total);
  const [_existReqList, setExistReqList] = React.useState<RequestListDTO>(new RequestListDTO());
  const apiRef = useGridApiRef();
  const convertFromRequestSortCriteriaToSortModel = (
    obj: Array<RequestSortCriteriaDTO>
  ) => {
    if (obj === undefined) return [];
    let gridSortModel: GridSortModel = obj
      .filter((item) => item !== undefined)
      .map((item) => {
        const gridSortItem: GridSortItem = {
          field: item.field ? item.field : "",
          sort: item.asc ? "asc" : "desc",
        };
        return gridSortItem;
      });

    return gridSortModel.length > 0 ? gridSortModel : [];
  };

  const _page = _reqList.page ?? 1;
  const _onPage = _reqList.onpage ?? Number(process.env.REACT_APP_ONPAGE);
  const _arrayRequestSortCriteria = _reqList.sortcriteria
    ? _reqList.sortcriteria
    : new Array<RequestSortCriteriaDTO>();

  const defaultGridSortModel = convertFromRequestSortCriteriaToSortModel(
    _arrayRequestSortCriteria
  );
  const [paginationModel, setPaginationModel] = React.useState({
    page: _page - 1,
    pageSize: _onPage,
  });

  useEffect(() => {
    setTotal(_total);

    let p = _reqList.page ?? 1;
    p = p - 1;
    const _onPage = _reqList.onpage ?? Number(process.env.REACT_APP_ONPAGE);
    const opm = {
      page: p,
      pageSize: _onPage
    };
    setPaginationModel(opm);

    
    const _filterTmps = _reqList.filters !== undefined ? _reqList.filters : null;
    setFilters(_filterTmps);
  }, [
    _columns,
    _data,
    _reqList,
    _tbFilterList,
    _total,
    _totalPage,
    loading,
    currentRoute,
    mainObject,
  ]);

  // =========================
  const [objectsToDelete, setObjectsToDelete] = useState<Array<any>>([]);
  const [objToDelete, setObjToDelete] = useState<any>(undefined);
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const handleDeleteAll = (e: any) => {
    logger("On Delete All", e);
    if (onDelete !== undefined) {
      if (rowSelectionModel?.length > 0) {

        const selectedRows = apiRef.current.getSelectedRows();
        const selectedRowsArray: any = [];
        selectedRows.forEach((value, key) => {
          selectedRowsArray.push(value);
        });
        logger("On Delete selectedRowsArray", selectedRowsArray);
        setObjectsToDelete(selectedRowsArray);


      }
    }
  };
  useEffect(() => {
    logger("useEffect rowSelectionModel", objectsToDelete);
    if (objectsToDelete.length > 0) {
      setObjToDelete(objectsToDelete[0]);
      setOpenConfirmDelete(true);
    }
  }, [objectsToDelete]);

  useEffect(() => {
    if (objectsToDelete.length > 0) {
      apiRef.current.selectRow(objectsToDelete[0].id, false, false);
      objectsToDelete.shift();
      setObjToDelete(objectsToDelete[0]);
    }

    if (objectsToDelete.length > 0) {
      setOpenConfirmDelete(true);
    }
    if (objectsToDelete.length === 0) {
      setObjToDelete(undefined);
    }
  }, [deleteAction]);

  useEffect(() => {
    if (deleteOneObject !== undefined) {
      setObjToDelete(deleteOneObject);
      setOpenConfirmDelete(true);
    }
  }, [deleteOneObject]);

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(false);
    setDeleteAction(!deleteAction);
    if (onDelete !== undefined) {
      onDelete(objToDelete);

    }
    if (setDeleteOneObject !== undefined) setDeleteOneObject(undefined);
  }
  const handleCancelDelete = () => {
    setOpenConfirmDelete(false);
    if (setDeleteOneObject !== undefined) setDeleteOneObject(undefined);
    setDeleteAction(!deleteAction);
  }
  // =========================

  const [arrayRequestSortCriteria, setArrayRequestSortCriteria] =
    React.useState<Array<RequestSortCriteriaDTO>>(_arrayRequestSortCriteria);

  const [sortModel, setSortModel] =
    useState<GridSortModel>(defaultGridSortModel);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const _filterTmps = _reqList.filters !== undefined ? _reqList.filters : null;
  const [filters, setFilters] = useState<RequestFilterDTO[] | null | undefined>(
    _filterTmps
  );


  const processFilters = (tfilters: Array<RequestFilterDTO>) => {
    setFilters(tfilters);
  };

  const handleRequestList = () => {
    const req = new RequestListDTO();
    req.page = paginationModel.page + 1;
    req.onpage = paginationModel.pageSize;
    if (arrayRequestSortCriteria.length !== 0)
      req.sortcriteria = arrayRequestSortCriteria;
    else req.sortcriteria = undefined;

    req.filters = filters ?? [];

    setCookies("onPage", paginationModel.pageSize, {
      path: "/",
      expires: DateTools.getDate(
        Number(process.env.REACT_APP_USERCOOKIES_EXPIRE)
      ),
    });

    if (CommonTools.areObjectsEqual(_existReqList,req)) {
      return;
    }
    setExistReqList(req);

    setReqList(req);
  };


  useEffect(() => {
    handleRequestList();
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    arrayRequestSortCriteria,
    filters,
  ]);

  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      handleSorter(sortModel);
      if (sortModel.length === 0) setSortModel([]);
      else setSortModel(sortModel);
    },
    []
  );

  const convertToRequestSortCriteria = (item: GridSortItem) => {
    const requestSortCriteriaDTO = new RequestSortCriteriaDTO();
    requestSortCriteriaDTO.field = item.field;
    let asc: boolean = item.sort === "asc" ? true : false;
    requestSortCriteriaDTO.asc = asc;

    return requestSortCriteriaDTO;
  };

  const handleSorter = (obj: GridSortModel) => {
    let arrRequestSortCriteriaDTO = new Array<RequestSortCriteriaDTO>();
    arrRequestSortCriteriaDTO = obj.map((item) =>
      convertToRequestSortCriteria(item)
    );
    setArrayRequestSortCriteria(arrRequestSortCriteriaDTO);
  };

  const onRowClick = (params: GridRowParams, event: any, details: any) => {
    logger("On Row click", params, event, details);
    event.stopPropagation();
    event.preventDefault();
    if (onRowClickCb === undefined)
      ComponentCommonTools.goToDetails(params.row, mainObject);
    else onRowClickCb(params.row);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ boxShadow: 2, p: 1 }} >
        <GridToolbarColumnsButton tabIndex={1} color="inherit" />
        <GridToolbarDensitySelector tabIndex={2} color="inherit" />
        {/* <GridToolbarExport tabIndex={3} color="inherit" /> */}
        <TableToolbar
          setFilter={processFilters}
          filters={filters}
          tbFilterList={_tbFilterList}
          // filterTmp={valueFilterTmp}
          tabIndex={4}
        />
        {onDelete !== undefined ? (
          <IconButton
            tabIndex={5}
            aria-label="delete"
            color="inherit"
            onClick={handleDeleteAll}
          >
            <DeleteOutlineIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  };

  const CustomColumnMenu = (props: GridColumnMenuProps) => {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuFilterItem: null,
        }}
      />
    );
  };



  const handleSetRowSelectionModel = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
    setRowSelectionModel(rowSelectionModel);
  }
  return (
    <>
      <Box className="boxTable">
        <DataGrid
          apiRef={apiRef}
          sx={{ bgcolor: "background.paper", boxShadow: 2 }}
          rows={_data}
          columns={columns}
          paginationMode="server"
          pagination
          rowCount={total}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          sortModel={sortModel}
          slots={{
            toolbar: CustomToolbar,
            loadingOverlay: LinearProgress,
            columnMenu: CustomColumnMenu,
          }}
          loading={loading}
          onRowSelectionModelChange={handleSetRowSelectionModel}
          // onColumnVisibilityModelChange={(
          //   params?: any,
          //   event?: any,
          //   details?: any
          // ) => columnVisibilityModelChange(params, event, details)}
          onRowClick={onRowClick}
          pageSizeOptions={[5, 10, 15, 25]}
          checkboxSelection
        />
      </Box>
      <MessagesContainerPopUpConfirmDelete
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        showField={fieldToShowOnDelete}
        _obj={objToDelete}
      />
    </>
  );
};

export default MyTable;