import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class QuestionDto implements Idto {
    
    id?: string;
    name?: string;
    status?: number;
    ordercriteria?: number;
    type?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;    
    idsanswer?: string[];
    idsrightanswer?: string[];
    idtest?: string;
    constructor() {
      this.status = Status.ACTIVE;
      this.ordercriteria = 0;
  }
  }

  export class PostQuestionDto implements Idto {
    
    name?: string;
    status?: number;
    ordercriteria?: number;
    type?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;    
    idsanswer?: string[];
    idsrightanswer?: string[];
    idtest?: string;
    constructor() {
      this.status = Status.ACTIVE;
      this.ordercriteria = 0;
  }
  }