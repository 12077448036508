
import React from 'react';

import Box from '@material-ui/core/Box';
import LinearProgress from '@mui/material/LinearProgress';

const Loading = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress  color="primary"/>
    </Box>
  );
};

export  { Loading };