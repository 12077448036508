import React, { useState, useEffect } from 'react';

import SiteConfigFileService from "services/siteconfig/siteconfigfile.service";
import { logger } from 'hoc/logger';

import { PostSiteConfigFileDto } from 'dto/app/siteconfigfile.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { StatusSelect } from "components/elements/Select/StatusSelect"
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Status } from 'tools/status';
import IconButton from '@mui/material/IconButton';
import MyFileField from 'components/form/myfilefield';

const siteConfigFileService = new SiteConfigFileService();

interface AddSiteConfigFileProps {
    children: any;
    currentRoute: any;
    _obj: PostSiteConfigFileDto,
    mainObject: any;
}

const AddSiteConfigFile: React.FC<AddSiteConfigFileProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();


    const [loading, setLoading] = useState<boolean>(false);
    var [obj, setObj] = useState<PostSiteConfigFileDto>(new PostSiteConfigFileDto());


    const [isFormDisabled, setIsFormDisabled] = useState(true);

    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.identifier = obj.identifier !== undefined ? obj.identifier : '';
       
       
        setObj(obj);

        let t = validateField("identifier", obj.identifier);
        
       
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            identifier: [],
        
          
        };

        v.identifier.push(new RequiredValidator(LL('Required field identifier', LabelTypes.LABEL_VALIDATION), ''));
        
      
        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    useEffect(() => {
        processValidators();
    }, []);

    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };
    const [files, setFiles] = useState(null);
    const setObjFile = (field: any, event: any) => {
        setFiles(event.target.files[0]);
    };

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'identifier');
        if (files == null) {
          rez = true;
      }
        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (files !== null) obj.files = files;
        siteConfigFileService.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
    };


    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Add" + mainObject) },
    ];
    return !loading ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <MyNavBt
            
            href={listUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />

          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {LL("AddBlock")}
          </Typography>
        </Box>

        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <form onSubmit={handleSubmit}>
            <MyTextField
              tabIndex={0}
              fullWidth
              id="identifier"
              name="identifier"
              variant="outlined"
              color="primary"
              _label={LL("label_text_field_identifier")}
              size="small"
              value={obj.identifier}
              setObj={setObjField}
              _vresults={
                vresults && vresults.identifier ? vresults.identifier : false
              }
            />
           
           <Box mt={3}>

<MyFileField
    tabIndex={1}
    fullWidth
    id="files"
    name="files"
    variant="outlined"
    color="primary"
    _label={LL("files")}
    size="small"
    setObj={setObjFile}
    _vresults={vresults && vresults.files ? vresults.files : false}
/>


</Box>
            <MyButton
              tabIndex={2}
              disabled={isFormDisabled}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
          </form>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default AddSiteConfigFile;
