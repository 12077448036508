import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import UserRoleDto from 'dto/user/userrole.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UserRoleService from "services/user/userrole.service";
import IconButton from '@mui/material/IconButton';
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { TypeGenderSelect } from "components/elements/Select/GenderTypeSelect";

import MyDatePicker from 'components/elements/MyDatePicker/MyDatePicker';
import { RoleSelect } from 'components/elements/Select/RoleSelect';
import MyTable from 'components/elements/TabelTools/MyTable';
import ResultListDTO from 'dto/app/resultlist.dto';
import TbFilterDTO from 'dto/app/tbfilter.dto';
import TbFilterListDTO from 'dto/app/tbfilterlist.dto';

import FilterTools from 'tools/filtertools';
import { Status } from 'tools/status';
import RequestListDTO from 'dto/app/requestlist.dto';
import RequestFilterDTO from 'dto/app/requestfilter.dto';


const userRoleService = new UserRoleService();

interface EditUserRoleProps {

    currentRoute: any;

    mainObject: any;
}

const EditUserRole: React.FC<EditUserRoleProps> = ({
    currentRoute,
    mainObject,
    ...props
}) => {

  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<UserRoleDto | undefined>(new UserRoleDto())


    const [cookies, setCookies] = useCookies();

    const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
    const [rows, setRows] = useState<Array<UserRoleDto>>(new Array<UserRoleDto>());

    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const [responseParams, setResponseParams] = useState<RequestListDTO>(
        new RequestListDTO()
    );

    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
    const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
        new TbFilterListDTO()
    );

    // ------------------------------------------------
    const deleteObj = (obj: any) => {
        setDeleteOneObject(obj);
    };

    const columns = [];
    columns.push(ComponentCommonTools.columns_GetCheck());
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "name"));
    columns.push(
        ComponentCommonTools.columns_ActionsColumn(
            LL,
            mainObject,
            deleteObj,
            ["delete"],
        )
    );



    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (obj !== undefined && id !== undefined && obj.idrole !== undefined) {
            obj.iduser = id;
            userRoleService.add(getList, {}, obj);
        }

    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };

    const loadObjects = (data: ResultListDTO) => {
        CommonTools.processListLoadObjects(
            data,
            setData,
            setRows,
            setTotal,
            setTotalPage,
            setResponseParams
        );
    };

    const getList = () => {
        setLoading(true);
        if (id !== undefined) userRoleService.getList(loadObjects, {}, reqList);
    };

    const getReqList = () => {
        let reqList = RouteTools.prepareListRequest(currentRoute, cookies);
        reqList = userRoleService.getDefaultRequestList(reqList);
        const filters = new Array<RequestFilterDTO>()
        const filter = new RequestFilterDTO();
        filter.field = "iduser";
        if (id !== undefined) filter.values = [id];
        else filter.values = [];
        filters.push(filter);
        reqList.filters = filters;

        setReqList(reqList);
    };


    const checkIsLoading = () => {
        const load: boolean = CommonTools.checkIsLoading(
            reqList,
            responseParams,
            data,
            total,
            totalPage,
            rows
        );
        setLoading(load);
    };

    const processTbFilterList = () => {
        const _tobj = new TbFilterListDTO();

        var t: TbFilterDTO;

        t = new TbFilterDTO("search", FilterTools.fiterTypeText);
        _tobj.addFilter(t);



        setTbFilterList(_tobj);
    };

    useEffect(() => {
        processTbFilterList();
    }, []);

    useEffect(() => {
        getReqList();
    }, [currentRoute]);

    useEffect(() => {
        if (CommonTools.atLeastOneFieldDefined(reqList)) {
            getList();
        }
    }, [reqList]);

    useEffect(() => {
        checkIsLoading();
    }, [data, rows, reqList, total, totalPage]);

    const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);


    const handleDelete = (item: any) => {


        userRoleService.delete(item.id, getList, undefined);
    };

    return !loading ? (
        <>
            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit} >

                    <RoleSelect
                        tabIndex={0}
                        setObj={setObjField}
                        obj={obj}
                        field="idrole"
                        size="small"
                    />
                    <MyButton
                        tabIndex={1}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>

            </Box>
            <Box mt={3} >
                <MyTable
                    _columns={columns}
                    _data={rows}
                    _total={total}
                    _totalPage={totalPage}
                    _reqList={reqList}
                    _tbFilterList={tbFilterList}
                    setReqList={setReqList}
                    loading={loading}
                    currentRoute={currentRoute}
                    onDelete={handleDelete}
                    mainObject={mainObject}
                    fieldToShowOnDelete="name"
                    deleteOneObject={deleteOneObject}
                    setDeleteOneObject={setDeleteOneObject}
                    onRowClickCb={() => { }}
                    {...props}
                />
            </Box>
        </>
    ) : (
        <Loading />
    );
};

export default EditUserRole;
