import React, { useState, useEffect } from "react";

import SupportArticleService from "services/supportarticle/supportarticle.service";
import { logger } from "hoc/logger";

import {
  SupportArticleDto,
  PostSupportArticleDto,
} from "dto/supportarticle/supportarticle.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";

import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from "tools/routetools";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import IconButton from "@mui/material/IconButton";
import { StatusSelect } from "components/elements/Select/StatusSelect";

import { LanguageSelect } from "components/elements/Select/LanguageSelect";

import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { Status } from "tools/status";
import { SupportArticleCategoryAutocomplete } from "components/elements/Autocomplete/SupportArticleCategoryAutocomplete";
const supportarticleService = new SupportArticleService();

interface AddSupportArticleProps {
  children: any;
  currentRoute: any;
  _obj: SupportArticleDto;
  mainObject: any;
}

const AddSupportArticle: React.FC<AddSupportArticleProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const [loading, setLoading] = useState<boolean>(false);
  var [obj, setObj] = useState<PostSupportArticleDto>(
    new PostSupportArticleDto()
  );

  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const [validators, setValidators] = useState(false);
  const [vresults, setVResults] = useState<any>(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    // if (vresults) return;

    obj.name = obj.name !== undefined ? obj.name : "";
    obj.title = obj.title !== undefined ? obj.title : "";
    obj.content = obj.content !== undefined ? obj.content : "";
    obj.descriptionmeta =
      obj.descriptionmeta !== undefined ? obj.descriptionmeta : "";
    obj.keymeta = obj.keymeta !== undefined ? obj.keymeta : "";
    obj.url = obj.url !== undefined ? obj.url : "";

    obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : "";
    obj.idsupportarticlecategory =
      obj.idsupportarticlecategory !== undefined
        ? obj.idsupportarticlecategory
        : "";
    obj.type = obj.type !== undefined ? obj.type : 0;
    obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
    obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;
    obj.shortdescription = obj.shortdescription !== undefined ? obj.shortdescription : "";

    setObj(obj);

    let t = validateField("name", obj.name);
    // t = validateField("title", obj.title, t);
    // // t = validateField("content", obj.content, t);
    // t = validateField("descriptionmeta", obj.descriptionmeta, t);
    // t = validateField("keymeta", obj.keymeta, t);
    // t = validateField("url", obj.url, t);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);
    setVResults(v);
    return v;
  };

  const processValidators = () => {
    let v: any = {
      name: [],
      // title: [],
      // // content: [],
      // descriptionmeta: [],
      // keymeta: [],
      // url: []
    };

    v.name.push(
      new RequiredValidator(
        LL("Required field name", LabelTypes.LABEL_VALIDATION),
        ""
      )
    );
    // v.title.push(new RequiredValidator(LL('Required field title', LabelTypes.LABEL_VALIDATION), ''));
    // // v.content.push(new RequiredValidator(LL('Required field content', LabelTypes.LABEL_VALIDATION), ''));
    // v.descriptionmeta.push(new RequiredValidator(LL('Required field descriptionmeta', LabelTypes.LABEL_VALIDATION), ''));
    // v.keymeta.push(new RequiredValidator(LL('Required field keymeta', LabelTypes.LABEL_VALIDATION), ''));
    // v.url.push(new RequiredValidator(LL('Required field url', LabelTypes.LABEL_VALIDATION), ''));

    setValidators(v);
  };

  const checkLoading = () => {
    if (!loading) return;
    if (!obj) return;
    if (!validators) return;
    validateAll();
    setLoading(false);
  };

  useEffect(() => {
    validateAll();
    processValidators();
  }, []);

  useEffect(() => {
    checkLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);

    validateField(field, value);
  };

  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, "name");
    // rez = ValidateTools.vForm(vresults, 'title', rez);
    // rez = ValidateTools.vForm(vresults, 'content', rez);
    // rez = ValidateTools.vForm(vresults, 'descriptionmeta', rez);
    // rez = ValidateTools.vForm(vresults, 'keymeta', rez);
    // rez = ValidateTools.vForm(vresults, 'url', rez);
    setIsFormDisabled(rez);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;

    supportarticleService.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject },
      obj
    );
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    { label: LL("crumb_Add" + mainObject) },
  ];
  return !loading ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={11}
          href={listUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />

        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {LL("AddBlock")}
        </Typography>
      </Box>

      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <form onSubmit={handleSubmit}>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={vresults && vresults.name ? vresults.name : false}
          />

          <Box sx={{ mt: 3 }}>
            <StatusSelect
              tabIndex={1}
              id="status"
              name="status"
              variant="outlined"
              color="primary"
              value={obj.status}
              field="status"
              size="small"
              setObj={setObjField}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={3}
              fullWidth
              id="shortdescription"
              name="shortdescription"
              variant="outlined"
              color="primary"
              _label={LL("shortdescription")}
              size="small"
              value={obj.shortdescription}
              setObj={setObjField}
              _vresults={null}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            {/* <SupportArticleTypeSelect
                tabIndex={2}
                id="type"
                name="type"
                variant="outlined"
                color="primary"
                value={obj.type}
                field="type"
                size="small"
                setObj={setObjField}
              /> */}
          </Box>
          <Box sx={{ mt: 3 }}>
            <LanguageSelect
              tabIndex={3}
              id="idlanguage"
              name="idlanguage"
              variant="outlined"
              color="primary"
              value={obj.idlanguage}
              field="idlanguage"
              size="small"
              setObj={setObjField}
              obj={obj}
              forAddForms={true}
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            {/* <SupportArticleCategorySelect
                tabIndex={4}
                id="idsupportarticlecategory"
                name="idsupportarticlecategory"
                variant="outlined"
                color="primary"
                value={obj.idsupportarticlecategory}
                field="idsupportarticlecategory"
                size="small"
                setObj={setObjField}
                obj={obj}
              /> */}

            <SupportArticleCategoryAutocomplete
              variant="outlined"
              color="primary"
              size="small"
              setObj={setObj}
              obj={obj}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={2}
              fullWidth
              id="ordercriteria"
              name="ordercriteria"
              variant="outlined"
              color="primary"
              _label={LL("ordercriteria")}
              size="small"
              value={obj.ordercriteria}
              setObj={setObjField}
              _vresults={
                vresults && vresults.ordercriteria
                  ? vresults.ordercriteria
                  : false
              }
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={5}
              fullWidth
              id="title"
              name="title"
              variant="outlined"
              color="primary"
              _label={LL("title")}
              size="small"
              value={obj.title}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={7}
              fullWidth
              id="descriptionmeta"
              name="descriptionmeta"
              variant="outlined"
              color="primary"
              _label={LL("descriptionmeta")}
              size="small"
              value={obj.descriptionmeta}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={8}
              fullWidth
              id="keymeta"
              name="keymeta"
              variant="outlined"
              color="primary"
              _label={LL("keymeta")}
              size="small"
              value={obj.keymeta}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={9}
              fullWidth
              id="url"
              name="url"
              variant="outlined"
              color="primary"
              _label={LL("url")}
              size="small"
              value={obj.url}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyCKEditor
              data={obj.content ? obj.content : ""}
              setObj={setObjField}
              name="content"
            />
          </Box>
          <MyButton
            tabIndex={10}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Submit")}
          />
        </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default AddSupportArticle;
