import Idto from "interfaces/idto.interface";
import { LanguageDto } from 'dto/language/language.dto';
import { TypeDto } from "dto/nomenclature/type.dto";

export class PopulateTypeDto implements Idto {
    id?: string;
    language?: LanguageDto;
    typeobject?: TypeDto;
    // idtype?: TypeDto;
    name?: string;
    idlanguage?: string | undefined;
    status?: number | undefined;
    ordercriteria?: number | undefined;
}
