
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class PostCurrencyDto implements Idto {

    status?: number;
    ordercriteria?: number;
    numcode?: string;
    identifier?: string;
    idlanguage?: string;
    idcurrency?: string;
    name?: string;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
    }
}

export class CurrencyDto implements Idto {
    
    language?: any;
    status_name?: any;
    id?: string;
    status?: number;
    ordercriteria?: number;
    numcode?: string;
    identifier?: string;
    name?: string;
    idlanguage?: string;
    idcurrency?: string;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
    }
}