import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { FilterDto, PostFilterDto } from "dto/course/filter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import FilterRepository from "repositories/course/filter.repository";
import { Status } from "tools/status";
import GeneralService from "services/general.service";

export default class FilterService extends GeneralService implements IService {
    filterRepository: FilterRepository = new FilterRepository();
    constructor() {
        super();
        this.handleGetList = this.handleGetList.bind(this);
        this.handleGet = this.handleGet.bind(this);
    }
    handleUpdate(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;

        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }

    async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        this.filterRepository.update(id, this.handleUpdate, data, cbparameters);
    }

    handleDelete(result?: any, cbparameters?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters);
        }
    }

    async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;

        this.filterRepository.delete(id, this.handleDelete, cbparameters);
    }

    prepareObjToAdd(obj: any): PostFilterDto {
        const rez = new PostFilterDto();

        rez.idlanguage = obj.idlanguage;

        rez.name = obj.name;
        rez.status = obj.status;
        rez.ordercriteria = obj.ordercriteria;
        rez.type = obj.type;



        return rez;
    }
    prepareObjToUpdate(obj: any): PostFilterDto {
        const rez = new PostFilterDto();

        rez.idlanguage = obj.idlanguage;

        rez.name = obj.name;
        rez.status = obj.status;
        rez.ordercriteria = obj.ordercriteria;
        rez.type = obj.type;


        rez.idfilter = obj.idfilter;
        return rez;
    }

    handleGet(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;
        this.populateObject(rez.obj, result.obj, [
            { key: 'status', type: 'status' },
            { key: 'type', type: 'filtertype' },
            { key: 'ismain', type: 'booleans'}
        ]);

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

    return rez;
    }


    async get(id: string, cb?: any, cbparameters?: any, idLanguage?: string): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.filterRepository.get(id, this.handleGet, cbparameters, idLanguage);
        if (cb == undefined) {
            return this.handleGet(t);
        }
    }

    handleGetList(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultListDTO();

        rez = result;
        if (result.err) return;

        this.parseResultPopulate(result, rez, [
            { key: 'status', type: 'status' },
            { key: 'type', type: 'filtertype' },
            { key: 'ismain', type: 'booleans'}

        ]);

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

        return rez;
    }

    async getList(
        cb?: any,
        cbparameters?: any,
        data?: RequestListDTO
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.filterRepository.getList(this.handleGetList, cbparameters, data);
        if (cb == undefined) {
            return this.handleGetList(t);
        }
    }

    handleAdd(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }



    async add(
        cb?: any,
        cbparameters?: any,
        data?: any
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;

        this.filterRepository.add(this.handleGetList, cbparameters, data);
    }


    getDefaultRequestListSelect(onPage?: number): RequestListDTO {
        onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
        if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
        var defaultRequestList = new RequestListDTO();
        defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
        defaultRequestList.onpage = onPage;
        // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
        // defaultRequestSortCriteria.asc = true;
        // defaultRequestSortCriteria.field = 'value';
        // defaultRequestList.sortcriteria = []
        // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
        return defaultRequestList;
    }

    getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
        if (
            !obj.sortcriteria ||
            !Array.isArray(obj.sortcriteria) ||
            !obj.sortcriteria.length
        ) {
            var sobj = new RequestSortCriteriaDTO();
            sobj.asc = true;
            sobj.field = "name";

            obj.sortcriteria = [];
            obj.sortcriteria?.push(sobj);
        }

        return obj;
    }
}
