import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import MyTable from "components/elements/TabelTools/MyTable";
import GalleryService from "services/gallery/gallery.service";
import { logger } from "hoc/logger";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { GalleryDto } from "dto/gallery/gallery.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import { CommonTools } from "tools/commontools";
import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { ComponentCommonTools } from "tools/componentcommontools";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import AddIcon from "@mui/icons-material/Add";
import { MessagesContainerPopUpConfirmDelete } from "components/message/MessagesContainerPopUpConfirmDelete";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { GridActionsCellItem } from "@mui/x-data-grid";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import FilterTools from "tools/filtertools";
import { RouteTools } from "tools/routetools";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import SpecialTopInfo from "./SpecialTopInfo";

import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const galleryService = new GalleryService();

interface SpecialGalleryProps {
  children: any;
  currentRoute: any;
  mainObject: any;
}

const SpecialGallery: React.FC<SpecialGalleryProps> = ({
  children,
  currentRoute,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [parentUrl, setParentUrl] = useState("");
  const [parentMainUrl, setParentMainUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrlSpecial(currentRoute);
    setMainUrl(mu);
    const pmu = CommonTools.generateParentMainUrlSpecial(currentRoute);
    setParentMainUrl(pmu);
    const au = CommonTools.generateAddUrlSpecial(currentRoute);
    setAddUrl(au);
    const lu = _getListUrl(pmu);
    setParentUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();
  
  const [cookies, setCookies] = useCookies();

  const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  const [rows, setRows] = useState<Array<GalleryDto>>(new Array<GalleryDto>());

  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [parentType, setParentType] = useState("");
  const [parentId, setParentId] = useState("");

  const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
  );

  const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
  const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
    new TbFilterListDTO()
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);

  const deleteUser = (obj: any) => {
    setDeleteOneObject(obj);
  };

  const columns = [];
  columns.push(ComponentCommonTools.columns_GetCheck());

  columns.push(ComponentCommonTools.columns_ImageColumn(LL, "idfile"));

  columns.push(ComponentCommonTools.columns_StandardColumn(LL, "name"));
  columns.push(ComponentCommonTools.columns_ConstantColumn(LL));
  columns.push(
    ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria")
  );

  columns.push(
    ComponentCommonTools.columns_StandardColumn(
      LL,
      "isdefault",
      undefined,
      true,
      true,
      "boolean"
    )
  );
  columns.push(
    ComponentCommonTools.columns_ActionsColumn(
      LL,
      mainObject,
      deleteUser,
      ["editspecial", "detailspecial", "delete"],
      ["detailspecial"],
      undefined,
      'gallery',
    )
  );

  //  LL: any,
  //   field: string,
  //   width?: any,
  //   sortable?: boolean,
  //   hideable?: boolean,
  //   type?: string

  // idfile?: string;
  // idparent?: string;
  // isdefault?: boolean;
  // idlanguage?: string;
  // language?: any;
  // status_name?: string;
  // idgallery?: string;
  // name?: string;
  // constructor() {
  //     this.status = Status.ACTIVE;
  //     this.ordercriteria = 0;
  // }

  const onRowClickCb = (row: any) => {
    ComponentCommonTools.goToDetailsSpecial(row, mainObject, "gallery");
  };

  const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
      data,
      setData,
      setRows,
      setTotal,
      setTotalPage,
      setResponseParams
    );
  };

  const getList = () => {
    if (!reqList) return;
    if (!reqList.filters) return;
    if (!reqList.filters.length) return;

    setLoading(true);
    const urlPart = RouteTools.prepareListLocation(reqList);

    RouteTools.setHistory(mainUrl + urlPart, {});
    // setListUrl(mainUrl + urlPart);
    galleryService.getList(loadObjects, {}, reqList);
  };

  const getReqList = () => {
    if (!parentType) return;
    if (!parentId) return;

    let reqList = RouteTools.prepareListRequest(currentRoute, cookies);

    const t = new RequestFilterDTO();
    t.field = "idparent";
    t.values = [parentId];

    reqList.filters = reqList.filters ?? [];

    // --------
    let exist = false;
    for (const v of reqList.filters) {
      if (v.field != "idparent") continue;

      exist = true;
      break;
    }
    if (!exist) reqList.filters?.push(t);
    // --------

    reqList = galleryService.getDefaultRequestList(reqList);

    setReqList(reqList);
  };

  const checkIsLoading = () => {
    const load: boolean = CommonTools.checkIsLoading(
      reqList,
      responseParams,
      data,
      total,
      totalPage,
      rows
    );
    setLoading(load);
  };

  const processTbFilterList = () => {
    const _tobj = new TbFilterListDTO();

    var t: TbFilterDTO;

    // t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    // _tobj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    _tobj.addFilter(t);

    setTbFilterList(_tobj);
  };

  const processParentData = () => {
    logger("processParentDataprocessParentDataprocessParentData", currentRoute);
    if (currentRoute == undefined) return;
    if (currentRoute._paths == undefined) return;

    const path = currentRoute._paths;

    if (path.length < 3) return;

    setParentType(path[0]);
    setParentId(path[1]);
  };

  useEffect(() => {
    processTbFilterList();
  }, []);

  useEffect(() => {
    getReqList();
  }, [currentRoute, parentType, parentId]);

  useEffect(() => {
    processParentData();
  }, [currentRoute, mainObject]);

  useEffect(() => {
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
      getList();
    }
  }, [reqList]);

  useEffect(() => {
    checkIsLoading();
  }, [data, rows, reqList, total, totalPage]);

  // const toggleEdit = (obj: any) => {
  //   logger("toggleAdmin", obj);
  //   ComponentCommonTools.goToEdit(obj, mainObject);
  // };

  // const toggleDetail = (obj: any) => {
  //   ComponentCommonTools.goToDetails(obj, mainObject);
  // };

  const handleDelete = (item: any) => {
    logger("DeletelabelDeletelabelDeletelabel", item);

    galleryService.delete(item.id, getList, undefined);
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: "/" + parentType,
      label: LL("crumb_" + parentType),
    },
    {
      label: LL("crumb_gallery"),
    },
  ];
  return !loading || 1 ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={1}
          href={parentUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={parentUrl}
        />

        <SpecialTopInfo
          parentType={parentType}
          parentId={parentId}
          specialtype={"gallery"}
          variant="h4"
          sx={{ my: 3 }}
        />

        <MyNavBt
          href={addUrl}
          children={LL("bt_Add")}
          className="addButton"
          size="small"
          _hstate={{}}
          _mainurl={mainUrl}
        />
      </Box>
      <MyTable
        _columns={columns}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        _tbFilterList={tbFilterList}
        setReqList={setReqList}
        loading={loading}
        currentRoute={currentRoute}
        onDelete={handleDelete}
        mainObject={mainObject}
        fieldToShowOnDelete="name"
        deleteOneObject={deleteOneObject}
        setDeleteOneObject={setDeleteOneObject}
        onRowClickCb={onRowClickCb}
        {...props}
      />
    </>
  ) : (
    <Loading />
  );
};

export default SpecialGallery;
