import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { TicketMessageDto } from 'dto/support/ticketmessage.dto'
import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import { Box, Stack, Divider, ListItemButton, ListItemText, Link } from "@mui/material";

import { logger } from "hoc/logger";


type TicketMessageItemTeacherProps = {
    obj: TicketMessageDto;
}

const TicketMessageItemTeacher: React.FC<TicketMessageItemTeacherProps> = ({ obj, ...props }) => {

    const { LL } = useResource();


    const processItem = () => {
        if (!obj) return <></>
        if (!obj.hasOwnProperty('id')) return <></>
        if (!obj.id) return <></>

        return (
            <Box>
                <Box>
                    <Stack direction="row" spacing={2}>
                        <Typography >
                            {LL('Teacher')}
                        </Typography>
                        <Typography >
                            {ComponentCommonTools.processObjectFieldOfObject(obj, 'teacher', 'email')}
                        </Typography>
                        <Typography >
                            {ComponentCommonTools.processObjectField(obj, 'date_name')}
                        </Typography>
                    </Stack>
                </Box>
                <Box>
                    <Typography >
                        {ComponentCommonTools.processObjectField(obj, 'message')}
                    </Typography>
                </Box>
            </Box>
        )
    }



    return processItem();
};

export { TicketMessageItemTeacher };
