import React, { useState, useEffect } from 'react';

import TypeHardwareService from "services/nomenclature/typehardware.service";
import GenListType from 'components/elements/MyGenComponents/GenListType';
import { PopulateTypeDto } from 'dto/nomenclature/populatetype.dto';


const typeHardwareService = new TypeHardwareService();


interface TypeHardwareProps {
    children: any;
    currentRoute: any;
    mainObject: any;
}

const TypeHardware: React.FC<TypeHardwareProps> = ({
    children,
    currentRoute,
    mainObject,
    ...props
}) => {

    return (
        <GenListType
            children={children}
            currentRoute={currentRoute}
            service={typeHardwareService}
            mainObject={mainObject}
            DTO={PopulateTypeDto}
            {...props}
        />
    )
};

export default TypeHardware;
