import React, { useState, useEffect } from 'react';

import TypeTargetGroupService from "services/nomenclature/typetargetgroup.service";
import GenDetailType from 'components/elements/MyGenComponents/GenDetailType';


const typeTargetGroupService = new TypeTargetGroupService();


interface DetailTypeTargetGroupProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const DetailTypeTargetGroup: React.FC<DetailTypeTargetGroupProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenDetailType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeTargetGroupService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default DetailTypeTargetGroup;
