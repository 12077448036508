import React from "react";

import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { logger } from "hoc/logger";

import { useReactPath } from "hook/useReactPath";

import { RouteTools } from "tools/routetools";

import MD5 from "crypto-js/md5";

import { useAuth } from "hook/useAuth";

import { v4 as uuidv4 } from "uuid";
import { useResource } from "hook/useResource";
import HomePage from "./HomePage";
import { Login } from "components/auth/Login";
import { NotFound } from "./NotFound";
import { Forbidden } from "./Forbidden";
// import { Test } from "./Test";

import User from "components/user/User";
import DetailUser from "components/user/DetailUser";
import AddUser from "components/user/AddUser";
import EditUser from "components/user/EditUser";

import Student from "components/student/Student";
import DetailStudent from "components/student/DetailStudent";
import AddStudent from "components/student/AddStudent";
import EditStudent from "components/student/EditStudent";

import Teacher from "components/teacher/Teacher";
import DetailTeacher from "components/teacher/DetailTeacher";
import AddTeacher from "components/teacher/AddTeacher";
import EditTeacher from "components/teacher/EditTeacher";

import TeacherFAQ from "components/teacher/TeacherFAQ";
import DetailTeacherFAQ from "components/teacher/DetailTeacherFAQ";
import AddTeacherFAQ from "components/teacher/AddTeacherFAQ";
import EditTeacherFAQ from "components/teacher/EditTeacherFAQ";

import Testimonial from "components/testimonial/Testimonial";
import DetailTestimonial from "components/testimonial/DetailTestimonial";
import AddTestimonial from "components/testimonial/AddTestimonial";
import EditTestimonial from "components/testimonial/EditTestimonial";

import Role from "components/role/Role";
import DetailRole from "components/role/DetailRole";
import AddRole from "components/role/AddRole";
import EditRole from "components/role/EditRole";

import Permission from "components/permission/Permission";
import EditPermission from "components/permission/EditPermission";

import Notification from "components/notification/Notification";
import DetailNotification from "components/notification/DetailNotification";

import TeacherRequest from "components/teacherrequest/TeacherRequest";
import DetailTeacherRequest from "components/teacherrequest/DetailTeacherRequest";
import EditTeacherRequest from "components/teacherrequest/EditTeacherRequest";

import File from "components/file/File";
import DetailFile from "components/file/DetailFile";

import Gallery from "components/gallery/Gallery";
import DetailGallery from "components/gallery/DetailGallery";
import AddGallery from "components/gallery/AddGallery";
import EditGallery from "components/gallery/EditGallery";

import Menu from "components/menu/Menu";
import DetailMenu from "components/menu/DetailMenu";
import AddMenu from "components/menu/AddMenu";
import EditMenu from "components/menu/EditMenu";

import FAQ from "components/faq/FAQ";
import DetailFAQ from "components/faq/DetailFAQ";
import AddFAQ from "components/faq/AddFAQ";
import EditFAQ from "components/faq/EditFAQ";

import Advertisement from "components/advertisement/Advertisement";
import DetailAdvertisement from "components/advertisement/DetailAdvertisement";
import AddAdvertisement from "components/advertisement/AddAdvertisement";
import EditAdvertisement from "components/advertisement/EditAdvertisement";

import PricePolicy from "components/finance/pricepolicy/PricePolicy";
import DetailPricePolicy from "components/finance/pricepolicy/DetailPricePolicy";
import AddPricePolicy from "components/finance/pricepolicy/AddPricePolicy";
import EditPricePolicy from "components/finance/pricepolicy/EditPricePolicy";

import Filter from "components/course/filter/Filter";
import DetailFilter from "components/course/filter/DetailFilter";
import AddFilter from "components/course/filter/AddFilter";
import EditFilter from "components/course/filter/EditFilter";

import FilterDictionary from "components/course/filterdictionary/FilterDictionary";
import DetailFilterDictionary from "components/course/filterdictionary/DetailFilterDictionary";
import AddFilterDictionary from "components/course/filterdictionary/AddFilterDictionary";
import EditFilterDictionary from "components/course/filterdictionary/EditFilterDictionary";

import CourseCategory from "components/course/coursecategory/CourseCategory";
import DetailCourseCategory from "components/course/coursecategory/DetailCourseCategory";
import AddCourseCategory from "components/course/coursecategory/AddCourseCategory";
import EditCourseCategory from "components/course/coursecategory/EditCourseCategory";

import Course from "components/course/course/Course";
import DetailCourse from "components/course/course/DetailCourse";
import AddCourse from "components/course/course/AddCourse";
import EditCourse from "components/course/course/EditCourse";

import CourseTeacherRequest from "components/course/courseteacherrequest/CourseTeacherRequest";
import DetailCourseTeacherRequest from "components/course/courseteacherrequest/DetailCourseTeacherRequest";
import EditCourseTeacherRequest from "components/course/courseteacherrequest/EditCourseTeacherRequest";

import DetailLesson from "components/course/course/components/lesson/DetailLesson";
import EditLesson from "components/course/course/components/lesson/EditLesson";

import DetailCourseEnrollment from "components/course/course/components/courseenrollment/DetailCourseEnrollment";
import EditCourseEnrollment from "components/course/course/components/courseenrollment/EditCourseEnrollment";

import DetailQuestion from "components/course/test/components/question/DetailQuestion";
import EditQuestion from "components/course/test/components/question/EditQuestion";

import DetailAnswer from "components/course/test/components/answer/DetailAnswer";
import EditAnswer from "components/course/test/components/answer/EditAnswer";

import Transaction from "components/finance/transaction/Transaction";

import CurrencyRate from "components/finance/currencyrate/CurrencyRate";
// import DetailCurrencyRate from "components/finance/currencyrate/DetailCurrencyRate";
// import AddCurrencyRate from "components/finance/currencyrate/AddCurrencyRate";
// import EditCurrencyRate from "components/finance/currencyrate/EditCurrencyRate";

import RecommendationPrice from "components/finance/recommendationprice/RecommendationPrice";
import DetailRecommendationPrice from "components/finance/recommendationprice/DetailRecommendationPrice";
import AddRecommendationPrice from "components/finance/recommendationprice/AddRecommendationPrice";
import EditRecommendationPrice from "components/finance/recommendationprice/EditRecommendationPrice";

import PricePolicyLinkTeacher from "components/finance/pricepolicylinkteacher/PricePolicyLinkTeacher";
import DetailPricePolicyLinkTeacher from "components/finance/pricepolicylinkteacher/DetailPricePolicyLinkTeacher";
import AddPricePolicyLinkTeacher from "components/finance/pricepolicylinkteacher/AddPricePolicyLinkTeacher";
import EditPricePolicyLinkTeacher from "components/finance/pricepolicylinkteacher/EditPricePolicyLinkTeacher";

import Currency from "components/finance/currency/Currency";
import DetailCurrency from "components/finance/currency/DetailCurrency";
import AddCurrency from "components/finance/currency/AddCurrency";
import EditCurrency from "components/finance/currency/EditCurrency";

import Blog from "components/blog/Blog";
import DetailBlog from "components/blog/DetailBlog";
import AddBlog from "components/blog/AddBlog";
import EditBlog from "components/blog/EditBlog";

import SupportArticle from "components/supportarticle/SupportArticle";
import DetailSupportArticle from "components/supportarticle/DetailSupportArticle";
import AddSupportArticle from "components/supportarticle/AddSupportArticle";
import EditSupportArticle from "components/supportarticle/EditSupportArticle";

import SiteSocial from "components/sitesocial/SiteSocial";
import DetailSiteSocial from "components/sitesocial/DetailSiteSocial";
import AddSiteSocial from "components/sitesocial/AddSiteSocial";
import EditSiteSocial from "components/sitesocial/EditSiteSocial";

import Agreement from "components/legal/Agreement";
import DetailAgreement from "components/legal/DetailAgreement";
import AddAgreement from "components/legal/AddAgreement";
import EditAgreement from "components/legal/EditAgreement";

import BlogCategory from "components/blog/BlogCategory";
import DetailBlogCategory from "components/blog/DetailBlogCategory";
import AddBlogCategory from "components/blog/AddBlogCategory";
import EditBlogCategory from "components/blog/EditBlogCategory";

import SupportArticleCategory from "components/supportarticle/SupportArticleCategory";
import DetailSupportArticleCategory from "components/supportarticle/DetailSupportArticleCategory";
import AddSupportArticleCategory from "components/supportarticle/AddSupportArticleCategory";
import EditSupportArticleCategory from "components/supportarticle/EditSupportArticleCategory";

import Page from "components/page/Page";
import DetailPage from "components/page/DetailPage";
import AddPage from "components/page/AddPage";
import EditPage from "components/page/EditPage";

import Attachment from "components/attachment/Attachment";
import DetailAttachment from "components/attachment/DetailAttachment";
import AddAttachment from "components/attachment/AddAttachment";
import EditAttachment from "components/attachment/EditAttachment";

import Video from "components/video/Video";
import DetailVideo from "components/video/DetailVideo";
import AddVideo from "components/video/AddVideo";
import EditVideo from "components/video/EditVideo";

import Schedule from "components/course/schedule/Schedule";
import DetailSchedule from "components/course/schedule/DetailSchedule";
import AddSchedule from "components/course/schedule/AddSchedule";
import EditSchedule from "components/course/schedule/EditSchedule";

import Test from "components/course/test/Test";
import DetailTest from "components/course/test/DetailTest";
import AddTest from "components/course/test/AddTest";
import EditTest from "components/course/test/EditTest";

import Language from "components/language/Language";
import AddLanguage from "components/language/AddLanguage";
import DetailLanguage from "components/language/DetailLanguage";
import EditLanguage from "components/language/EditLanguage";

import SiteConfig from "components/siteconfig/SiteConfig";
import AddSiteConfig from "components/siteconfig/AddSiteConfig";
import DetailSiteConfig from "components/siteconfig/DetailSiteConfig";
import EditSiteConfig from "components/siteconfig/EditSiteConfig";

import SiteConfigFile from "components/siteconfig/SiteConfigFile";
import AddSiteConfigFile from "components/siteconfig/AddSiteConfigFile";
import DetailSiteConfigFile from "components/siteconfig/DetailSiteConfigFile";
import EditSiteConfigFile from "components/siteconfig/EditSiteConfigFile";

import Label from "components/label/Label";
import DetailLabel from "components/label/DetailLabel";
import EditLabel from "components/label/EditLabel";

import Journal from "components/journal/Journal";
import DetailJournal from "components/journal/DetailJournal";

import Country from "components/nomenclature/country/Country";
import AddCountry from "components/nomenclature/country/AddCountry";
import DetailCountry from "components/nomenclature/country/DetailCountry";
import EditCountry from "components/nomenclature/country/EditCountry";

import TypeAgreement from "components/nomenclature/typeagreement/TypeAgreement";
import AddTypeAgreement from "components/nomenclature/typeagreement/AddTypeAgreement";
import DetailTypeAgreement from "components/nomenclature/typeagreement/DetailTypeAgreement";
import EditTypeAgreement from "components/nomenclature/typeagreement/EditTypeAgreement";

import TypeBlogCategory from "components/nomenclature/typeblogcategory/TypeBlogCategory";
import AddTypeBlogCategory from "components/nomenclature/typeblogcategory/AddTypeBlogCategory";
import DetailTypeBlogCategory from "components/nomenclature/typeblogcategory/DetailTypeBlogCategory";
import EditTypeBlogCategory from "components/nomenclature/typeblogcategory/EditTypeBlogCategory";

import TicketCategory from "components/support/ticketcategory/TicketCategory";
import AddTicketCategory from "components/support/ticketcategory/AddTicketCategory";
import DetailTicketCategory from "components/support/ticketcategory/DetailTicketCategory";
import EditTicketCategory from "components/support/ticketcategory/EditTicketCategory";

import Ticket from "components/support/ticket/Ticket";
import DetailTicket from "components/support/ticket/DetailTicket";

import Locality from "components/address/Locality";
import AddLocality from "components/address/AddLocality";
import DetailLocality from "components/address/DetailLocality";
import EditLocality from "components/address/EditLocality";

import TypeCourse from "components/nomenclature/typecourse/TypeCourse";
import AddTypeCourse from "components/nomenclature/typecourse/AddTypeCourse";
import DetailTypeCourse from "components/nomenclature/typecourse/DetailTypeCourse";
import EditTypeCourse from "components/nomenclature/typecourse/EditTypeCourse";

import TypeDiscipline from "components/nomenclature/typediscipline/TypeDiscipline";
import AddTypeDiscipline from "components/nomenclature/typediscipline/AddTypeDiscipline";
import DetailTypeDiscipline from "components/nomenclature/typediscipline/DetailTypeDiscipline";
import EditTypeDiscipline from "components/nomenclature/typediscipline/EditTypeDiscipline";

import TypeExperience from "components/nomenclature/typeexperience/TypeExperience";
import AddTypeExperience from "components/nomenclature/typeexperience/AddTypeExperience";
import DetailTypeExperience from "components/nomenclature/typeexperience/DetailTypeExperience";
import EditTypeExperience from "components/nomenclature/typeexperience/EditTypeExperience";

import TypeFilter from "components/nomenclature/typefilter/TypeFilter";
import AddTypeFilter from "components/nomenclature/typefilter/AddTypeFilter";
import DetailTypeFilter from "components/nomenclature/typefilter/DetailTypeFilter";
import EditTypeFilter from "components/nomenclature/typefilter/EditTypeFilter";

import TypeHardware from "components/nomenclature/typehardware/TypeHardware";
import AddTypeHardware from "components/nomenclature/typehardware/AddTypeHardware";
import DetailTypeHardware from "components/nomenclature/typehardware/DetailTypeHardware";
import EditTypeHardware from "components/nomenclature/typehardware/EditTypeHardware";

import TypeInternetConnection from "components/nomenclature/typeinternetconnection/TypeInternetConnection";
import AddTypeInternetConnection from "components/nomenclature/typeinternetconnection/AddTypeInternetConnection";
import DetailTypeInternetConnection from "components/nomenclature/typeinternetconnection/DetailTypeInternetConnection";
import EditTypeInternetConnection from "components/nomenclature/typeinternetconnection/EditTypeInternetConnection";

import TypeLesson from "components/nomenclature/typelesson/TypeLesson";
import AddTypeLesson from "components/nomenclature/typelesson/AddTypeLesson";
import DetailTypeLesson from "components/nomenclature/typelesson/DetailTypeLesson";
import EditTypeLesson from "components/nomenclature/typelesson/EditTypeLesson";

import TypeLocality from "components/nomenclature/typelocality/TypeLocality";
import AddTypeLocality from "components/nomenclature/typelocality/AddTypeLocality";
import DetailTypeLocality from "components/nomenclature/typelocality/DetailTypeLocality";
import EditTypeLocality from "components/nomenclature/typelocality/EditTypeLocality";

import TypeOccupation from "components/nomenclature/typeoccupation/TypeOccupation";
import AddTypeOccupation from "components/nomenclature/typeoccupation/AddTypeOccupation";
import DetailTypeOccupation from "components/nomenclature/typeoccupation/DetailTypeOccupation";
import EditTypeOccupation from "components/nomenclature/typeoccupation/EditTypeOccupation";

import TypePhone from "components/nomenclature/typephone/TypePhone";
import AddTypePhone from "components/nomenclature/typephone/AddTypePhone";
import DetailTypePhone from "components/nomenclature/typephone/DetailTypePhone";
import EditTypePhone from "components/nomenclature/typephone/EditTypePhone";

import TypePrice from "components/nomenclature/typeprice/TypePrice";
import AddTypePrice from "components/nomenclature/typeprice/AddTypePrice";
import DetailTypePrice from "components/nomenclature/typeprice/DetailTypePrice";
import EditTypePrice from "components/nomenclature/typeprice/EditTypePrice";

import TypeProfessionalismLevel from "components/nomenclature/typeprofessionalismlevel/TypeProfessionalismLevel";
import AddTypeProfessionalismLevel from "components/nomenclature/typeprofessionalismlevel/AddTypeProfessionalismLevel";
import DetailTypeProfessionalismLevel from "components/nomenclature/typeprofessionalismlevel/DetailTypeProfessionalismLevel";
import EditTypeProfessionalismLevel from "components/nomenclature/typeprofessionalismlevel/EditTypeProfessionalismLevel";

import TypeSocial from "components/nomenclature/typesocial/TypeSocial";
import AddTypeSocial from "components/nomenclature/typesocial/AddTypeSocial";
import DetailTypeSocial from "components/nomenclature/typesocial/DetailTypeSocial";
import EditTypeSocial from "components/nomenclature/typesocial/EditTypeSocial";

import TypeStudies from "components/nomenclature/typestudies/TypeStudies";
import AddTypeStudies from "components/nomenclature/typestudies/AddTypeStudies";
import DetailTypeStudies from "components/nomenclature/typestudies/DetailTypeStudies";
import EditTypeStudies from "components/nomenclature/typestudies/EditTypeStudies";

import TypeSpecialty from "components/nomenclature/typespecialty/TypeSpecialty";
import AddTypeSpecialty from "components/nomenclature/typespecialty/AddTypeSpecialty";
import DetailTypeSpecialty from "components/nomenclature/typespecialty/DetailTypeSpecialty";
import EditTypeSpecialty from "components/nomenclature/typespecialty/EditTypeSpecialty";

import TypeTargetGroup from "components/nomenclature/typetargetgroup/TypeTargetGroup";
import AddTypeTargetGroup from "components/nomenclature/typetargetgroup/AddTypeTargetGroup";
import DetailTypeTargetGroup from "components/nomenclature/typetargetgroup/DetailTypeTargetGroup";
import EditTypeTargetGroup from "components/nomenclature/typetargetgroup/EditTypeTargetGroup";

import TypeGender from "components/nomenclature/typegender/TypeGender";
import AddTypeGender from "components/nomenclature/typegender/AddTypeGender";
import DetailTypeGender from "components/nomenclature/typegender/DetailTypeGender";
import EditTypeGender from "components/nomenclature/typegender/EditTypeGender";

import SpecialGallery from "components/special/SpecialGallery";
import SpecialGalleryAdd from "components/special/SpecialGalleryAdd";
import SpecialGalleryEdit from "components/special/SpecialGalleryEdit";
import SpecialGalleryDetail from "components/special/SpecialGalleryDetail";

import SpecialAttachment from "components/special/SpecialAttachment";
import SpecialAttachmentAdd from "components/special/SpecialAttachmentAdd";
import SpecialAttachmentEdit from "components/special/SpecialAttachmentEdit";
import SpecialAttachmentDetail from "components/special/SpecialAttachmentDetail";

import SpecialVideo from "components/special/SpecialVideo";
import SpecialVideoAdd from "components/special/SpecialVideoAdd";
import SpecialVideoEdit from "components/special/SpecialVideoEdit";
import SpecialVideoDetail from "components/special/SpecialVideoDetail";

const MainLayout = ({ children, ...props }) => {
  const components = {
    SpecialGallery,
    SpecialGalleryAdd,
    SpecialGalleryEdit,
    SpecialGalleryDetail,

    SpecialAttachment,
    SpecialAttachmentAdd,
    SpecialAttachmentEdit,
    SpecialAttachmentDetail,

    SpecialVideo,
    SpecialVideoAdd,
    SpecialVideoEdit,
    SpecialVideoDetail,

    User,
    DetailUser,
    AddUser,
    EditUser,

    Student,
    DetailStudent,
    AddStudent,
    EditStudent,

    Teacher,
    DetailTeacher,
    AddTeacher,
    EditTeacher,

    TeacherFAQ,
    DetailTeacherFAQ,
    AddTeacherFAQ,
    EditTeacherFAQ,

    Testimonial,
    DetailTestimonial,
    AddTestimonial,
    EditTestimonial,

    Role,
    DetailRole,
    AddRole,
    EditRole,

    Permission,
    EditPermission,

    Notification,
    DetailNotification,

    TeacherRequest,
    DetailTeacherRequest,
    EditTeacherRequest,

    File,
    DetailFile,

    Gallery,
    DetailGallery,
    AddGallery,
    EditGallery,

    Menu,
    DetailMenu,
    AddMenu,
    EditMenu,

    FAQ,
    DetailFAQ,
    AddFAQ,
    EditFAQ,

    Advertisement,
    DetailAdvertisement,
    AddAdvertisement,
    EditAdvertisement,

    PricePolicy,
    DetailPricePolicy,
    AddPricePolicy,
    EditPricePolicy,

    CurrencyRate,
    Transaction,

    RecommendationPrice,
    DetailRecommendationPrice,
    AddRecommendationPrice,
    EditRecommendationPrice,

    PricePolicyLinkTeacher,
    DetailPricePolicyLinkTeacher,
    AddPricePolicyLinkTeacher,
    EditPricePolicyLinkTeacher,


    Currency,
    DetailCurrency,
    AddCurrency,
    EditCurrency,

    Blog,
    DetailBlog,
    AddBlog,
    EditBlog,

    SupportArticle,
    DetailSupportArticle,
    AddSupportArticle,
    EditSupportArticle,

    SiteSocial,
    DetailSiteSocial,
    AddSiteSocial,
    EditSiteSocial,

    Agreement,
    DetailAgreement,
    AddAgreement,
    EditAgreement,

    BlogCategory,
    DetailBlogCategory,
    AddBlogCategory,
    EditBlogCategory,

    SupportArticleCategory,
    DetailSupportArticleCategory,
    AddSupportArticleCategory,
    EditSupportArticleCategory,

    TicketCategory,
    DetailTicketCategory,
    AddTicketCategory,
    EditTicketCategory,

    Ticket,
    DetailTicket,

    Page,
    DetailPage,
    AddPage,
    EditPage,

    Attachment,
    DetailAttachment,
    AddAttachment,
    EditAttachment,

    Video,
    DetailVideo,
    AddVideo,
    EditVideo,

    Schedule,
    DetailSchedule,
    AddSchedule,
    EditSchedule,

    Test,
    DetailTest,
    AddTest,
    EditTest,

    Language,
    AddLanguage,
    DetailLanguage,
    EditLanguage,

    SiteConfig,
    AddSiteConfig,
    DetailSiteConfig,
    EditSiteConfig,

    SiteConfigFile,
    AddSiteConfigFile,
    DetailSiteConfigFile,
    EditSiteConfigFile,

    Label,
    DetailLabel,
    EditLabel,

    Journal,
    DetailJournal,

    Country,
    AddCountry,
    DetailCountry,
    EditCountry,

    TypeAgreement,
    AddTypeAgreement,
    DetailTypeAgreement,
    EditTypeAgreement,

    TypeBlogCategory,
    AddTypeBlogCategory,
    DetailTypeBlogCategory,
    EditTypeBlogCategory,

    Locality,
    AddLocality,
    DetailLocality,
    EditLocality,

    TypeCourse,
    AddTypeCourse,
    DetailTypeCourse,
    EditTypeCourse,

    TypeDiscipline,
    AddTypeDiscipline,
    DetailTypeDiscipline,
    EditTypeDiscipline,

    TypeExperience,
    AddTypeExperience,
    DetailTypeExperience,
    EditTypeExperience,

    TypeFilter,
    AddTypeFilter,
    DetailTypeFilter,
    EditTypeFilter,

    TypeHardware,
    AddTypeHardware,
    DetailTypeHardware,
    EditTypeHardware,

    TypeInternetConnection,
    AddTypeInternetConnection,
    DetailTypeInternetConnection,
    EditTypeInternetConnection,

    TypeLesson,
    AddTypeLesson,
    DetailTypeLesson,
    EditTypeLesson,

    TypeLocality,
    AddTypeLocality,
    DetailTypeLocality,
    EditTypeLocality,

    TypeOccupation,
    AddTypeOccupation,
    DetailTypeOccupation,
    EditTypeOccupation,

    TypePhone,
    AddTypePhone,
    DetailTypePhone,
    EditTypePhone,

    TypePrice,
    AddTypePrice,
    DetailTypePrice,
    EditTypePrice,

    TypeProfessionalismLevel,
    AddTypeProfessionalismLevel,
    DetailTypeProfessionalismLevel,
    EditTypeProfessionalismLevel,

    TypeSocial,
    AddTypeSocial,
    DetailTypeSocial,
    EditTypeSocial,

    TypeStudies,
    AddTypeStudies,
    DetailTypeStudies,
    EditTypeStudies,

    TypeSpecialty,
    AddTypeSpecialty,
    DetailTypeSpecialty,
    EditTypeSpecialty,

    TypeTargetGroup,
    AddTypeTargetGroup,
    DetailTypeTargetGroup,
    EditTypeTargetGroup,

    TypeGender,
    AddTypeGender,
    DetailTypeGender,
    EditTypeGender,

    Filter,
    DetailFilter,
    AddFilter,
    EditFilter,

    FilterDictionary,
    DetailFilterDictionary,
    AddFilterDictionary,
    EditFilterDictionary,

    CourseCategory,
    DetailCourseCategory,
    AddCourseCategory,
    EditCourseCategory,

    Course,
    DetailCourse,
    AddCourse,
    EditCourse,

    CourseTeacherRequest,
    DetailCourseTeacherRequest,
    EditCourseTeacherRequest,

    DetailLesson,
    EditLesson,

    DetailCourseEnrollment,
    EditCourseEnrollment,

    DetailQuestion,
    EditQuestion,

    DetailAnswer,
    EditAnswer,
  };

  const { currentRoute, setCurrentRoute, user } = useResource();
  const location = useLocation();
  const pathObject = useReactPath();

  const [_currentpath, setCurrentPath] = React.useState("");
  const [_mainobject, setMainObject] = React.useState(false);

  useEffect(() => {
    var h = MD5(pathObject.path).toString();
    var hr = MD5(pathObject.path).toString();

    var routeobj = RouteTools.parseLocation(location);
    routeobj.historystate = pathObject.historystate;

    if (routeobj.url == _currentpath) return;
    setCurrentPath(routeobj.url);

    setCurrentRoute(routeobj);
  }, [pathObject]);

  useEffect(() => {
    if (currentRoute && currentRoute._mainobject) {
      setMainObject(currentRoute._mainobject);
    }
  }, [currentRoute]);

  // ----------------------------------

  const processStandardModule_GetSpecial = (prefix) => {
    if (!currentRoute) return "";
    const path = currentRoute._paths;

    let specprefix = "";

    if (path.length === 3) {
      //
    } else if (path[3] === "add") {
      specprefix = "Add";
    } else if (path[3] === "edit") {
      specprefix = "Edit";
    } else {
      specprefix = "Detail";
    }

    if (path[2] === "gallery") {
      return "SpecialGallery" + specprefix;
    }

    if (path[2] === "attachment") {
      return "SpecialAttachment" + specprefix;
    }

    if (path[2] === "video") {
      return "SpecialVideo" + specprefix;
    }
  };

  const processStandardModule_GetPrefix = () => {
    if (!currentRoute) return "";
    const path = currentRoute._paths;
    if (path.length === 1) return "";
    if (path[1] === "edit") return "Edit";
    if (path[1] === "add") return "Add";

    if (path[2] != undefined && path[2] === "gallery") return "Special";
    if (path[2] != undefined && path[2] === "attachment") return "Special";
    if (path[2] != undefined && path[2] === "video") return "Special";

    return "Detail";
  };

  const processStandardModule_GetComponentName = () => {
    if (_mainobject == "user") return "User";
    if (_mainobject == "student") return "Student";
    if (_mainobject == "teacher") return "Teacher";
    if (_mainobject == "teacherfaq") return "TeacherFAQ";
    if (_mainobject == "testimonial") return "Testimonial";
    if (_mainobject == "role") return "Role";
    if (_mainobject == "permission") return "Permission";
    if (_mainobject == "notification") return "Notification";
    if (_mainobject == "teacherrequest") return "TeacherRequest";
    if (_mainobject == "file") return "File";
    if (_mainobject == "gallery") return "Gallery";
    if (_mainobject == "menu") return "Menu";
    if (_mainobject == "faq") return "FAQ";
    if (_mainobject == "advertisement") return "Advertisement";
    if (_mainobject == "pricepolicy") return "PricePolicy";
    if (_mainobject == "currencyrate") return "CurrencyRate";
    if (_mainobject == "transaction") return "Transaction";
    if (_mainobject == "recommendationprice") return "RecommendationPrice";
    if (_mainobject == "pricepolicylinkteacher") return "PricePolicyLinkTeacher";
    if (_mainobject == "currency") return "Currency";
    if (_mainobject == "blog") return "Blog";
    if (_mainobject == "supportarticle") return "SupportArticle";
    if (_mainobject == "sitesocial") return "SiteSocial";
    if (_mainobject == "agreement") return "Agreement";
    if (_mainobject == "blogcategory") return "BlogCategory";
    if (_mainobject == "supportarticlecategory")
      return "SupportArticleCategory";
    if (_mainobject == "ticketcategory") return "TicketCategory";
    if (_mainobject == "ticket") return "Ticket";
    if (_mainobject == "page") return "Page";
    if (_mainobject == "attachment") return "Attachment";
    if (_mainobject == "video") return "Video";
    if (_mainobject == "schedule") return "Schedule";
    if (_mainobject == "test") return "Test";
    if (_mainobject == "language") return "Language";
    if (_mainobject == "siteconfig") return "SiteConfig";
    if (_mainobject == "siteconfigfile") return "SiteConfigFile";
    if (_mainobject == "journal") return "Journal";
    if (_mainobject == "label") return "Label";
    if (_mainobject == "country") return "Country";
    if (_mainobject == "typeagreement") return "TypeAgreement";
    if (_mainobject == "typeblogcategory") return "TypeBlogCategory";
    if (_mainobject == "locality") return "Locality";
    if (_mainobject == "typecourse") return "TypeCourse";
    if (_mainobject == "typediscipline") return "TypeDiscipline";
    if (_mainobject == "typeexperience") return "TypeExperience";
    if (_mainobject == "typefilter") return "TypeFilter";
    if (_mainobject == "typehardware") return "TypeHardware";
    if (_mainobject == "typelesson") return "TypeLesson";
    if (_mainobject == "typeinternetconnection")
      return "TypeInternetConnection";
    if (_mainobject == "typelocality") return "TypeLocality";
    if (_mainobject == "typeoccupation") return "TypeOccupation";
    if (_mainobject == "typephone") return "TypePhone";
    if (_mainobject == "typeprice") return "TypePrice";
    if (_mainobject == "typeprofessionalismlevel")
      return "TypeProfessionalismLevel";
    if (_mainobject == "typesocial") return "TypeSocial";
    if (_mainobject == "typestudies") return "TypeStudies";
    if (_mainobject == "typespecialty") return "TypeSpecialty";
    if (_mainobject == "typetargetgroup") return "TypeTargetGroup";
    if (_mainobject == "typegender") return "TypeGender";
    if (_mainobject == "filter") return "Filter";
    if (_mainobject == "filterdictionary") return "FilterDictionary";
    if (_mainobject == "coursecategory") return "CourseCategory";
    if (_mainobject == "course") return "Course";
    if (_mainobject == "courseteacherrequest") return "CourseTeacherRequest";
    if (_mainobject == "lesson") return "Lesson";
    if (_mainobject == "courseenrollment") return "CourseEnrollment";
    if (_mainobject == "question") return "Question";
    if (_mainobject == "answer") return "Answer";
    return "";
  };

  const processStandardModule = () => {
    if (!_mainobject) return false;

    if (RouteTools.modules.indexOf(_mainobject) == -1) return false;

    const prefix = processStandardModule_GetPrefix();
    const cname = processStandardModule_GetComponentName();

    if (prefix == "Special") {
      return processStandardModule_GetSpecial(prefix);
    }

    return prefix + cname;
  };

  const processMainObject = () => {
    if (!_mainobject) return <NotFound currentRoute={currentRoute} />;

    if (_mainobject == "homepage")
      return <HomePage currentRoute={currentRoute} />;
    if (_mainobject == "login") return <Login currentRoute={currentRoute} />;
    if (_mainobject == "forbidden")
      return <Forbidden currentRoute={currentRoute} />;
    if (_mainobject == "notfound")
      return <NotFound currentRoute={currentRoute} />;

    logger("processMainObjectprocessMainObject", currentRoute);

    const tag = processStandardModule();
    const DynamicComponent = components[tag] || null;
    if (DynamicComponent)
      return (
        <>
          {
            <DynamicComponent
              currentRoute={currentRoute}
              mainObject={_mainobject}
            />
          }
        </>
      );

    return <NotFound currentRoute={currentRoute} />;
  };

  return <>{processMainObject()}</>;
};

export { MainLayout };
