import React, { useState, useEffect } from 'react';

import TypeProfessionalismLevelService from "services/nomenclature/typeprofessionalismlevel.service";
import GenDetailType from 'components/elements/MyGenComponents/GenDetailType';


const typeProfessionalismLevelService = new TypeProfessionalismLevelService();


interface DetailTypeProfessionalismLevelProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const DetailTypeProfessionalismLevel: React.FC<DetailTypeProfessionalismLevelProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenDetailType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeProfessionalismLevelService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default DetailTypeProfessionalismLevel;
