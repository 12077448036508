import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { PostSupportArticleDto } from "dto/supportarticle/supportarticle.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import SupportArticleRepository from "repositories/supportarticle/supportarticle.repository";
import { Status } from "tools/status";

import GeneralService from "services/general.service";
export default class SupportArticleService
  extends GeneralService
  implements IService
{
  supportarticleRepository: SupportArticleRepository =
    new SupportArticleRepository();
  constructor() {
    super();
    this.handleGetList = this.handleGetList.bind(this);
    this.handleGet = this.handleGet.bind(this);
  }
  handleUpdate(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;

    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  async update(
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.supportarticleRepository.update(
      id,
      this.handleUpdate,
      this.prepareObjToUpdate(data),
      cbparameters
    );
  }

  handleDelete(result?: any, cbparameters?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters);
    }
  }

  async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    this.supportarticleRepository.delete(id, this.handleDelete, cbparameters);
  }

  handleGet(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;
    this.populateObject(rez.obj, result.obj, [
      { key: "status", type: "status" },
      { key: "type", type: "types" },
    ]);
    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

    return rez;
  }

  async get(
    id: string,
    cb?: any,
    cbparameters?: any,
    idLanguage?: string
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.supportarticleRepository.get(
      id,
      this.handleGet,
      cbparameters,
      idLanguage
    );
    if (cb == undefined) {
      return this.handleGet(t);
    }
  }

  handleGetList(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultListDTO();

    rez = result;
    if (result.err) return;

    this.parseResultPopulate(result, rez, [
      { key: "status", type: "status" },
      { key: "type", type: "types" },
    ]);

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

    return rez;
  }

  async getList(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.supportarticleRepository.getList(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb == undefined) {
      return this.handleGetList(t);
    }
  }

  handleAdd(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  prepareObjToAdd(obj: any): PostSupportArticleDto {
    const rez = new PostSupportArticleDto();

    if (obj.hasOwnProperty("idlanguage")) rez.idlanguage = obj.idlanguage;
    if (obj.hasOwnProperty("type")) rez.type = obj.type;
    if (obj.hasOwnProperty("status")) rez.status = obj.status;

    if (obj.hasOwnProperty("name")) rez.name = obj.name;
    if (obj.hasOwnProperty("url")) rez.url = obj.url;
    if (obj.hasOwnProperty("title")) rez.title = obj.title;
    if (obj.hasOwnProperty("keymeta")) rez.keymeta = obj.keymeta;
    if (obj.hasOwnProperty("descriptionmeta"))
      rez.descriptionmeta = obj.descriptionmeta;
    if (obj.hasOwnProperty("content")) rez.content = obj.content;
    if (obj.hasOwnProperty("idsupportarticlecategory"))
      rez.idsupportarticlecategory = obj.idsupportarticlecategory;
    if (obj.hasOwnProperty("ordercriteria"))
      rez.ordercriteria = obj.ordercriteria;
    if(obj.hasOwnProperty("shortdescription")) rez.shortdescription = obj.shortdescription;

    return rez;
  }

  prepareObjToUpdate(obj: any): PostSupportArticleDto {
    const rez = new PostSupportArticleDto();

    if (obj.hasOwnProperty("idlanguage")) rez.idlanguage = obj.idlanguage;
    if (obj.hasOwnProperty("type")) rez.type = obj.type;
    if (obj.hasOwnProperty("status")) rez.status = obj.status;

    if (obj.hasOwnProperty("name")) rez.name = obj.name;
    if (obj.hasOwnProperty("url")) rez.url = obj.url;
    if (obj.hasOwnProperty("title")) rez.title = obj.title;
    if (obj.hasOwnProperty("keymeta")) rez.keymeta = obj.keymeta;
    if (obj.hasOwnProperty("descriptionmeta"))
      rez.descriptionmeta = obj.descriptionmeta;
    if (obj.hasOwnProperty("content")) rez.content = obj.content;
    if (obj.hasOwnProperty("idsupportarticlecategory"))
      rez.idsupportarticlecategory = obj.idsupportarticlecategory;
    if (obj.hasOwnProperty("ordercriteria"))
      rez.ordercriteria = obj.ordercriteria;
    if(obj.hasOwnProperty("shortdescription")) rez.shortdescription = obj.shortdescription;

    return rez;
  }

  async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    this.supportarticleRepository.add(
      this.handleGetList,
      cbparameters,
      this.prepareObjToAdd(data)
    );
  }

  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = "name";
    defaultRequestList.sortcriteria = [];
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  }

  getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
    if (
      !obj.sortcriteria ||
      !Array.isArray(obj.sortcriteria) ||
      !obj.sortcriteria.length
    ) {
      var sobj = new RequestSortCriteriaDTO();
      sobj.asc = true;
      sobj.field = "name";

      obj.sortcriteria = [];
      obj.sortcriteria?.push(sobj);
    }

    return obj;
  }
}
