import Idto from "interfaces/idto.interface";
import { Status } from "tools/status";
export class PostLanguageDto implements Idto {

  name?: string;
  cod2?: string;
  cod3?: string;
  status?: number;
  ordercriteria?: number;
  hmtlcode?: string;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
}
}
