import Idto from "interfaces/idto.interface";
import TbFilterDTO from "./tbfilter.dto";

export default class TbFilterListDTO implements Idto {
  filters?: TbFilterDTO[];

  constructor() {
    this.filters = [];
  }

  addFilter(f: TbFilterDTO) {
    this.filters?.push(f);
  }
}
