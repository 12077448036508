import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class FilterDictionaryDto implements Idto {

    id?: string;
    idlanguage?: string;
    status?: number;
    ordercriteria?: number;
    name?: string;
    idfilter?: string;
    idfilterdictionary?: string;
    filter?:any;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
    }
}

export class PostFilterDictionaryDto implements Idto {

    idlanguage?: string;
    status?: number;
    ordercriteria?: number;
    name?: string;
    idfilter?: string;
    idfilterdictionary?: string;
    filter?:any;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
    }
}