import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { logger } from "hoc/logger";
import { useResource } from "hook/useResource";
import RequestFilterDTO from "dto/app/requestfilter.dto";

interface GenAutocompleteSearchProps {
    obj?: any;
    setObj: any;
    _idfield?: string;
    _objfield?: string;
    _mainObject?: any;
    _label?: string;
    _otherdata?: any;
    _showfield?: string;
    searchField?: string;
    _multiple?: boolean;
    _loadonempty?: boolean;
    service: any;
    loadDataSpecial?: any;
}

const GenAutocompleteSearch: React.FC<GenAutocompleteSearchProps> = ({
    obj,
    setObj,
    _idfield,
    _objfield,
    _mainObject,
    _label,
    _otherdata,
    _showfield,
    _multiple,
    _loadonempty,
    service,
    searchField,
    loadDataSpecial,
    ...props
}) => {
    const { LL } = useResource();


    // ...rest of the code
    let tobject = obj != undefined && _objfield !== undefined ?
        obj[_objfield] ? obj[_objfield] : null
        : null;
    let tvalue = obj != undefined && _idfield !== undefined ?
        obj[_idfield] ? obj[_idfield] : null
        : null;

    let tshowfield = _showfield != undefined && _showfield ?
        _showfield : "name";

    let _multipleattr = _multiple != undefined ? _multiple : false;

    const [loadonempty, setLoadOnEmpty] = useState<any>(_loadonempty);
    const [multiple, setMultiple] = useState<any>(_multipleattr);

    const [showfield, setShowField] = useState<string>(tshowfield);
    const [idfield, setIdField] = useState<any>(_idfield);
    const [objfield, setObjField] = useState<any>(_objfield);

    const [defaultValues, setDefaultValues] = useState<any>(tobject);
    const [objects, setObjects] = useState<any>(tobject);

    const [values, setValues] = useState<any>();
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [isloaded, setIsLoaded] = useState<boolean>(false);

    const [open, setOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<any>([{}]);

    const [label, setLabel] = useState<string>("");

    const [mainObject, setMainObject] = useState<any>(_mainObject);
    const onPage = 2000;

    const [reqList] = useState<any>(service.getDefaultRequestListSelect(onPage));
    // ...rest of the code

    useEffect(() => {
        if (obj == undefined) return;
        if (!obj) return;
        if (mainObject == undefined) return;
        if (!mainObject) return;

        var L =
            _label != undefined && _label
                ? _label
                : LL(mainObject);


        setLabel(L);
        setIdField(_idfield);
        setObjField(_objfield);
        setMainObject(_mainObject);

        logger("useEffectAutocompleteFilter sdfgsdgsdfgsdfgsdf", tobject)
    }, [
        options,
        obj,
        _label,
        _idfield,
        _objfield,
        _mainObject,
        _label
    ]);


    const loadData = () => {
        if ((search === undefined || search === null)) {
            setOptions([]);
            setLoading(false);
            return;
        }
        if (loadDataSpecial === undefined) {
            const tmp: RequestFilterDTO = new RequestFilterDTO()
            tmp.field = searchField;
            tmp.values = [];
            tmp.values.push(search);
            reqList.filters = [];
            reqList.filters.push(tmp);
        }
        else {
            loadDataSpecial(reqList, search);
        }
        getList();
    };

    const getList = () => {
        setLoading(true);
        service.getList(loadObjects, {}, reqList);
    };
    const loadObjects = (data: any) => {
        if(!data) setOptions([]);
        
        setOptions(data.objects);
        setLoading(false);
    };

    useEffect(() => {
        loadData();

    }, [obj, _idfield, _objfield, search]);

    useEffect(() => {
        logger("useEffectAutocomplete", objects);
        if (multiple) {
            var tarr = [];
            for (var i in objects) {
                tarr[tarr.length] = objects[i].id;
            }
            setValues(tarr);
        } else {
            if (objects !== null && objects !== undefined) {
                setValues(objects.id);
            } else {
                setValues([]);
            }
        }
    }, [objects]);

    useEffect(() => {
        if (isloaded) return;
        setDefaultValues(tobject);
        setObjects(tobject);
        setIsLoaded(true);
    }, [options]);


    useEffect(() => {
        if (!isloaded) return;
        if (!idfield) return;
        if (!objfield) return;
        if (!obj) return;
        if (!setObj) return;
        if (setObj == undefined) return;


        var _obj = obj;

        if (multiple) {
            var ids = [];
            for (var i in objects) {
                ids[ids.length] = objects[i].id;
            }

            _obj[idfield] = ids;
            _obj[objfield] = objects;
            setObj(_obj);
        } else {
            if (objects == null || objects == undefined) return;
            _obj[idfield] = objects.id;
            _obj[objfield] = objects;
        }

        setObj(_obj);
    }, [values]);

    const handleChange = (value: any) => {
        logger("handleChange", value);
        setObjects(value);
    };

    useEffect(() => {
        if (!open) {
            setSearch("");
        }
    }, [open]);


    return (

        <Autocomplete
            id="asynchronous-demo"
            multiple={multiple}
            fullWidth
            open={open}
            size='small'
            // defaultValue={defaultValues}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, value) => {
                logger("onChange", value);
                handleChange(value);
            }}
            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
            getOptionLabel={(option: any) => option[showfield] ? option[showfield] : ""}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    margin="normal"

                    variant="outlined"
                    color="primary"                   
                    {...params}
                    label={label}
                    onChange={(event) => {
                        setSearch(event.target.value);
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export { GenAutocompleteSearch };
