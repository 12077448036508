import React, { useState, useEffect } from 'react';

import TeacherRequestService from "services/teacher/teacherrequest.service";
import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import { TeacherRequestDto } from 'dto/teacher/teacherrequest.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MyCheckboxField from "components/form/mycheckboxfield";
import IconButton from '@mui/material/IconButton';
import { StudyTypeSelect } from 'components/elements/Select/StudyTypeSelect';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { Status } from 'tools/status';
import { useAuth } from 'hook/useAuth';
import { Stack } from '@mui/material';


const teacherrequestService = new TeacherRequestService();

interface EditTeacherRequestProps {
    children: any;
    currentRoute: any;
    _obj: TeacherRequestDto,
    mainObject: any;
}

const EditTeacherRequest: React.FC<EditTeacherRequestProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {

    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");
    const [addUrl, setAddUrl] = useState("");

    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const au = CommonTools.generateAddUrl(mainObject);
        setAddUrl(au);
        const lu = _getListUrl(mu);
        setListUrl(lu);
    }, [mainObject, currentRoute]);

    const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<TeacherRequestDto | undefined>()
    const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);

    const { user } = useAuth();

    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.managermessage = obj.managermessage !== undefined ? obj.managermessage : '';


        setObj(obj);
        validateField("managermessage", obj.managermessage);
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        return v;
    };

    const processValidators = () => {
        let v: any = {

            managermessage: [],

        };

        v.managermessage.push(new RequiredValidator(LL('Required field managermessage', LabelTypes.LABEL_VALIDATION), ''));

        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        if (!validators) return;

        setLoading(false);
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {

            teacherrequestService.get(id, loadObject, {});
        }
    };

    useEffect(() => {
        processValidators();
        if (id === '') {
            if (obj !== undefined) {
                setId(obj.id)
            }
        }
        if (obj === undefined) getObject();
        validateAll();
    }, [id, currentRoute]);
    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'managermessage');

        if(obj?.status !== Status.UNPROCESSED) rez = true;

        setIsFormDisabled(rez);
    };

    useEffect(() => {

        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);



    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const handleAccept = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (!obj) return false;
        if (!user) return false;
        if (!user.id) return false;

        if (id !== undefined) {
            obj.idmanager = user.id;
            teacherrequestService.accept(id, ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
        }
    };


    const handleReject = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (!obj) return false;
        if (!user) return false;
        if (!user.id) return false;

        if (id !== undefined) {
            obj.idmanager = user.id;
            teacherrequestService.reject(id, ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
        }
    };

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        {
            url: detailUrl,
            label: LL("crumb_Detail" + mainObject),
        },
        { label: LL("crumb_Edit" + mainObject) },
    ];

    return !loading && obj ? (
        <>

            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

            <Box sx={{ flexDirection: 'row', display: 'flex', alignContent: 'center', alignItems: 'center', my: 3 }} >

                <MyNavBt
                    tabIndex={18}
                    href={detailUrl}
                    children={<IconButton color="primary" sx={{ mr: 3 }}><ArrowBackIcon /></IconButton>}
                    size="large"
                    _hstate={{}}
                    _mainurl={mainUrl}
                />

                <Typography variant="h6">{LL("EditBlock")}</Typography>

            </Box>

            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form >
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        multiline
                        minRows={4}
                        id="managermessage"
                        name="managermessage"
                        variant="outlined"
                        color="primary"
                        _label={LL("managermessage")}
                        size="small"
                        value={obj.managermessage}
                        setObj={setObjField}
                        _vresults={
                            vresults && vresults.managermessage ? vresults.managermessage : false
                        }
                    />
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{ mt: 4 }}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <MyButton
                            tabIndex={2}
                            disabled={isFormDisabled}
                            color="error"
                            fullWidth
                            variant="contained"
                            size="large"
                            type="submit"
                            cb={handleReject}
                            children={LL("Bt_Reject")}
                        />
                        <MyButton
                            tabIndex={1}
                            disabled={isFormDisabled}
                            color="primary"
                            fullWidth
                            variant="contained"
                            size="large"
                            type="submit"
                            cb={handleAccept}
                            children={LL("Bt_Accept")}
                        />
                    </Stack>
                </form>

            </Box>
        </>
    ) : (
        <Loading />
    );
};

export default EditTeacherRequest;