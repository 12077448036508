import { LeftPartUserMenu } from "./LeftPartUserMenu";
import { v4 as uuidv4 } from "uuid";
const LeftPart = ({
  setOpen,
  ...props }) => {
    const key = uuidv4();
  return (
    <>
      <LeftPartUserMenu key={key} setOpen={setOpen} {...props} />
    </>
  );
};

export { LeftPart };
