import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { PostAttachmentDto } from "dto/attachment/attachment.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import AttachmentRepository from "repositories/attachment/attachment.repository";
import { Status } from "tools/status";

import GeneralService from "services/general.service";
export default class AttachmentService extends GeneralService implements IService {
  attachmentRepository: AttachmentRepository = new AttachmentRepository();
  constructor() {
    super();
    this.handleGetList = this.handleGetList.bind(this);
    this.handleGet = this.handleGet.bind(this);
  }
  handleUpdate(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;

    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {
    
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.attachmentRepository.update(id, this.handleUpdate, this.prepareObjToUpdate(data), cbparameters);
  }

  handleDelete(result?: any, cbparameters?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters);
    }
  }

  async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    this.attachmentRepository.delete(id, this.handleDelete, cbparameters);
  }



  handleGet(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;
    this.populateObject(rez.obj, result.obj, [
      { key: 'status', type: 'status' },
      { key: 'isdefault', type: 'booleans' },
  ]);
    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

    return rez;
  }

  async get(id: string, cb?: any, cbparameters?: any,idLanguage?:string): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.attachmentRepository.get(id, this.handleGet, cbparameters, idLanguage);
  }
  handleGetList(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultListDTO();

    rez = result;
    if (result.err) return;
    this.parseResultPopulate(result, rez, [
      { key: 'status', type: 'status' },
      { key: 'isdefault', type: 'booleans' },
    ]);

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

        return rez;
  }

  async getList(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.attachmentRepository.getList(this.handleGetList, cbparameters, data);
        if (cb == undefined)
        {
            return this.handleGetList(t);
        }
  }

  handleAdd(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  prepareObjToAdd(obj:any):PostAttachmentDto{
    const rez = new PostAttachmentDto();
    rez.idfile = null;
    rez.idlanguage = obj.idlanguage;
    rez.ordercriteria = obj.ordercriteria;
    rez.name = obj.name;
    rez.status = obj.status;
    rez.idparent = obj.idparent;
    rez.parent = obj.parent;
    rez.isdefault = obj.isdefault;
    rez.files = obj.files;
    return rez;
  }

  prepareObjToUpdate(obj:any):PostAttachmentDto{
    const rez = new PostAttachmentDto();
    rez.idlanguage = obj.idlanguage;
    rez.ordercriteria = obj.ordercriteria;
    rez.name = obj.name;
    rez.status = obj.status;
    rez.parent = obj.parent;
    rez.isdefault = obj.isdefault;
    rez.idfile = obj.idfile;
    return rez;
  }

  async add(
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.attachmentRepository.add(this.handleGetList, cbparameters, this.prepareObjToAdd(data));
  }


  // getDefaultRequestList(onPage?: number): RequestListDTO {
  //   onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
  //   if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
  //   var defaultRequestList = new RequestListDTO();
  //   defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
  //   defaultRequestList.onpage = onPage;
  //   var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
  //   defaultRequestSortCriteria.asc = true;
  //   defaultRequestSortCriteria.field = 'name';
  //   defaultRequestList.sortcriteria = []
  //   defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
  //   return defaultRequestList;
  // }

  getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
    if (
      !obj.sortcriteria ||
      !Array.isArray(obj.sortcriteria) ||
      !obj.sortcriteria.length
    ) {
      var sobj = new RequestSortCriteriaDTO();
      sobj.asc = true;
      sobj.field = "name";

      obj.sortcriteria = [];
      obj.sortcriteria?.push(sobj);
    }

    return obj;
  }
}
