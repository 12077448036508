import React, { useState, useEffect } from "react";

import { logger } from "hoc/logger";
import useMyFormValidation from "hook/useMyFormValidation";

import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";

import CoursePriceService from "services/course/courseprice.service";

import { CoursePriceDto } from "dto/course/courseprice.dto";

import { CourseTypeSelect } from "components/elements/Select/CourseTypeSelect";
import { PricePolicySelect } from "components/elements/Select/PricePolicySelect";
import { CurrencySelect } from "components/elements/Select/CurrencyTypeSelect";
import { RecommendationPriceSelect } from "components/elements/Select/RecommendationPriceSelect";
import MyCheckboxField from "components/form/mycheckboxfield";
import { PriceTypeSelect } from "components/elements/Select/PriceTypeSelect";
import { Status } from "tools/status";
import { CourseDto } from "dto/course/course.dto";
const coursePriceService = new CoursePriceService();

interface AddCoursePriceProps {
  currentRoute: any;
  mainObject: any;
  course: CourseDto;
}

const AddCoursePrice: React.FC<AddCoursePriceProps> = ({
  currentRoute,
  mainObject,
  course,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [id, setId] = useState<string | undefined>(_id);

  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<CoursePriceDto | undefined>(
    new CoursePriceDto()
  );

  const [validators, setValidators] = useState<any>();
  const [vresults, setVResults] = useState<any>(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    // if (vresults) return;

    obj.idcurrency = obj.idcurrency !== undefined ? obj.idcurrency : "";

    obj.idpricepolicy =
      obj.idpricepolicy !== undefined ? obj.idpricepolicy : "";
    obj.userecommendationprice =
      obj.userecommendationprice !== undefined
        ? obj.userecommendationprice
        : false;
    obj.idrecommendationprice =
      obj.idrecommendationprice !== undefined ? obj.idrecommendationprice : "";
    obj.priceperlesson =
      obj.priceperlesson !== undefined ? obj.priceperlesson : 0;
    obj.totalprice = obj.totalprice !== undefined ? obj.totalprice : 0;

    setObj(obj);

    let t = validateField("totalprice", obj.totalprice);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);

    // setIsFormDisabled(ValidateTools.vForm(v, field));
    setVResults(v);
    return v;
  };

  const checkIsFormDisabled = () => {
    let rez = false;
    if (!obj) rez = true;
    if (obj && !obj.idpricepolicy) rez = true;
    if (obj && obj.userecommendationprice) {
      if (!obj.idrecommendationprice) rez = true;
    } else {
      if (obj && !obj.idcurrency) rez = true;
    }
    setIsFormDisabled(rez);
  };
  useEffect(() => {
    checkIsFormDisabled();
  }, [
    obj?.idpricepolicy,
    obj?.userecommendationprice,
    obj?.idrecommendationprice,
    obj?.idcurrency,
  ]);

  useEffect(() => {
    validateAll();
  }, [obj, validators]);

  const processValidators = () => {
    let v: any = {
      totalprice: [],
    };
    v.totalprice.push(
      new RequiredValidator(
        LL("Required field totalprice", LabelTypes.LABEL_VALIDATION),
        ""
      )
    );
    setValidators(v);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (obj !== undefined && id !== undefined) {
      obj.idcourse = id;
      coursePriceService.add(
        ComponentCommonTools.handleSuccessAddAndEdit,
        { mainObject, idRoute: id, anchor: "coursePrice" },
        obj
      );
    }

    // setObj(new CoursePriceDto());
  };

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };
  const setCheckFixed = (value: any) => {
    let t = JSON.parse(JSON.stringify(obj));

    t["userecommendationprice"] = value;
    setObj(t);
  };

  const processPricePolicySelect = () => {
    if (!obj) return <></>;
    if (!course) return <></>;
    if (!course.hasOwnProperty("coursesettings")) return <></>;
    if (!course.coursesettings) return <></>;
    if (!course.coursesettings.hasOwnProperty("idtypecourse")) return <></>;
    if (!course.coursesettings.idtypecourse) return <></>;

    return (
      <Box mt={3}>
        <PricePolicySelect
          tabIndex={1}
          id="idpricepolicy"
          name="idpricepolicy"
          variant="standard"
          className="form-select"
          color="primary"
          value={obj.idpricepolicy}
          field="idpricepolicy"
          idTypeCourse={course.coursesettings.idtypecourse}
          setObj={setObjField}
          obj={obj}
          _defaultValue={obj.idpricepolicy}
        />
      </Box>
    );
  };
  const processRecommendationPriceSelect = () => {
    if (!obj) return <></>;
    if (!obj.idpricepolicy) return <></>;
    return (
      <Box>
        <Box sx={{ mt: 3 }}>
          <MyCheckboxField
            tabIndex={3}
            id="userecommendationprice"
            name="userecommendationprice"
            _label={LL("userecommendationprice")}
            setObj={setCheckFixed}
            checked={obj.userecommendationprice}
            color="primary"
            size="small"
            _vresults={undefined}
          />
        </Box>
        {obj.userecommendationprice ? (
          <Box sx={{ mt: 3 }}>
            <RecommendationPriceSelect
              id="idrecommendationprice"
              name="idrecommendationprice"
              variant="standard"
              className="form-select"
              color="primary"
              value={obj.idrecommendationprice}
              field="idrecommendationprice"
              idpricepolicy={obj.idpricepolicy}
              setObj={setObjField}
              obj={obj}
              _defaultValue={obj.idrecommendationprice}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const processFieldByTypeCourse = () => {
    if (!obj) return <></>;
    if (!course) return <></>;
    if (!course.hasOwnProperty("coursesettings")) return <></>;
    if (!course.coursesettings) return <></>;
    if (!course.coursesettings.hasOwnProperty("idtypecourse")) return <></>;
    if (!course.coursesettings.idtypecourse) return <></>;
    if (
      course.coursesettings.idtypecourse ===
      process.env.REACT_APP_ID_TYPE_COURSE_LIVE
    ) {
      return (
        <Box sx={{ mt: 3 }}>
          <MyTextField
            tabIndex={4}
            fullWidth
            id="priceperlesson"
            name="priceperlesson"
            variant="standard"
            className="form-input"
            color="primary"
            _label={LL("priceperlesson")}
            value={obj.priceperlesson}
            setObj={setObjField}
            _vresults={false}
          />
        </Box>
      );
    }
    if (
      course.coursesettings.idtypecourse ===
      process.env.REACT_APP_ID_TYPE_COURSE_ONLINE
    ) {
      return (
        <Box sx={{ mt: 3 }}>
          <MyTextField
            tabIndex={5}
            fullWidth
            id="totalprice"
            name="totalprice"
            variant="standard"
            className="form-input"
            color="primary"
            _label={LL("totalprice")}
            value={obj.totalprice}
            setObj={setObjField}
            _vresults={
              vresults && vresults.totalprice ? vresults.totalprice : false
            }
          />
        </Box>
      );
    }
    return <></>;
  };
  const processPriceField = () => {
    if (!obj) return <></>;
    if (!obj.idpricepolicy) return <></>;
    if (obj.userecommendationprice) return <></>;
    return (
      <Box>
        {processFieldByTypeCourse()}
        <Box sx={{ mt: 3 }}>
          <CurrencySelect
            tabIndex={0}
            id="idcurrency"
            name="idcurrency"
            variant="standard"
            className="form-select"
            color="primary"
            value={obj.idcurrency}
            field="idcurrency"
            setObj={setObjField}
            obj={obj}
            _defaultValue={obj.idcurrency}
          />
        </Box>
      </Box>
    );
  };

  return !loading && obj ? (
    <>
      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <form onSubmit={handleSubmit}>
          {processPricePolicySelect()}
          {processRecommendationPriceSelect()}
          {processPriceField()}
          <MyButton
            tabIndex={6}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Submit")}
          />
        </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default AddCoursePrice;
