import React, { useState, useEffect } from 'react';

import TypeGenderService from "services/nomenclature/typegender.service";
import GenEditType from 'components/elements/MyGenComponents/GenEditType';
import { PostNomenclatureDto } from 'dto/nomenclature/postnomenclature.dto';


const typeGenderService = new TypeGenderService();


interface EditTypeGenderProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const EditTypeGender: React.FC<EditTypeGenderProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenEditType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeGenderService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default EditTypeGender;
