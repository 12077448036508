import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class CourseDto implements Idto {


  id?: string;
  name?: string;
  description?: string;
  status?: number;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  idcoursesettings?: string;
  idlanguage?: string;
  idslesson?: string[];
  idscoursecategory?: string[];
  idscoursefilter?: string[];
  idteacher?: string;
  courseduration?: number;
  teacher?: any;
  courseprice?: any;
  certificate?: any;
  popularidentifier?: number;
  requirementstext?: string;
  whatwillyoulearntext?: string;
  targetaudiencetext?: string;
  numberoflessons?: number;
  shortdescription?: string;
  coursesettings?: any;
  gallery?: any;
  attachment?: any;
  video?: any;
  constructor() {
    this.status = Status.ACTIVE;
    this.popularidentifier = 0;
  }
}

export class PostCourseDto implements Idto {

  name?: string;
  description?: string;
  status?: number;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  idcoursesettings?: string;
  idlanguage?: string;
  idslesson?: string[];
  idscoursecategory?: string[];
  idscoursefilter?: string[];
  idteacher?: string;
  courseduration?: number;
  teacher?: any;
  certificate?: any;
  popularidentifier?: number;
  requirementstext?: string;
  whatwillyoulearntext?: string;
  targetaudiencetext?: string;
  numberoflessons?: number;
  shortdescription?: string;
  
  

  constructor() {
    this.status = Status.ACTIVE;
    this.popularidentifier = 0;
  }
}