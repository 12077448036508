import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class SiteConfigFileDto implements Idto {
    
    id?: string;
    identifier?: string;
    idfile?: string | null;
    file?: any;
    files?: any;
    deletefile?: boolean;
  }

  export class PostSiteConfigFileDto implements Idto {
    
    identifier?: string;
    idfile?: string | null;
    files?: any;
    deletefile?: boolean;
  }