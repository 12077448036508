import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";

import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import SiteConfigRepository from "repositories/siteconfig/siteconfig.repository";
import { Status } from "tools/status";
import GeneralService from "services/general.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { PostSiteConfigDto } from "dto/app/siteconfig.dto";

export default class SiteConfigService extends GeneralService implements IService {
  siteConfigRepository: SiteConfigRepository = new SiteConfigRepository();
  constructor() {
    super();
    this.handleGetList = this.handleGetList.bind(this);
    this.handleGet = this.handleGet.bind(this);
  }
  handleUpdate(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;

    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  async update(id: string, cb?: any, cbparameters?: any, data?: PostSiteConfigDto): Promise<any> {
    
    const updateData: PostSiteConfigDto = {
        identifier: data ? data.identifier : '',
        value: data ? data.value : '',
    }
    
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.siteConfigRepository.update(id, this.handleUpdate, updateData, cbparameters);
  }

  handleDelete(result?: any, cbparameters?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters);
    }
  }

  async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    this.siteConfigRepository.delete(id, this.handleDelete, cbparameters);
  }


  handleGetServerSiteConfigsHash(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  async getServerSiteConfigsHash(cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.siteConfigRepository.getServerSiteConfigsHash(
      this.handleGetServerSiteConfigsHash,
      cbparameters
    );
  }

  handleGet(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;
    
    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

    return rez;
  }

  async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.siteConfigRepository.get(id, this.handleGet, cbparameters);
        if (cb == undefined)
        {
            return this.handleGet(t);
        }
  }

  handleGetList(result?: any, cbparameters?: any, data?: any): any {
    
    let rez = new ResultListDTO();

    rez = result;
    if (result.err) return;



    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

        return rez;
  }

  async getList(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.siteConfigRepository.getList(this.handleGetList, cbparameters, data);
        if (cb == undefined)
        {
            return this.handleGetList(t);
        }
  }

  handleAdd(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  async add(
    cb?: any,
    cbparameters?: any,
    data?: PostSiteConfigDto
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.siteConfigRepository.add(this.handleGetList, cbparameters, data);
  }


  // getDefaultRequestListSelect(onPage?: number): RequestListDTO {
  //   onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
  //   if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
  //   var defaultRequestList = new RequestListDTO();
  //   defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
  //   defaultRequestList.onpage = onPage;
  //   var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
  //   defaultRequestSortCriteria.asc = true;
  //   defaultRequestSortCriteria.field = 'identifier';
  //   defaultRequestList.sortcriteria = []
  //   defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
  //   return defaultRequestList;
  // }

  getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
    if (
      !obj.sortcriteria ||
      !Array.isArray(obj.sortcriteria) ||
      !obj.sortcriteria.length
    ) {
      var sobj = new RequestSortCriteriaDTO();
      sobj.asc = true;
      sobj.field = "identifier";

      obj.sortcriteria = [];
      obj.sortcriteria?.push(sobj);
    }

    return obj;
  }
}
