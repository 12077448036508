import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { useResource } from "hook/useResource";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Types } from "tools/types";
import { Status } from "tools/status";
import FilterService from "services/course/filter.service";
const service = new FilterService();

interface Props {
  obj?: any;
  setObj: any;
  _defaultValue?: any;
  
  setIdFilter: any;
}

const CourseFilterBlockAutocomplete: React.FC<Props> = ({
  obj,
  setObj,
  _defaultValue,
    
    setIdFilter
}) => {
  const { LL } = useResource();

  const idField = 'idfilter';
  const objField = 'filter';
  const searchValueField = 'searchvalue';
  const labelShowField = 'name';
  const labelText = LL('Autocomplete_Filter');


  const [objects, setObjects] = useState<any>();
  const [value, setValue] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([{}]);
  const [label] = useState<string>(labelText);

  const onPage = 20;

  const [reqList] = useState<any>(service.getDefaultRequestListSelect(onPage));

  useEffect(() => {
    if (_defaultValue) setValue(_defaultValue);
  }, [_defaultValue]);

  const loadData = () => {
    if (search === undefined || search === undefined) {
      setOptions([]);
      setLoading(false);
      return;
    }

    const tmp: RequestFilterDTO = new RequestFilterDTO()
    tmp.field = searchValueField;
    tmp.values = [];
    tmp.values.push(search);

    const tmp2: RequestFilterDTO = new RequestFilterDTO()
    tmp2.field = 'status';
    tmp2.values = [];
    tmp2.values.push(Status.ACTIVE.toString());

    const tmp3: RequestFilterDTO = new RequestFilterDTO()
    tmp3.field = 'type';
    tmp3.values = [];
    tmp3.values.push(Types.FILTER_TYPE_DICTIONARY.toString());

    reqList.filters = [];
    reqList.filters.push(tmp);
    reqList.filters.push(tmp2);
    reqList.filters.push(tmp3);
    

    getList();
  };

  const getList = () => {
    setLoading(true);
    service.getList(loadObjects, {}, reqList);
  };
  const loadObjects = (data: any) => {
    if (!data) setOptions([]);

    setOptions(data.objects);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [search]);

  useEffect(() => {
    if (objects !== null && objects !== undefined) {
      setValue(objects);
    } else {
      setValue(null);
    }
  }, [objects]);

  useEffect(() => {
    if (isLoaded) return;
    setIsLoaded(true);
  }, [options]);

  useEffect(() => {
    if (!isLoaded) return;
    if (!obj) return;
    if (!setObj) return;
    if (setObj == undefined) return;

    var _obj = obj;

    if (objects == null || objects == undefined) return;
    _obj[idField] = objects.id;
    _obj[objField] = objects;
    
    

    setObj(_obj);
    setIdFilter(objects.id);
  }, [value]);

  const handleChange = (value: any) => {
    setObjects(value);
  };

  useEffect(() => {
    if (!open) {
      setSearch("");
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      fullWidth
      open={open}
      value={value}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        handleChange(value);
      }}
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option: any) =>
        option[labelShowField] ? option[labelShowField] : ""
      }
      options={options}
      loading={loading}
      
      renderInput={(params) => (
        <TextField
          variant="standard"
          color="primary"
          className="form-input autocomplete"
          
            {...params}
          label={label}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export { CourseFilterBlockAutocomplete };
