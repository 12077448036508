import React, { useState, useEffect } from 'react';

import PhoneService from "services/user/phone.service";
import { logger } from 'hoc/logger';

import PhoneDto from 'dto/user/phone.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { AnyMxRecord } from 'dns';

const phoneService = new PhoneService();


interface PhoneProps {
    currentRoute: any

}

const PhoneInfo: React.FC<PhoneProps> = ({
    currentRoute,
    ...props
}) => {

    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<Array<PhoneDto> | undefined>();
    const [id] = useState<string>(currentRoute._paths[1]);


    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {
        if (id !== undefined) phoneService.getPhonesByUserId(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [currentRoute]);

    useEffect(() => {
        checkIsLoading();
        logger('PhoneInfo', obj)
    }, [obj]);


    return !loading && obj ? (
        <>

            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4, marginTop: '1%' }}
            >

                <Grid spacing={2} >
                    <Grid>
                        <Typography color="text.disabled" variant='caption'>{LL("Phones")}</Typography>
                    </Grid>
                    <Grid>
                        
                            {obj && obj.length > 0 ? obj.map((item, index) => {
                                return (<>
                                    <Typography>
                                        {ComponentCommonTools.processObjectField(item, 'countrycode')}
                                        {' '}
                                        {ComponentCommonTools.processObjectField(item, 'phonenumber')}
                                        {' '}
                                        {ComponentCommonTools.processObjectFieldOfObject(item, 'typephone', 'name')}
                                        {' '}
                                        {ComponentCommonTools.processObjectField(item, 'status_name')}</Typography>
                                </>)
                            }) : ''}
                        
                    </Grid>



                </Grid>
            </Box>
        </>
    ) : (
        <></>
    );
};

export default PhoneInfo;
