
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class PostPageDto implements Idto {
    
    type?: number;  
    status?: number;
    idlanguage?: string;
    idpage?: string;
    name?: string;
    url?: string;
    title?: string;
    keymeta?: string;
    descriptionmeta?: string;
    content?: string;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    status_name?: string;
    type_name?: string;
    constructor() {
        this.status = Status.ACTIVE;
    }
}


export class PageDto implements Idto {
    
    id?: string;
    type?: number;  
    status?: number;
    idlanguage?: string;
    idpage?: string;
    name?: string;
    url?: string;
    title?: string;
    keymeta?: string;
    descriptionmeta?: string;
    content?: string;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    language?: any;
    status_name?: string;
    type_name?: string;
    constructor() {
        this.status = Status.ACTIVE;
    }
}