
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class PostBlogCategoryDto implements Idto {

    status?: number;
    ordercriteria?: number;
    idlanguage?: string;
    idblogcategory?: string;
    idparent?: string;
    idtypeblogcategory?: string;
    name?: string;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
    }
}

export class BlogCategoryDto implements Idto {
    
    id?: string;
    status?: number;
    ordercriteria?: number;
    idlanguage?: string;
    idblogcategory?: string;
    language?: any;
    code?: string;
    level?: number;
    idparent?: string;
    idtypeblogcategory?: string;
    name?: string;
    status_name?: string;
    typeblogcategory?: any;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
    }
}