import React, { useState, useEffect } from 'react';

import SocialService from "services/user/social.service";
import { logger } from 'hoc/logger';

import SocialDto from 'dto/user/social.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { AnyMxRecord } from 'dns';
import { Link } from '@mui/material';

const socialService = new SocialService();


interface SocialProps {
    currentRoute: any

}

const SocialInfo: React.FC<SocialProps> = ({
    currentRoute,
    ...props
}) => {

    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<Array<SocialDto> | undefined>();
    const [id] = useState<string>(currentRoute._paths[1]);


    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {
        if (id !== undefined) socialService.getSocialsByUserId(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [currentRoute]);

    useEffect(() => {
        checkIsLoading();
        logger('SocialInfo', obj)
    }, [obj]);


    return !loading && obj ? (
        <>

            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4, marginTop: '1%' }}
            >

                <Grid spacing={2} >
                    <Grid>
                        <Typography color="text.disabled" variant='caption'>{LL("Socials")}</Typography>
                    </Grid>
                    <Grid>

                        {obj && obj.length > 0 ? obj.map((item, index) => {
                            return (<>
                                <Typography>
                                    {
                                    ComponentCommonTools.processObjectFieldOfObject(item, 'typesocial', 'name')}
                                    {' '}
                                    <Link href={item.link} target="_blank" rel="noopener noreferrer">    {ComponentCommonTools.processObjectField(item, 'link')}</Link>
                                </Typography>
                            </>)
                        }) : ''}

                    </Grid>



                </Grid>
            </Box>
        </>
    ) : (
        <></>
    );
};

export default SocialInfo;
