import React from "react";

import { useEffect, useState } from "react";

import logo from "assets/images/logo.svg";

import { CookiesProvider } from "react-cookie";

import { ResourceProvider } from "hoc/ResourceProvider";

import { AuthProvider } from "hoc/AuthProvider";
import { HeadProvider } from "hoc/HeadProvider";
import theme from "assets/themes/theme";

import { ThemeProvider } from "@mui/material/styles";
import { MessageProvider } from "hoc/MessageProvider";
import { AppRoutes } from "hoc/AppRoutes";

function App() {
  const [_theme, setTheme] = useState({});
  useEffect(() => {
    setTheme(theme);
  }, []);

  return (
    <ThemeProvider theme={_theme}>
      <CookiesProvider>
        <HeadProvider>
          <AuthProvider>
            <ResourceProvider>
              <MessageProvider>
                <AppRoutes />
              </MessageProvider>
            </ResourceProvider>
          </AuthProvider>
        </HeadProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default App;
