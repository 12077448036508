import React, { useState, useEffect } from 'react';

import CourseEnrollmentService from "services/course/courseenrollment.service";
import { logger } from 'hoc/logger';

import { CourseEnrollmentDto } from 'dto/course/courseenrollment.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';


const courseEnrollmentService = new CourseEnrollmentService();


interface DetailCourseEnrollmentProps {
    children: any;
    currentRoute: any;
    _obj: CourseEnrollmentDto,
    mainObject: any;
}

const DetailCourseEnrollment: React.FC<DetailCourseEnrollmentProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");

    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const lu = _getListUrl(mu);
        setListUrl(lu);
        if (obj === undefined) getObject();
    }, [mainObject, currentRoute]);

    const { LL, _getListUrl, _setListUrl } = useResource();
    const detailObj = _obj !== undefined ? _obj : undefined;
    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<CourseEnrollmentDto | undefined>(detailObj);
    const [id] = useState<string>(currentRoute._paths[1]);
    const editUrl = CommonTools.generateEditUrl(mainObject, id);

    const _courseUrl = obj ? CommonTools.generateDetailUrl('Course', obj.idcourse) : '';
    const mainUrlCourse = CommonTools.generateMainUrl('Course');
    const [courseDetailUrl, setCourseDetailUrl] = useState<string>(_courseUrl);

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {
            courseEnrollmentService.get(id, loadObject, {});

        }
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };


    useEffect(() => {
        checkIsLoading();
        if (courseDetailUrl === '') {
            if (obj !== undefined) {
                setCourseDetailUrl(CommonTools.generateDetailUrl('Course', obj.idcourse));
            }
        }

    }, [obj]);

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrlCourse,
            label: LL("crumb_Course"),
        },
        {
            url: courseDetailUrl,
            label: LL("crumb_DetailCourse"),
        },
        { label: LL("crumb_Detail" + mainObject) },
    ];

    return !loading ? (
        <>
            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
            <Box sx={{ flexDirection: 'row', display: 'flex', alignContent: 'center', alignItems: 'center' }} maxWidth="md">
                <MyNavBt
                    tabIndex={1}
                    href={courseDetailUrl}
                    children={<IconButton color="primary" ><ArrowBackIcon /></IconButton>}
                    size="large"
                    _hstate={{}}
                    _mainurl={mainUrlCourse}
                />
                <Typography variant="h4" sx={{ my: 3, ml: 3 }}>  {LL("DetailBlock")} </Typography>

                {obj ? (
                    <MyNavBt
                        tabIndex={0}
                        href={editUrl}
                        children={<IconButton className='editBtn'><EditIcon /></IconButton>}
                        sx={{ ml: 'auto' }}
                        _hstate={CommonTools.prepareObjectForUpdate(obj, mainObject)}
                        _mainurl={mainUrlCourse}
                    />) : (<></>)
                }

            </Box>
            <Box >
                <Box
                    maxWidth="md"
                    sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4, }}
                >

                    <Grid spacing={2} >
                    <Grid>
                            <Typography color="text.disabled" variant='caption'>{LL("Student_email")}</Typography>
                        </Grid>
                        <Grid>
                            {ComponentCommonTools.processObjectFieldOfObject(obj, 'student','email')}
                        </Grid>
                        <Grid mt={3}>
                            <Typography color="text.disabled" variant='caption'>{LL("Student_name")}</Typography>
                        </Grid>
                        <Grid>
                            {ComponentCommonTools.processObjectFieldOfObject(obj, 'student','fullname')}
                        </Grid>

                        <Grid mt={3}>
                            <Typography color="text.disabled" variant='caption'>{LL("Status")}</Typography>
                        </Grid>
                        <Grid>
                            {ComponentCommonTools.processObjectField(obj, 'status_name')}
                        </Grid>

                        <Grid mt={3}>
                            <Typography color="text.disabled" variant='caption'>{LL("PaymentStatus")}</Typography>
                        </Grid>
                        <Grid>
                            {ComponentCommonTools.processObjectField(obj, 'paymentstatus_name')}
                        </Grid>

                        <Grid mt={3}>
                            <Typography color="text.disabled" variant='caption'>{LL("Date")}</Typography>
                        </Grid>
                        <Grid>
                            {ComponentCommonTools.processObjectField(obj, 'data_name')}
                        </Grid>

                        <Grid mt={3}>
                            <Typography color="text.disabled" variant='caption'>{LL("numberoflessons")}</Typography>
                        </Grid>
                        <Grid>
                            {ComponentCommonTools.processObjectField(obj, 'numberoflessons')}
                        </Grid>
                        <Grid mt={3}>
                            <Typography color="text.disabled" variant='caption'>{LL("numberoflessonspassed")}</Typography>
                        </Grid>
                        <Grid>
                            {ComponentCommonTools.processObjectField(obj, 'numberoflessonspassed')}
                        </Grid>
                    </Grid>
                </Box>


            </Box>

        </>
    ) : (
        <Loading />
    );
};

export default DetailCourseEnrollment;