import { TeacherDto } from "dto/teacher/teacher.dto";
import Idto from "interfaces/idto.interface";
import { PricePolicyDto } from "./pricepolicy.dto";

export class PostPricePolicyLinkTeacherDto implements Idto {
  id?: string;
  idteacher?: string;
  idpricepolicy?: string;
  teacher?: TeacherDto;
  pricepolicy?: PricePolicyDto;
}

export class PricePolicyLinkTeacherDto implements Idto {
  id?: string;
  idteacher?: string;
  idpricepolicy?: string;
}
