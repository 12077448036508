import React, { useState, useEffect } from 'react';

import FileService from "services/file/file.service";
import { logger } from 'hoc/logger';

import {FileDto} from 'dto/file/file.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

const fileService = new FileService();


interface DetailFileProps {
    children: any;
    currentRoute: any;
    _obj: FileDto,
    mainObject: any;
}

const DetailFile: React.FC<DetailFileProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();
    const detailObj = _obj !== undefined ? _obj : undefined;
    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<FileDto | undefined>(detailObj);
    const [id] = useState<string>(currentRoute._paths[1]);
    
    

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {
            fileService.get(id, loadObject, {});
            
        }
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        if (obj === undefined) getObject();
    }, [obj]);

    useEffect(() => {
        checkIsLoading();
    }, [obj]);

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Detail" + mainObject) },
    ];
    return !loading && obj ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
          maxWidth="md"
        >
          <MyNavBt
            tabIndex={1}
            href={listUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />
          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {" "}
            {LL("DetailBlock")}{" "}
          </Typography>

          {/* {obj ? (
                    <MyNavBt
                        tabIndex={0}
                        href={editUrl}
                        children={<IconButton className='editBtn'><EditIcon /></IconButton>}
                        sx={{ ml: 'auto' }}
                        _hstate={CommonTools.prepareObjectForUpdate(obj, mainObject)}
                        _mainurl={mainUrl}
                    />) : (<></>)
                } */}
        </Box>

        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <Grid spacing={2}>
            <Grid>
              <Typography color="text.disabled" variant="caption">
                {LL("Name")}
              </Typography>
            </Grid>
            <Grid>{obj ? obj.name : ""}</Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("type")}
              </Typography>
            </Grid>
            <Grid>{obj ? obj.type : ""}</Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("location")}
              </Typography>
            </Grid>
            <Grid>{obj ? obj.location : ""}</Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("storage")}
              </Typography>
            </Grid>
            <Grid>{obj ? obj.storage : ""}</Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("size")}
              </Typography>
            </Grid>
            <Grid>{obj ? obj.size : ""}</Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("value")}
              </Typography>
            </Grid>
            <Grid>{obj ? obj.value : ""}</Grid>
          </Grid>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default DetailFile;
