
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export default class UserRoleDto implements Idto {
    id?: string;
    name?: string;
    idrole?: string;
    iduser?: string;
}
