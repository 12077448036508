import React, { useState, useEffect } from 'react';

import TypeSpecialtyService from "services/nomenclature/typespecialty.service";
import GenListType from 'components/elements/MyGenComponents/GenListType';
import { PopulateTypeDto } from 'dto/nomenclature/populatetype.dto';


const typeSpecialtyService = new TypeSpecialtyService();


interface TypeSpecialtyProps {
    children: any;
    currentRoute: any;
    mainObject: any;
}

const TypeSpecialty: React.FC<TypeSpecialtyProps> = ({
    children,
    currentRoute,
    mainObject,
    ...props
}) => {

    return (
        <GenListType
            children={children}
            currentRoute={currentRoute}
            service={typeSpecialtyService}
            mainObject={mainObject}
            DTO={PopulateTypeDto}
            {...props}
        />
    )
};

export default TypeSpecialty;
