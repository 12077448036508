import React, { useState, useEffect } from 'react';

import QuestionService from "services/course/question.service";
import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import { QuestionDto } from 'dto/course/question.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { TeacherAutocomplete } from 'components/elements/Autocomplete/TeacherAutocomplete';
import { Status } from 'tools/status';
import { QuestionTypeSelect } from 'components/elements/Select/QuestionTypeSelect';

import AddAnswer from 'components/course/test/components/answer/AddAnswer';

const questionService = new QuestionService();

interface EditQuestionProps {
    children: any;
    currentRoute: any;
    _obj: QuestionDto,
    mainObject: any;
}

const EditQuestion: React.FC<EditQuestionProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");
    const [addUrl, setAddUrl] = useState("");

    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const au = CommonTools.generateAddUrl(mainObject);
        setAddUrl(au);
        const lu = _getListUrl(mu);
        setListUrl(lu);
    }, [mainObject, currentRoute]);

    const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<QuestionDto | undefined>()
    const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);

    const _testUrl = obj ? CommonTools.generateDetailUrl('Test', obj.idtest) : '';
    const mainUrlTest = CommonTools.generateMainUrl('Test');
    const [testDetailUrl, setTestDetailUrl] = useState<string>(_testUrl);

    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.name = obj.name !== undefined ? obj.name : '';

        obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 1;

        obj.idtest = obj.idtest !== undefined ? obj.idtest : '';

        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.type = obj.type !== undefined ? obj.type : 0;

        setObj(obj);

        let t = validateField("name", obj.name);
        t = validateField("ordercriteria", obj.ordercriteria, t);
    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            name: [],
            ordercriteria: [],
        };

        v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
        v.ordercriteria.push(new RequiredValidator(LL('Required field ordercriteria', LabelTypes.LABEL_VALIDATION), ''));
        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {

            questionService.get(id, loadObject, {});
        }
    };

    useEffect(() => {
        processValidators();
        if (id === '') {
            if (obj !== undefined) {
                setId(obj.id)
            }
        }
    }, []);
    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'name');
        // rez = ValidateTools.vForm(vresults, 'ordercriteria', rez);
        setIsFormDisabled(rez);
    };

    useEffect(() => {
        validateAll();
        checkLoading();
        if (testDetailUrl === '') {
            if (obj !== undefined) {
                setTestDetailUrl(CommonTools.generateDetailUrl('Test', obj.idtest));
            }
        }
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);

    useEffect(() => {
        if (obj === undefined) getObject();
    }, [obj]);


    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (id !== undefined) {
            questionService.update(id, ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
        }
    };
    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrlTest,
            label: LL("crumb_Test"),
        },
        {
            url: testDetailUrl,
            label: LL("crumb_DetailTest"),
        },
        {
            url: detailUrl,
            label: LL("crumb_Detail" + mainObject),
        },
        { label: LL("crumb_Edit" + mainObject) },
    ];

    const [value, setValue] = React.useState(CommonTools.getAnchor(currentRoute, 'generalInfo'));

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue);
        RouteTools.setAnchor(newValue)
    };

    return !loading && obj ? (
        <>

            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
            <Box sx={{ flexDirection: 'row', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                <MyNavBt
                    tabIndex={2}
                    href={detailUrl}
                    children={<IconButton color="primary"><ArrowBackIcon /></IconButton>}
                    size="large"
                    _hstate={{anchor:value}}
                    _mainurl={testDetailUrl}
                />
                <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
                    {LL("EditBlock")}
                </Typography>
            </Box>



            <>
                <Box maxWidth="md" className="tabs">
                    <Tabs

                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                    >
                        {obj ? <Tab label={LL("Edit_Question_main_Info")} value={'generalInfo'} /> : <></>}
                        {obj ? <Tab label={LL("Answer_Settings_Info")} value={'answer'} /> : <></>}

                    </Tabs>
                </Box>
                <TabPanel value={value} index={'generalInfo'}>
                    <Box
                        maxWidth="md"
                        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
                    >
                        <form onSubmit={handleSubmit}>
                            <MyTextField
                                tabIndex={0}
                                fullWidth
                                id="name"
                                name="name"
                                variant="outlined"
                                color="primary"
                                _label={LL("Name")}
                                size="small"
                                value={obj.name}
                                setObj={setObjField}
                                _vresults={
                                    vresults && vresults.name ? vresults.name : false
                                }
                            />
                            <Box mt={3}>
                                <StatusSelect
                                    tabIndex={1}
                                    id="status"
                                    name="status"
                                    variant="outlined"
                                    color="primary"
                                    value={obj.status}
                                    field="status"
                                    size="small"
                                    setObj={setObjField}
                                    _defaultValue={obj.status}
                                />
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <QuestionTypeSelect
                                    tabIndex={2}
                                    id="type"
                                    name="type"
                                    variant="outlined"
                                    color="primary"
                                    value={obj.type}
                                    field="type"
                                    size="small"
                                    setObj={setObjField}
                                    _defaultValue={obj.type}
                                />
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <MyTextField
                                    tabIndex={3}
                                    fullWidth
                                    id="ordercriteria"
                                    name="ordercriteria"
                                    variant="outlined"
                                    color="primary"
                                    _label={LL("Ordercriteria")}
                                    size="small"
                                    value={obj.ordercriteria}
                                    setObj={setObjField}
                                    _vresults={
                                        vresults && vresults.ordercriteria ? vresults.ordercriteria : false
                                    }
                                />
                            </Box>
                            <MyButton
                                tabIndex={4}
                                disabled={isFormDisabled}
                                color="primary"
                                fullWidth
                                variant="contained"
                                size="large"
                                type="submit"
                                sx={{ mt: 4 }}
                                children={LL("Bt_Submit")}
                            />
                        </form>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={'answer'}>
                    {obj ? <AddAnswer mainObject={mainObject} currentRoute={currentRoute} /> : <></>}
                </TabPanel>

            </>
        </>
    ) : (
        <Loading />
    );
};

export default EditQuestion;