import RequestListDTO from "dto/app/requestlist.dto";
import { logger } from "hoc/logger";
import Idto from "interfaces/idto.interface";
import IRepository from "interfaces/irepository.interface";
import GeneralRepository from "repositories/general.repository";

const { REACT_APP_API_SERVER, REACT_APP_URLPREFIX } = process.env;

export default class LabelRepository extends GeneralRepository implements IRepository {
  async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/label/" + id;
    return await this.deleteAxios(url, cb, cbparameters);
  }
  async update(id: string, cb?: any, data?: Idto | undefined, cbparameters?: any): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/label/" + id;
    return await this.putAxios(url, cb, data, cbparameters);
  }
  patch(id: string, cb?: any, data?: Idto | undefined, cbparameters?: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

  async get(id: string, cb?: any, cbparameters?: any, idLanguage?: string): Promise<any> {
    let url;
    if (idLanguage == undefined) url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + `/label/${id}`;
    else url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + `/label/${id}?idlanguage=${idLanguage}`;
    return await this._get(url, cb, cbparameters);
  }

  async getByLanguage(id: string, idLanguage: string, cb?: any, cbparameters?: any): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + `/labelvalue/${id}/${idLanguage}`;
    return await this._get(url, cb, cbparameters);
  }

  async getPopulate(id: string, cb?: any, cbparameters?: any): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + `/label/populate/${id}`;
    return await this._get(url, cb, cbparameters);
  }

  async getList(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + `/label`;
    return await this._getList(url, cb, cbparameters, data);
  }

  async add(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + `/label`;

    return await this.postAxios(url, cb, data, cbparameters);
  }

  async getServerLabelsHash(cb?: any, cbparameters?: any): Promise<any> {
    return await this.getAxios(
      (REACT_APP_API_SERVER ?? '') + `/admin/label/hash`,
      cb,
      {},
      cbparameters
    );
  }
}
