import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class LinkCourseCategoryDto implements Idto {

    id?: string;
    idcourse?: string;
    idcoursecategory?: string;
    
    course?: any;
    coursecategory?: any;
}

export class PostLinkCourseCategoryDto implements Idto {

    idcourse?: string;
    
    idcoursecategory?: string;
    
    
}