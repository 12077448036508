import React, { useState, useEffect } from "react";

import { logger } from "hoc/logger";
import useMyFormValidation from "hook/useMyFormValidation";
import UserSettingsDto from "dto/user/usersettings.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserSettingsService from "services/user/usersettings.service";
import IconButton from "@mui/material/IconButton";
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { TypeGenderSelect } from "components/elements/Select/GenderTypeSelect";

import MyDatePicker from "components/elements/MyDatePicker/MyDatePicker";
import MyFileField from "components/form/myfilefield";
import AvatarCropper from "components/elements/MyAvatar/AvatarCropper";

const userSettings = new UserSettingsService();

interface EditUserSettingsInfoProps {
  currentRoute: any;

  mainObject: any;
}

const EditUserSettingsInfo: React.FC<EditUserSettingsInfoProps> = ({
  currentRoute,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [id, setId] = useState<string | undefined>(_id);

  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<UserSettingsDto | undefined>();
  const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const [validators, setValidators] = useState<any>();
  const [vresults, setVResults] = useState<any>(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    // if (vresults) return;

    obj.id = obj.id ?? obj.id;
    obj.name = obj.name !== undefined ? obj.name : "";
    obj.surname = obj.surname !== undefined ? obj.surname : "";
    obj.birthday = obj.birthday !== undefined ? obj.birthday : undefined;
    obj.idtypegender = obj.idtypegender !== undefined ? obj.idtypegender : "";
    // obj.idsphone = obj.idsphone !== undefined ? obj.idsphone : [];
    obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : "";
    obj.idavatar = obj.idavatar !== undefined ? obj.idavatar : null;
    // obj.idavatar = obj.idavatar !== undefined ? obj.idavatar : '';
    // obj.idphysicaladdress = obj.idphysicaladdress !== undefined ? obj.idphysicaladdress : '';

    setObj(obj);

    let t = validateField("name", obj.name);
    t = validateField("surname", obj.surname, t);
    logger("validateAll", t);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);

    setVResults(v);
    setIsFormDisabled(ValidateTools.vForm(v, field));

    return v;
  };

  const processValidators = () => {
    let v: any = {
      name: [],
      surname: [],
    };

    v.name.push(
      new RequiredValidator(
        LL("Required field name", LabelTypes.LABEL_VALIDATION),
        ""
      )
    );
    v.surname.push(
      new RequiredValidator(
        LL("Required field surname", LabelTypes.LABEL_VALIDATION),
        ""
      )
    );

    setValidators(v);
  };

  const checkLoading = () => {
    if (!loading) return;
    if (obj === undefined) return;
    if (!validators) return;
    validateAll();
    setLoading(false);
  };

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined) {
      userSettings.getByUserId(id, loadObject, {});
    }
  };

  useEffect(() => {
    processValidators();
  }, []);
  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, "name");
    rez = ValidateTools.vForm(vresults, "surname", rez);
    setIsFormDisabled(rez);
  };

  useEffect(() => {
    validateAll();
    checkLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);

  useEffect(() => {
    if (obj === undefined) getObject();
  }, [obj]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (obj && obj.id !== undefined) {
      obj.iduser = id;
      if (files !== null) obj.files = files;
      userSettings.update(
        obj.id,
        ComponentCommonTools.handleSuccessAddAndEdit,
        { mainObject, idRoute: id, anchor: "userSettings" },
        obj
      );
    }
  };
  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };
  const setCheckDeleteAvatar = (value: any) => {
    CommonTools.handleCheckValue(obj, setObj, "deleteavatar", value);
  };

  const processAvatarDelete = () => {
    if (!obj) return <></>;
    return (
      <Box sx={{ mt: 3 }}>
        {ComponentCommonTools.processCheckboxDelete(
          obj,
          obj,
          "avatar",
          "deleteavatar",
          "name",
          setCheckDeleteAvatar,
          LL
        )}
      </Box>
    );
  };

  const [files, setFiles] = useState(null);

  const setObjFile = (field: any, event: any) => {
    setFiles(event.target.files[0]);
  };

  const setObjFile2 = (value: any) => {
    setFiles(value);
  };

  return !loading && obj ? (
    <>
      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <form onSubmit={handleSubmit}>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("Name")}
            value={obj.name}
            setObj={setObjField}
            size="small"
            _vresults={vresults && vresults.name ? vresults.name : false}
          />

          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={1}
              fullWidth
              id="surname"
              name="surname"
              variant="outlined"
              color="primary"
              size="small"
              _label={LL("Surname")}
              value={obj.surname}
              setObj={setObjField}
              _vresults={
                vresults && vresults.surname ? vresults.surname : false
              }
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyDatePicker
              tabIndex={3}
              field="birthday"
              _defaultValue={obj.birthday}
              LL={LL}
              setObjField={setObjField}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <LanguageSelect
              tabIndex={4}
              id="idlanguage"
              name="idlanguage"
              variant="outlined"
              color="primary"
              value={obj.idlanguage}
              field="idlanguage"
              size="small"
              setObj={setObjField}
              obj={obj}
              forAddForms={true}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <TypeGenderSelect
              tabIndex={5}
              id="idtypegender"
              name="idtypegender"
              variant="outlined"
              color="primary"
              value={obj.idtypegender}
              field="idtypegender"
              size="small"
              _defaultValue={obj.idtypegender}
              setObj={setObjField}
              obj={obj}
            />
          </Box>
          {/* <Box sx={{ mt: 3 }}>
                        <MyFileField
                            tabIndex={6}
                            fullWidth
                            id="files"
                            name="files"
                            variant="outlined"
                            color="primary"
                            _label={LL("files")}
                            size="small"
                            setObj={setObjFile}
                            _vresults={undefined}
                        />
                    </Box> */}

          <Box sx={{ mt: 3 }}>
            <AvatarCropper setFile={setObjFile2} avatar={obj.avatar} />
          </Box>
          {processAvatarDelete()}
          <MyButton
            tabIndex={7}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Submit")}
          />
        </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default EditUserSettingsInfo;
