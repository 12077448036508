import React, { useState, useEffect } from "react";

import UserService from "services/user/user.service";
import { logger } from "hoc/logger";

import ChangePasswordByAdminDto from "dto/user/changepasswordbyadmin.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";

import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from "tools/routetools";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import IconButton from "@mui/material/IconButton";
const userService = new UserService();

interface ModifyPasswordProps {
  currentRoute: any;

  mainObject: any;
}

const ModifyPassword: React.FC<ModifyPasswordProps> = ({
  currentRoute,

  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const [loading, setLoading] = useState<boolean>(false);
  var [obj, setObj] = useState<ChangePasswordByAdminDto>(
    new ChangePasswordByAdminDto()
  );
  const [id] = useState<string>(currentRoute._paths[2]);

  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const [validators, setValidators] = useState(false);
  const [vresults, setVResults] = useState<any>(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    // if (vresults) return;

    obj.password = obj.password !== undefined ? obj.password : "";

    setObj(obj);

    let t = validateField("password", obj.password);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);
    setVResults(v);
    return v;
  };

  const processValidators = () => {
    let v: any = {
      password: [],
    };

    v.password.push(
      new RequiredValidator(
        LL("Required field password", LabelTypes.LABEL_VALIDATION),
        ""
      )
    );

    setValidators(v);
  };

  const checkLoading = () => {
    if (!loading) return;
    if (!obj) return;
    if (!validators) return;
    validateAll();
    setLoading(false);
  };

  useEffect(() => {
    processValidators();
  }, []);

  useEffect(() => {
    checkLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);

    validateField(field, value);
  };

  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, "password");

    setIsFormDisabled(rez);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (id === undefined) return;
    if (id === "") return;
    userService.modifyPasswordByAdmin(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, anchor: "changePassword" },
      obj
    );
  };

  return !loading ? (
    <>
      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <form onSubmit={handleSubmit}>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="password"
            name="password"
            variant="outlined"
            color="primary"
            _label={LL("Password")}
            size="small"
            value={obj.password}
            setObj={setObjField}
            _vresults={
              vresults && vresults.password ? vresults.password : false
            }
          />

          <MyButton
            tabIndex={1}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Submit")}
          />
        </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default ModifyPassword;
