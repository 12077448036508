import React, { useState, useEffect } from 'react';

import LabelService from "services/label/label.service";
import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import { PostLabelDto } from 'dto/label/postlabel.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Status } from 'tools/status';
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { LabelTypeSelect } from 'components/elements/Select/LabelTypeSelect';
import { LanguageSelect } from 'components/elements/Select/LanguageSelect';
import { LabelValuePopulateDto } from 'dto/label/labelvaluepopulate.dto';
import { LabelDto } from 'dto/label/label.dto';

const labelService = new LabelService();

interface EditLabelProps {
    children: any;
    currentRoute: any;
    // _obj: LabelDto,
    mainObject: any;
}

const EditLabel: React.FC<EditLabelProps> = ({
    children,
    currentRoute,
    mainObject,
    ...props
}) => {

  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';
    const _idLanguage = currentRoute.historystate.idLanguage !== undefined ? currentRoute.historystate.idLanguage : '';
    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<LabelDto | undefined>()
    const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const [idLanguage, setIdLanguage] = useState<string>(_idLanguage);
    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);

    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {
            if (idLanguage === '') labelService.get(id, loadObject, {});
            else labelService.get(id, loadObject, {}, idLanguage);
        }
    };

    useEffect(() => {
        processValidators();
        if (id === '') {
            if (obj !== undefined) {
                setId(obj.id)
                if (obj.idlanguage !== undefined) {
                    setIdLanguage(obj.idlanguage)
                }
            }
        }
        if (idLanguage !== '') getObject();
        
    }, [idLanguage]);

    useEffect(() => {
        validateAll();
        checkLoading();
        if (idLanguage === '') {
          if (obj !== undefined) {
              if (obj.idlanguage !== undefined) setIdLanguage(obj.idlanguage)
          }
      }
    }, [obj, validators]);


    useEffect(() => {
        
        if (currentRoute.historystate.obj !== undefined) {
            setObj(currentRoute.historystate.obj);
            if (idLanguage === '') setIdLanguage(currentRoute.historystate.obj.idlanguage)
        }
        if (obj === undefined) getObject();
        
    }, []);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        
        obj.value = obj.value !== undefined ? obj.value : '';

        setObj(obj);

        let t = validateField("value", obj.value);

    }

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));

        return v;
    };

    const processValidators = () => {
        let v: any = {
            value: [],
        };
        v.value.push(new RequiredValidator(LL('Required field value', LabelTypes.LABEL_VALIDATION), ''));

        setValidators(v);
    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
        validateField(field, value);
    };


    const setObjFieldLanguage = (field: any, value: any) => {
        if (value !== undefined) setIdLanguage(value)
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        
        if (isFormDisabled) return false;
        if (obj !== undefined) {
            if (obj.id !== undefined) {
                obj.idlanguage = idLanguage;
                setLoading(true);
                labelService.update(
                    obj.id,
                    ComponentCommonTools.handleSuccessAddAndEdit,
                    { mainObject, idLanguage },
                    obj
                );
            }
        }
    };

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        {
            url: detailUrl,
            label: LL("crumb_Detail" + mainObject),
        },
        { label: LL("crumb_Edit" + mainObject) },
    ];

    return !loading && obj ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <MyNavBt
            href={detailUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />
          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {LL("EditBlock")}
          </Typography>
        </Box>
        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <Box>
            <Typography color="text.disabled" variant="caption">
              {LL("Identifier")}:{" "}
            </Typography>
            <Typography color="text.disabled" variant="caption">
              {obj.identifier ? obj.identifier : ""}
            </Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" variant="caption">
              {LL("TypeLabel")}:{" "}
            </Typography>
            <Typography color="text.disabled" variant="caption">
              {obj.type ? obj.type_name : ""}
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box sx={{ mt: 3 }}>
              <LanguageSelect
                tabIdex="2"
                id="idlanguage"
                name="idlanguage"
                variant="outlined"
                color="primary"
                value={idLanguage}
                field="idlanguage"
                size="small"
                setObj={setObjFieldLanguage}
                obj={obj}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <StatusSelect
                tabIndex={1}
                id="status"
                name="status"
                variant="outlined"
                color="primary"
                value={obj.status}
                field="status"
                size="small"
                setObj={setObjField}
                _defaultValue={obj.status}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={0}
                fullWidth
                id="value"
                name="value"
                variant="outlined"
                color="primary"
                _label={LL("label_text_field_value")}
                size="small"
                value={obj.value}
                setObj={setObjField}
                _vresults={vresults && vresults.value ? vresults.value : false}
              />
            </Box>
                    
            <MyButton
              tabIdex="3"
              disabled={isFormDisabled}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
          </form>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default EditLabel;