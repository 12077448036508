import RequestListDTO from "dto/app/requestlist.dto";
import SignInDto from "dto/auth/signin.dto";
import SignInHashDto from "dto/auth/signinhash.dto";
import { logger } from "hoc/logger";
import Idto from "interfaces/idto.interface";
import IRepository from "interfaces/irepository.interface";
import GeneralRepository from "repositories/general.repository";

const { REACT_APP_API_SERVER, REACT_APP_URLPREFIX } = process.env;

export default class UserRepository extends GeneralRepository implements IRepository {
  async signIn(data: SignInDto, cb?: any, cbparameters?: any): Promise<any> {
    return await this.postAxios(
      `${REACT_APP_API_SERVER}/auth/signin`,
      cb,
      data,
      cbparameters
    );
  }

  async signInHash(
    data: SignInHashDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    return await this.postAxios(
      `${REACT_APP_API_SERVER}/auth/signin-hash`,
      cb,
      data,
      cbparameters
    );
  }

  async refreshToken(cb?: any, cbparameters?: any): Promise<any> {
    return await this.getAxios(
      `${REACT_APP_API_SERVER}/auth/refresh`,
      cb,
      {},
      cbparameters
    );
  }

  async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/" + id;
    return await this._get(url, cb, cbparameters);
  }

  async getList(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user";
    
    return await this._getList(url, cb, cbparameters, data);
  }

  async add(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user";

    return await this.postAxios(url, cb, data, cbparameters);
  }

  async addNewStudent(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/new-student";

    return await this.postAxios(url, cb, data, cbparameters);
  }

  async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/" + id;
    return await this.deleteAxios(url, cb, cbparameters);
  }

  async update(id: string, cb?: any, data?: Idto, cbparameters?: any): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/" + id;
    
    return await this.putAxios(url, cb, data, cbparameters);
  }

  async patch(id: string, cb?: any, data?: Idto, cbparameters?: any): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/" + id;
    
    return await this.patchAxios(url, cb, data, cbparameters);
  }
  async modifyPasswordByAdmin(id: string, cb?: any, data?: Idto, cbparameters?: any): Promise<any> {
    const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/user/password/" + id;
    
    return await this.patchAxios(url, cb, data, cbparameters);
  }
  
  async modifyPassword(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
    const url = REACT_APP_API_SERVER + "/user/modify-password";

    return await this.postAxios(url, cb, data, cbparameters);
  }
  
}
