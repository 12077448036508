
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class PostRecommendationPriceDto implements Idto {

    status?: number;
    ordercriteria?: number;
    idlanguage?: string;
    idrecommendationprice?: string;
    
    name?: string;
    idpricepolicy?: string;
    idcurrency?: string;
    price?: number;
    isdefault?: boolean;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
        this.isdefault = false;
    }
}

export class RecommendationPriceDto implements Idto {
    
    id?: string;
    status?: number;
    ordercriteria?: number;
    idlanguage?: string;
    idrecommendationprice?: string;
    language?: any;
    status_name?: string;
    name?: string;
    idpricepolicy?: string;
    idcurrency?: string;
    price?: number;
    pricepolicy?: any;
    currency?: any;
    isdefault?: boolean;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
        this.isdefault = false;
    }
}