
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class GalleryDto implements Idto {
    id?: string;
    status?: number;
    ordercriteria?: number;
    idfile?: string;
    parent?: string;
    idparent?: string;
    isdefault?: boolean;
    idlanguage?: string;
    language?: any;
    status_name?: string;
    idgallery?: string;
    name?: string;
    fileInfoObj?: any;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
    }
}


export class PostGalleryDto implements Idto {
  status?: number;
  ordercriteria?: number;
  idfile?: string | null;
  parent?: string;
  idparent?: string;
  isdefault?: boolean;
  idlanguage?: string;
  language?: any;
  status_name?: string;
  idgallery?: string;
  name?: string;
  files?: any;
  fileInfoObj?: any;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
  }
}