import Idto from "interfaces/idto.interface";
import SelectOptions from "./selectoptions.dto";

export default class TbFilterDTO implements Idto {
  field: string;
  type: number;
  label?: string;
  labelto?: string;
  values?: Array<SelectOptions>;

  constructor(f: string, t: number, l?: string, lto?: string) {
    this.field = f;
    this.type = t;
    this.values = [];
    this.label = l;
    this.labelto = lto;
  }

  addValue(v: SelectOptions) {
    this.values?.push(v);
  }

  setValues(v: Array<SelectOptions>) {
    this.values = v;
  }
}
