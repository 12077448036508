import { useLocation, Navigate } from "react-router-dom";

import { useAuth } from "hook/useAuth";
import { logger } from "hoc/logger";

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { user } = useAuth();

  // if (!user) {
  //   if (location.pathname != "/login") {
  //     return <Navigate to="/login" state={{ from: location }} />;
  //   }
  //   return children;
  // } else if (location.pathname == "/login") {
  //   return <Navigate to="/" state={{ from: location }} />;
  // }
  return children;
};

export { RequireAuth };
