
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class PostAgreementDto implements Idto {

    status?: number;
    idlanguage?: string;
    idagreement?: string;
    type?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    name?: string;
    description?: string;    
    idtypeagreement?:string;
    constructor() {
        this.status = Status.ACTIVE;
    }
}

export class AgreementDto implements Idto {
    
    id?: string;
    status?: number;
    idlanguage?: string;
    idagreement?: string;
    type?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    name?: string;
    description?: string;    
    idtypeagreement?:string;
    language?: any;
    status_name?: any;
    date_name?: any;
    typeagreement?:any;
    
    constructor() {
        this.status = Status.ACTIVE;
    }
}