import React, { useState, useEffect } from 'react';

import LangService from "services/lang/lang.service";
import { logger } from 'hoc/logger';

import { PostLanguageDto } from 'dto/language/postlanguage.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { StatusSelect } from "components/elements/Select/StatusSelect"
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Status } from 'tools/status';
import IconButton from '@mui/material/IconButton';
const languageService = new LangService();

interface AddLanguageProps {
    children: any;
    currentRoute: any;
    _obj: PostLanguageDto,
    mainObject: any;
}

const AddLanguage: React.FC<AddLanguageProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

    const [loading, setLoading] = useState<boolean>(false);
    var [obj, setObj] = useState<PostLanguageDto>(new PostLanguageDto());


    const [isFormDisabled, setIsFormDisabled] = useState(true);

    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.name = obj.name !== undefined ? obj.name : '';
        obj.cod2 = obj.cod2 !== undefined ? obj.cod2 : '';
        obj.cod3 = obj.cod3 !== undefined ? obj.cod3 : '';
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;
        obj.hmtlcode = obj.hmtlcode !== undefined ? obj.hmtlcode : '';

        setObj(obj);

        let t = validateField("name", obj.name);
        t = validateField("cod2", obj.cod2, t);
        t = validateField("cod3", obj.cod3, t);
        // t = validateField("status", obj.status, t);
        t = validateField("ordercriteria", obj.ordercriteria, t);
        t = validateField("hmtlcode", obj.hmtlcode, t);
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            name: [],
            cod2: [],
            cod3: [],
            // status: [],
            ordercriteria: [],
            hmtlcode: [],
        };

        v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
        v.cod2.push(new RequiredValidator(LL('Required field cod2', LabelTypes.LABEL_VALIDATION), ''));
        v.cod3.push(new RequiredValidator(LL('Required field cod3', LabelTypes.LABEL_VALIDATION), ''));
        // v.status.push(new RequiredValidator(LL('Required field status', LabelTypes.LABEL_VALIDATION), ''));
        v.ordercriteria.push(new RequiredValidator(LL('Required field ordercriteria', LabelTypes.LABEL_VALIDATION), ''));
        v.hmtlcode.push(new RequiredValidator(LL('Required field hmtlcode', LabelTypes.LABEL_VALIDATION), ''));

        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    useEffect(() => {
        processValidators();
    }, []);

    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'name');
        rez = ValidateTools.vForm(vresults, 'cod2');
        rez = ValidateTools.vForm(vresults, 'cod3');
        // rez = ValidateTools.vForm(vresults, 'status');
        // rez = ValidateTools.vForm(vresults, 'ordercriteria');
        rez = ValidateTools.vForm(vresults, 'hmtlcode');


        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        languageService.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
    };


    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Add" + mainObject) },
    ];
    return !loading ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <MyNavBt
            tabIndex="7"
            href={listUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />

          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {LL("AddBlock")}
          </Typography>
        </Box>

        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <form onSubmit={handleSubmit}>
            <MyTextField
              tabIndex={0}
              fullWidth
              id="name"
              name="name"
              variant="outlined"
              color="primary"
              _label={LL("label_text_field_Name")}
              size="small"
              value={obj.name}
              setObj={setObjField}
              _vresults={vresults && vresults.name ? vresults.name : false}
            />
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={1}
                fullWidth
                id="cod2"
                name="cod2"
                variant="outlined"
                color="primary"
                _label={LL("label_text_field_cod2")}
                size="small"
                value={obj.cod2}
                setObj={setObjField}
                _vresults={vresults && vresults.cod2 ? vresults.cod2 : false}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={2}
                fullWidth
                id="cod3"
                name="cod3"
                variant="outlined"
                color="primary"
                _label={LL("label_text_field_cod3")}
                size="small"
                value={obj.cod3}
                setObj={setObjField}
                _vresults={vresults && vresults.cod3 ? vresults.cod3 : false}
              />
            </Box>
            {/* <MyTextField
                        fullWidth
                        id="status"
                        name="status"
                        variant="outlined"
                        color="primary"
                        _label={LL("label_text_field_status")}
                        size="small"
                        value={obj.status}
                        setObj={setObjField}
                        _vresults={
                            vresults && vresults.status ? vresults.status : false
                        }
                    /> */}
            <Box sx={{ mt: 3 }}>
              <StatusSelect
                tabIndex="3"
                id="status"
                name="status"
                variant="outlined"
                color="primary"
                value={obj.status}
                field="status"
                size="small"
                setObj={setObjField}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex="4"
                fullWidth
                id="ordercriteria"
                name="ordercriteria"
                variant="outlined"
                color="primary"
                _label={LL("label_text_field_ordercriteria")}
                size="small"
                value={obj.ordercriteria}
                setObj={setObjField}
                _vresults={
                  vresults && vresults.ordercriteria
                    ? vresults.ordercriteria
                    : false
                }
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex="5"
                fullWidth
                id="hmtlcode"
                name="hmtlcode"
                variant="outlined"
                color="primary"
                _label={LL("label_text_field_hmtlcode")}
                size="small"
                value={obj.hmtlcode}
                setObj={setObjField}
                _vresults={
                  vresults && vresults.hmtlcode ? vresults.hmtlcode : false
                }
              />
            </Box>

            <MyButton
              tabIndex="6"
              disabled={isFormDisabled}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
          </form>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default AddLanguage;
