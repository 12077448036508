import React, { useState, useEffect } from 'react';

import CourseSettingsService from "services/course/coursesettings.service";
import { logger } from 'hoc/logger';

import { CourseSettingsDto } from 'dto/course/coursesettings.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

const courseSettingsService = new CourseSettingsService();


interface CourseSettingsProps {
    currentRoute: any;

}

const CourseSettingsInfo: React.FC<CourseSettingsProps> = ({
    currentRoute,
    ...props
}) => {

    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<Array<CourseSettingsDto> | undefined>();
    const [id] = useState<string>(currentRoute._paths[1]);


    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {
        if (id !== undefined) courseSettingsService.getCourseSettingsByCourseId(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [currentRoute]);

    useEffect(() => {
        checkIsLoading();

    }, [obj]);


    return !loading && obj ? (
        <>

            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4, marginTop: '1%' }}
            >

                <Grid spacing={2} >

                    <Grid>
                        <Typography color="text.disabled" variant='caption'> {LL("typecourse")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'typecourse', 'name')}
                    </Grid>

                    <Grid mt={3}>
                        <Typography color="text.disabled" variant='caption' >{LL("Password")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, 'password')}
                    </Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant='caption' >{LL("HasTest")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, 'hastest_name')}
                    </Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant='caption' >{LL("maxnumberofstudent")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, 'maxnumberofstudent')}
                    </Grid>
                </Grid>
            </Box>
        </>
    ) : (
        <></>
    );
};

export default CourseSettingsInfo;
