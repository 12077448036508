import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import {CourseSettingsDto} from 'dto/course/coursesettings.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CourseSettingsService from "services/course/coursesettings.service";
import IconButton from '@mui/material/IconButton';
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { TypeGenderSelect } from "components/elements/Select/GenderTypeSelect";
import { CourseTypeSelect } from 'components/elements/Select/CourseTypeSelect';
import MyDatePicker from 'components/elements/MyDatePicker/MyDatePicker';
import { Status } from 'tools/status';
const courseSettings = new CourseSettingsService();

interface EditCourseSettingsInfoProps {

    currentRoute: any;

    mainObject: any;
}

const EditCourseSettingsInfo: React.FC<EditCourseSettingsInfoProps> = ({
    currentRoute,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<CourseSettingsDto | undefined>()
    const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);



    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.id = obj.id ?? obj.id;
        obj.idtypecourse = obj.idtypecourse !== undefined ? obj.idtypecourse : '';
        obj.password = obj.password !== undefined ? obj.password : '';
        obj.maxnumberofstudents = obj.maxnumberofstudents !== undefined ? obj.maxnumberofstudents : undefined;

        setObj(obj);

        let t = validateField("password", obj.password);
        
        logger("validateAll", t);
    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        
        return v;
    };

    const processValidators = () => {
        let v: any = {
            password: [],
            
        };

        v.password.push(new RequiredValidator(LL('Required field password', LabelTypes.LABEL_VALIDATION), ''));
        

        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {

            courseSettings.getCourseSettingsByCourseId(id, loadObject, {});
        }
    };

    useEffect(() => {
        processValidators();
    }, []);
    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'password');
        setIsFormDisabled(rez);
    };

    useEffect(() => {
        validateAll();
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);

    useEffect(() => {
        if (obj === undefined) getObject();
    }, [obj]);


    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (obj && obj.id !== undefined) {
            obj.idcourse = id;
            courseSettings.update(obj.id, ComponentCommonTools.handleSuccessAddAndEdit, { mainObject, idRoute: id,anchor:"courseSettings" }, obj);
        }
    };
    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };


    return !loading && obj ? (
        <>
            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit} >
                <CourseTypeSelect
                            tabIndex={0}
                            id="idtypecourse"
                            name="idtypecourse"
                            variant="outlined"
                            color="primary"
                            value={obj.idtypecourse}
                            field="idtypecourse"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypecourse}
                        />
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="password"
                            name="password"
                            variant="outlined"
                            color="primary"
                            _label={LL("password")}
                            size="small"
                            value={obj.password}
                            setObj={setObjField}
                            _vresults={false}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="maxnumberofstudents"
                            name="maxnumberofstudents"
                            variant="standard"
                            color="primary"
                            _label={LL("Max_number_of_students")}
                            size="small"
                            value={obj.maxnumberofstudents}
                            setObj={setObjField}
                            _vresults={false}
                        />
                    </Box>
                    <MyButton
                        tabIndex={2}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>

            </Box>
        </>
    ) : (
        <Loading />
    );
};

export default EditCourseSettingsInfo;