import React, { useState, useEffect } from 'react';

import { logger } from 'hoc/logger';

import { PostLanguageDto } from 'dto/language/postlanguage.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { StatusSelect } from "components/elements/Select/StatusSelect"
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LanguageSelect } from 'components/elements/Select/LanguageSelect';
import IconButton from '@mui/material/IconButton';
import { TypesSelect } from 'components/elements/Select/TypesSelect';
import AdvertisementService from 'services/advertisement/advertisement.service';
import { AdvertisementDto } from 'dto/advertisement/advertisement.dto';
import MyCheckboxField from 'components/form/mycheckboxfield';
import { PageAutocomplete } from 'components/elements/Autocomplete/PageAutocomplete';
import { SectionSelect } from 'components/elements/Select/SectionSelect';
import { PageSelect } from 'components/elements/Select/PageSelect';
import { AdvertisementTypeSelect } from 'components/elements/Select/AdvertisementTypeSelect';
import { Status } from 'tools/status';
import { AdvertisementSectionSelect } from 'components/elements/Select/AdvertisementSectionSelect';
import MyDatePicker from 'components/elements/MyDatePicker/MyDatePicker';
const advertisementService = new AdvertisementService();
interface EditAdvertisementProps {
    children: any;
    currentRoute: any;
    _obj: AdvertisementDto,
    mainObject: any;
}

const EditAdvertisement: React.FC<EditAdvertisementProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,

    ...props
}) => {
    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");
    const [addUrl, setAddUrl] = useState("");

    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const au = CommonTools.generateAddUrl(mainObject);
        setAddUrl(au);
        const lu = _getListUrl(mu);
        setListUrl(lu);
    }, [mainObject, currentRoute]);

    const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';
    const _idLanguage = currentRoute.historystate.idLanguage !== undefined ? currentRoute.historystate.idLanguage : '';
    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<AdvertisementDto | undefined>()
    const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const [idLanguage, setIdLanguage] = useState<string>(_idLanguage);
    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);

    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;
        obj.idadvertisement = obj.idadvertisement !== undefined ? obj.idadvertisement : '';
        obj.name = obj.name !== undefined ? obj.name : '';
        obj.description = obj.description !== undefined ? obj.description : '';
        obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.startdate = obj.startdate !== undefined ? obj.startdate : undefined;
        obj.enddate = obj.enddate !== undefined ? obj.enddate : undefined;
        obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : '';
        obj.type = obj.type !== undefined ? obj.type : 0;
        obj.clabt = obj.clabt !== undefined ? obj.clabt : '';
        obj.section = obj.section !== undefined ? obj.section : 0;
        obj.url = obj.url !== undefined ? obj.url : '';

        setObj(obj);

        let t = validateField("name", obj.name);
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            name: [],
            // ordercriteria: [],
            // description: []
        };

        v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
        // v.description.push(new RequiredValidator(LL('Required field description', LabelTypes.LABEL_VALIDATION), ''));
        // v.ordercriteria.push(new RequiredValidator(LL('Required field ordercriteria', LabelTypes.LABEL_VALIDATION), ''));
        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {
            if (idLanguage === '') advertisementService.get(id, loadObject, {});
            else advertisementService.get(id, loadObject, {}, idLanguage);
        }
    };

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults, obj?.startdate, obj?.enddate]);

    useEffect(() => {
        processValidators();
        if (id === '') {
            if (obj !== undefined) {
                setId(obj.id)
                if (obj.idlanguage !== undefined) {
                    setIdLanguage(obj.idlanguage)
                }
            }
        }
        if (idLanguage !== '') getObject();

    }, [idLanguage]);

    useEffect(() => {
        validateAll();
        checkLoading();
        if (idLanguage === '') {
            if (obj !== undefined) {
                if (obj.idlanguage !== undefined) setIdLanguage(obj.idlanguage)
            }
        }
    }, [obj, validators]);


    useEffect(() => {

        if (currentRoute.historystate.obj !== undefined) {
            setObj(currentRoute.historystate.obj);
            if (idLanguage === '') setIdLanguage(currentRoute.historystate.obj.idlanguage)
        }
        if (obj === undefined) getObject();

    }, []);



    const setObjFieldLanguage = (field: any, value: any) => {
        if (value !== undefined) setIdLanguage(value)
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (isFormDisabled) return false;
        if (obj !== undefined) {
            if (obj.id !== undefined) {
                obj.idlanguage = idLanguage;
                setLoading(true);
                advertisementService.update(
                    obj.id,
                    ComponentCommonTools.handleSuccessAddAndEdit,
                    { mainObject, idLanguage },
                    obj
                );
            }
        }
    };



    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'name');
        // rez = ValidateTools.vForm(vresults, 'ordercriteria', rez);
        // rez = ValidateTools.vForm(vresults, 'description', rez);
        // if (obj && obj.startdate && obj.enddate) {
        //     if (obj.startdate > obj.enddate) {
        //         rez = false;
        //     }
        // } else {
        //     rez = true;
        // }


        setIsFormDisabled(rez);
    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };


    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        {
            url: detailUrl,
            label: LL("crumb_Detail" + mainObject),
        },
        { label: LL("crumb_Edit" + mainObject) },
    ];
    return !loading && obj ? (
        <>

            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
            <Box sx={{ flexDirection: 'row', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                <MyNavBt
                    tabIndex={7}
                    href={detailUrl}
                    children={<IconButton color="primary"><ArrowBackIcon /></IconButton>}
                    size="large"
                    _hstate={{}}
                    _mainurl={mainUrl}
                />
                <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
                    {LL("EditBlock")}
                </Typography>
            </Box>
            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit} >
                    <LanguageSelect
                        tabIdex={0}
                        id="idlanguage"
                        name="idlanguage"
                        variant="outlined"
                        color="primary"
                        value={idLanguage}
                        field="idlanguage"
                        size="small"
                        setObj={setObjFieldLanguage}
                        obj={obj}
                    />
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="name"
                            name="name"
                            variant="outlined"
                            color="primary"
                            _label={LL("name")}
                            size="small"
                            value={obj.name}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.name ? vresults.name : false
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={2}
                            fullWidth
                            id="description"
                            name="description"
                            variant="outlined"
                            color="primary"
                            _label={LL("description")}
                            size="small"
                            value={obj.description}
                            setObj={setObjField}
                            _vresults={
                                undefined
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={3}
                            fullWidth
                            id="clabt"
                            name="clabt"
                            variant="outlined"
                            color="primary"
                            _label={LL("clabt")}
                            size="small"
                            value={obj.clabt}
                            setObj={setObjField}
                            _vresults={
                                undefined
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={4}
                            fullWidth
                            id="ordercriteria"
                            name="ordercriteria"
                            variant="outlined"
                            color="primary"
                            _label={LL("ordercriteria")}
                            size="small"
                            value={obj.ordercriteria}

                            setObj={setObjField}
                            _vresults={
                                undefined
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={5}
                            fullWidth
                            id="url"
                            name="url"
                            variant="outlined"
                            color="primary"
                            _label={LL("url")}
                            size="small"
                            value={obj.url}

                            setObj={setObjField}
                            _vresults={
                                undefined
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <StatusSelect
                            tabIndex={6}
                            id="status"
                            name="status"
                            variant="outlined"
                            color="primary"
                            value={obj.status}
                            field="status"
                            size="small"
                            setObj={setObjField}
                            _defaultValue={obj.status}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <AdvertisementTypeSelect
                            tabIndex={7}
                            id="type"
                            name="type"
                            variant="outlined"
                            color="primary"
                            value={obj.type}
                            field="type"
                            size="small"
                            setObj={setObjField}
                            _defaultValue={obj.type}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <AdvertisementSectionSelect
                            tabIndex={8}
                            id="section"
                            name="section"
                            variant="outlined"
                            color="primary"
                            value={obj.section}
                            field="section"
                            size="small"
                            setObj={setObjField}
                            _defaultValue={obj.section}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }} >
                        <MyDatePicker
                            tabIndex={9}
                            field='startdate'
                            _defaultValue={obj.startdate}
                            LL={LL}
                            setObjField={setObjField}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }} >
                        <MyDatePicker
                            tabIndex={10}
                            field='enddate'
                            _defaultValue={obj.enddate}
                            LL={LL}
                            setObjField={setObjField}
                        />
                    </Box>
                    <MyButton
                        tabIndex={11}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>

            </Box>
        </>
    ) : (
        <Loading />
    );
};

export default EditAdvertisement;
