import React, { useState, useEffect } from "react";

import { logger } from "hoc/logger";

import { PostLanguageDto } from "dto/language/postlanguage.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import { StatusSelect } from "components/elements/Select/StatusSelect";
import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from "tools/routetools";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import IconButton from "@mui/material/IconButton";
import { TypesSelect } from "components/elements/Select/TypesSelect";
import RecommendationPriceService from "services/finance/recommendationprice.service";
import { RecommendationPriceDto } from "dto/finance/recommendationprice.dto";
import { CurrencySelect } from "components/elements/Select/CurrencyTypeSelect";
import { PricePolicySelect } from "components/elements/Select/PricePolicySelect";
import { Status } from "tools/status";
import MyCheckboxField from "components/form/mycheckboxfield";
const recommendationPriceService = new RecommendationPriceService();
interface EditRecommendationPriceProps {
  children: any;
  currentRoute: any;
  _obj: RecommendationPriceDto;
  mainObject: any;
}

const EditRecommendationPrice: React.FC<EditRecommendationPriceProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,

  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";
  const _idLanguage =
    currentRoute.historystate.idLanguage !== undefined
      ? currentRoute.historystate.idLanguage
      : "";
  const [id, setId] = useState<string | undefined>(_id);

  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<RecommendationPriceDto | undefined>();
  const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const [idLanguage, setIdLanguage] = useState<string>(_idLanguage);
  const [validators, setValidators] = useState<any>();
  const [vresults, setVResults] = useState<any>(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    // if (vresults) return;
    obj.idrecommendationprice =
      obj.idrecommendationprice !== undefined ? obj.idrecommendationprice : "";
    obj.name = obj.name !== undefined ? obj.name : "";
    obj.price = obj.price !== undefined ? obj.price : 0;
    obj.idcurrency = obj.idcurrency !== undefined ? obj.idcurrency : "";

    obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;
    obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;

    obj.idpricepolicy =
      obj.idpricepolicy !== undefined ? obj.idpricepolicy : "";

    setObj(obj);

    let t = validateField("name", obj.name);
    t = validateField("price", obj.price, t);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);
    setIsFormDisabled(ValidateTools.vForm(v, field));
    setVResults(v);
    return v;
  };

  const processValidators = () => {
    let v: any = {
      name: [],
      ordercriteria: [],
      price: [],
    };

    v.name.push(
      new RequiredValidator(
        LL("Required field name", LabelTypes.LABEL_VALIDATION),
        ""
      )
    );
    v.price.push(
      new RequiredValidator(
        LL("Required field price", LabelTypes.LABEL_VALIDATION),
        ""
      )
    );
    setValidators(v);
  };

  const setCheckFixed = (value: any) => {
    let t = JSON.parse(JSON.stringify(obj));

    t["isdefault"] = value;
    setObj(t);
  };

  const checkLoading = () => {
    if (!loading) return;
    if (obj === undefined) return;
    if (!validators) return;
    validateAll();
    setLoading(false);
  };

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined) {
      if (idLanguage === "") recommendationPriceService.get(id, loadObject, {});
      else recommendationPriceService.get(id, loadObject, {}, idLanguage);
    }
  };

  useEffect(() => {
    processValidators();
    if (id === "") {
      if (obj !== undefined) {
        setId(obj.id);
        if (obj.idlanguage !== undefined) {
          setIdLanguage(obj.idlanguage);
        }
      }
    }
    if (idLanguage !== "") getObject();
  }, [idLanguage]);

  useEffect(() => {
    validateAll();
    checkLoading();
    if (idLanguage === "") {
      if (obj !== undefined) {
        if (obj.idlanguage !== undefined) setIdLanguage(obj.idlanguage);
      }
    }
  }, [obj, validators]);
  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);

  useEffect(() => {
    processValidators();
    if (currentRoute.historystate.obj !== undefined) {
      setObj(currentRoute.historystate.obj);
      if (idLanguage === "")
        setIdLanguage(currentRoute.historystate.obj.idlanguage);
    }
    if (obj === undefined) getObject();
  }, []);

  const setObjFieldLanguage = (field: any, value: any) => {
    if (value !== undefined) setIdLanguage(value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (isFormDisabled) return false;
    if (obj !== undefined) {
      if (obj.id !== undefined) {
        obj.idlanguage = idLanguage;
        setLoading(true);
        recommendationPriceService.update(
          obj.id,
          ComponentCommonTools.handleSuccessAddAndEdit,
          { mainObject, idLanguage },
          obj
        );
      }
    }
  };

  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, "name");
    rez = ValidateTools.vForm(vresults, "price", rez);

    setIsFormDisabled(rez);
  };

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    {
      url: detailUrl,
      label: LL("crumb_Detail" + mainObject),
    },
    { label: LL("crumb_Edit" + mainObject) },
  ];
  return !loading && obj ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={7}
          href={detailUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />
        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {LL("EditBlock")}
        </Typography>
      </Box>
      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <form onSubmit={handleSubmit}>
          <LanguageSelect
            tabIdex={0}
            id="idlanguage"
            name="idlanguage"
            variant="outlined"
            color="primary"
            value={idLanguage}
            field="idlanguage"
            size="small"
            setObj={setObjFieldLanguage}
            obj={obj}
          />
          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={1}
              fullWidth
              id="name"
              name="name"
              variant="outlined"
              color="primary"
              _label={LL("Name")}
              size="small"
              value={obj.name}
              setObj={setObjField}
              _vresults={vresults && vresults.name ? vresults.name : false}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={2}
              fullWidth
              id="ordercriteria"
              name="ordercriteria"
              variant="outlined"
              color="primary"
              _label={LL("ordercriteria")}
              size="small"
              value={obj.ordercriteria}
              setObj={setObjField}
              _vresults={
                vresults && vresults.ordercriteria
                  ? vresults.ordercriteria
                  : false
              }
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={3}
              fullWidth
              type="number"
              id="price"
              name="price"
              variant="outlined"
              color="primary"
              _label={LL("price")}
              size="small"
              value={obj.price}
              setObj={setObjField}
              _vresults={vresults && vresults.price ? vresults.price : false}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyCheckboxField
              tabIndex={4}
              id="isdefault"
              name="isdefault"
              _label={LL("isdefault")}
              setObj={setCheckFixed}
              checked={obj.isdefault}
              color="primary"
              size="small"
              _vresults={undefined}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <StatusSelect
              tabIndex={4}
              id="status"
              name="status"
              variant="outlined"
              color="primary"
              value={obj.status}
              field="status"
              size="small"
              setObj={setObjField}
              _defaultValue={obj.status}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <CurrencySelect
              tabIndex={5}
              id="idcurrency"
              name="idcurrency"
              variant="outlined"
              color="primary"
              value={obj.idcurrency}
              field="idcurrency"
              size="small"
              setObj={setObjField}
              obj={obj}
              _defaultValue={obj.idcurrency}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <PricePolicySelect
              tabIndex={6}
              id="idpricepolicy"
              name="idpricepolicy"
              variant="outlined"
              color="primary"
              value={obj.idpricepolicy}
              field="idpricepolicy"
              idTypeCourse=""
              size="small"
              setObj={setObjField}
              obj={obj}
              _defaultValue={obj.idpricepolicy}
            />
          </Box>

          <MyButton
            tabIndex={7}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Submit")}
          />
        </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default EditRecommendationPrice;
