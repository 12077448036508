import React, { useState, useEffect } from 'react';

import FilterService from "services/course/filter.service";
import { logger } from 'hoc/logger';

import { FilterDto, PostFilterDto } from 'dto/course/filter.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { LanguageSelect } from 'components/elements/Select/LanguageSelect';
import { LessonTypeSelect } from 'components/elements/Select/LessonTypeSelect';
import { TeacherStatusSelect } from 'components/elements/Select/TeacherStatusSelect';
import { FilterTypeSelect } from 'components/elements/Select/FilterTypeSelect';
import { Status } from 'tools/status';
import MyCheckboxField from "components/form/mycheckboxfield";
const filterService = new FilterService();

interface AddFilterProps {
  children: any;
  currentRoute: any;
  _obj: FilterDto,
  mainObject: any;
}

const AddFilter: React.FC<AddFilterProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();


  const [loading, setLoading] = useState<boolean>(false);
  var [obj, setObj] = useState<PostFilterDto>(new PostFilterDto());


  const [isFormDisabled, setIsFormDisabled] = useState(true);


  const [validators, setValidators] = useState(false);
  const [vresults, setVResults] = useState<any>(false);


  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    // if (vresults) return;

    obj.name = obj.name !== undefined ? obj.name : '';

    obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;
    obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;

    obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : '';
    obj.type = obj.type !== undefined ? obj.type : 0;

    obj.ismain = obj.ismain !== undefined ? obj.ismain : false;
    setObj(obj);

    let t = validateField("name", obj.name);
    t = validateField("ordercriteria", obj.ordercriteria, t);


  };

  const validateField = (field: any, value: any, v?: any) => {
    v = (v) ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);
    setVResults(v);
    return v;
  };

  const processValidators = () => {
    let v: any = {
      name: [],
      ordercriteria: [],

    };

    v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
    v.ordercriteria.push(new RequiredValidator(LL('Required field ordercriteria', LabelTypes.LABEL_VALIDATION), ''));
    setValidators(v);
  };

  const checkLoading = () => {
    if (!loading) return;
    if (!obj) return;
    if (!validators) return;
    validateAll();
    setLoading(false);
  };

  useEffect(() => {
    validateAll();
    processValidators();
  }, []);

  useEffect(() => {
    checkLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);


  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);

    validateField(field, value);
  };



  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, 'name');
    // rez = ValidateTools.vForm(vresults, 'ordercriteria', rez);


    setIsFormDisabled(rez);
  };

  const setCheckIsMain = (value: any) => {
    let t = JSON.parse(JSON.stringify(obj));

    t['ismain'] = value;
    setObj(t);

  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;

    filterService.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
  };


  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    { label: LL("crumb_Add" + mainObject) },
  ];
  return !loading ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={8}
          href={listUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />

        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {LL("AddBlock")}
        </Typography>
      </Box>

      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <form onSubmit={handleSubmit}>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("Name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={vresults && vresults.name ? vresults.name : false}
          />

          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={1}
              fullWidth
              id="ordercriteria"
              name="ordercriteria"
              variant="outlined"
              color="primary"
              _label={LL("ordercriteria")}
              size="small"
              value={obj.ordercriteria}
              setObj={setObjField}
              _vresults={
                vresults && vresults.ordercriteria
                  ? vresults.ordercriteria
                  : false
              }
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <StatusSelect
              tabIndex={4}
              id="status"
              name="status"
              variant="outlined"
              color="primary"
              value={obj.status}
              field="status"
              size="small"
              setObj={setObjField}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <FilterTypeSelect
              tabIndex={5}
              id="type"
              name="type"
              variant="outlined"
              color="primary"
              value={obj.type}
              field="type"
              size="small"
              setObj={setObjField}
              obj={obj}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <LanguageSelect
              tabIndex={5}
              id="idlanguage"
              name="idlanguage"
              variant="outlined"
              color="primary"
              value={obj.idlanguage}
              field="idlanguage"
              size="small"
              setObj={setObjField}
              obj={obj}
              forAddForms={true}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyCheckboxField

              tabIndex={6}
              id="ismain"
              name="ismain"
              _label={LL("ismain")}
              setObj={setCheckIsMain}
              checked={obj.ismain}
              color="primary"
              size="small"
              _vresults={undefined}
            />
          </Box>

          <MyButton
            tabIndex={7}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Submit")}
          />
        </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default AddFilter;
