import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';

import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';

import LessonService from "services/course/lesson.service";

import MyTable from 'components/elements/TabelTools/MyTable';
import ResultListDTO from 'dto/app/resultlist.dto';
import TbFilterDTO from 'dto/app/tbfilter.dto';
import TbFilterListDTO from 'dto/app/tbfilterlist.dto';
import FilterTools from 'tools/filtertools';
import RequestListDTO from 'dto/app/requestlist.dto';
import RequestFilterDTO from 'dto/app/requestfilter.dto';
import { LessonDto } from 'dto/course/lesson.dto';
import { FilterAutocomplete } from 'components/elements/Autocomplete/FilterAutocomplete';

import { FilterDictionaryAutocomplete } from 'components/elements/Autocomplete/FilterDictionaryAutocomplete';


const lessonService = new LessonService();

interface LessonProps {

    currentRoute: any;

    mainObject: any;
}

const Lesson: React.FC<LessonProps> = ({
    currentRoute,
    mainObject,
    ...props
}) => {


    const { LL } = useResource();
    const _id = currentRoute._paths !== undefined ? currentRoute._paths[1] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);


    const [cookies, setCookies] = useCookies();

    const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
    const [rows, setRows] = useState<Array<LessonDto>>(new Array<LessonDto>());

    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const [responseParams, setResponseParams] = useState<RequestListDTO>(
        new RequestListDTO()
    );

    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
    const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
        new TbFilterListDTO()
    );
    const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);


    const handleDelete = (item: any) => {

        lessonService.delete(item.id, getList, undefined);
    };

    const deleteObj = (obj: any) => {
        setDeleteOneObject(obj);
    };

    const columns = [];
    columns.push(ComponentCommonTools.columns_GetCheck());
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "name"));
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "description"));
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"));
    columns.push(ComponentCommonTools.columns_TimestampObjectColumn(LL, "lessonduration"));
    columns.push(ComponentCommonTools.columns_StandardObjectColumn(LL, "typelesson", 'name'));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL, 'istest'));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL, 'islive'));
    columns.push(
        ComponentCommonTools.columns_ActionsColumn(
            LL,
            mainObject,
            deleteObj,
            ["delete","gallery", "attachment", "video"],
        )
    );
    
    const loadObjects = (data: ResultListDTO) => {
        CommonTools.processListLoadObjects(
            data,
            setData,
            setRows,
            setTotal,
            setTotalPage,
            setResponseParams
        );
    };

    const getList = () => {
        setLoading(true);
        if (id !== undefined) lessonService.getList(loadObjects, {}, reqList);
    };

    const getReqList = () => {
        let reqList = RouteTools.prepareListRequest(currentRoute, cookies);
        reqList = lessonService.getDefaultRequestList(reqList);
        const filters = new Array<RequestFilterDTO>()
        const filter = new RequestFilterDTO();
        filter.field = "idcourse";
        if (id !== undefined) filter.values = [id];
        else filter.values = [];
        filters.push(filter);
        reqList.filters = filters;

        setReqList(reqList);
    };


    const checkIsLoading = () => {

        const load: boolean = CommonTools.checkIsLoading(
            reqList,
            responseParams,
            data,
            total,
            totalPage,
            rows
        );
        setLoading(load);
    };

    const processTbFilterList = () => {
        const _tobj = new TbFilterListDTO();

        var t: TbFilterDTO;

        t = new TbFilterDTO("search", FilterTools.fiterTypeText);
        _tobj.addFilter(t);



        setTbFilterList(_tobj);
    };

    useEffect(() => {
        processTbFilterList();

    }, []);

    useEffect(() => {
        getReqList();
    }, [currentRoute]);

    useEffect(() => {
        if (CommonTools.atLeastOneFieldDefined(reqList)) {
            getList();
        }
    }, [reqList]);

    useEffect(() => {
        checkIsLoading();
    }, [data, rows, reqList, total, totalPage]);



    return  (

        <Box >
            <MyTable
                _columns={columns}
                _data={rows}
                _total={total}
                _totalPage={totalPage}
                _reqList={reqList}
                _tbFilterList={tbFilterList}
                setReqList={setReqList}
                loading={loading}
                currentRoute={currentRoute}
                onDelete={handleDelete}
                mainObject={mainObject}
                fieldToShowOnDelete="name"
                deleteOneObject={deleteOneObject}
                setDeleteOneObject={setDeleteOneObject}
                onRowClickCb={(obj: any) => { ComponentCommonTools.goToDetailsKeepingMainObject(obj, "Lesson") }}
                {...props}
            />
        </Box>

    ) 
};

export default Lesson;
