import React, { useState, useEffect } from 'react';

import CountryService from "services/nomenclature/country.service";
import GenAddType from 'components/elements/MyGenComponents/GenAddType';
import { PostNomenclatureDto } from 'dto/nomenclature/postnomenclature.dto';


const countryService = new CountryService();


interface AddCountryProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const AddCountry: React.FC<AddCountryProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenAddType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={countryService}
            mainObject={mainObject}
            DTO={PostNomenclatureDto}
            {...props}
        />
    )
};

export default AddCountry;
