import React, { useEffect, useState } from "react";

import { useResource } from "hook/useResource";

import LocalityService from "services/address/locality.service";
import { GenAutocompleteSearch } from "./GenAutocompleteSearch";

const localityService = new LocalityService();
interface Props {
    obj?: any;
    setObj?: (obj: any) => void;
    _idfield?: string;
    _objfield?: string;
    _label?: string;
    setObjFieldGeneral: (field: any, value: any) => void;
    [key: string]: any;
}

const LocalityAutocomplete: React.FC<Props> = ({
    obj,
    setObj,
    _idfield,
    _objfield,
    _label,
    setObjFieldGeneral,
    ...props
}) => {
    const { LL } = useResource();

    const [label, setLabel] = useState("");
    var tidfield = _idfield != undefined ? _idfield : "idlocality";
    var tobjfield = _objfield != undefined ? _objfield : "locality";
    
    const [idfield, setIdField] = useState(tidfield);
    const [objfield, setObjField] = useState(tobjfield);
    const [mainObject, setMainObject] = React.useState('Locality');


    useEffect(() => {
        if (obj == undefined) return;
        if (!obj) return;
        if (mainObject == undefined) return;
        if (!mainObject) return;

        var L = _label != undefined && _label ? _label : LL(mainObject);

        setLabel(L);

    }, [obj, _label]);

    const handleSetObjFieldGeneral = (field: any, value: any) => {
        setObjFieldGeneral(field, value);
    }
    return (
        <GenAutocompleteSearch
            obj={obj}
            setObj={setObj}
            _idfield={idfield}
            _objfield={objfield}
            _mainObject={mainObject}
            _label={label}
            _showfield={"name"}
            service={localityService}
            searchField={"searchvalue"}
            // setObjFieldGeneral={handleSetObjFieldGeneral}
            {...props}
        />
    );
};

export { LocalityAutocomplete };
