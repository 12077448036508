import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { TicketMessageDto } from 'dto/support/ticketmessage.dto'
import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import { Box, Stack, Divider, ListItemButton, ListItemText, Link } from "@mui/material";
import { logger } from "hoc/logger";
import { Types } from "tools/types";
import { TicketMessageItemAdmin } from "./TicketMessageItemAdmin";
import { TicketMessageItemTeacher } from "./TicketMessageItemTeacher";
import { TicketMessageItemStudent } from "./TicketMessageItemStudent";


type TicketMessageItemProps = {
    obj: TicketMessageDto;
}

const TicketMessageItem: React.FC<TicketMessageItemProps> = ({ obj, ...props }) => {


    const processItem = () => {
        if (!obj) return <></>
        if (!obj.hasOwnProperty('typesender')) return <></>
        if (!obj.typesender) return <></>
        
        if(obj.typesender === Types.TYPE_SENDER_ADMIN) return <TicketMessageItemAdmin obj={obj} />
        if(obj.typesender === Types.TYPE_SENDER_TEACHER) return <TicketMessageItemTeacher obj={obj} />
        if(obj.typesender === Types.TYPE_SENDER_STUDENT) return <TicketMessageItemStudent obj={obj} />
        
        return <></>;

    }



    return processItem();
};

export { TicketMessageItem };
