import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import MyTable from "components/elements/TabelTools/MyTable";
import MenuService from "services/menu/menu.service";
import { logger } from "hoc/logger";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { MenuDto } from "dto/menu/menu.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import { CommonTools } from "tools/commontools";
import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { ComponentCommonTools } from "tools/componentcommontools";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import AddIcon from "@mui/icons-material/Add";
import { GridActionsCellItem } from "@mui/x-data-grid";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { MyButton } from "components/general/MyButton";

import FilterTools from "tools/filtertools";
import { RouteTools } from "tools/routetools";
import { Status } from "tools/status";

import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { Types } from "tools/types";

const menuService = new MenuService();

interface MenuProps {
  children: any;
  currentRoute: any;
  mainObject: any;
}

const Menu: React.FC<MenuProps> = ({
  children,
  currentRoute,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const lu = CommonTools.generateListUrl(mainObject, currentRoute);
    setListUrl(lu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
  }, [mainObject, currentRoute]);

  useEffect(() => {
    _setListUrl(listUrl);
  }, [listUrl]);

  const { LL, _getListUrl, _setListUrl } = useResource();
  const [cookies, setCookies] = useCookies();

  const [obj, setObj] = useState<MenuDto | undefined>(undefined);
  const [idParent, setIdParent] = useState<string>(
    RouteTools.getDefaultIdParent(currentRoute, cookies)
  );
  const [id, setId] = useState<string>("");

  const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  const [rows, setRows] = useState<Array<MenuDto>>(new Array<MenuDto>());

  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
  );

  const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
  const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
    new TbFilterListDTO()
  );

  const deleteUser = (obj: any) => {
    setDeleteOneObject(obj);
  };

  const setParent = (obj: any) => {
    setLoading(true);
    setIdParent(obj.id);
    setObj(obj);
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);

  const columns = [];
  columns.push(ComponentCommonTools.columns_GetCheck());
  columns.push(ComponentCommonTools.columns_StandardColumn(LL, "name"));
  columns.push(ComponentCommonTools.columns_ConstantColumn(LL));
  columns.push(ComponentCommonTools.columns_ConstantColumn(LL, "section"));
  columns.push(
    ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria")
  );
  columns.push(
    ComponentCommonTools.columns_ActionsColumn(
      LL,
      mainObject,
      deleteUser,
      ["edit", "detail", "delete", "children"],
      ["detail"],
      setParent
    )
  );

  const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
      data,
      setData,
      setRows,
      setTotal,
      setTotalPage,
      setResponseParams
    );
  };

  const _setReqList = (_reqList: RequestListDTO) => {
    _reqList = CommonTools.addToRequestFilter(_reqList, "idparent", idParent);
    setReqList(_reqList);
  };

  const getList = () => {
    setLoading(true);

    const _reqList: RequestListDTO = CommonTools.addToRequestFilter(
      reqList,
      "idparent",
      idParent
    );

    setReqList(_reqList);

    const urlPart = RouteTools.prepareListLocation(reqList);

    RouteTools.setHistory(mainUrl + urlPart, {});
    setListUrl(mainUrl + urlPart);
    menuService.getList(loadObjects, {}, reqList);
  };

  const getReqList = () => {
    if (idParent != id) {
      setId(idParent);
    }
    let reqList = RouteTools.prepareListRequest(currentRoute, cookies);
    reqList = menuService.getDefaultRequestList(reqList, idParent);

    setReqList(reqList);
  };

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== "") {
      menuService.get(id, loadObject, {});
    }
  };

  useEffect(() => {
    if (id !== "") getObject();
  }, [id]);

  const goUp = (e: any) => {
    CommonTools.goToParent(obj, setObj, setIdParent, setId);
  };

  const checkIsLoading = () => {
    let load: boolean = CommonTools.checkIsLoading(
      reqList,
      responseParams,
      data,
      total,
      totalPage,
      rows
    );
    if (id !== "") {
      if (obj === undefined) load = true;
      else load = false;
    }
    setLoading(load);
  };

  const processTbFilterList = () => {
    const _tobj = new TbFilterListDTO();

    var t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    _tobj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    _tobj.addFilter(t);

    t = new TbFilterDTO("section", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Types.GA("section", true));
    _tobj.addFilter(t);

    setTbFilterList(_tobj);
  };

  useEffect(() => {
    processTbFilterList();
  }, []);

  useEffect(() => {
    getReqList();
  }, [currentRoute, idParent]);

  useEffect(() => {
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
      getList();
    }
  }, [reqList]);

  useEffect(() => {
    checkIsLoading();
  }, [data, rows, reqList, total, totalPage, obj]);

  const handleDelete = (item: any) => {
    menuService.delete(item.id, getList, undefined);
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      label: LL("crumb_" + mainObject),
    },
  ];

  return !loading || 1 ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 3 }}>
          {LL(mainObject + "page")} {obj ? obj.name : ""}
          {obj ? <MyButton children={LL("Go up")} cb={goUp} /> : <></>}
        </Typography>
        <MyNavBt
          href={addUrl}
          children={LL("bt_Add")}
          className="addButton"
          size="small"
          _hstate={CommonTools.prepareObjectToAddChild(mainObject, idParent)}
          _mainurl={mainUrl}
        />
      </Box>
      <MyTable
        _columns={columns}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        _tbFilterList={tbFilterList}
        setReqList={_setReqList}
        loading={loading}
        currentRoute={currentRoute}
        onDelete={handleDelete}
        mainObject={mainObject}
        fieldToShowOnDelete="name"
        deleteOneObject={deleteOneObject}
        setDeleteOneObject={setDeleteOneObject}
        {...props}
      />
    </>
  ) : (
    <Loading />
  );
};

export default Menu;
