import React, { useState, useEffect } from 'react';
import MyTable from "components/elements/TabelTools/MyTable";
import UserService from "services/user/user.service";
import { logger } from 'hoc/logger';
import RequestListDTO from 'dto/app/requestlist.dto';
import ResultListDTO from 'dto/app/resultlist.dto';
import UserDto from 'dto/user/user.dto';
import { useCookies } from 'react-cookie';
import { Loading } from './Loading';
import User from 'components/user/User'
import { MyBreadcrumbs } from 'components/elements/MyBreadcrumbs/MyBreadcrumbs';
import { useResource } from 'hook/useResource';
import { ChangPasswordForm } from 'components/auth/ChangePasswordForm';
import { useAuth } from 'hook/useAuth';


interface HomeProps {
  children: any,

}
const HomePage: React.FC<HomeProps> = ({ children, ...props }) => {
  const [cookies, setCookies] = useCookies();
  const { LL } = useResource();

  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useAuth();

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
  ];
  return !loading ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={"HomePage"} />
      {user ? (<ChangPasswordForm />) : (<></>)}
    </>) : (<Loading />);
};

export default HomePage;
