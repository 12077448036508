import React, { useState, useEffect } from 'react';

import JournalService from "services/journal/journal.service";
import { logger } from 'hoc/logger';

import { JournalDto } from 'dto/journal/journal.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

const journalService = new JournalService();


interface DetailJournalProps {
  children: any;
  currentRoute: any;
  _obj: JournalDto,
  mainObject: any;
}

const DetailJournal: React.FC<DetailJournalProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();
  const detailObj = _obj !== undefined ? _obj : undefined;
  const [loading, setLoading] = useState<boolean>(true);
  const [obj, setObj] = useState<JournalDto | undefined>(detailObj);
  const [id] = useState<string>(currentRoute._paths[1]);
  const editUrl = CommonTools.generateEditUrl(mainObject, id);

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    if (id !== undefined) journalService.get(id, loadObject, {});
  };

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    if (obj === undefined) getObject();
  }, [id]);

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    { label: LL("crumb_Detail" + mainObject) },
  ];

 
  
  return !loading ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
        maxWidth="md"
      >
        <MyNavBt
          tabIndex={1}
          href={listUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />
        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {" "}
          {LL("DetailBlock")}{" "}
        </Typography>

        
      </Box>

      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <Grid spacing={2}>
          <Grid>
            <Typography color="text.disabled" variant="caption">
              {LL("type")}
            </Typography>
          </Grid>
          <Grid>{ComponentCommonTools.processObjectField(obj, "type_name")}</Grid>
          <Grid mt={3}>
            <Typography color="text.disabled" variant="caption">
              {LL("date")}
            </Typography>
          </Grid>
          <Grid>{ComponentCommonTools.processObjectField(obj, "date_name")}</Grid>
         
          <Grid mt={3}>
            <Typography color="text.disabled" variant="caption">
              {LL("parent")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectField(obj, "parent")}
          </Grid>
         
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("user_email")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "user",
              "email"
            )}
          </Grid>
          <Grid mt={3}>
            <Typography color="text.disabled" variant="caption">
              {LL("info")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectField(obj, "info")}
          </Grid>
       
        </Grid>
      </Box>
    </>
  ) : (
    <Loading />
  );
};


export default DetailJournal;
