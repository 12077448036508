import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class CourseCategoryDto implements Idto {

  id?: string;
  idlanguage?: string;
  status?: number;
  ordercriteria?: number;
  name?: string;
  idparent?: string;
  idcoursecategory?: string;
  keymeta?: string;
  descriptionmeta?: string;
  url?: string;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  istop?: boolean;
  popularidentifier?: number;
  files?: any;
  icon?: any;
  idicon?: string | null;
  deleteicon?: boolean;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.popularidentifier = 0;
    this.istop = false;
  }
}

export class PostCourseCategoryDto implements Idto {

  idlanguage?: string;
  status?: number;
  ordercriteria?: number;
  name?: string;
  idparent?: string;
  idcoursecategory?: string;
  keymeta?: string;
  descriptionmeta?: string;
  url?: string;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  istop?: boolean;
  popularidentifier?: number;
  files?: any;
  idicon?: string;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.popularidentifier = 0;
    this.istop = false;
  }
}