import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class FilterDto implements Idto {
    
    id?: string;
    idlanguage?: string;
    status?: number;
    ordercriteria?: number;
    name?: string;
    type?: number;
    idfilter?: string;
    typefilter?: any;
    ismain?: boolean;
    constructor() {
      this.status = Status.ACTIVE;
      this.ordercriteria = 0;
      this.ismain = false;
  } 
  }

  export class PostFilterDto implements Idto {
    
    idlanguage?: string;
    status?: number;
    ordercriteria?: number;
    name?: string;
    type?: number;
    idfilter?: string;
    ismain?: boolean;
    constructor() {
      this.status = Status.ACTIVE;
      this.ordercriteria = 0;
      this.ismain = false;
  }
  }