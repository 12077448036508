import React, { useState, useEffect } from 'react';

import TypeAgreementService from "services/nomenclature/typeagreement.service";
import GenListType from 'components/elements/MyGenComponents/GenListType';
import { PopulateTypeDto } from 'dto/nomenclature/populatetype.dto';


const typeAgreementService = new TypeAgreementService();


interface TypeAgreementProps {
    children: any;
    currentRoute: any;
    mainObject: any;
}

const TypeAgreement: React.FC<TypeAgreementProps> = ({
    children,
    currentRoute,
    mainObject,
    ...props
}) => {

    return (
        <GenListType
            children={children}
            currentRoute={currentRoute}
            service={typeAgreementService}
            mainObject={mainObject}
            DTO={PopulateTypeDto}
            {...props}
        />
    )
};

export default TypeAgreement;
