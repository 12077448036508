
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export default class ModifyPasswordDto implements Idto {
  
  email?: string;
  currentpassword?: string;
  newpassword?: string;
  
}
