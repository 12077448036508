import Idto from "interfaces/idto.interface";
import { Status } from "tools/status";

export class PostPricePolicyDto implements Idto {
  status?: number;
  ordercriteria?: number;
  idlanguage?: string;
  idpricepolicy?: string;

  name?: string;
  idtypecourse?: string;
  teacherstatus?: number;
  percentageteacher?: number;
  percentageplatform?: number;
  isdefault?: boolean;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.isdefault = false;
  }
}

export class PricePolicyDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  idlanguage?: string;
  idpricepolicy?: string;
  language?: any;
  teacherstatus_name?: string;
  status_name?: string;
  name?: string;
  idtypecourse?: string;
  typecourse?: any;
  teacherstatus?: number;
  percentageteacher?: number;
  percentageplatform?: number;
  isdefault?: boolean;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.isdefault = false;
  }
}
