import React, { useState, useEffect } from 'react';

import TypeInternetConnectionService from "services/nomenclature/typeinternetconnection.service";
import GenDetailType from 'components/elements/MyGenComponents/GenDetailType';


const typeInternetConnectionService = new TypeInternetConnectionService();


interface DetailTypeInternetConnectionProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const DetailTypeInternetConnection: React.FC<DetailTypeInternetConnectionProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenDetailType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeInternetConnectionService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default DetailTypeInternetConnection;
