import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

export default class DateTools {
    
  static getDateStr(obj: Date | null): string {
    if (!obj) return "";
    return moment(obj.toISOString()).format(DateTools.getDateFormatMoment());
  }

  static getDateTimeStamp(obj: Date | null): number | null {
    if (!obj) return null;

    const diffLocale = -1 * obj.getTimezoneOffset() * 60000;
    const timestamp = obj.getTime() + diffLocale;
      
    return Math.round(timestamp / 1000);
  }

  static getDateFormat(): any {
    return "dd.MM.yyyy";
  }

  static getDateTimeFormat(): any {
    return "dd.MM.yyyy HH:mm";
  }

  static getDateFormatMoment(): any {
    return "YYYY-MM-DD";
  }

  static getDate(incrementSecond?: any): any {
    incrementSecond = incrementSecond ? parseInt(incrementSecond) : 0;
    incrementSecond = isNaN(incrementSecond) ? 0 : incrementSecond;

    var rez = new Date();
    rez.setSeconds(rez.getSeconds() + incrementSecond);

    return rez;
  }

  static getTimeStamp(incrementSecond?: any): number {
    const date = this.getDate(incrementSecond);
    let timestamp = date.getTime();
    timestamp = timestamp / 1000;
    timestamp = Math.round(timestamp);

    return timestamp;
  }

  public static convertTimestampToDate(timestamp: number | undefined): Date {
    if (!timestamp) return new Date();
    const t = timestamp * 1000;
    return new Date(t);
  }

  public static convertToDate(value: any): Date {
    return new Date(value);
  }

  public static displayTimestampInHHMM(timestamp: number | undefined): string {
    if (!timestamp) return "";
    const date = this.convertTimestampToDate(timestamp);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    
    return `${hours}:${minutes}`;
  }
  public static displayTimestampInDateAndHHMM(timestamp: number | undefined): string {
    if (!timestamp) return "";
    const date = this.convertTimestampToDate(timestamp);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).padStart(2, '0');
    
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }
}
