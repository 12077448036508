import React, { useState, useEffect } from 'react';

import CourseEnrollmentService from "services/course/courseenrollment.service";
import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import { CourseEnrollmentDto } from 'dto/course/courseenrollment.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { TeacherAutocomplete } from 'components/elements/Autocomplete/TeacherAutocomplete';

import MyCheckboxField from "components/form/mycheckboxfield";
import MyDateTimePicker from 'components/elements/MyDatePicker/MyDateTimePicker';
import DateTools from 'tools/date.tools';
import {Stack} from "@mui/material";
import { Status } from 'tools/status';
const courseEnrollmentService = new CourseEnrollmentService();

interface EditCourseEnrollmentProps {
    children: any;
    currentRoute: any;
    _obj: CourseEnrollmentDto,
    mainObject: any;
}

const EditCourseEnrollment: React.FC<EditCourseEnrollmentProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");
    const [addUrl, setAddUrl] = useState("");

    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const au = CommonTools.generateAddUrl(mainObject);
        setAddUrl(au);
        const lu = _getListUrl(mu);
        setListUrl(lu);
        if (obj === undefined) getObject();
    }, [mainObject, currentRoute]);

    const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<CourseEnrollmentDto | undefined>()
    const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);

    const _courseUrl = obj ? CommonTools.generateDetailUrl('Course', obj.idcourse) : '';
    const mainUrlCourse = CommonTools.generateMainUrl('Course');
    const [courseDetailUrl, setCourseDetailUrl] = useState<string>(_courseUrl);

    const validateAll = () => {
        if (!obj) return;
        
        obj.status = obj.status !== undefined ? obj.status : Status.ENR_NEED_CHECKOUT;
        obj.paymentstatus = obj.paymentstatus !== undefined ? obj.paymentstatus : Status.PAYMENT_UNPAID;
       
        setObj(obj);

       
    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };



    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        
        setLoading(false);
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {

            courseEnrollmentService.get(id, loadObject, {});
        }
    };



    useEffect(() => {
        // processValidators();
        if (id === '') {
            if (obj !== undefined) {
                setId(obj.id)
            }
        }
    }, []);
    
    useEffect(() => {
        validateAll();
        checkLoading();
        if (courseDetailUrl === '') {
            if (obj !== undefined) {
                setCourseDetailUrl(CommonTools.generateDetailUrl('Course', obj.idcourse));
            }
        }
    }, [obj]);

    


    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (id !== undefined) {
            courseEnrollmentService.update(id, ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
        }
    };
    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrlCourse,
            label: LL("crumb_Course"),
        },
        {
            url: courseDetailUrl,
            label: LL("crumb_DetailCourse"),
        },
        {
            url: detailUrl,
            label: LL("crumb_Detail" + mainObject),
        },
        { label: LL("crumb_Edit" + mainObject) },
    ];
  

    return !loading && obj ? (
        <>

            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
            <Box sx={{ flexDirection: 'row', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                <MyNavBt
                    tabIndex={2}
                    href={detailUrl}
                    children={<IconButton color="primary"><ArrowBackIcon /></IconButton>}
                    size="large"
                    _hstate={{}}
                    _mainurl={courseDetailUrl}
                />
                <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
                    {LL("EditBlock")}
                </Typography>
            </Box>

                <Box
                    maxWidth="md"
                    sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
                >
                    <form onSubmit={handleSubmit}>
                      
                        
                        <Box mt={3}>
                            <StatusSelect
                                tabIndex={2}
                                id="status"
                                name="status"
                                variant="outlined"
                                color="primary"
                                value={obj.status}
                                field="status"
                                size="small"
                                statusType='enrollment'
                                setObj={setObjField}
                                _defaultValue={obj.status}
                            />
                        </Box>
                        <Box mt={3}>
                            <StatusSelect
                                tabIndex={3}
                                id="paymentstatus"
                                name="paymentstatus"
                                variant="outlined"
                                color="primary"
                                value={obj.paymentstatus}
                                field="paymentstatus"
                                size="small"
                                statusType='payment'
                                setObj={setObjField}
                                _defaultValue={obj.paymentstatus}
                            />
                        </Box>
                        
                        <MyButton
                            tabIndex={3}
                            disabled={isFormDisabled}
                            color="primary"
                            fullWidth
                            variant="contained"
                            size="large"
                            type="submit"
                            sx={{ mt: 4 }}
                            children={LL("Bt_Submit")}
                        />
                    </form>
                </Box>

            </>
        
    ) : (
        <Loading />
    );
};

export default EditCourseEnrollment;