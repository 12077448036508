import React, { useState, useEffect } from 'react';

import CourseService from "services/course/course.service";
import { logger } from 'hoc/logger';

import { CourseDto } from 'dto/course/course.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { TeacherAutocomplete } from 'components/elements/Autocomplete/TeacherAutocomplete';
import { LanguageSelect } from 'components/elements/Select/LanguageSelect';
import { Status } from 'tools/status';
import MyCKEditor from 'components/elements/ckeditor/MyCKEditor';
const courseService = new CourseService();

interface AddCourseProps {
    children: any;
    currentRoute: any;
    _obj: CourseDto,
    mainObject: any;
}

const AddCourse: React.FC<AddCourseProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();


    const [loading, setLoading] = useState<boolean>(false);
    var [obj, setObj] = useState<CourseDto>(new CourseDto());


    const [isFormDisabled, setIsFormDisabled] = useState(true);

    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.name = obj.name !== undefined ? obj.name : '';
        obj.description = obj.description !== undefined ? obj.description : '';
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : '';
        obj.idteacher = obj.idteacher !== undefined ? obj.idteacher : '';
        obj.popularidentifier = obj.popularidentifier !== undefined ? obj.popularidentifier : 0;
        obj.requirementstext = obj.requirementstext !== undefined ? obj.requirementstext : '';
        obj.whatwillyoulearntext = obj.whatwillyoulearntext !== undefined ? obj.whatwillyoulearntext : '';
        obj.targetaudiencetext = obj.targetaudiencetext !== undefined ? obj.targetaudiencetext : '';
        obj.shortdescription = obj.shortdescription !== undefined ? obj.shortdescription : '';
        setObj(obj);

        let t = validateField("name", obj.name);
        // t = validateField("description", obj.description, t);
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            name: [],
            // description: [],
        };

        v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
        // v.description.push(new RequiredValidator(LL('Required field description', LabelTypes.LABEL_VALIDATION), ''));
        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    useEffect(() => {
        processValidators();
    }, []);

    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults, obj.idlanguage]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'name');
        if(!obj.idlanguage) rez = true;
        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        courseService.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
    };


    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Add" + mainObject) },
    ];
    return !loading ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <MyNavBt
            tabIndex={2}
            href={listUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />

          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {LL("AddBlock")}
          </Typography>
        </Box>

        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <form onSubmit={handleSubmit}>
            <MyTextField
              tabIndex={0}
              fullWidth
              id="name"
              name="name"
              variant="outlined"
              color="primary"
              _label={LL("Name")}
              size="small"
              value={obj.name}
              setObj={setObjField}
              _vresults={vresults && vresults.name ? vresults.name : false}
            />
            {/* <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={1}
                fullWidth
                id="description"
                name="description"
                variant="outlined"
                color="primary"
                _label={LL("Description")}
                size="small"
                value={obj.description}
                setObj={setObjField}
                _vresults={
                  vresults && vresults.description
                    ? vresults.description
                    : false
                }
              />
            </Box> */}
            <Box mt={3}>
            <Typography color="text.disabled" variant="caption">
                        { LL('description')}
                      </Typography>
            <MyCKEditor
                            data={obj.description ? obj.description : ''}
                            setObj={setObjField}
                            name="description"
                        />
                    </Box>
                    <Box mt={3}>
                    <Typography color="text.disabled" variant="caption">
                        { LL('requirementstext')}
                      </Typography>
                    <MyCKEditor
                            data={obj.requirementstext ? obj.requirementstext : ''}
                            setObj={setObjField}
                            name="requirementstext"
                        />
                    </Box>
                    <Box mt={3}>
                    <Typography color="text.disabled" variant="caption">
                        { LL('whatwillyoulearntext')}
                      </Typography>
                    <MyCKEditor
                            data={obj.whatwillyoulearntext ? obj.whatwillyoulearntext : ''}
                            setObj={setObjField}
                            name="whatwillyoulearntext"
                        />
                    </Box>
                    <Box mt={3}>
                    <Typography color="text.disabled" variant="caption">
                        { LL('targetaudiencetext')}
                      </Typography>
                    <MyCKEditor
                            data={obj.targetaudiencetext ? obj.targetaudiencetext :''}
                            setObj={setObjField}
                            name="targetaudiencetext"
                        />
                    </Box>
                    <Box mt={3}>
                      <Typography color="text.disabled" variant="caption">
                        {LL("shortdescription")}
                      </Typography>
                    <MyCKEditor
                            data={obj.shortdescription ? obj.shortdescription : ''}
                            setObj={setObjField}
                            name="shortdescription"
                        />
                    </Box>
            <Box sx={{ mt: 3 }}>
              <StatusSelect
                tabIndex={2}
                id="status"
                name="status"
                variant="outlined"
                color="primary"
                value={obj.status}
                field="status"
                size="small"
                statusType='course'
                setObj={setObjField}
              />
            </Box>
            <TeacherAutocomplete
              tabIndex={3}
              id="idteacher"
              variant="outlined"
              color="primary"
              field="idteacher"
              size="small"
              setObj={setObj}
              obj={obj}
              setObjFieldGeneral={setObjField}
            />
            <Box sx={{ mt: 3 }}>
              <LanguageSelect
                tabIndex={4}
                id="idlanguage"
                name="idlanguage"
                variant="outlined"
                color="primary"
                value={obj.idlanguage}
                field="idlanguage"
                size="small"
                setObj={setObjField}
                obj={obj}
                forAddForms={true}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={5}
                fullWidth
                id="popularidentifier"
                name="popularidentifier"
                variant="outlined"
                color="primary"
                _label={LL("PopularIdentifier")}
                size="small"
                value={obj.popularidentifier}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
            <MyButton
              tabIndex={5}
              disabled={isFormDisabled}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
          </form>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default AddCourse;
