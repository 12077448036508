
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export default class SocialDto implements Idto {
    id?: string;
    link?: string;
    idtypesocial?: string;
    typesocial?: any;
    iduser?: string;

}
