import React, { useState, useEffect } from "react";

import PricePolicyLinkTeacherService from "services/finance/pricepolicylinkteacher.service";
import { logger } from "hoc/logger";

import {
  PricePolicyLinkTeacherDto,
  PostPricePolicyLinkTeacherDto,
} from "dto/finance/pricepolicylinkteacher.dto";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";

import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import IconButton from "@mui/material/IconButton";

import { useForm } from "hook/useForm";
import { PricePolicySelect } from "components/elements/Select/PricePolicySelect";
import { TeacherAutocomplete } from "components/elements/Autocomplete/TeacherAutocomplete";

const service = new PricePolicyLinkTeacherService();

interface AddPricePolicyLinkTeacherProps {
  children: any;
  currentRoute: any;
  _obj: PricePolicyLinkTeacherDto;
  mainObject: any;
}

const AddPricePolicyLinkTeacher: React.FC<AddPricePolicyLinkTeacherProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const [loading, setLoading] = useState<boolean>(false);
  const [obj, isFormDisabled, setObjField, setObj] =
    useForm<PostPricePolicyLinkTeacherDto>(
      new PostPricePolicyLinkTeacherDto(),
      RequiredValidator.getValidators(["idteacher", "idpricepolicy"])
    );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;

    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject },
      obj
    );
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    { label: LL("crumb_Add" + mainObject) },
  ];
  return !loading ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={9}
          href={listUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />

        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {LL("AddBlock")}
        </Typography>
      </Box>

      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <form onSubmit={handleSubmit}>
          <Box sx={{ mt: 3 }}>
            <PricePolicySelect
              tabIndex={8}
              id="idpricepolicy"
              name="idpricepolicy"
              variant="outlined"
              color="primary"
              value={obj.idpricepolicy}
              idTypeCourse=""
              field="idpricepolicy"
              size="small"
              setObj={setObjField}
              obj={obj}
            />
          </Box>
          <TeacherAutocomplete
            tabIndex={3}
            id="idteacher"
            variant="outlined"
            color="primary"
            field="idteacher"
            size="small"
            setObj={setObj}
            obj={obj}
            setObjFieldGeneral={setObjField}
          />
          <MyButton
            tabIndex={8}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Submit")}
          />
        </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default AddPricePolicyLinkTeacher;
