import React, { useEffect, useState } from "react";

import { useResource } from "hook/useResource";

import TeacherService from "services/teacher/teacher.service";
import { GenAutocompleteSearch } from "./GenAutocompleteSearch";

const teacherService = new TeacherService();
interface Props {
    obj?: any;
    setObj?: (obj: any) => void;
    _idfield?: string;
    _objfield?: string;
    _label?: string;
    setObjFieldGeneral: (field: any, value: any) => void;
    [key: string]: any;
}

const TeacherAutocomplete: React.FC<Props> = ({
    obj,
    setObj,
    _idfield,
    _objfield,
    _label,
    setObjFieldGeneral,
    ...props
}) => {
    const { LL } = useResource();

    const [label, setLabel] = useState("");
    var tidfield = _idfield != undefined ? _idfield : "idteacher";
    var tobjfield = _objfield != undefined ? _objfield : "teacher";
    const [idfield, setIdField] = useState(tidfield);
    const [objfield, setObjField] = useState(tobjfield);
    const [mainObject, setMainObject] = React.useState('Teacher');


    useEffect(() => {
        if (obj == undefined) return;
        if (!obj) return;
        if (mainObject == undefined) return;
        if (!mainObject) return;

        var L = _label != undefined && _label ? _label : LL(mainObject);

        setLabel(L);

    }, [obj, _label]);

    const handleSetObjFieldGeneral = (field: any, value: any) => {
        setObjFieldGeneral(field, value);
    }
    return (
        <GenAutocompleteSearch
        obj={obj}
        setObj={setObj}
        _idfield={idfield}
        _objfield={objfield}
        _mainObject={mainObject}
        _label={label}
        _showfield={"email"}
        searchField={"emailsearch"}
        service={teacherService}
            // setObjFieldGeneral={handleSetObjFieldGeneral}
            {...props}
        />
    );
};

export { TeacherAutocomplete };
