import React, { useState, useEffect } from 'react';

import TypeFilterService from "services/nomenclature/typefilter.service";
import GenEditType from 'components/elements/MyGenComponents/GenEditType';
import { PostNomenclatureDto } from 'dto/nomenclature/postnomenclature.dto';


const typeFilterService = new TypeFilterService();


interface EditTypeFilterProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const EditTypeFilter: React.FC<EditTypeFilterProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenEditType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeFilterService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default EditTypeFilter;
