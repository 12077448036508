import React, { useState, useEffect } from 'react';

import TypeGenderService from "services/nomenclature/typegender.service";
import GenDetailType from 'components/elements/MyGenComponents/GenDetailType';


const typeGenderService = new TypeGenderService();


interface DetailTypeGenderProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const DetailTypeGender: React.FC<DetailTypeGenderProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenDetailType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeGenderService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default DetailTypeGender;
