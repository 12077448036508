import React, { useState, useEffect } from 'react';

import CourseTeacherRequestService from "services/course/courseteacherrequest.service";
import { logger } from 'hoc/logger';

import { CourseTeacherRequestDto } from 'dto/course/courseteacherrequest.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { Link } from '@mui/material';
import { Status } from 'tools/status';

const courseTeacherRequestService = new CourseTeacherRequestService();


interface DetailCourseTeacherRequestProps {
    children: any;
    currentRoute: any;
    _obj: CourseTeacherRequestDto,
    mainObject: any;
}

const DetailCourseTeacherRequest: React.FC<DetailCourseTeacherRequestProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");

    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const lu = _getListUrl(mu);
        setListUrl(lu);
    }, [mainObject, currentRoute]);

    const { LL, _getListUrl, _setListUrl } = useResource();
    const detailObj = _obj !== undefined ? _obj : undefined;
    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<CourseTeacherRequestDto | undefined>(detailObj);
    const [id] = useState<string>(currentRoute._paths[1]);
    const editUrl = CommonTools.generateEditUrl(mainObject, id);

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        if (id !== undefined) courseTeacherRequestService.get(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        if (obj === undefined) getObject();
    }, [id]);

    useEffect(() => {
        checkIsLoading();
    }, [obj]);

    const processLinkTeacher = () => {
        if (!obj) return '';
        if (!obj.hasOwnProperty('idteacher')) return '';
        if (!obj.idteacher) return '';
        return '/teacher/' + obj.idteacher;
    }

    const processLinkCourse = () => {
        if (!obj) return '';
        if (!obj.hasOwnProperty('idcourse')) return '';
        if (!obj.idcourse) return '';
        return '/course/' + obj.idcourse;
    }

    const processNavBtEdit = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('status')) return <></>;
        if (!obj.status) return <></>;
        if (obj.status === Status.UNPROCESSED) {
            return (
                <MyNavBt
                    href={editUrl}
                    children={
                        <IconButton className="editBtn">
                            <EditIcon />
                        </IconButton>
                    }
                    _hstate={CommonTools.prepareObjectForUpdate(obj, mainObject)}
                    _mainurl={mainUrl}
                />
            )
        }
        return <></>;
    }

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Detail" + mainObject) },
    ];
    return !loading ? (
        <>
            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
            <Box
                sx={{
                    flexDirection: "row",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                }}
                maxWidth="md"
            >
                <MyNavBt
                    tabIndex={1}
                    href={listUrl}
                    children={
                        <IconButton color="primary">
                            <ArrowBackIcon />
                        </IconButton>
                    }
                    size="large"
                    _hstate={{}}
                    _mainurl={mainUrl}
                />
                <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
                    {" "}
                    {LL("DetailBlock")}{" "}
                </Typography>
                {obj ? (
                    <Box sx={{ ml: "auto" }}>

                        <Link
                            href={processLinkTeacher()}
                            className="detailBtn"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {LL("DetailTeacher")}
                        </Link>
                        <Link
                            href={processLinkCourse()}
                            className="detailBtn"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {LL("DetailCourse")}
                        </Link>

                        {processNavBtEdit()}
                    </Box>
                ) : (
                    <></>
                )}
            </Box>

            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >
                <Grid spacing={2}>
                    <Grid>
                        <Typography color="text.disabled" variant="caption">
                            {LL("Type")}
                        </Typography>
                    </Grid>
                    <Grid>{ComponentCommonTools.processObjectField(obj, "type_name")}</Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant="caption">
                            {LL("date")}
                        </Typography>
                    </Grid>
                    <Grid>{ComponentCommonTools.processObjectField(obj, "date_name")}</Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant="caption">
                            {LL("changedate")}
                        </Typography>
                    </Grid>
                    <Grid>{ComponentCommonTools.processObjectField(obj, "changedate_name")}</Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant="caption">
                            {LL("Status")}
                        </Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, "status_name")}
                    </Grid>

                    <Grid mt={3}>
                        <Typography color="text.disabled" variant="caption">
                            {LL("teacherfullname")}
                        </Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, "teacherfullname")}
                    </Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant="caption">
                            {LL("teacher_email")}
                        </Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, "teacher", "email")}
                    </Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant="caption">
                            {LL("teachermessage")}
                        </Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, "teachermessage")}
                    </Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant="caption">
                            {LL("managerfullname")}
                        </Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, "managerfullname")}
                    </Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant="caption">
                            {LL("manager_email")}
                        </Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, "manager", "email")}
                    </Grid>

                    <Grid mt={3}>
                        <Typography color="text.disabled" variant="caption">
                            {LL("managermessage")}
                        </Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, "managermessage")}
                    </Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant="caption">
                            {LL("course_name")}
                        </Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, "course", "name")}
                    </Grid>
                </Grid>
            </Box>
        </>
    ) : (
        <Loading />
    );
};

export default DetailCourseTeacherRequest;
