import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';

import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';

import CourseSettingsService from "services/course/coursesettings.service";
import { Status } from 'tools/status';

import { CourseSettingsDto } from 'dto/course/coursesettings.dto';


import { CourseTypeSelect } from 'components/elements/Select/CourseTypeSelect';

const courseSettingsService = new CourseSettingsService();

interface AddCourseSettingsProps {

    currentRoute: any;

    mainObject: any;
}

const AddCourseSettings: React.FC<AddCourseSettingsProps> = ({
    currentRoute,
    mainObject,
    ...props
}) => {


    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");
    const [addUrl, setAddUrl] = useState("");

    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const au = CommonTools.generateAddUrl(mainObject);
        setAddUrl(au);
        const lu = _getListUrl(mu);
        setListUrl(lu);
    }, [mainObject, currentRoute]);

    const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<CourseSettingsDto | undefined>(new CourseSettingsDto())


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.idtypecourse = obj.idtypecourse !== undefined ? obj.idtypecourse : '';
        obj.password = obj.password !== undefined ? obj.password : '';
        obj.maxnumberofstudents = obj.maxnumberofstudents !== undefined ? obj.maxnumberofstudents : undefined;

        setObj(obj);

        // let t = validateField("value", obj.value);

    };

    // const validateField = (field: any, value: any, v?: any) => {

    //     v = (v) ? v : JSON.parse(JSON.stringify(vresults));
    //     if (v === false) v = {};
    //     v[field] = ValidateTools.validate(field, value, validators, vresults);

    //     // setIsFormDisabled(ValidateTools.vForm(v, field));
    //     setVResults(v);
    //     return v;
    // };

    // const checkIsFormDisabled = () => {
    //     var rez = ValidateTools.vForm(vresults, 'value');


    //     setIsFormDisabled(rez);
    // };
    // useEffect(() => {
    //     checkIsFormDisabled();
    // }, [vresults]);

    useEffect(() => {
        validateAll();
    }, [obj, validators]);

    // const processValidators = () => {
    //     let v: any = {
    //         value: [],
    //     };
    //     v.value.push(new RequiredValidator(LL('Required field value', LabelTypes.LABEL_VALIDATION), ''));
    //     setValidators(v);
    // };




    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (obj !== undefined && id !== undefined) {
            obj.idcourse = id;
            courseSettingsService.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject, idRoute: id,anchor:"courseSettings" }, obj);
        }

        // setObj(new CourseSettingsDto());
    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };


    return !loading && obj ? (
        <>
            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit} >
                    <CourseTypeSelect
                        tabIndex={0}
                        id="idtypecourse"
                        name="idtypecourse"
                        variant="outlined"
                        color="primary"
                        value={obj.idtypecourse}
                        field="idtypecourse"
                        size="small"
                        setObj={setObjField}
                        obj={obj}
                    />
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="password"
                            name="password"
                            variant="outlined"
                            color="primary"
                            _label={LL("password")}
                            size="small"
                            value={obj.password}
                            setObj={setObjField}
                            _vresults={false}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="maxnumberofstudents"
                            name="maxnumberofstudents"
                            variant="standard"
                            color="primary"
                            _label={LL("Max_number_of_students")}
                            size="small"
                            value={obj.maxnumberofstudents}
                            setObj={setObjField}
                            _vresults={false}
                        />
                    </Box>
                    <MyButton
                        tabIndex={2}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>

            </Box>

        </>
    ) : (
        <Loading />
    );
};

export default AddCourseSettings;
