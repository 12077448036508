import { createContext, useState, useEffect } from "react";
import { logger } from "hoc/logger";
import { v4 as uuidv4 } from "uuid";
import GeneralRepository from "repositories/general.repository";
import { MessageItem } from "components/message/MessageItem";
import { MessagesContainerPopUpConfirmDelete } from "components/message/MessagesContainerPopUpConfirmDelete";

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  
  const [messages, setMessages] = useState([]);
  const [mesinfo, setMesInfo] = useState(false);

  const RegisterMessage = (obj) => {
    if (!obj.code || obj.code == undefined || obj.code == null) return;
    obj.id = uuidv4();
    const _m = [...messages, obj];
    setMessages(_m);
    const mi = obj.message === undefined ? false : obj.message;
    setMesInfo(mi);
  };
  
  const CloseMessage = (obj) => {
    var t = [];
    for (var i in messages)
    {
      if (messages[i].id == obj.id) continue;
      t.push(messages[i]);
    }
    setMessages(t);
  };

  // ----------------------------------------
  useEffect(() => {
    GeneralRepository.setMessagesF(RegisterMessage);
  }, []);

  // ----------------------------------------
  const processMessageList = () => {
    if (!messages) return <></>;
    if (!messages.length) return <></>;
    
    if (mesinfo && mesinfo.type == "popupconfirmdelete") {
      logger("processMessages_XXXX", mesinfo);
      const localhash = uuidv4();
      return (
        <MessagesContainerPopUpConfirmDelete
          key={localhash}
          _objects={messages}
          cb={setMessages}
          _info={mesinfo}
        />
      );
    }

    return (
      <>
        {messages.map((obj, i) => {
          
          return (
            <MessageItem _obj={obj} _closeMessage={CloseMessage} key={obj.id} />
          );
        })}
      </>
    );
  };

  const value = {};

  return (
    <MessageContext.Provider value={value} messages={messages}>
      {children}

      {processMessageList()}
    </MessageContext.Provider>
  );
};
