import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class AnswerDto implements Idto {
    
    id?: string;
    name?: string;
    status?: number;
    ordercriteria?: number;
    score?: number;
    idquestion?:string;
    type?:number;
    constructor() {
      this.status = Status.ACTIVE;
      this.ordercriteria = 0;
      this.score = 0;
  }
  }

  export class PostAnswerDto implements Idto {
    
    name?: string;
    status?: number;
    ordercriteria?: number;
    score?: number;
    idquestion?:string;
    type?:number;
    constructor() {
      this.status = Status.ACTIVE;
      this.ordercriteria = 0;
      this.score = 0;
  }
  }