import Idto from "interfaces/idto.interface";
import { Status } from "tools/status";

export class SupportArticleCategoryDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  code?: string;
  level?: number;
  idparent?: string;
  type?: number;
  idlanguage?: any;
  language?: any;
  name?: any;
  haschildren?: boolean;
  idsupportarticlecategory?: string;
  shortdescription?: string;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
  }
}

export class PostSupportArticleCategoryDto implements Idto {
    id?: string;
    status?: number;
    ordercriteria?: number;
    code?: string;
    level?: number;
    idparent?: string;
    type?: number;
    allvalues?: any;
    idlanguage?: any;
    language?: any;
    name?: any;
    haschildren?: boolean;
    idsupportarticlecategory?: string;
    shortdescription?: string;

    constructor() {
      this.status = Status.ACTIVE;
      this.ordercriteria = 0;
    }
  }