import React, { useState, useEffect } from 'react';

import TypePriceService from "services/nomenclature/typeprice.service";
import GenListType from 'components/elements/MyGenComponents/GenListType';
import { PopulateTypeDto } from 'dto/nomenclature/populatetype.dto';


const typePriceService = new TypePriceService();


interface TypePriceProps {
    children: any;
    currentRoute: any;
    mainObject: any;
}

const TypePrice: React.FC<TypePriceProps> = ({
    children,
    currentRoute,
    mainObject,
    ...props
}) => {

    return (
        <GenListType
            children={children}
            currentRoute={currentRoute}
            service={typePriceService}
            mainObject={mainObject}
            DTO={PopulateTypeDto}
            {...props}
        />
    )
};

export default TypePrice;
