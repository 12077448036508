import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import UserSettingsDto from 'dto/user/usersettings.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UserSettingsService from "services/user/usersettings.service";
import IconButton from '@mui/material/IconButton';
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { TypeGenderSelect } from "components/elements/Select/GenderTypeSelect";
import { Status } from 'tools/status';
import MyDatePicker from 'components/elements/MyDatePicker/MyDatePicker';
import AddAddressForm from 'components/address/components/AddAddressForm';
import MyFileField from 'components/form/myfilefield';
import AvatarCropper from 'components/elements/MyAvatar/AvatarCropper';

const userSettings = new UserSettingsService();

interface AddUserSettingsInfoProps {

    currentRoute: any;

    mainObject: any;
}

const AddUserSettingsInfo: React.FC<AddUserSettingsInfoProps> = ({
    currentRoute,
    mainObject,
    ...props
}) => {

  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<UserSettingsDto>(new UserSettingsDto())

    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);
    const [files, setFiles] = useState(null);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.id = obj.id ?? obj.id;
        obj.name = obj.name !== undefined ? obj.name : '';
        obj.surname = obj.surname !== undefined ? obj.surname : '';
        obj.birthday = obj.birthday !== undefined ? obj.birthday : undefined;
        obj.idtypegender = obj.idtypegender !== undefined ? obj.idtypegender : '';
        // obj.idsphone = obj.idsphone !== undefined ? obj.idsphone : [];
        obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : '';
        // obj.idavatar = obj.idavatar !== undefined ? obj.idavatar : '';
        // obj.idphysicaladdress = obj.idphysicaladdress !== undefined ? obj.idphysicaladdress : '';

        setObj(obj);

        let t = validateField("name", obj.name);
        t = validateField("surname", obj.surname, t);
        logger("validateAll", t);
    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        
        return v;
    };

    const processValidators = () => {
        let v: any = {
            name: [],
            surname: [],
        };

        v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
        v.surname.push(new RequiredValidator(LL('Required field surname', LabelTypes.LABEL_VALIDATION), ''));

        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };



    useEffect(() => {
        processValidators();
    }, []);

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'name');
        rez = ValidateTools.vForm(vresults, 'surname', rez);
        setIsFormDisabled(rez);
    };

    useEffect(() => {
        validateAll();
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);


    // const setObjFile = (field: any, event: any) => {
    //     setFiles(event.target.files[0]);
    // };

    const setObjFile = (value: any) => {
        setFiles(value);
    };


    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (obj !== undefined) {
            obj.iduser = id;
            if (files !== null) obj.files = files;
            userSettings.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject, idRoute: id,anchor:"userSettings" }, obj);
        }
    };
    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };


    return !loading ? (
        <>
            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit} >
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="name"
                        name="name"
                        variant="outlined"
                        color="primary"
                        _label={LL("Name")}
                        value={obj.name}
                        setObj={setObjField}
                        _vresults={
                            vresults && vresults.name ? vresults.name : false
                        }
                    />

                    <Box sx={{ mt: 3 }} >
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="surname"
                            name="surname"
                            variant="outlined"
                            color="primary"
                            _label={LL("Surname")}
                            value={obj.surname}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.surname ? vresults.surname : false
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }} >
                        <MyDatePicker
                            tabIndex={3}
                            field='birthday'
                            _defaultValue={obj.birthday}
                            LL={LL}
                            setObjField={setObjField}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <LanguageSelect
                            tabIndex={4}
                            id="idlanguage"
                            name="idlanguage"
                            variant="outlined"
                            color="primary"
                            value={obj.idlanguage}
                            field="idlanguage"
                            size="small"
                            setObj={setObjField}
                            obj={obj} forAddForms={true}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <TypeGenderSelect
                            tabIndex={5}
                            id="idtypegender"
                            name="idtypegender"
                            variant="outlined"
                            color="primary"
                            value={obj.idtypegender}
                            field="idtypegender"
                            size="small"
                            _defaultValue={obj.idtypegender}
                            setObj={setObjField}
                            obj={obj}
                        />
                    </Box>
                    {/* <Box sx={{ mt: 3 }}>
                        <MyFileField
                            tabIndex={6}
                            fullWidth
                            id="files"
                            name="files"
                            variant="outlined"
                            color="primary"
                            _label={LL("files")}
                            size="small"
                            setObj={setObjFile}
                            _vresults={undefined}
                        />
                    </Box> */}
                    <Box sx={{ mt: 3 }}>
                        <AvatarCropper
                            setFile={setObjFile}
                        />
                    </Box>
                    <MyButton
                        tabIndex={7}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>

            </Box>
            
        </>
    ) : (
        <Loading />
    );
};

export default AddUserSettingsInfo;