import React, { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { logger } from "hoc/logger";
import { useResource } from "hook/useResource";
import PageService from "services/page/page.service";
import { MySelect } from "components/elements/Select/MySelect";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { PageDto } from "dto/page/page.dto";
import { CommonTools } from "tools/commontools";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/status";
import { Skeleton } from "@mui/material";
const pageService = new PageService();

type PageSelectProps = {
  setObj: (field: any, value: any) => void;
  field: any;
  obj: any;
  type?: any;
  [key: string]: any; // for the rest of the props which we do not have types for
};

const PageSelect: React.FC<PageSelectProps> = ({
  setObj,
  field,
  obj,
  type,
  ...props
}) => {
  const { LL } = useResource();

  const label = "Page";
  const [options, setOptions] = useState<Array<SelectOptions>>(
    new Array<SelectOptions>()
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [value, setValue] = useState<string>();
  const [data, setData] = useState<Array<PageDto> | undefined>();
  const onPage = 2000;

  const [reqList, setReqList] = useState<RequestListDTO>(
    pageService.getDefaultRequestListSelect(onPage)
  );

  const loadObjects = (data: ResultListDTO) => {
    setData(data.objects);
  };

  const getReqList = () => {
    const reqList = pageService.getDefaultRequestListSelect(onPage);
    setReqList(reqList);
  };

  const getList = () => {
    setLoading(true);
    pageService.getList(loadObjects, {}, reqList);
  };

  const processOptions = () => {
    if (data !== undefined) {
      const options: Array<SelectOptions> = data.map((item) => {
        const obj = {
          label: item.name,
          value: item.id,
        };
        return obj;
      });
      setOptions(options);
    }
  };

  // useEffect(() => {
  //     getReqList();
  // }, []);

  useEffect(() => {
    reqList.filters = reqList.filters ?? [];
    let t = null;

    t = new RequestFilterDTO();
    t.field = "status";
    t.values = [Status.ACTIVE.toString()];
    reqList.filters.push(t);

    if (type != undefined) {
      t = new RequestFilterDTO();
      t.field = "type";
      t.values = Array.isArray(type) ? type : [type.toString()];
      reqList.filters.push(t);
    }
    logger(
      "PageSelectPageSelectPageSelectPageSelectPageSelectPageSelectPageSelect",
      reqList
    );
    setLoading(true);
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
      getList();
    }
  }, [reqList]);

  useEffect(() => {
    if (data !== undefined) processOptions();
    checkIsLoading();
  }, [options, data]);

  useEffect(() => {
    setObj(field, value);
  }, [value]);

  const checkIsLoading = () => {
    let load: boolean = false;
    if (data === undefined) load = true;
    if (options === undefined) load = true;
    // if (value === undefined) load = true;
    setLoading(load);
  };

  return !loading ? (
    <MySelect
      options={options}
      _label={label}
      setValue={setValue}
      value={value}
      {...props}
    />
  ) : (<Skeleton variant="rectangular" height={50} />);
};

export { PageSelect };
