import IValidator from "interfaces/ivalidator.interface";
import ValidatorResult from "validators/validatorresult";

export default class UrlValidator implements IValidator {
  messageErr: string;
  messageOk: string;

  isValidURL = (value: any) => {
    value = value.toString().trim();

    if (value == '') return true;

    const pattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?(\?.*)?$/;
    return pattern.test(value);
  };

  constructor(mE: string, mO: string) {
    this.messageErr = mE;
    this.messageOk = mO;
  }

  validate = (value: any): ValidatorResult => {
    let rez: ValidatorResult = new ValidatorResult();

    value = this.isValidURL(value);
    if (value) {
      rez.isOk = true;
      rez.message = this.messageOk;
    } else {
      rez.isOk = false;
      rez.message = this.messageErr;
    }

    return rez;
  };
}
