import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import PermissionRepository from "repositories/auth/permission.repository";
import { PostPermissionDto } from "repositories/auth/postpermission.dto";
import GeneralService from "services/general.service";

export default class PermissionService
  extends GeneralService
  implements IService
{
  permissionRepository: PermissionRepository = new PermissionRepository();
  constructor() {
    super();
    this.handleGetList = this.handleGetList.bind(this);
    this.handleGet = this.handleGet.bind(this);
  }
  handleUpdate(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;

    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  async update(
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.permissionRepository.update(
      id,
      this.handleUpdate,
      this.prepareDataForUpdate(data),
      cbparameters
    );
  }
  prepareDataForUpdate(data: any): PostPermissionDto {
    const obj = new PostPermissionDto();
    if (data === undefined) obj.acceptedroles = null;
    if (data.acceptedRoles === undefined) obj.acceptedroles = null;
    if (data.acceptedRoles.length === 0) obj.acceptedroles = null;
    if (data.acceptedRoles.length > 0) obj.acceptedroles = data.acceptedRoles;
    return obj;
  }

  handleDelete(result?: any, cbparameters?: any) {
    throw new Error("Method not implemented.");
  }
  delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

  handleGetServerPermissionsHash(
    result?: any,
    cbparameters?: any,
    data?: any
  ): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  async getServerPermissionsHash(cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.permissionRepository.getServerPermissionsHash(
      this.handleGetServerPermissionsHash,
      cbparameters
    );
  }

  handleGet(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

    return rez;
  }

  async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.permissionRepository.get(
      id,
      this.handleGet,
      cbparameters
    );
    if (cb == undefined) {
      return this.handleGet(t);
    }
  }

  handleGetList(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

        return rez;
  }

  async getList(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.permissionRepository.getList(this.handleGetList, cbparameters, data);
        if (cb == undefined)
        {
            return this.handleGetList(t);
        }
  }

  handleAdd(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  async add(
    cb?: any,
    cbparameters?: any,
    data?: PostPermissionDto
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.permissionRepository.add(this.handleGetList, cbparameters, data);
  }

  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = "name";
    defaultRequestList.sortcriteria = [];
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  }

  getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
    if (
      !obj.sortcriteria ||
      !Array.isArray(obj.sortcriteria) ||
      !obj.sortcriteria.length
    ) {
      var sobj = new RequestSortCriteriaDTO();
      sobj.asc = true;
      sobj.field = "name";

      obj.sortcriteria = [];
      obj.sortcriteria?.push(sobj);
    }

    return obj;
  }
}
