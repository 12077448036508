import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class LessonDto implements Idto {

    id?: string;
    name?: string;
    description?: string;
    status?: number;
    ordercriteria?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    istest?: boolean;
    idtest?: string;
    idlessonsettings?: string | null;
    lessonsettings?: any;
    idtypelesson?: string;
    idcourse?: string
    lessonduration?: number;
    islive?: boolean;
    startdate?: number;
    enddate?: number;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
        this.islive = false;
        this.lessonduration = 0;
    }
}

export class PostLessonDto implements Idto {

    name?: string;
    description?: string;
    status?: number;
    ordercriteria?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    istest?: boolean;
    idtest?: string | null;
    idlessonsettings?: string | null;
    lessonsettings?: any;
    idtypelesson?: string;
    idcourse?: string;
    lessonduration?: number;
    islive?: boolean;
    startdate?: number;
    enddate?: number;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
        this.islive = false;
        this.lessonduration = 0;
    }
}