import Idto from "interfaces/idto.interface";
import { Status } from "tools/status";

export class StudentDto implements Idto {
  id?: string;
  iduser?: string;
  status?: number;
  idssociallink?: string[];
  bio?: string;
  idtypestudies?: string;
  institution?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  email?: string;
  status_name?: string;
  name?: string;
  surname?: string;
  constructor() {
    this.status = Status.ACTIVE;
  }
}

export class PostStudentDto implements Idto {
  iduser?: string;
  status?: number;
  idssociallink?: string[];
  bio?: string;
  idtypestudies?: string;
  institution?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  constructor() {
    this.status = Status.ACTIVE;
  }
}

export class PostNewStudentDto implements Idto {
  email: string;
  constructor(email?: string) {
    this.email = email ?? "";
  }
}
