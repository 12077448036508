import ValidateTools from "tools/validate.tools";
import { LabelTypes } from "tools/labeltypes";
import RequiredValidator from "validators/required.validator";
import RequestListDTO from "dto/app/requestlist.dto";
import { logger } from "hoc/logger";
import { AnyComponent } from "styled-components/dist/types";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Types } from "./types";

export class CommonTools {

  public static getAnchor = (currentRoute: any, defaultValue: string) => {
    if (!currentRoute) return defaultValue;
    if (!currentRoute.hasOwnProperty("anchor")) return defaultValue;
    if (!currentRoute.anchor) return defaultValue;
    return currentRoute.anchor;
  }

  public static processObjectField(
    obj: any,
    fields: string[],
    type?: number,
    numberAfterPoint?: number
  ): string {
    if (numberAfterPoint === undefined || numberAfterPoint === null)
      numberAfterPoint = 2;
    if (!type) type = Types.FIELD_TYPE_STRING;

    let currentObj = obj;

    for (const field of fields) {
      if (
        !currentObj ||
        typeof currentObj !== "object" ||
        !currentObj.hasOwnProperty(field)
      ) {
        return "";
      }
      currentObj = currentObj[field];
    }

    if (typeof currentObj === "object" && currentObj !== null) return "";

    if (currentObj == null || currentObj == undefined) return "";
    if (type == Types.FIELD_TYPE_NUMBER && typeof currentObj === "number") {
      return currentObj.toFixed(numberAfterPoint);
    }

    return currentObj;
  }

  public static processListLoadObjects = (
    obj: any,
    setData: any,
    setRows: any,
    setTotal: any,
    setTotalPage: any,
    setResponseParams: any
  ) => {
    setData(obj);

    if (!obj) return;

    if (!obj.err) {
      const objects = obj.objects ? obj.objects : [{}];
      const total = obj.total !== undefined ? obj.total : -1;
      const totalPage = obj.totalpages !== undefined ? obj.totalpages : -1;
      const reqInfo = obj.requestinfo ? obj.requestinfo : {};

      setRows(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  };

  public static checkIsLoading = (
    reqList: any,
    responseParams: any,
    data: any,
    total: any,
    totalPage: any,
    rows: any
  ) => {
    if (!data) return true;
    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (rows === undefined) return true;
    if (Object.keys(responseParams).length === 0) return true;
    return CommonTools.checkRequestAndParams(reqList, responseParams);
  };

  public static checkRequestAndParams = (req: any, res: any) => {
    if (req.page?.toString() !== res.page?.toString()) return true;
    if (req.onpage?.toString() !== res.onpage?.toString()) return true;
    if (!CommonTools.arraysAreEqual(req.sortcriteria, res.sortcriteria))
      return true;
    // if (!CommonTools.arraysAreEqual(req.filters, res.filters)) return true;
    return false;
  };

  public static prepareLabeldentifier = (str: string): string => {
    str = str ?? "";

    str = str.replace(/[^\w\d]/gi, "-");
    str = str.replace(/(-+)/gi, "-");

    str = str.replace(/^[-]/gi, "");
    str = str.replace(/[-]$/gi, "");

    str = str.toString().toLowerCase();

    str = str ?? "-";

    return str;
  };

  public static arraysAreEqual = (arr1: any, arr2: any) => {
    if (arr1 == undefined && arr2 == undefined) return true;
    if (arr1 == undefined || arr2 == undefined) return false;

    if (arr1.length !== arr2.length) return false;

    const sortedArr1 = arr1.map(JSON.stringify).sort();
    const sortedArr2 = arr2.map(JSON.stringify).sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    }

    return true;
  };

  public static atLeastOneFieldDefined = (obj: any) => {
    for (const key in obj) {
      if (obj[key] !== undefined) {
        return true;
      }
    }
    return false;
  };

  public static generateMainUrlSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;

    const url = "/" + path[0] + "/" + path[1] + "/" + path[2];

    return url;
  };

  public static generateParentMainUrlSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;

    const url = "/" + path[0];

    return url;
  };

  public static generateEditUrlSpecial = (
    id?: string,
    parentType?: string,
    parentId?: string,
    specialtype?: string
  ) => {
    return (
      "/" +
      parentType +
      "/" +
      parentId +
      "/" +
      specialtype +
      "/" +
      "edit" +
      "/" +
      id
    );
  };

  public static generateDetailUrlSpecial = (
    id?: string,
    parentType?: string,
    parentId?: string,
    specialtype?: string
  ) => {
    return "/" + parentType + "/" + parentId + "/" + specialtype + "/" + id;
  };

  public static generateAddUrlSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;

    const url = "/" + path[0] + "/" + path[1] + "/" + path[2] + "/add";

    return url;
  };

  public static generateMainUrl = (input: any) => {
    if (typeof input !== "string") return "";
    const url = "/" + input.toLowerCase();
    return url;
  };

  public static generateListUrl = (mainObject: any, currentRoute: any) => {
    if (typeof currentRoute != "object") {
      return CommonTools.generateMainUrl(mainObject);
    }

    // if (currentRoute.url)
    // {
    //   return currentRoute.url;
    // }

    if (
      currentRoute.historystate == undefined ||
      typeof currentRoute.historystate != "object"
    ) {
      return CommonTools.generateMainUrl(mainObject);
    }
    if (currentRoute.historystate.listUrl == undefined) {
      return CommonTools.generateMainUrl(mainObject);
    }

    return currentRoute.historystate.listUrl;
  };

  public static generateDetailUrl = (input: any, id: string | undefined) => {
    if (typeof input !== "string" && id === undefined) return "";

    const mainUrl = this.generateMainUrl(input);
    const url = mainUrl + "/" + id;
    return url;
  };

  public static generateAddUrl = (input: any) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    const url = mainUrl + "/add";
    return url;
  };

  public static userDetailUrl = (obj: any) => {
    if (!obj) return "";
    const url = "/user/" + obj.iduser;
    return url;
  };

  public static generateEditUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl + "/edit";
    if (id !== undefined) url = url + "/" + id;
    return url;
  };

  public static generateGalleryUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl;
    url += "/";
    url += id !== undefined ? id : "-";
    url += "/gallery";

    return url;
  };

  public static generateAttachmentUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl;
    url += "/";
    url += id !== undefined ? id : "-";
    url += "/attachment";

    return url;
  };

  public static generateVideoUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl;
    url += "/";
    url += id !== undefined ? id : "-";
    url += "/video";

    return url;
  };

  public static prepareObjectForUpdate = (obj: any, mainObj: any,anchor?:string) => {
    if (!obj) return;
    if (!mainObj) return;
    if(!anchor) anchor = "";

    const mainUrl = this.generateMainUrl(mainObj);
    const object: any = {
      id: obj.id,
      obj: obj,
      _mainurl: mainUrl,
      anchor:anchor
    };
    return object;
  };

  public static generateRandomString(length: number) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length),
      );
    }
    return result;
  }

  public static prepareObjectForAddChild = (
    obj: any,
    mainObj: any,
    field: any
  ) => {
    if (!obj) return;
    if (!field) return;
    if (!mainObj) return;

    const mainUrl = this.generateMainUrl(mainObj);
    const object: any = {
      id: obj[field],
      _mainurl: mainUrl,
    };
    return object;
  };
  public static goToParent = (
    obj: any,
    setObj: any,
    setIdParent: any,
    setId: any
  ) => {
    if (obj) {
      if (obj.idparent !== undefined) {
        setIdParent(obj.idparent);
        setObj(undefined);
        if (obj.idparent === "") setId("");
        else setId(obj.idparent);
      }
    }
  };
  public static prepareObjectToAddChild = (mainObj: any, idParent: string) => {
    if (!mainObj) return;
    if (idParent === "") return {};
    const mainUrl = CommonTools.generateMainUrl(mainObj);
    const object: any = {
      idParent: idParent,
      _mainurl: mainUrl,
    };
    return object;
  };

  public static handleCheckValue = (
    obj: any,
    setObj: any,
    field: string,
    value: any
  ) => {
    if (!obj) return;
    if (!field) return;
    if (!setObj) return;
    if (value === undefined) return;

    let t = JSON.parse(JSON.stringify(obj));

    t[field] = value;
    setObj(t);
  };

  public static addToRequestFilter = (
    obj: RequestListDTO,
    field: string,
    value: any
  ): RequestListDTO => {
    const filters: RequestFilterDTO[] = [];

    obj.filters = obj.filters ?? [];

    for (var i in obj.filters) {
      if (obj.filters[i].field == field) continue;
      filters.push(obj.filters[i]);
    }

    const f = new RequestFilterDTO();
    f.field = field;
    f.values = Array.isArray(value) ? value : [value];

    filters.push(f);

    obj.filters = filters;

    return obj;
  };

  public static areObjectsEqual(objA: any, objB: any) {
    // Check if both values are objects
    if (typeof objA !== "object" || typeof objB !== "object") {
      return objA == objB; // For non-objects, perform a simple comparison
    }

    // Get the keys of both objects
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    // Check if the number of keys is the same
    if (keysA.length != keysB.length) {
      return false;
    }

    // Check if the values of each key are equal (recursively)
    for (const key of keysA) {
      if (!CommonTools.areObjectsEqual(objA[key], objB[key])) {
        return false;
      }
    }

    // If all checks passed, the objects are equal
    return true;
  }


}
