import React, { useState, useEffect } from "react";

import UserService from "services/user/user.service";
import { logger } from "hoc/logger";
import useMyFormValidation from "hook/useMyFormValidation";
import UserDto from "dto/user/user.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";

import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { StatusSelect } from "components/elements/Select/StatusSelect";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "components/elements/Tabs/TabPanel";

import EditUserSettingsInfo from "components/user/components/EditUserSettingsInfo";
import AddUserSettingsInfo from "components/user/components/AddUserSettingsInfo";
import EditUserRole from "components/user/components/EditUserRole";

import AddPhone from "components/user/components/AddPhone";
import AddSocial from "components/user/components/AddSocial";
import AddAddressForm from "components/address/components/AddAddressForm";
import ModifyPassword from "components/user/components/ModifyPassword";
import { Status } from "tools/status";

const userService = new UserService();

interface EditUserProps {
  children: any;
  currentRoute: any;
  _obj: UserDto;
  mainObject: any;
}

const EditUser: React.FC<EditUserProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [id, setId] = useState<string | undefined>(_id);

  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<UserDto | undefined>();
  const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const [validators, setValidators] = useState<any>();
  const [vresults, setVResults] = useState<any>(false);

  const validateAll = () => {
    if (!obj) return;
    // if (!validators) return;
    // if (vresults) return;

    obj.status = obj.status ? obj.status : Status.ACTIVE;
    obj.statuspassword = obj.statuspassword
      ? obj.statuspassword
      : Status.ACTIVE;
    obj.statusemail = obj.statusemail ? obj.statusemail : Status.ACTIVE;

    setObj(obj);

    // let t = validateField("status", obj.status);
    // t = validateField("statuspassword", obj.statuspassword, t);
    // t = validateField("statusemail", obj.statusemail, t);
    // logger("validateAll", t);
  };

  // const validateField = (field: any, value: any, v?: any) => {

  //     v = (v) ? v : JSON.parse(JSON.stringify(vresults));
  //     if (v === false) v = {};
  //     v[field] = ValidateTools.validate(field, value, validators, vresults);

  //     setIsFormDisabled(ValidateTools.vForm(v, field));
  //     setVResults(v);
  //     return v;
  // };

  // const processValidators = () => {
  //     let v: any = {
  //         status: [],
  //         emailstatus: [],
  //         passwordstatus: []
  //     };

  //     v.status.push(new RequiredValidator('', ''));
  //     v.emailstatus.push(new RequiredValidator('', ''));
  //     v.passwordstatus.push(new RequiredValidator('', ''));

  //     setValidators(v);
  // };

  const checkLoading = () => {
    if (!loading) return;
    if (obj === undefined) return;
    // if (!validators) return;
    validateAll();
    setLoading(false);
  };

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined) {
      userService.get(id, loadObject, {});
    }
  };

  useEffect(() => {
    // processValidators();
    if (id === "") {
      if (obj !== undefined) {
        setId(obj.id);
      }
    }
  }, []);
  // const checkIsFormDisabled = () => {
  //     let rez = ValidateTools.vForm(vresults, 'status');
  //     rez = ValidateTools.vForm(vresults, 'statuspassword', rez);
  //     rez = ValidateTools.vForm(vresults, 'statusemail', rez);

  //     setIsFormDisabled(rez);
  // };

  useEffect(() => {
    validateAll();
    checkLoading();
  }, [obj]);

  // useEffect(() => {
  //     checkIsFormDisabled();
  // }, [vresults]);

  useEffect(() => {
    if (obj === undefined) getObject();
  }, [obj]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (id !== undefined) {
      userService.update(
        id,
        ComponentCommonTools.handleSuccessAddAndEdit,
        { mainObject, anchor: "generalInfo" },
        obj
      );
    }
  };
  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    {
      url: detailUrl,
      label: LL("crumb_Detail" + mainObject),
    },
    { label: LL("crumb_Edit" + mainObject) },
  ];

  const [value, setValue] = React.useState(
    CommonTools.getAnchor(currentRoute, "generalInfo")
  );

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
    RouteTools.setAnchor(newValue);
  };

  const prepareNavState = (value: string) => {
    if (!value) value = "generalInfo";
    if (value === "changePassword") value = "generalInfo";
    return {
      anchor: value,
    };
  };

  return !loading && obj ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={2}
          href={detailUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={prepareNavState(value)}
          _mainurl={mainUrl}
        />
        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {LL("EditBlock")}
        </Typography>
      </Box>

      <>
        <Box maxWidth="md" className="tabs">
          <Tabs variant="scrollable" value={value} onChange={handleChange}>
            {obj ? (
              <Tab label={LL("Edit_User_main_Info")} value={"generalInfo"} />
            ) : (
              <></>
            )}
            {obj ? (
              <Tab
                label={LL("Edit_User_Settings_Info")}
                value={"userSettings"}
              />
            ) : (
              <></>
            )}
            {obj ? <Tab label={LL("User_Role")} value={"userRole"} /> : <></>}
            {obj ? <Tab label={LL("User_Phone")} value={"userPhone"} /> : <></>}
            {obj ? (
              <Tab label={LL("User_Social")} value={"userSocial"} />
            ) : (
              <></>
            )}
            {obj ? (
              <Tab label={LL("User_Address")} value={"userAddress"} />
            ) : (
              <></>
            )}
            {obj ? (
              <Tab
                label={LL("User_Change_Password")}
                value={"changePassword"}
              />
            ) : (
              <></>
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={"generalInfo"}>
          <Box
            maxWidth="md"
            sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
          >
            <form onSubmit={handleSubmit}>
              {/* <MyTextField
                                tabIndex={0}
                                fullWidth
                                id="email"
                                name="email"
                                size="small"
                                variant="outlined"
                                color="primary"
                                _label={LL("Email")}
                                value={obj.email}
                                setObj={setObjField}
                                _vresults={
                                    vresults && vresults.email ? vresults.email : false
                                }
                            /> */}

              <StatusSelect
                tabIndex={0}
                id="status"
                name="status"
                variant="outlined"
                color="primary"
                value={obj.status}
                field="status"
                size="small"
                setObj={setObjField}
                _defaultValue={obj.status}
              />

              <Box sx={{ mt: 3 }}>
                <StatusSelect
                  tabIndex={1}
                  id="statuspassword"
                  name="statuspassword"
                  variant="outlined"
                  color="primary"
                  value={obj.statuspassword}
                  field="statuspassword"
                  size="small"
                  setObj={setObjField}
                  _defaultValue={obj.statuspassword}
                  statusType="password"
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <StatusSelect
                  tabIndex={2}
                  id="statusemail"
                  name="statusemail"
                  variant="outlined"
                  color="primary"
                  value={obj.statusemail}
                  field="statusemail"
                  size="small"
                  setObj={setObjField}
                  _defaultValue={obj.statusemail}
                  statusType="email"
                  _label="statusemail"
                />
              </Box>
              <MyButton
                tabIndex={3}
                disabled={isFormDisabled}
                color="primary"
                fullWidth
                variant="contained"
                size="large"
                type="submit"
                sx={{ mt: 4 }}
                children={LL("Bt_Submit")}
              />
            </form>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={"userSettings"}>
          {obj && obj.idusersettings ? (
            <EditUserSettingsInfo
              mainObject={mainObject}
              currentRoute={currentRoute}
            />
          ) : (
            <AddUserSettingsInfo
              mainObject={mainObject}
              currentRoute={currentRoute}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={"userRole"}>
          {obj && obj.userroles ? (
            <EditUserRole currentRoute={currentRoute} mainObject={mainObject} />
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel value={value} index={"userPhone"}>
          {obj && obj.phones ? (
            <AddPhone currentRoute={currentRoute} mainObject={mainObject} />
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel value={value} index={"userSocial"}>
          {obj ? (
            <AddSocial currentRoute={currentRoute} mainObject={mainObject} />
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel value={value} index={"userAddress"}>
          {obj ? (
            <AddAddressForm
              currentRoute={currentRoute}
              mainObject={mainObject}
            />
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel value={value} index={"changePassword"}>
          {obj ? (
            <ModifyPassword
              currentRoute={currentRoute}
              mainObject={mainObject}
            />
          ) : (
            <></>
          )}
        </TabPanel>
      </>
    </>
  ) : (
    <Loading />
  );
};

export default EditUser;
