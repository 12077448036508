import React, { useState, useEffect } from 'react';

import { logger } from 'hoc/logger';

import { PostLanguageDto } from 'dto/language/postlanguage.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { StatusSelect } from "components/elements/Select/StatusSelect"
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LanguageSelect } from 'components/elements/Select/LanguageSelect';
import IconButton from '@mui/material/IconButton';
import { TypesSelect } from 'components/elements/Select/TypesSelect';
import LocalityService from 'services/address/locality.service';
import { LocalityDto } from 'dto/address/locality.dto';
import { Status } from 'tools/status';

import { LocalityTypeSelect } from 'components/elements/Select/LocalityTypeSelect';
import { CountrySelect } from 'components/elements/Select/CountrySelect';

const localityService = new LocalityService();
interface EditLocalityProps {
    children: any;
    currentRoute: any;
    _obj: LocalityDto,
    mainObject: any;
}

const EditLocality: React.FC<EditLocalityProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,

    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';
    const _idLanguage = currentRoute.historystate.idLanguage !== undefined ? currentRoute.historystate.idLanguage : '';
    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<LocalityDto | undefined>()
    const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const [idLanguage, setIdLanguage] = useState<string>(_idLanguage);
    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);

    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;
        obj.idlocality = obj.idlocality !== undefined ? obj.idlocality : '';
        obj.name = obj.name !== undefined ? obj.name : '';
        obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.idtypelocality = obj.idtypelocality !== undefined ? obj.idtypelocality : '';
        obj.idcountry = obj.idcountry !== undefined ? obj.idcountry : '';

        setObj(obj);

        validateField("name", obj.name);
        
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            name: [],
            

        };

        v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
        
        setValidators(v);
    };


    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {
            if (idLanguage === '') localityService.get(id, loadObject, {});
            else localityService.get(id, loadObject, {}, idLanguage);
        }
    };

    useEffect(() => {
        processValidators();
        if (id === '') {
            if (obj !== undefined) {
                setId(obj.id)
                if (obj.idlanguage !== undefined) {
                    setIdLanguage(obj.idlanguage)
                }
            }
        }
        if (idLanguage !== '') getObject();

    }, [idLanguage]);

    useEffect(() => {
        validateAll();
        checkLoading();
        if (idLanguage === '') {
            if (obj !== undefined) {
                if (obj.idlanguage !== undefined) setIdLanguage(obj.idlanguage)
            }
        }
    }, [obj, validators]);


    useEffect(() => {
        processValidators();
        if (currentRoute.historystate.obj !== undefined) {
            setObj(currentRoute.historystate.obj);
            if (idLanguage === '') setIdLanguage(currentRoute.historystate.obj.idlanguage)
        }
        if (obj === undefined) getObject();

    }, []);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'name');
        // rez = ValidateTools.vForm(vresults, 'ordercriteria', rez);
        setIsFormDisabled(rez);
    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };


    const setObjFieldLanguage = (field: any, value: any) => {
        if (value !== undefined) setIdLanguage(value)
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (isFormDisabled) return false;
        if (obj !== undefined) {
            if (obj.id !== undefined) {
                obj.idlanguage = idLanguage;
                setLoading(true);
                localityService.update(
                    obj.id,
                    ComponentCommonTools.handleSuccessAddAndEdit,
                    { mainObject, idLanguage },
                    obj
                );
            }
        }
    };



    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        {
            url: detailUrl,
            label: LL("crumb_Detail" + mainObject),
        },
        { label: LL("crumb_Edit" + mainObject) },
    ];
    return !loading && obj ? (
        <>

            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
            <Box sx={{ flexDirection: 'row', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                <MyNavBt
                    tabIndex={8}
                    href={detailUrl}
                    children={<IconButton color="primary"><ArrowBackIcon /></IconButton>}
                    size="large"
                    _hstate={{}}
                    _mainurl={mainUrl}
                />
                <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
                    {LL("EditBlock")}
                </Typography>
            </Box>
            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit} >
                <LanguageSelect
                        tabIdex={0}
                        id="idlanguage"
                        name="idlanguage"
                        variant="outlined"
                        color="primary"
                        value={idLanguage}
                        field="idlanguage"
                        size="small"
                        setObj={setObjFieldLanguage}
                        obj={obj}
                    />
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={0}
                            fullWidth
                            id="name"
                            name="name"
                            variant="outlined"
                            color="primary"
                            _label={LL("name")}
                            size="small"
                            value={obj.name}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.name ? vresults.name : false
                            }
                        />
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={2}
                            fullWidth
                            id="ordercriteria"
                            name="ordercriteria"
                            variant="outlined"
                            color="primary"
                            _label={LL("ordercriteria")}
                            size="small"
                            value={obj.ordercriteria} 
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.ordercriteria ? vresults.ordercriteria : false
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <StatusSelect
                            tabIndex={3}
                            id="status"
                            name="status"
                            variant="outlined"
                            color="primary"
                            value={obj.status}
                            field="status"
                            size="small"
                            setObj={setObjField}
                            _defaultValue={obj.status}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <LocalityTypeSelect
                            tabIndex={4}
                            id="idtypelocality"
                            name="idtypelocality"
                            variant="outlined"
                            color="primary"
                            value={obj.idtypelocality}
                            field="idtypelocality"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <CountrySelect
                            tabIndex={6}
                            id="idcountry"
                            name="idcountry"
                            variant="outlined"
                            color="primary"
                            value={obj.idcountry}
                            field="idcountry"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                        />
                    </Box>
                    <MyButton
                        tabIndex={7}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>
            </Box>
        </>
    ) : (
        <Loading />
    );
};

export default EditLocality;
