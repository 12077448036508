import React, { useState, useEffect } from 'react';

import TypeLocalityService from "services/nomenclature/typelocality.service";
import GenListType from 'components/elements/MyGenComponents/GenListType';
import { PopulateTypeDto } from 'dto/nomenclature/populatetype.dto';


const typeLocalityService = new TypeLocalityService();


interface TypeLocalityProps {
    children: any;
    currentRoute: any;
    mainObject: any;
}

const TypeLocality: React.FC<TypeLocalityProps> = ({
    children,
    currentRoute,
    mainObject,
    ...props
}) => {

    return (
        <GenListType
            children={children}
            currentRoute={currentRoute}
            service={typeLocalityService}
            mainObject={mainObject}
            DTO={PopulateTypeDto}
            {...props}
        />
    )
};

export default TypeLocality;
