import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class SiteConfigDto implements Idto {
    
    id?: string;
    identifier?: string;
    value?: string;
  }

  export class PostSiteConfigDto implements Idto {
    
    identifier?: string;
    value?: string;
  }