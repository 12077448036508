import React, { useState, useEffect } from 'react';

import TypeLessonService from "services/nomenclature/typelesson.service";
import GenDetailType from 'components/elements/MyGenComponents/GenDetailType';


const typeLessonService = new TypeLessonService();


interface DetailTypeLessonProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const DetailTypeLesson: React.FC<DetailTypeLessonProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenDetailType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeLessonService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default DetailTypeLesson;
