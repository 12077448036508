import React, { useState, useEffect } from 'react';

import TypeSocialService from "services/nomenclature/typesocial.service";
import GenListType from 'components/elements/MyGenComponents/GenListType';
import { PopulateTypeDto } from 'dto/nomenclature/populatetype.dto';


const typeSocialService = new TypeSocialService();


interface TypeSocialProps {
    children: any;
    currentRoute: any;
    mainObject: any;
}

const TypeSocial: React.FC<TypeSocialProps> = ({
    children,
    currentRoute,
    mainObject,
    ...props
}) => {

    return (
        <GenListType
            children={children}
            currentRoute={currentRoute}
            service={typeSocialService}
            mainObject={mainObject}
            DTO={PopulateTypeDto}
            {...props}
        />
    )
};

export default TypeSocial;
