

import React from "react";


export const useReactPath = () => {
  const [_path, setPath] = React.useState(window.location.pathname);
  const [_historystate, setHistoryState] = React.useState(window.location.pathname);
  const listenToPopstate = () => {
    const winPath = window.location.pathname;
    setPath(winPath);
    setHistoryState(window.history.state);
  };
  React.useEffect(() => {
    window.addEventListener("popstate", listenToPopstate);
    return () => {
      window.removeEventListener("popstate", listenToPopstate);
    };
  }, []);
    return { path: _path, historystate: _historystate };
};
