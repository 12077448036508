import React, { useState, useEffect } from 'react';

import TeacherService from "services/teacher/teacher.service";
import { logger } from 'hoc/logger';

import { TeacherDto } from 'dto/teacher/teacher.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

const teacherService = new TeacherService();


interface DetailTeacherProps {
  children: any;
  currentRoute: any;
  _obj: TeacherDto,
  mainObject: any;
}

const DetailTeacher: React.FC<DetailTeacherProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();
  const detailObj = _obj !== undefined ? _obj : undefined;
  const [loading, setLoading] = useState<boolean>(true);
  const [obj, setObj] = useState<TeacherDto | undefined>(detailObj);
  const [id] = useState<string>(currentRoute._paths[1]);
  const editUrl = CommonTools.generateEditUrl(mainObject, id);

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    if (id !== undefined) teacherService.get(id, loadObject, {});
  };

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    if (obj === undefined) getObject();
  }, []);

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    { label: LL("crumb_Detail" + mainObject) },
  ];

  const processCV = () => {
    if (!obj) return <></>;
    if(!obj.hasOwnProperty("attachmentcv")) return <></>;
    if (!obj.attachmentcv) return <></>;
    return <>{ComponentCommonTools.processFileLink(obj.attachmentcv)}</>

  }

  const processVideoPresentation = () => {
    if (!obj) return <></>;
    if(!obj.hasOwnProperty("videopresentation")) return <></>;
    if (!obj.videopresentation) return <></>;
    return <>{ComponentCommonTools.getFileBlockVideo(obj.videopresentation)}</>
  }

  const processVideoLessonExample = () => {
    if (!obj) return <></>;
    if(!obj.hasOwnProperty("videolessonexample")) return <></>;
    if (!obj.videolessonexample) return <></>;
    return <>{ComponentCommonTools.getFileBlockVideo(obj.videolessonexample)}</>
  }

  
  return !loading ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
        maxWidth="md"
      >
        <MyNavBt
          tabIndex={1}
          href={listUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />
        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {" "}
          {LL("DetailBlock")}{" "}
        </Typography>

        {obj ? (
          <Box sx={{ ml: "auto" }}>
            <MyNavBt
              tabIndex={1}
              href={CommonTools.userDetailUrl(obj)}
              children={LL("DetailUser")}
              className="detailBtn"
              _hstate={{}}
              _mainurl={mainUrl}
            />
            <MyNavBt
              tabIndex={0}
              href={editUrl}
              children={
                <IconButton className="editBtn">
                  <EditIcon />
                </IconButton>
              }
              _hstate={CommonTools.prepareObjectForUpdate(obj, mainObject)}
              _mainurl={mainUrl}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <Grid spacing={2}>
          <Grid>
            <Typography color="text.disabled" variant="caption">
              {LL("Email")}
            </Typography>
          </Grid>
          <Grid>{ComponentCommonTools.processObjectField(obj, "email")}</Grid>
          <Grid mt={3}>
            <Typography color="text.disabled" variant="caption">
              {LL("Name")}
            </Typography>
          </Grid>
          <Grid>{ComponentCommonTools.processObjectField(obj, "name")}</Grid>
          <Grid mt={3}>
            <Typography color="text.disabled" variant="caption">
              {LL("Surname")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectField(obj, "surname")}
          </Grid>
          <Grid mt={3}>
            <Typography color="text.disabled" variant="caption">
              {LL("Birthday")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectField(obj, "birthday_name")}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("bio")}
            </Typography>
          </Grid>
          <Grid>{ComponentCommonTools.processObjectField(obj, "bio")}</Grid>

          <Grid mt={3}>
            <Typography color="text.disabled" variant="caption">
              {LL("Status")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectField(obj, "status_name")}
          </Grid>
          <Grid mt={3}>
            <Typography color="text.disabled" variant="caption">
              {LL("hasaudience")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectField(obj, "hasaudience_name")}
          </Grid>
          <Grid mt={3}>
            <Typography color="text.disabled" variant="caption">
              {LL("timetoteachperweek")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectField(
              obj,
              "timetoteachperweek"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("graduatedinstitution")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectField(
              obj,
              "graduatedinstitution"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("typestudies")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typestudies",
              "name"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("whyliveclass")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectField(obj, "whyliveclass")}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("typespecialty")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typespecialty",
              "name"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("typetargetgroup")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typetargetgroup",
              "name"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("typeinternetconnection")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typeinternetconnection",
              "name"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("typehardware")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typehardware",
              "name"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("typelesson")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typelesson",
              "name"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("typediscipline")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typediscipline",
              "name"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("typeoccupation")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typeoccupation",
              "name"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("typeprofessionalismlevel")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typeprofessionalismlevel",
              "name"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("typeexperience")}
            </Typography>
          </Grid>
          <Grid>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typeexperience",
              "name"
            )}
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("CV")}
            </Typography>
          </Grid>
          <Grid>
            {processCV()}
          </Grid>

          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("VideoPresentation")}
            </Typography>
          </Grid>
          <Grid>
            {processVideoPresentation()}
          </Grid>

          <Grid sx={{ mt: 3 }}>
            <Typography color="text.disabled" variant="caption">
              {LL("VideoLessonExample")}
            </Typography>
          </Grid>
          <Grid>
            {processVideoLessonExample()}
          </Grid>
        </Grid>
      </Box>
    </>
  ) : (
    <Loading />
  );
};


export default DetailTeacher;
