import React, { useEffect, useState } from "react";

import { logger } from "hoc/logger";
import { useResource } from "hook/useResource";
import RoleService from "services/user/role.service";

import RequestListDTO from 'dto/app/requestlist.dto';

import ResultListDTO from 'dto/app/resultlist.dto';
import RoleDto from 'dto/user/role.dto';
import { CommonTools } from 'tools/commontools';
import MyCheckboxField from "components/form/mycheckboxfield";
import Box from '@mui/material/Box';

const roleService = new RoleService();

type RoleSelectorProps = {
    
    checkDefaultRoles:any
    setCheckRole:any
    [key: string]: any; // for the rest of the props which we do not have types for
}

const RoleSelector: React.FC<RoleSelectorProps> = ({
    
    checkDefaultRoles,
    setCheckRole,
    ...props
}) => {
    const { LL } = useResource();

    
    const [roles, setRoles] = useState<Array<RoleDto>| any>();
    const [loading, setLoading] = useState<boolean>(true);
    
    
    const onPage = 2000;
    
    const [reqList, setReqList] = useState<RequestListDTO>(roleService.getDefaultRequestListSelect(onPage));
    
    const loadObjects = (data: ResultListDTO) => {
        setRoles(data.objects);
    };

    const getReqList = () => {
        const reqList = roleService.getDefaultRequestListSelect(onPage)
        setReqList(reqList);
    };

    const getList = () => {
        setLoading(true);
        roleService.getList(loadObjects, {}, reqList);
    };


    // useEffect(() => {
    //     getReqList();
    // }, []);

    useEffect(() => {
        setLoading(true);
        if (CommonTools.atLeastOneFieldDefined(reqList)) {
            getList();
        }
    }, [reqList]);


    useEffect(() => {
        checkIsLoading();
    }, [roles]);

    const checkIsLoading = () => {
        let load: boolean = false;
        if (roles === undefined) load = true;
        
        setLoading(load);
    };

    const processRoles = () => {
        if(roles === undefined) return (<></>);
        return roles.map((item: RoleDto,index:any) =>
        (
            <Box key={index}>
                <MyCheckboxField
                    defaultChecked={checkDefaultRoles(item.id)}
                    id={item.id}
                    name={item.name}
                    _label={item.name}
                    setObj={undefined}
                    onChange={setCheckRole}
                    checked={undefined}
                    value={item.id}
                    color="primary"
                    size="small"
                    _vresults={undefined}
                />
            </Box>
        )
        )
    }

    return !loading ? (
       <>
       {roles && roles.length > 0 ? processRoles() : (<></>)}
       </>
    ) : (<></>);
};

export { RoleSelector };
