import Idto from "interfaces/idto.interface";
import { Status } from "tools/status";
export class PostUserDto implements Idto {
  status?: number;
  registerdate?: number;
  email?: string;
  statusemail?: number;
  password?: string;
  statuspassword?: number;
  hash?: string;
  constructor() {
    this.status = Status.ACTIVE;
}
}
