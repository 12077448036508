

import React, { useEffect, useState, useRef } from "react";

import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";


import {
    IconButton,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Stack,
    Typography,
    ListItemIcon
} from "@mui/material";


import { NotificationParamsDto } from "dto/user/notificationparams.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ComponentCommonTools } from "tools/componentcommontools";
import NotificationParamsService from "services/user/notificationparams.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Status } from "tools/status";
import FilePresentIcon from '@mui/icons-material/FilePresent';
const notificationParamsService = new NotificationParamsService();
type NotificationParamsListProps = {
    idnotification: string;
}
const NotificationParamsList: React.FC<NotificationParamsListProps> = ({
    idnotification,

    ...props
}) => {
    const { LL } = useResource();
    const [loading, setLoading] = useState<boolean>(false);
    const [objects, setObjects] = useState<Array<NotificationParamsDto>>([]);

    const getList = () => {
        if (!idnotification) return;
        const reqList = new RequestListDTO();
        const onpage = process.env.REACT_APP_NOTIFICATION_PARAMS_ON_PAGE || '999999';
        reqList.onpage = parseInt(onpage);
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];


        let f: RequestFilterDTO;


        f = new RequestFilterDTO();
        f.field = "idnotification";
        f.values = [idnotification];
        reqList.filters.push(f);
        setLoading(true);

        notificationParamsService.getList(loadObjects, {}, reqList);
    };

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }

    const checkLoading = () => {
        let load = false;
        if (!objects) load = true;
        setLoading(load);
    }

    useEffect(() => {
        checkLoading();
    }, [objects]);

    useEffect(() => {
        getList();
    }, [idnotification]);


    const processItem = (item: NotificationParamsDto, i: number) => {
        if (!item) return (<></>);
        if (!item.hasOwnProperty('id')) return (<></>);
        if (!item.id) return (<></>);

        return (
            <ListItem
                key={i}
            >
                <Stack direction={'row'} spacing={1}>
                <Typography>
                    {ComponentCommonTools.processObjectField(item, 'params')}
                </Typography>
                <Typography>
                    {ComponentCommonTools.processObjectField(item, 'value')}
                </Typography>
                </Stack>
            </ListItem>
        );
    }
    const processList = () => {
        if (loading) return <Skeleton variant="rectangular" />;
        if (!objects.length) return <></>;

        return (
            <List>
                {objects.map((item: NotificationParamsDto, i: number) => {
                    return processItem(item, i);
                })}
            </List >
        );
    };
    return (processList())
};

export { NotificationParamsList };