
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class PostCurrencyRateDto implements Idto {

    date?: Date;
    fromidcurrency?: string;
    toidcurrency?: string;
    rate?: number;
}

export class CurrencyRateDto implements Idto {

    id?: string;
    date?: Date;
    fromidcurrency?: string;
    toidcurrency?: string;
    rate?: number;
    fromidcurrencyobj?: any;
    toidcurrencyobj?: any;
}