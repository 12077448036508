import React from "react";

import Link from "@mui/material/Link";

// import { v4 as uuidv4 } from "uuid";
// import MD5 from "crypto-js/md5";
import { useEffect, useState } from "react";
import { logger } from "hoc/logger";
import { RouteTools } from "tools/routetools";
import { useResource } from "hook/useResource";

const MyNavBt = ({
  children,
  href,
  _hstate,
  _mainurl,
  ...props
}) => {
  const { currentRoute, setCurrentRoute } = useResource();

  const [hstate, setHstate] = useState({});
  const [mainurl, setMainUrl] = useState("");

  useEffect(() => {
    const h = _hstate != undefined ? _hstate : {};
    setHstate(h);
  }, [_hstate]);

  useEffect(() => {
    const m = _mainurl != undefined ? _mainurl : href;
    setMainUrl(m);
  }, [_mainurl]);

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const h = hstate ? hstate : {};
    h._mainurl = mainurl;
    let anchor = "";
    if (h.hasOwnProperty('anchor') && h.anchor) anchor = h.anchor;

    RouteTools.setHistory(href, h, anchor);
  };

  return (
    <Link href={href} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
};

export { MyNavBt };
