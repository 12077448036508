import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class ScheduleDto implements Idto {
    
    id?: string;
    startdate?: Date;
    enddate?: Date;
    islifetime?: boolean;
  }

  export class PostScheduleDto implements Idto {
    
    startdate?: Date;
    enddate?: Date;
    islifetime?: boolean;
  }