import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { PostAgreementDto } from "dto/legal/agreement.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import AgreementRepository from "repositories/legal/agreement.repository";
import { Status } from "tools/status";

import GeneralService from "services/general.service";
export default class AgreementService extends GeneralService implements IService {
    agreementRepository: AgreementRepository = new AgreementRepository();
    constructor() {
        super();
        this.handleGetList = this.handleGetList.bind(this);
        this.handleGet = this.handleGet.bind(this);
      }
    handleUpdate(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;

        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }

    async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        this.agreementRepository.update(id, this.handleUpdate, this.prepareObjToUpdate(data), cbparameters);
    }

    handleDelete(result?: any, cbparameters?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters);
        }
    }

    async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;

        this.agreementRepository.delete(id, this.handleDelete, cbparameters);
    }



    handleGet(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;
        this.populateObject(rez.obj, result.obj, [
            { key: 'status', type: 'status' },
            { key: 'date', type: 'timestamp' },
        ]);
        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

    return rez;
    }

    async get(id: string, cb?: any, cbparameters?: any,idLanguage?:string): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.agreementRepository.get(id, this.handleGet, cbparameters, idLanguage);
        if (cb == undefined)
        {
            return this.handleGet(t);
        }
      }

    handleGetList(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultListDTO();

        rez = result;
        if (result.err) return;

this.parseResultPopulate(result, rez, [
  { key: 'status', type: 'status' },
  { key: 'date', type: 'timestamp' },
  
]);

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

        return rez;
    }

    async getList(
        cb?: any,
        cbparameters?: any,
        data?: RequestListDTO
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.agreementRepository.getList(this.handleGetList, cbparameters, data);
        if (cb == undefined)
        {
            return this.handleGetList(t);
        }
    }

    handleAdd(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }

    prepareObjToAdd(obj: any): PostAgreementDto {
        const rez = new PostAgreementDto();

        rez.idlanguage = obj.idlanguage;
        rez.status = obj.status;
        rez.name = obj.name;
        rez.description = obj.description;
        rez.idtypeagreement = obj.idtypeagreement;

        return rez;
    }

    prepareObjToUpdate(obj: any): PostAgreementDto {
        const rez = new PostAgreementDto();

        
        rez.idagreement = obj.idagreement;

        rez.idlanguage = obj.idlanguage;
        rez.status = obj.status;
        rez.name = obj.name;
        rez.description = obj.description;
        rez.idtypeagreement = obj.idtypeagreement;
        
        return rez;
    }

    async add(
        cb?: any,
        cbparameters?: any,
        data?: any
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;

        this.agreementRepository.add(this.handleGetList, cbparameters, this.prepareObjToAdd(data));
    }


    getDefaultRequestListSelect(onPage?: number): RequestListDTO {
        onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
        if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
        var defaultRequestList = new RequestListDTO();
        defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
        defaultRequestList.onpage = onPage;
        var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
        defaultRequestSortCriteria.asc = true;
        defaultRequestSortCriteria.field = 'name';
        defaultRequestList.sortcriteria = []
        defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
        return defaultRequestList;
      }
      getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
        if (
          !obj.sortcriteria ||
          !Array.isArray(obj.sortcriteria) ||
          !obj.sortcriteria.length
        ) {
          var sobj = new RequestSortCriteriaDTO();
          sobj.asc = true;
          sobj.field = "name";
    
          obj.sortcriteria = [];
          obj.sortcriteria?.push(sobj);
        }
    
        return obj;
      }
}
