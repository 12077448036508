
import { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { useAuth } from "hook/useAuth";
import AppBar from '@mui/material/AppBar';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import SignInDto from "dto/auth/signin.dto";

import Button from "@mui/material/Button";
import { Loading } from "components/general/Loading";
import MyTextField from "components/form/mytextfield";
import MyCheckboxField from "components/form/mycheckboxfield";
import RequiredValidator from "validators/required.validator";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import Container from "@mui/material/Container";
import InputAdornment from '@mui/material/InputAdornment';

import MailOutlineIcon from '@mui/icons-material/MailOutline';

import Box from '@mui/material/Box';
import { logger } from "hoc/logger";
import ModifyPasswordDto from "dto/user/modifypassword.dto";
import UserService from "services/user/user.service";
const userService = new UserService();

interface AddUserProps {

}

const ChangPasswordForm: React.FC<AddUserProps> = () => {

    const { user, signOut } = useAuth();

    const { LL } = useResource();

    const [isLoading, setIsLoading] = useState(true);
    const [isFormDisabled, setIsFormDisabled] = useState(true);

    const [obj, setObj] = useState<ModifyPasswordDto>(new ModifyPasswordDto());

    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        if (vresults) return;
        obj.email = user ? user.email : '';
        obj.currentpassword = obj.currentpassword != undefined ? obj.currentpassword : "";
        obj.newpassword = obj.newpassword != undefined ? obj.newpassword : "";
        setObj(obj);

        let t = validateField("currentpassword", obj.currentpassword);
        t = validateField("newpassword", obj.newpassword, t);
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            currentpassword: [],
            newpassword: []
        }

        v.currentpassword.push(new RequiredValidator(LL('Required field currentpassword', LabelTypes.LABEL_VALIDATION), ''));
        v.newpassword.push(new RequiredValidator(LL('Required field newpassword', LabelTypes.LABEL_VALIDATION), ''));

        setValidators(v);
    };

    const checkIsLoading = () => {
        if (!isLoading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setIsLoading(false);
    };

    useEffect(() => {
        processValidators();

    }, []);

    useEffect(() => {
        checkIsLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };



    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'currentpassword');
        rez = ValidateTools.vForm(vresults, "newpassword", rez);

        setIsFormDisabled(rez);
    };

    const handlePasswordChange = () => {
        setIsLoading(true);
        setIsFormDisabled(true);
        setObjField('currentpassword', '')
        setObjField('newpassword', '')
        signOut();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (isFormDisabled) return false;
        userService.modifyPassword(handlePasswordChange, {}, obj);
    };

    return !user && isLoading ? (
        <></>
    ) : (

        <Container sx={{
            marginTop: "5%"
        }} >

            <Box sx={{
                alignItems: "center",
                alignContent: "center",
                width: "50%",
                flexDirection: "column",
                justifyContent: "center",
            }}>



                <form onSubmit={handleSubmit} >
                    <Box pb={3}>
                        <MyTextField
                            fullWidth
                            id="currentpassword"
                            name="currentpassword"
                            variant="outlined"
                            color="primary"
                            type="password"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><LockOpenIcon /></InputAdornment>,
                            }}
                            _label={LL("currentpassword")}
                            value={obj.currentpassword}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.currentpassword ? vresults.currentpassword : false
                            }
                        />
                    </Box>
                    <Box pb={1}>
                        <MyTextField
                            fullWidth
                            type="password"
                            id="newpassword"
                            name="newpassword"
                            variant="outlined"
                            color="primary"

                            InputProps={{
                                startAdornment: <InputAdornment position="start"><LockOpenIcon /></InputAdornment>,
                            }}
                            _label={LL("newpassword")}
                            value={obj.newpassword}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.newpassword ? vresults.newpassword : false
                            }
                        />
                    </Box>

                    <Box mt={2}>
                        <Button
                            disabled={isFormDisabled}
                            color="primary"
                            fullWidth
                            variant="contained"
                            size="large"
                            type="submit"
                        >
                            {LL("Bt ChangPassword")}
                        </Button>
                    </Box>
                </form>

            </Box>
        </Container>
    );
};

export { ChangPasswordForm };
