import React from "react";

import { UserTopInfo } from "components/auth/UserTopInfo.jsx";

import Box from "@mui/material/Box";
import { TopLanguageBlock } from "components/language/TopLanguageBlock";

const TopBar = () => {
  return (
    <>
      <UserTopInfo />
      <TopLanguageBlock />
    </>
  );
};

export { TopBar };
