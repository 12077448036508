import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class TicketCategoryDto implements Idto {
    
    id?: string;
    idlanguage?: string;
    status?: number;
    ordercriteria?: number;
    name?: string;
    idparent?: string;
    idticketcategory?: string;   
    constructor() {
      this.status = Status.ACTIVE;
      this.ordercriteria = 0;
  }
  }

  export class PostTicketCategoryDto implements Idto {
   
    idlanguage?: string;
    status?: number;
    ordercriteria?: number;
    name?: string;
    idparent?: string;
    idticketcategory?: string;  
    constructor() {
      this.status = Status.ACTIVE;
      this.ordercriteria = 0;
  }
  }