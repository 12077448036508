
import { createTheme } from "@mui/material/styles";

const theme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#ff002a',
      },
      error: {
        main: '#e53935',
      },
      background: {
        default: '#FAFEFF',
        paper: '#ffffff',
      },
      text: {
        primary: 'rgba(5, 19, 36, 0.9)',
        secondary: 'rgba(5, 19, 36, 0.6)',
        disabled: 'rgba(5, 19, 36, 0.4)',
      },
    },

    typography: {
      fontFamily: 'Open Sans',
      fontSize: 13,
      htmlFontSize: 17,
      h1: {
        fontSize: '2rem',
        fontWeight: 700,
      },
      h2: {
        fontSize: '1.8rem',
        fontWeight: 600,
      },
      h3: {
        fontSize: '1.6rem',
        fontWeight: 600,
      },
      h4: {
        fontSize: '1.4rem',
        fontWeight: 600,
      },
      h5: {
        fontSize: '1.4rem',
        fontWeight: 600,
      },
      h6: {
        fontSize: '1.2rem',
        fontWeight: 600,
      },
      caption: {fontSize:12, textTransform:'uppercase', fontWeight:500, letterSpacing: '1px'},

      overline:{sontSize:12}
      
    },
  },
);

export default theme;