import Link from "@mui/material/Link";
import { useEffect, useState } from "react";
import { logger } from "hoc/logger";
import { useAuth } from "hook/useAuth";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { v4 as uuidv4 } from "uuid";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const LeftPartUserMenu = ({
  setOpen,
  ...props }

) => {
  const { user } = useAuth();
  const { currentRoute, setCurrentRoute } = useResource();
  const [adminMenu, setAdminMenu] = useState(false);
  const [openedIdentifier, setOpenedIdentifier] = useState("");

  // ----------------------------------------
  useEffect(() => {
    let t = RouteTools.getAdminLeftMenu();
    t = processAdminMenSelected(t);
    setAdminMenu(t);
  }, [currentRoute, user]);

  const processAdminMenSelected = (objects) => {
    if (!objects) return false;
    if (!objects.length) return false;

    var rez = [];
    
    for (var k in objects) {
      var obj = objects[k];
      var childs = processAdminMenSelected(obj.childs);

      var isChildsSelected = false;

      if (childs && childs.length) {
        for (var i in childs) {
          if (!childs[i].isSelected) continue;
          isChildsSelected = true;

          break;
        }
      }

      obj.childs = childs;

      obj.isSelected = false;

      if (isChildsSelected) {
        obj.isSelected = true;

      } else {
        if (currentRoute) {
          let h = currentRoute.url.split('?');
          h = h[0];
          // logger(
          //   "currentRoutecurrentRoutecurrentRoutecurrentRoute",
          //   currentRoute,
          //   obj.href, h
          // );
          if (
            obj.href == currentRoute.url ||
            obj.href == h ||
            (currentRoute &&
              currentRoute.historystate &&
              currentRoute.historystate._mainurl &&
              currentRoute.historystate._mainurl == obj.href)
          ) {
            obj.isSelected = true;


          }
        }
      }

      rez.push(obj);
    }

    // ---------

    return rez;
  };

  const openMenu = (item) => {
    // var item = obj.target;
    logger("openMenu", item)
    if (item.identifier != openedIdentifier) {
      setOpenedIdentifier(item.identifier);
      setOpen(true)
    } else {
      setOpenedIdentifier("");
      // setOpen(false)
    }
  };

  const processAdminMenItemChilds = (item, level, i) => {
    const nextLevel = level + 1;

    if (!item.isSelected && item.identifier != openedIdentifier) return <></>;

    return processAdminMen(item.childs, nextLevel, item, i);
  };

  const processAdminMenItemDetailBt = (item) => {
    if (!item.childs) return <></>;

    if (!item.isSelected && item.identifier != openedIdentifier) return <><ChevronRightRoundedIcon color="primary" sx={{ ml: 'auto' }} /></>;

    return <><ExpandMoreRoundedIcon color="primary" sx={{ ml: 'auto' }} /></>;
  };

  const processAdminMenItemDetailIcon = (item) => {
    return item.getIcon();
  };

  const processAdminMenItem = (item, level, i) => {
    var nextLevel = level + 1;
    const key = uuidv4();;
    const key2 = uuidv4();;
    return (
      <>
        <li
          className={item.isSelected ? "active" : "incative"}
          key={key}
          onClick={() => {
            openMenu(item);

          }}
        >
          {processAdminMenItemDetailIcon(item)}

          {item.href ? (
            <MyNavBt key={key2} href={item.href}>
              {item.label}
              {/* {level} */}
            </MyNavBt>
          ) : (
            <span>
              {item.label}
              {/* {level} */}
            </span>
          )}
          {processAdminMenItemDetailBt(item)}
        </li>
        {processAdminMenItemChilds(item, level, i)}
      </>
    );
  };

  const processAdminMen = (objects, level, parentObj, i) => {
    if (!objects) return <></>;
    if (!objects.length) return <></>;

    parentObj = parentObj ? parentObj : false;

    // const cl = vresults.isOk ? "active" : "disabled";
    if (i === undefined) i = 1;

    const key = uuidv4();
    
    return (
        <ul key={key}>{objects.map((item, i) => processAdminMenItem(item, level, i))}</ul>
    );
  };

  return processAdminMen(adminMenu, 1);
};

export { LeftPartUserMenu };
