import LabelTypesDto from "dto/app/labeltypes.dto";
import SelectOptions from "dto/app/selectoptions.dto";

export class LabelTypes {
  static _LL: any = false;

  static setLL(_l: any): any {
    this._LL = _l;
  }

  // -------------------------------------

  public static LABEL_ADMIN = 1;
  public static LABEL_SITE = 2;
  public static LABEL_SUPPORT = 3;
  public static LABEL_VALIDATION = 4;
  public static LABEL_MESSAGE = 5;

  // -------------------------------------

  public static _constants: { [key: number]: string } = {
    1: "LABEL_ADMIN",
    2: "LABEL_SITE",
    3: "LABEL_SUPPORT",
    4: "LABEL_VALIDATION",
    5: "LABEL_MESSAGE",
  };

  // -------------------------------------

  public static LL = (key: any) => {
    const k: number = parseInt(key);
    if (!this._constants.hasOwnProperty(k)) return null;
    return this._LL(
      "Status_" + this._constants[k].toString(),
      LabelTypes.LABEL_MESSAGE
    );
  };

  // -------------------------------------

  public static GA_KEYS = (key: string): Array<number> => {
    var rez: Array<number> = [];

    if (key == "clasificator") {
      rez = [
        this.LABEL_ADMIN,
        this.LABEL_SITE,
        this.LABEL_SUPPORT,
        this.LABEL_VALIDATION,
        this.LABEL_MESSAGE,
      ];
    }

    return rez;
  };

  public static GA = (
    key: string,
    emptyVal?: boolean,
    emptyLabel?: string
  ): Array<SelectOptions> => {
    const keys = this.GA_KEYS(key);

    const rez: Array<SelectOptions> = [];

    if (emptyVal) {
      const t = new SelectOptions();
      t.value = "";
      t.label = emptyLabel ?? LabelTypes._LL("all");
      rez.push(t);
    }

    keys.map(function (item) {
      const t = new SelectOptions();
      t.value = item;
      t.label = LabelTypes.LL(item);
      rez.push(t);
    });

    return rez;
  };

  // -------------------------------------
}
