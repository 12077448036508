
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export default class UserDto implements Idto {
  id?: string;
  status?: number;
  registerdate?: number;
  email?: string;
  statusemail?: number;
  password?: string;
  statuspassword?: number;
  hash?: string;
  registerdate_name?: any;
  statusemail_name?: any;
  statuspassword_name?: any;
  status_name?: any;
  idusersettings?: string;
  userroles?: any;
  phones?: any;
  constructor() {
    this.status = Status.ACTIVE;
}
}
