import React, { useState, useEffect } from 'react';

import TestimonialService from "services/testimonial/testimonial.service";
import { logger } from 'hoc/logger';

import { TestimonialDto, PostTestimonialDto } from 'dto/testimonial/testimonial.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import MyCheckboxField from "components/form/mycheckboxfield";
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';

import { Status } from 'tools/status';

import { UserAutocomplete } from 'components/elements/Autocomplete/UserAutocomplete';
import { CourseAutocomplete } from 'components/elements/Autocomplete/CourseAutocomplete';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { TeacherAutocomplete } from 'components/elements/Autocomplete/TeacherAutocomplete';


const testimonialService = new TestimonialService();

interface AddTestimonialProps {
    children: any;
    currentRoute: any;
    _obj: TestimonialDto,
    mainObject: any;
}

const AddTestimonial: React.FC<AddTestimonialProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

    const [loading, setLoading] = useState<boolean>(false);
    var [obj, setObj] = useState<PostTestimonialDto>(new PostTestimonialDto());


    const [isFormDisabled, setIsFormDisabled] = useState(true);


    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.iduser = obj.iduser !== undefined ? obj.iduser : '';
        obj.message = obj.message !== undefined ? obj.message : '';
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.idcourse = obj.idcourse !== undefined ? obj.idcourse : '';
        obj.idteacher = obj.idteacher !== undefined ? obj.idteacher : '';
        obj.rating = obj.rating !== undefined ? obj.rating : 5;


        setObj(obj);

        let t = validateField("message", obj.message);


    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {

            message: [],

        };


        v.message.push(new RequiredValidator(LL('Required field message', LabelTypes.LABEL_VALIDATION), ''));


        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    useEffect(() => {
        validateAll();
        processValidators();
    }, []);

    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };



    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'message');


        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;

        testimonialService.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
    };


    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Add" + mainObject) },
    ];
    return !loading ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <MyNavBt
            tabIndex={6}
            href={listUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />

          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {LL("AddBlock")}
          </Typography>
        </Box>

        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <form onSubmit={handleSubmit}>
            <MyTextField
              tabIndex={0}
              fullWidth
              id="message"
              name="message"
              variant="outlined"
              color="primary"
              _label={LL("message")}
              size="small"
              value={obj.message}
              setObj={setObjField}
              _vresults={
                vresults && vresults.message ? vresults.message : false
              }
            />

            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={1}
                fullWidth
                id="rating"
                name="rating"
                variant="outlined"
                color="primary"
                _label={LL("rating")}
                size="small"
                value={obj.rating}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>

            <UserAutocomplete
              tabIndex={2}
              id="iduser"
              variant="outlined"
              color="primary"
              field="iduser"
              size="small"
              setObj={setObj}
              obj={obj}
              setObjFieldGeneral={setObjField}
            />

            <TeacherAutocomplete
              tabIndex={3}
              id="idteacher"
              variant="outlined"
              color="primary"
              field="idteacher"
              size="small"
              setObj={setObj}
              obj={obj}
              setObjFieldGeneral={setObjField}
            />

            <Box sx={{ mt: 3 }}>
              <StatusSelect
                tabIndex={4}
                id="status"
                name="status"
                variant="outlined"
                color="primary"
                value={obj.status}
                field="status"
                size="small"
                setObj={setObjField}
              />
            </Box>

            <CourseAutocomplete
              variant="outlined"
              color="primary"
              size="small"
              setObj={setObj}
              obj={obj}
              _defaultValue={obj.course}
            />

            <MyButton
              tabIndex={5}
              disabled={isFormDisabled}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
          </form>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default AddTestimonial;
