import React, { useState, useEffect } from 'react';

import TeacherService from "services/teacher/teacher.service";
import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import { TeacherDto } from 'dto/teacher/teacher.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MyCheckboxField from "components/form/mycheckboxfield";
import IconButton from '@mui/material/IconButton';
import { StudyTypeSelect } from 'components/elements/Select/StudyTypeSelect';
// import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { SpecialtyTypeSelect } from 'components/elements/Select/SpecialtyTypeSelect';
import { TargetGroupTypeSelect } from 'components/elements/Select/TargetGroupTypeSelect';
import { InternetConnectionTypeSelect } from 'components/elements/Select/InternetConnectionTypeSelect';
import { HardwareTypeSelect } from 'components/elements/Select/HardwareTypeSelect';
import { LessonTypeSelect } from 'components/elements/Select/LessonTypeSelect';
import { OccupationTypeSelect } from 'components/elements/Select/OccupationTypeSelect';
import { ProfessionalismLevelTypeSelect } from 'components/elements/Select/ProfessionalismLevelTypeSelect';
import { ExperienceTypeSelect } from 'components/elements/Select/ExperienceTypeSelect';
import { DisciplineTypeSelect } from 'components/elements/Select/DisciplineTypeSelect';
import { TeacherStatusSelect } from 'components/elements/Select/TeacherStatusSelect';
import MyCKEditor from 'components/elements/ckeditor/MyCKEditor';
import { Status } from 'tools/status';
import { TabPanel } from 'components/elements/Tabs/TabPanel';
import { Tab, Tabs } from '@mui/material';
import AddTeacherMedia from './AddTeacherMedia';
const teacherService = new TeacherService();

interface EditTeacherProps {
    children: any;
    currentRoute: any;
    _obj: TeacherDto,
    mainObject: any;
}

const EditTeacher: React.FC<EditTeacherProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {

    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");
    const [addUrl, setAddUrl] = useState("");

    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const au = CommonTools.generateAddUrl(mainObject);
        setAddUrl(au);
        const lu = _getListUrl(mu);
        setListUrl(lu);
    }, [mainObject, currentRoute]);

    const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<TeacherDto | undefined>()
    const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.bio = obj.bio !== undefined ? obj.bio : '';
        obj.graduatedinstitution = obj.graduatedinstitution !== undefined ? obj.graduatedinstitution : '';
        obj.timetoteachperweek = obj.timetoteachperweek !== undefined ? obj.timetoteachperweek : '';
        obj.whyliveclass = obj.whyliveclass !== undefined ? obj.whyliveclass : '';
        obj.hasaudience = obj.hasaudience !== undefined ? obj.hasaudience : false;

        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.idtypestudies = obj.idtypestudies !== undefined ? obj.idtypestudies : '';
        obj.idtypespecialty = obj.idtypespecialty !== undefined ? obj.idtypespecialty : '';
        obj.idtypetargetgroup = obj.idtypetargetgroup !== undefined ? obj.idtypetargetgroup : '';
        obj.idtypeinternetconnection = obj.idtypeinternetconnection !== undefined ? obj.idtypeinternetconnection : '';
        obj.idtypelesson = obj.idtypelesson !== undefined ? obj.idtypelesson : '';
        obj.idtypediscipline = obj.idtypediscipline !== undefined ? obj.idtypediscipline : '';
        obj.idtypeoccupation = obj.idtypeoccupation !== undefined ? obj.idtypeoccupation : '';
        obj.idtypeprofessionalismlevel = obj.idtypeprofessionalismlevel !== undefined ? obj.idtypeprofessionalismlevel : '';
        obj.idtypeexperience = obj.idtypeexperience !== undefined ? obj.idtypeexperience : '';
        setObj(obj);

        let t = validateField("timetoteachperweek", obj.timetoteachperweek);
        t = validateField("graduatedinstitution", obj.graduatedinstitution, t);
        t = validateField("whyliveclass", obj.whyliveclass, t);
        logger("validateAll", t);
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {

            timetoteachperweek: [],
            graduatedinstitution: [],
            whyliveclass: [],
        };


        v.timetoteachperweek.push(new RequiredValidator(LL('Required field timetoteachperweek', LabelTypes.LABEL_VALIDATION), ''));
        v.graduatedinstitution.push(new RequiredValidator(LL('Required field graduatedinstitution', LabelTypes.LABEL_VALIDATION), ''));
        v.whyliveclass.push(new RequiredValidator(LL('Required field whyliveclass', LabelTypes.LABEL_VALIDATION), ''));

        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {

            teacherService.get(id, loadObject, {});
        }
    };

    useEffect(() => {
        processValidators();
        if (id === '') {
            if (obj !== undefined) {
                setId(obj.id)
            }
        }
    }, []);
    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'timetoteachperweek');

        rez = ValidateTools.vForm(vresults, 'graduatedinstitution', rez);
        rez = ValidateTools.vForm(vresults, 'whyliveclass', rez);
        setIsFormDisabled(rez);
    };

    useEffect(() => {
        validateAll();
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);

    useEffect(() => {
        if (obj === undefined) getObject();
    }, [obj]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (id !== undefined) {
            teacherService.update(id, ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
        }
    };

    const setCheckFixed = (value: any) => {
        let t = JSON.parse(JSON.stringify(obj));

        t['hasaudience'] = value;
        setObj(t);

    };
    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        {
            url: detailUrl,
            label: LL("crumb_Detail" + mainObject),
        },
        { label: LL("crumb_Edit" + mainObject) },
    ];

    const [value, setValue] = React.useState(1);

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue);
    };

    return !loading && obj ? (
        <>

            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

            <Box sx={{ flexDirection: 'row', display: 'flex', alignContent: 'center', alignItems: 'center', my: 3 }} >

                <MyNavBt
                    tabIndex={18}
                    href={detailUrl}
                    children={<IconButton color="primary" sx={{ mr: 3 }}><ArrowBackIcon /></IconButton>}
                    size="large"
                    _hstate={{}}
                    _mainurl={mainUrl}
                />

                <Typography variant="h6">{LL("EditBlock")}</Typography>


            </Box>

            <Box maxWidth="md" className="tabs">
                <Tabs

                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                >
                    {obj ? <Tab label={LL("Edit_Teacher")} value={1} /> : <></>}
                    {obj ? <Tab label={LL("Teacher_Media")} value={2} /> : <></>}

                </Tabs>
            </Box>
            <TabPanel value={value} index={1}>
                <Box
                    maxWidth="md"
                    sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
                >

                    <form onSubmit={handleSubmit} >
                        <MyTextField
                            tabIndex={0}
                            fullWidth
                            id="timetoteachperweek"
                            name="timetoteachperweek"
                            variant="outlined"
                            color="primary"
                            _label={LL("timetoteachperweek")}
                            size="small"
                            value={obj.timetoteachperweek}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.timetoteachperweek ? vresults.timetoteachperweek : false
                            }
                        />

                        <Box sx={{ mt: 3 }}>
                            <MyTextField
                                tabIndex={1}
                                fullWidth
                                id="graduatedinstitution"
                                name="graduatedinstitution"
                                variant="outlined"
                                color="primary"
                                _label={LL("graduatedinstitution")}
                                size="small"
                                value={obj.graduatedinstitution}
                                setObj={setObjField}
                                _vresults={
                                    vresults && vresults.graduatedinstitution ? vresults.graduatedinstitution : false
                                }
                            />
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <TeacherStatusSelect
                                tabIndex={22}
                                id="status"
                                name="status"
                                variant="outlined"
                                color="primary"
                                value={obj.status}
                                field="status"
                                size="small"
                                setObj={setObjField}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>


                            <MyCKEditor
                                data={obj.bio ? obj.bio : ''}
                                setObj={setObjField}
                                name="bio"
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <MyTextField
                                tabIndex={4}
                                fullWidth
                                id="whyliveclass"
                                name="whyliveclass"
                                variant="outlined"
                                color="primary"
                                _label={LL("whyliveclass")}
                                size="small"
                                value={obj.whyliveclass}
                                setObj={setObjField}
                                _vresults={
                                    vresults && vresults.whyliveclass ? vresults.whyliveclass : false
                                }
                            />
                        </Box>
                       
                        <Box sx={{ mt: 3 }}>
                            <MyCheckboxField

                                tabIndex={6}
                                id="hasaudience"
                                name="hasaudience"
                                _label={LL("hasaudience")}
                                setObj={setCheckFixed}
                                checked={obj.hasaudience}
                                color="primary"
                                size="small"
                                _vresults={undefined}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <StudyTypeSelect
                                tabIndex={7}
                                id="idtypestudies"
                                name="idtypestudies"
                                variant="outlined"
                                color="primary"
                                value={obj.idtypestudies}
                                field="idtypestudies"
                                size="small"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypestudies}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <SpecialtyTypeSelect
                                tabIndex={8}
                                id="idtypespecialty"
                                name="idtypespecialty"
                                variant="outlined"
                                color="primary"
                                value={obj.idtypespecialty}
                                field="idtypespecialty"
                                size="small"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypespecialty}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <TargetGroupTypeSelect
                                tabIndex={9}
                                id="idtypetargetgroup"
                                name="idtypetargetgroup"
                                variant="outlined"
                                color="primary"
                                value={obj.idtypetargetgroup}
                                field="idtypetargetgroup"
                                size="small"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypetargetgroup}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <InternetConnectionTypeSelect
                                tabIndex={10}
                                id="idtypeinternetconnection"
                                name="idtypeinternetconnection"
                                variant="outlined"
                                color="primary"
                                value={obj.idtypeinternetconnection}
                                field="idtypeinternetconnection"
                                size="small"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypeinternetconnection}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <HardwareTypeSelect
                                tabIndex={11}
                                id="idtypehardware"
                                name="idtypehardware"
                                variant="outlined"
                                color="primary"
                                value={obj.idtypehardware}
                                field="idtypehardware"
                                size="small"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypehardware}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <LessonTypeSelect
                                tabIndex={12}
                                id="idtypelesson"
                                name="idtypelesson"
                                variant="outlined"
                                color="primary"
                                value={obj.idtypelesson}
                                field="idtypelesson"
                                size="small"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypelesson}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <OccupationTypeSelect
                                tabIndex={13}
                                id="idtypeoccupation"
                                name="idtypeoccupation"
                                variant="outlined"
                                color="primary"
                                value={obj.idtypeoccupation}
                                field="idtypeoccupation"
                                size="small"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypeoccupation}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <ProfessionalismLevelTypeSelect
                                tabIndex={14}
                                id="idtypeprofessionalismlevel"
                                name="idtypeprofessionalismlevel"
                                variant="outlined"
                                color="primary"
                                value={obj.idtypeprofessionalismlevel}
                                field="idtypeprofessionalismlevel"
                                size="small"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypeprofessionalismlevel}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <ExperienceTypeSelect
                                tabIndex={15}
                                id="idtypeexperience"
                                name="idtypeexperience"
                                variant="outlined"
                                color="primary"
                                value={obj.idtypeexperience}
                                field="idtypeexperience"
                                size="small"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypeexperience}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <DisciplineTypeSelect
                                tabIndex={16}
                                id="idtypediscipline"
                                name="idtypediscipline"
                                variant="outlined"
                                color="primary"
                                value={obj.idtypediscipline}
                                field="idtypediscipline"
                                size="small"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypediscipline}
                            />
                        </Box>
                        <MyButton
                            tabIndex={17}
                            disabled={isFormDisabled}
                            color="primary"
                            fullWidth
                            variant="contained"
                            size="large"
                            type="submit"
                            sx={{ mt: 4 }}
                            children={LL("Bt_Submit")}
                        />
                    </form>


                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <AddTeacherMedia currentRoute={currentRoute} objTeacher={obj} mainObject={mainObject} />
            </TabPanel>
        </>
    ) : (
        <Loading />
    );
};

export default EditTeacher;