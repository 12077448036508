import React, { useState, useEffect } from 'react';

import TestimonialService from "services/testimonial/testimonial.service";
import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import { TestimonialDto } from 'dto/testimonial/testimonial.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MyCheckboxField from "components/form/mycheckboxfield";
import IconButton from '@mui/material/IconButton';
import { StudyTypeSelect } from 'components/elements/Select/StudyTypeSelect';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { Status } from 'tools/status';


const testimonialService = new TestimonialService();

interface EditTestimonialProps {
    children: any;
    currentRoute: any;
    _obj: TestimonialDto,
    mainObject: any;
}

const EditTestimonial: React.FC<EditTestimonialProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {

  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<TestimonialDto | undefined>()
    const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.message = obj.message !== undefined ? obj.message : '';
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;        
        obj.rating = obj.rating !== undefined ? obj.rating : 5;


        setObj(obj);

        let t = validateField("message", obj.message);


    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {

            message: [],
       
        };

        v.message.push(new RequiredValidator(LL('Required field message', LabelTypes.LABEL_VALIDATION), ''));
    
        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {

            testimonialService.get(id, loadObject, {});
        }
    };

    useEffect(() => {
        processValidators();
        if (id === '') {
            if (obj !== undefined) {
                setId(obj.id)
            }
        }
    }, []);
    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'message');


        setIsFormDisabled(rez);
    };

    useEffect(() => {
        validateAll();
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);

    useEffect(() => {
        if (obj === undefined) getObject();
    }, [obj]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (id !== undefined) {
            testimonialService.update(id, ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
        }
    };

   
    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        {
            url: detailUrl,
            label: LL("crumb_Detail" + mainObject),
        },
        { label: LL("crumb_Edit" + mainObject) },
    ];
    
    return !loading && obj ? (
        <>

            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

            <Box sx={{ flexDirection: 'row', display: 'flex', alignContent: 'center', alignItems: 'center', my:3 }} >
                
                <MyNavBt
                    tabIndex={18}
                    href={detailUrl}
                    children={<IconButton color="primary" sx={{mr:3}}><ArrowBackIcon /></IconButton>}
                    size="large"
                    _hstate={{}}
                    _mainurl={mainUrl}
                />

                <Typography variant="h6">{LL("EditBlock")}</Typography>

            </Box>

            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit} >
                <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="message"
                        name="message"
                        variant="outlined"
                        color="primary"
                        _label={LL("message")}
                        size="small"
                        value={obj.message}
                        setObj={setObjField}
                        _vresults={
                            vresults && vresults.message ? vresults.message : false
                        }
                    />


                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="rating"
                            name="rating"
                            variant="outlined"
                            color="primary"
                            _label={LL("rating")}
                            size="small"
                            value={obj.rating}
                            setObj={setObjField}
                            _vresults={
                                undefined
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <StatusSelect
                            tabIndex={2}
                            id="status"
                            name="status"
                            variant="outlined"
                            color="primary"
                            value={obj.status}
                            field="status"
                            size="small"
                            setObj={setObjField}
                        />
                    </Box>
                    <MyButton
                        tabIndex={3}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>

            </Box>
        </>
    ) : (
        <Loading />
    );
};

export default EditTestimonial;