import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';

import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';

import LinkCourseCategoryService from "services/course/linkcoursecategory.service";

import MyTable from 'components/elements/TabelTools/MyTable';
import ResultListDTO from 'dto/app/resultlist.dto';
import TbFilterDTO from 'dto/app/tbfilter.dto';
import TbFilterListDTO from 'dto/app/tbfilterlist.dto';
import FilterTools from 'tools/filtertools';
import RequestListDTO from 'dto/app/requestlist.dto';
import RequestFilterDTO from 'dto/app/requestfilter.dto';
import { LinkCourseCategoryDto } from 'dto/course/linkcoursecategory.dto';



import { Status } from 'tools/status';
import { CourseCategoryAutocomplete } from 'components/elements/Autocomplete/CourseCategoryAutocomplete';

const linkcoursecategoryService = new LinkCourseCategoryService();

interface AddLinkCourseCategoryProps {

    currentRoute: any;

    mainObject: any;
}

const AddLinkCourseCategory: React.FC<AddLinkCourseCategoryProps> = ({
    currentRoute,
    mainObject,
    ...props
}) => {


  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<LinkCourseCategoryDto | undefined>(new LinkCourseCategoryDto())


    const [cookies, setCookies] = useCookies();

    const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
    const [rows, setRows] = useState<Array<LinkCourseCategoryDto>>(new Array<LinkCourseCategoryDto>());

    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const [responseParams, setResponseParams] = useState<RequestListDTO>(
        new RequestListDTO()
    );

    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
    const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
        new TbFilterListDTO()
    );
    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.idcoursecategory = obj.idcoursecategory !== undefined ? obj.idcoursecategory : '';

        setObj(obj);

        // let t = validateField("value", obj.value);

    };

    // const validateField = (field: any, value: any, v?: any) => {

    //     v = (v) ? v : JSON.parse(JSON.stringify(vresults));
    //     if (v === false) v = {};
    //     v[field] = ValidateTools.validate(field, value, validators, vresults);

    //     // setIsFormDisabled(ValidateTools.vForm(v, field));
    //     setVResults(v);
    //     return v;
    // };

    // const checkIsFormDisabled = () => {
    //     var rez = ValidateTools.vForm(vresults, 'value');


    //     setIsFormDisabled(rez);
    // };
    // useEffect(() => {
    //     checkIsFormDisabled();
    // }, [vresults]);
    useEffect(() => {
        validateAll();
    }, [obj, validators]);
    // const processValidators = () => {
    //     let v: any = {
    //         value: [],

    //     };

    //     v.value.push(new RequiredValidator(LL('Required field value', LabelTypes.LABEL_VALIDATION), ''));


    //     setValidators(v);
    // };


    const deleteObj = (obj: any) => {
        setDeleteOneObject(obj);
    };

    const columns = [];
    columns.push(ComponentCommonTools.columns_GetCheck());
    columns.push(ComponentCommonTools.columns_StandardObjectColumn(LL, "coursecategory", "name"));
    columns.push(
        ComponentCommonTools.columns_ActionsColumn(
            LL,
            mainObject,
            deleteObj,
            ["delete"],
        )
    );



    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (obj !== undefined && id !== undefined ) {
            obj.idcourse = id;
            linkcoursecategoryService.add(getList, {}, obj);
        }

        setObj(new LinkCourseCategoryDto());
    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };

    const loadObjects = (data: ResultListDTO) => {
        CommonTools.processListLoadObjects(
            data,
            setData,
            setRows,
            setTotal,
            setTotalPage,
            setResponseParams
        );
    };

    const getList = () => {
        setLoading(true);
        if (id !== undefined) linkcoursecategoryService.getList(loadObjects, {}, reqList);
    };

    const getReqList = () => {
        let reqList = RouteTools.prepareListRequest(currentRoute, cookies);
        reqList = linkcoursecategoryService.getDefaultRequestList(reqList);
        const filters = new Array<RequestFilterDTO>()
        const filter = new RequestFilterDTO();
        filter.field = "idcourse";
        if (id !== undefined) filter.values = [id];
        else filter.values = [];
        filters.push(filter);
        reqList.filters = filters;

        setReqList(reqList);
    };


    const checkIsLoading = () => {
        validateAll();
        const load: boolean = CommonTools.checkIsLoading(
            reqList,
            responseParams,
            data,
            total,
            totalPage,
            rows
        );
        setLoading(load);
    };

    const processTbFilterList = () => {
        const _tobj = new TbFilterListDTO();

        var t: TbFilterDTO;

        t = new TbFilterDTO("search", FilterTools.fiterTypeText);
        _tobj.addFilter(t);



        setTbFilterList(_tobj);
    };

    useEffect(() => {
        processTbFilterList();
        // processValidators();
    }, []);

    useEffect(() => {
        getReqList();
    }, [currentRoute]);

    useEffect(() => {
        if (CommonTools.atLeastOneFieldDefined(reqList)) {
            getList();
        }
    }, [reqList]);

    useEffect(() => {
        checkIsLoading();
    }, [data, rows, reqList, total, totalPage]);

    const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);


    const handleDelete = (item: any) => {

        linkcoursecategoryService.delete(item.id, getList, undefined);
    };

    return !loading && obj ? (
        <>
            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit} >
                    <CourseCategoryAutocomplete
                        tabIndex={0}
                        id="idcoursecategory"
                        variant="outlined"
                        color="primary"
                        field="idcoursecategory"
                        size="small"
                        setObj={setObj}
                        obj={obj}
                        setObjFieldGeneral={setObjField}
                    />
                    

                    <MyButton
                        tabIndex={1}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>

            </Box>
            <Box mt={3} >
                <MyTable
                    _columns={columns}
                    _data={rows}
                    _total={total}
                    _totalPage={totalPage}
                    _reqList={reqList}
                    _tbFilterList={tbFilterList}
                    setReqList={setReqList}
                    loading={loading}
                    currentRoute={currentRoute}
                    onDelete={handleDelete}
                    mainObject={mainObject}
                    fieldToShowOnDelete="id"
                    deleteOneObject={deleteOneObject}
                    setDeleteOneObject={setDeleteOneObject}
                    onRowClickCb={() => { }}
                    {...props}
                />
            </Box>
        </>
    ) : (
        <Loading />
    );
};

export default AddLinkCourseCategory;
