import React from "react";

import { useAuth } from "hook/useAuth";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const UserTopInfo = () => {

    const { user } = useAuth(false);

    const { signOut } = useAuth();

    const handleLogOut = () => {
        signOut();
    }
    

    return !user ? (
        <></>
    ) : (
        <Box className='userInfo'>
            {user.email}
            <IconButton
                aria-label="delete"
                onClick={handleLogOut}
                variant="outlined"
                sx={{ color: 'primary'}}
            >
                <ExitToAppIcon />
            </IconButton>
        </Box>
    );
}

export { UserTopInfo };