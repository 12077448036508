import React, { useState, useEffect } from 'react';

import CoursePriceService from "services/course/courseprice.service";
import { logger } from 'hoc/logger';

import { CoursePriceDto } from 'dto/course/courseprice.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

const coursePriceService = new CoursePriceService();


interface CoursePriceProps {
    currentRoute: any;

}

const CoursePriceInfo: React.FC<CoursePriceProps> = ({
    currentRoute,
    ...props
}) => {

    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<CoursePriceDto | undefined>();
    const [id] = useState<string>(currentRoute._paths[1]);


    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {
        if (id !== undefined) coursePriceService.getCoursePriceByCourseId(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [currentRoute]);

    useEffect(() => {
        checkIsLoading();

    }, [obj]);


    return !loading && obj ? (
        <>

            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4, marginTop: '1%' }}
            >

                <Grid spacing={2} >

                    <Grid >
                        <Typography color="text.disabled" variant='caption'>{LL("currency")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'currency', 'name')}
                    </Grid>

                    <Grid sx={{ mt: 3 }}>
                        <Typography color="text.disabled" variant='caption'>{LL("pricepolicy")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'pricepolicy', 'name')}
                    </Grid>
                    <Grid sx={{ mt: 3 }}>
                        <Typography color="text.disabled" variant='caption'>{LL("userecommendationprice")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, 'userecommendationprice_name')}
                    </Grid>
                    {obj.userecommendationprice ? (
                        <>
                            <Grid sx={{ mt: 3 }}>
                                <Typography color="text.disabled" variant='caption'>{LL("recommendationprice")}</Typography>
                            </Grid>
                            <Grid>
                                {ComponentCommonTools.processObjectFieldOfObject(obj, 'recommendationprice', 'name')}
                            </Grid>
                        </>
                    ) : (<></>)}
                    <Grid sx={{ mt: 3 }}>
                        <Typography color="text.disabled" variant='caption'>{LL("priceperlesson")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, 'priceperlesson')}
                    </Grid>
                    <Grid sx={{ mt: 3 }}>
                        <Typography color="text.disabled" variant='caption'>{LL("totalprice")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, 'totalprice')}
                    </Grid>


                    <Grid sx={{ mt: 3 }}>
                        <Typography color="text.disabled" variant='caption'>{LL("teachertotalprice")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, 'teachertotalprice')}
                    </Grid>
                    <Grid sx={{ mt: 3 }}>
                        <Typography color="text.disabled" variant='caption'>{LL("teachertotalpriceperlesson")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, 'teachertotalpriceperlesson')}
                    </Grid>

                </Grid>
            </Box>
        </>
    ) : (
        <></>
    );
};

export default CoursePriceInfo;
