
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class PostAddressDto implements Idto {

    status?: number;
    street?: string;
    zipcode?: string;
    streetnumber?: number;
    idlocality?: string;
    iduser?: string;
    locality?:any;
    constructor() {
        this.status = Status.ACTIVE;
    }
}

export class AddressDto implements Idto {
    
    id?: string;
    status?: number;
    street?: string;
    zipcode?: string;
    streetnumber?: number;
    idlocality?: string;
    locality?:any;
    iduser?: string;
    constructor() {
        this.status = Status.ACTIVE;
    }
}