import React, { useState, useEffect } from 'react';

import SiteConfigFileService from "services/siteconfig/siteconfigfile.service";
import { logger } from 'hoc/logger';

import { SiteConfigFileDto } from 'dto/app/siteconfigfile.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

const siteConfigFileService = new SiteConfigFileService();


interface DetailSiteConfigFileProps {
    children: any;
    currentRoute: any;
    _obj: SiteConfigFileDto,
    mainObject: any;
}

const DetailSiteConfigFile: React.FC<DetailSiteConfigFileProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const lu = _getListUrl(mu);
    setListUrl(lu);
    if (obj === undefined) getObject();
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();
    const detailObj = _obj !== undefined ? _obj : undefined;
    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<SiteConfigFileDto | undefined>(detailObj);
    const [id] = useState<string>(currentRoute._paths[1]);
    const editUrl = CommonTools.generateEditUrl(mainObject, id);

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        if (id !== undefined) siteConfigFileService.get(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    
    const processFileObj = () => {
        if(!obj) return <></>;
        if(!obj.hasOwnProperty("file")) return <></>;
        if(!obj.file) return <></>;
        return ComponentCommonTools.processFileLink(obj.file);
    }

    useEffect(() => {
        checkIsLoading();
    }, [obj]);

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Detail" + mainObject) },
    ];
    return !loading ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
          maxWidth="md"
        >
          <MyNavBt
            tabIndex={1}
            href={listUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />
          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {" "}
            {LL("DetailBlock")}{" "}
          </Typography>

          {obj ? (
            <MyNavBt
              tabIndex={0}
              href={editUrl}
              children={
                <IconButton className="editBtn">
                  <EditIcon />
                </IconButton>
              }
              sx={{ ml: "auto" }}
              _hstate={CommonTools.prepareObjectForUpdate(obj, mainObject)}
              _mainurl={mainUrl}
            />
          ) : (
            <></>
          )}
        </Box>

        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <Grid spacing={2}>
            <Grid>
              <Typography color="text.disabled" variant="caption">
                {LL("Identifier")}
              </Typography>
            </Grid>
            <Grid>
              {ComponentCommonTools.processObjectField(obj, "identifier")}
            </Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("File")}
              </Typography>
            </Grid>
            <Grid>{processFileObj()}</Grid>
          </Grid>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default DetailSiteConfigFile;
