import React, { useState, useEffect } from 'react';

import TypeAgreementService from "services/nomenclature/typeagreement.service";
import GenEditType from 'components/elements/MyGenComponents/GenEditType';
import { PostNomenclatureDto } from 'dto/nomenclature/postnomenclature.dto';


const typeAgreementService = new TypeAgreementService();


interface EditTypeAgreementProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const EditTypeAgreement: React.FC<EditTypeAgreementProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenEditType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeAgreementService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default EditTypeAgreement;
