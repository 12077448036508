import React, { useState, useEffect } from 'react';

import TypeBlogCategoryService from "services/nomenclature/typeblogcategory.service";
import GenEditType from 'components/elements/MyGenComponents/GenEditType';
import { PostNomenclatureDto } from 'dto/nomenclature/postnomenclature.dto';


const typeBlogCategoryService = new TypeBlogCategoryService();


interface EditTypeBlogCategoryProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const EditTypeBlogCategory: React.FC<EditTypeBlogCategoryProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenEditType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeBlogCategoryService}
            mainObject={mainObject}
            {...props}
        />
    )
};


export default EditTypeBlogCategory;
