import React, { useState, useEffect } from 'react';

import TypeSocialService from "services/nomenclature/typesocial.service";
import GenEditType from 'components/elements/MyGenComponents/GenEditType';
import { PostNomenclatureDto } from 'dto/nomenclature/postnomenclature.dto';


const typeSocialService = new TypeSocialService();


interface EditTypeSocialProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const EditTypeSocial: React.FC<EditTypeSocialProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenEditType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeSocialService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default EditTypeSocial;
