import React, { useState, useEffect } from "react";

import SupportArticleCategoryService from "services/supportarticle/supportarticlecategory.service";
import { logger } from "hoc/logger";

import { SupportArticleCategoryDto } from "dto/supportarticle/supportarticlecategory.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from "@mui/material/Grid";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { LanguageSelect } from "components/elements/Select/LanguageSelect";

const supportarticleCategoryService = new SupportArticleCategoryService();

interface DetailSupportArticleCategoryProps {
  children: any;
  currentRoute: any;
  _obj: SupportArticleCategoryDto;
  mainObject: any;
}

const DetailSupportArticleCategory: React.FC<
  DetailSupportArticleCategoryProps
> = ({ children, currentRoute, _obj, mainObject, ...props }) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();
  const detailObj = _obj !== undefined ? _obj : undefined;
  const [loading, setLoading] = useState<boolean>(true);
  const [obj, setObj] = useState<SupportArticleCategoryDto | undefined>(
    detailObj
  );
  const [id] = useState<string>(currentRoute._paths[1]);
  const editUrl = CommonTools.generateEditUrl(mainObject, id);
  const _idLanguage =
    currentRoute.historystate.idLanguage !== undefined
      ? currentRoute.historystate.idLanguage
      : "";
  const [idLanguage, setIdLanguage] = useState<string>(_idLanguage);
  const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined) {
      if (idLanguage === "")
        supportarticleCategoryService.get(id, loadObject, {});
      else supportarticleCategoryService.get(id, loadObject, {}, idLanguage);
    }
  };

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    if (obj === undefined) getObject();
    if (idLanguage !== "") getObject();
  }, [idLanguage]);

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const setObjFieldLanguage = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;

    if (value !== undefined) setIdLanguage(value);
  };

  const prepareObjectForUpdate = (obj: any, mainObj: any) => {
    if (!obj) return;
    if (!mainObj) return;

    const mainUrl = CommonTools.generateMainUrl(mainObj);
    const object: any = {
      id: id,
      obj: obj,
      idLanguage: idLanguage,
      _mainurl: mainUrl,
    };
    return object;
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    { label: LL("crumb_Detail" + mainObject) },
  ];
  return !loading ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
        maxWidth="md"
      >
        <MyNavBt
          tabIndex={1}
          href={listUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />
        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {" "}
          {LL("DetailBlock")}{" "}
        </Typography>

        {obj ? (
          <>
            <MyNavBt
              tabIndex={0}
              href={editUrl}
              children={
                <IconButton className="editBtn">
                  <EditIcon />
                </IconButton>
              }
              sx={{ ml: "auto" }}
              _hstate={prepareObjectForUpdate(obj, mainObject)}
              _mainurl={mainUrl}
            />
          </>
        ) : (
          <></>
        )}
      </Box>

      {obj ? (
        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <Box>
            <LanguageSelect
              tabIdex={0}
              id="idlanguage"
              name="idlanguage"
              variant="outlined"
              color="primary"
              value={obj.idlanguage ? obj.idlanguage : idLanguage}
              field="idlanguage"
              size="small"
              setObj={setObjFieldLanguage}
              obj={obj}
            />
          </Box>
          <Grid spacing={2}>
            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("Name")}
              </Typography>
            </Grid>
            <Grid>{ComponentCommonTools.processObjectField(obj, "name")}</Grid>
            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("Status")}
              </Typography>
            </Grid>
            <Grid>
              {ComponentCommonTools.processObjectField(obj, "status_name")}
            </Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("language")}
              </Typography>
            </Grid>
            <Grid>
              {ComponentCommonTools.processObjectFieldOfObject(
                obj,
                "language",
                "name"
              )}
            </Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("ordercriteria")}
              </Typography>
            </Grid>
            <Grid>
              {ComponentCommonTools.processObjectField(obj, "ordercriteria")}
            </Grid>
            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("shortdescription")}
              </Typography>
            </Grid>
            <Grid>
              {ComponentCommonTools.processObjectField(obj, "shortdescription")}
            </Grid>
            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("type")}
              </Typography>
            </Grid>
            <Grid>
              {ComponentCommonTools.processObjectField(obj, "type_name")}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <></>
      )}
    </>
  ) : (
    <Loading />
  );
};

export default DetailSupportArticleCategory;
