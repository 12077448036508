import React, { useState, useEffect } from 'react';

import TypeSocialService from "services/nomenclature/typesocial.service";
import GenAddType from 'components/elements/MyGenComponents/GenAddType';
import { PostNomenclatureDto } from 'dto/nomenclature/postnomenclature.dto';


const typeSocialService = new TypeSocialService();


interface AddTypeSocialProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const AddTypeSocial: React.FC<AddTypeSocialProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenAddType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeSocialService}
            mainObject={mainObject}
            DTO={PostNomenclatureDto}
            {...props}
        />
    )
};

export default AddTypeSocial;
