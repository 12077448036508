import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';

import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';

import AnswerService from "services/course/answer.service";

import MyTable from 'components/elements/TabelTools/MyTable';
import ResultListDTO from 'dto/app/resultlist.dto';
import TbFilterDTO from 'dto/app/tbfilter.dto';
import TbFilterListDTO from 'dto/app/tbfilterlist.dto';
import FilterTools from 'tools/filtertools';
import RequestListDTO from 'dto/app/requestlist.dto';
import RequestFilterDTO from 'dto/app/requestfilter.dto';
import { AnswerDto } from 'dto/course/answer.dto';
import { Status } from 'tools/status';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { AnswerTypeSelect } from 'components/elements/Select/AnswerTypeSelect';


const answerService = new AnswerService();

interface AddAnswerProps {

    currentRoute: any;

    mainObject: any;
}

const AddAnswer: React.FC<AddAnswerProps> = ({
    currentRoute,
    mainObject,
    ...props
}) => {


  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<AnswerDto>(new AnswerDto())


    const [cookies, setCookies] = useCookies();

    const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
    const [rows, setRows] = useState<Array<AnswerDto>>(new Array<AnswerDto>());

    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const [responseParams, setResponseParams] = useState<RequestListDTO>(
        new RequestListDTO()
    );

    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
    const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
        new TbFilterListDTO()
    );
    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.name = obj.name !== undefined ? obj.name : '';
        obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 1;
        obj.score = obj.score !== undefined ? obj.score : 0;
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.type = obj.type !== undefined ? obj.type : 0;
        setObj(obj);

        let t = validateField("name", obj.name);
        t = validateField("ordercriteria", obj.ordercriteria, t);
        t = validateField("score", obj.score, t);

    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'name');
 // rez = ValidateTools.vForm(vresults, 'ordercriteria');
    rez = ValidateTools.vForm(vresults, 'score');

        setIsFormDisabled(rez);
    };
    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);
    useEffect(() => {
        validateAll();
    }, [obj, validators]);
    const processValidators = () => {
        let v: any = {
            name: [],
            ordercriteria: [],
            score: [],
        };

        v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
        v.ordercriteria.push(new RequiredValidator(LL('Required field ordercriteria', LabelTypes.LABEL_VALIDATION), ''));
        v.score.push(new RequiredValidator(LL('Required field score', LabelTypes.LABEL_VALIDATION), ''));
        setValidators(v);
    };


    const deleteObj = (obj: any) => {
        setDeleteOneObject(obj);
    };

    const columns = [];
    columns.push(ComponentCommonTools.columns_GetCheck());
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "name"));
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL));
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "score"));
    columns.push(
        ComponentCommonTools.columns_ActionsColumn(
            LL,
            mainObject,
            deleteObj,
            ["delete"],
        )
    );



    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (obj !== undefined && id !== undefined) {
            obj.idquestion = id;
            answerService.add(getList, {}, obj);
        }

        setObj(new AnswerDto());
    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };

    const loadObjects = (data: ResultListDTO) => {
        CommonTools.processListLoadObjects(
            data,
            setData,
            setRows,
            setTotal,
            setTotalPage,
            setResponseParams
        );
    };

    const getList = () => {
        setLoading(true);
        if (id !== undefined) answerService.getList(loadObjects, {}, reqList);
    };

    const getReqList = () => {
        let reqList = RouteTools.prepareListRequest(currentRoute, cookies);
        reqList = answerService.getDefaultRequestList(reqList);
        const filters = new Array<RequestFilterDTO>()
        const filter = new RequestFilterDTO();
        filter.field = "idquestion";
        if (id !== undefined) filter.values = [id];
        else filter.values = [];
        filters.push(filter);
        reqList.filters = filters;

        setReqList(reqList);
    };


    const checkIsLoading = () => {
        const load: boolean = CommonTools.checkIsLoading(
            reqList,
            responseParams,
            data,
            total,
            totalPage,
            rows
        );
        setLoading(load);
    };

    const processTbFilterList = () => {
        const _tobj = new TbFilterListDTO();

        var t: TbFilterDTO;

        t = new TbFilterDTO("search", FilterTools.fiterTypeText);
        _tobj.addFilter(t);



        setTbFilterList(_tobj);
    };

    useEffect(() => {
        processTbFilterList();
        processValidators();
    }, []);

    useEffect(() => {
        getReqList();
    }, [currentRoute]);

    useEffect(() => {
        if (CommonTools.atLeastOneFieldDefined(reqList)) {
            getList();
        }
    }, [reqList]);

    useEffect(() => {
        checkIsLoading();
    }, [data, rows, reqList, total, totalPage]);

    const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);


    const handleDelete = (item: any) => {

        answerService.delete(item.id, getList, undefined);
    };

    return  (
        <>
            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit} >
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="name"
                        name="name"
                        variant="outlined"
                        color="primary"
                        _label={LL("name")}
                        size="small"
                        value={obj.name}
                        setObj={setObjField}
                        _vresults={
                            vresults && vresults.name ? vresults.name : false
                        }
                    />
                    <Box sx={{ mt: 3 }}>
                        <StatusSelect
                            tabIndex={1}
                            id="status"
                            name="status"
                            variant="outlined"
                            color="primary"
                            value={obj.status}
                            field="status"
                            size="small"
                            setObj={setObjField}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <AnswerTypeSelect
                            tabIndex={2}
                            id="type"
                            name="type"
                            variant="outlined"
                            color="primary"
                            value={obj.type}
                            field="type"
                            size="small"
                            setObj={setObjField}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={3}
                            fullWidth
                            id="ordercriteria"
                            name="ordercriteria"
                            variant="outlined"
                            color="primary"
                            _label={LL("ordercriteria")}
                            size="small"
                            value={obj.ordercriteria} 
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.ordercriteria ? vresults.ordercriteria : false
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={4}
                            fullWidth
                            id="score"
                            name="score"
                            variant="outlined"
                            color="primary"
                            _label={LL("score")}
                            size="small"
                            value={obj.score}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.score ? vresults.score : false
                            }
                        />
                    </Box>
                    <MyButton
                        tabIndex={5}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>

            </Box>
            <Box mt={3} >
                <MyTable
                    _columns={columns}
                    _data={rows}
                    _total={total}
                    _totalPage={totalPage}
                    _reqList={reqList}
                    _tbFilterList={tbFilterList}
                    setReqList={setReqList}
                    loading={loading}
                    currentRoute={currentRoute}
                    onDelete={handleDelete}
                    mainObject={mainObject}
                    fieldToShowOnDelete="name"
                    deleteOneObject={deleteOneObject}
                    setDeleteOneObject={setDeleteOneObject}
                    onRowClickCb={(obj: any) => { ComponentCommonTools.goToDetailsKeepingMainObject(obj, "Answer","Question","idquestion") }}
                    {...props}
                />
            </Box>
        </>
    ) 
};

export default AddAnswer;
