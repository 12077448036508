import React, { useState, useEffect } from 'react';

import CourseCategoryService from "services/course/coursecategory.service";
import { logger } from 'hoc/logger';

import { CourseCategoryDto, PostCourseCategoryDto } from 'dto/course/coursecategory.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Status } from 'tools/status';
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MyCheckboxField from "components/form/mycheckboxfield";
import IconButton from '@mui/material/IconButton';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { SectionSelect } from 'components/elements/Select/SectionSelect';
import { LanguageSelect } from 'components/elements/Select/LanguageSelect';
import MyFileField from 'components/form/myfilefield';
// import MyCheckboxField from "components/form/mycheckboxfield";

const courseCategoryService = new CourseCategoryService();

interface AddCourseCategoryProps {
  children: any;
  currentRoute: any;
  _obj: any,
  mainObject: any;
}

const AddCourseCategory: React.FC<AddCourseCategoryProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const idParent = currentRoute !== undefined ?
    currentRoute.historystate.hasOwnProperty('idParent') ?
      currentRoute.historystate.idParent : undefined
    : undefined;
  const [loading, setLoading] = useState<boolean>(false);
  var [obj, setObj] = useState<PostCourseCategoryDto>(new PostCourseCategoryDto());

  const [files, setFiles] = useState(null);

  const [isFormDisabled, setIsFormDisabled] = useState(true);


  const [validators, setValidators] = useState(false);
  const [vresults, setVResults] = useState<any>(false);


  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;


    obj.name = obj.name !== undefined ? obj.name : '';
    obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 1;
    obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
    obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : '';
    obj.url = obj.url !== undefined ? obj.url : "";
    obj.keymeta = obj.keymeta !== undefined ? obj.keymeta : "";
    obj.descriptionmeta =
      obj.descriptionmeta !== undefined ? obj.descriptionmeta : "";
    obj.istop = obj.istop !== undefined ? obj.istop : false;
    obj.popularidentifier = obj.popularidentifier !== undefined ? obj.popularidentifier : 0;
    setObj(obj);

    let t = validateField("name", obj.name);
    // t = validateField("ordercriteria", obj.ordercriteria, t);
    // t = validateField("url", obj.url, t);
    // t = validateField("keymeta", obj.keymeta, t);
    // t = validateField("descriptionmeta", obj.descriptionmeta, t);


  };

  const validateField = (field: any, value: any, v?: any) => {
    v = (v) ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);
    setVResults(v);
    return v;
  };

  const processValidators = () => {
    let v: any = {
      name: [],
      ordercriteria: [],

      // url: [],
      // keymeta: [],
      // descriptionmeta: [],
    };

    v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
    // v.ordercriteria.push(new RequiredValidator(LL('Required field ordercriteria', LabelTypes.LABEL_VALIDATION), ''));
    // v.url.push(
    //     new RequiredValidator(
    //         LL("Required field url", LabelTypes.LABEL_VALIDATION),
    //         ""
    //     )
    // );
    // v.keymeta.push(
    //     new RequiredValidator(
    //         LL("Required field keymeta", LabelTypes.LABEL_VALIDATION),
    //         ""
    //     )
    // );
    // v.descriptionmeta.push(
    //     new RequiredValidator(
    //         LL("Required field descriptionmeta", LabelTypes.LABEL_VALIDATION),
    //         ""
    //     )
    // );
    setValidators(v);
  };

  const checkLoading = () => {
    if (!loading) return;
    if (!obj) return;
    if (!validators) return;
    validateAll();
    setLoading(false);
  };

  useEffect(() => {
    validateAll();
    processValidators();
    logger("Idparent", idParent)
  }, []);

  useEffect(() => {
    checkLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);


  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);

    validateField(field, value);
  };



  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, 'name');
    // rez = ValidateTools.vForm(vresults, 'ordercriteria', rez);
    // rez = ValidateTools.vForm(vresults, "url", rez);
    // rez = ValidateTools.vForm(vresults, "keymeta", rez);
    // rez = ValidateTools.vForm(vresults, "descriptionmeta", rez);
    setIsFormDisabled(rez);
  };

  const setCheckFixed = (value: any) => {
    let t = JSON.parse(JSON.stringify(obj));

    t['istop'] = value;
    setObj(t);

  };

  const setObjFile = (field: any, event: any) => {
    setFiles(event.target.files[0]);
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (idParent !== undefined) obj.idparent = idParent;
    if (files !== null) obj.files = files;
    courseCategoryService.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
  };


  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    { label: LL("crumb_Add" + mainObject) },
  ];
  return !loading ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={8}
          href={listUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />

        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {LL("AddBlock")}
        </Typography>
      </Box>

      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <form onSubmit={handleSubmit}>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={vresults && vresults.name ? vresults.name : false}
          />

          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={2}
              fullWidth
              id="ordercriteria"
              name="ordercriteria"
              variant="outlined"
              color="primary"
              _label={LL("ordercriteria")}
              size="small"
              value={obj.ordercriteria}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <StatusSelect
              tabIndex={3}
              id="status"
              name="status"
              variant="outlined"
              color="primary"
              value={obj.status}
              field="status"
              size="small"
              setObj={setObjField}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <LanguageSelect
              tabIndex={5}
              id="idlanguage"
              name="idlanguage"
              variant="outlined"
              color="primary"
              value={obj.idlanguage}
              field="idlanguage"
              size="small"
              setObj={setObjField}
              obj={obj}
              forAddForms={true}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={4}
              fullWidth
              id="url"
              name="url"
              variant="outlined"
              color="primary"
              _label={LL("url")}
              size="small"
              value={obj.url}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={6}
              fullWidth
              id="keymeta"
              name="keymeta"
              variant="outlined"
              color="primary"
              _label={LL("keymeta")}
              size="small"
              value={obj.keymeta}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={7}
              fullWidth
              id="descriptionmeta"
              name="descriptionmeta"
              variant="outlined"
              color="primary"
              _label={LL("descriptionmeta")}
              size="small"
              value={obj.descriptionmeta}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyCheckboxField
              tabIndex={8}
              id="istop"
              name="istop"
              _label={LL("istop")}
              setObj={setCheckFixed}
              checked={obj.istop}
              color="primary"
              size="small"
              _vresults={undefined}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={9}
              fullWidth
              id="popularidentifier"
              name="popularidentifier"
              variant="outlined"
              color="primary"
              _label={LL("PopularIdentifier")}
              size="small"
              value={obj.popularidentifier}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyFileField
              tabIndex={10}
              fullWidth
              id="files"
              name="files"
              variant="outlined"
              color="primary"
              _label={LL("Icon")}
              size="small"
              setObj={setObjFile}
              _vresults={undefined}
            />
          </Box>
          <MyButton
            tabIndex={11}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Submit")}
          />
        </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default AddCourseCategory;
