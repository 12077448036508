import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class TicketDto implements Idto {
    
    id?: string;
    status?: number;
    type?: number;
    idoperator?: string;
    idstudent?: string;
    idteacher?: string;
    idsticketcategory?: string[];
    constructor() {
      this.status = Status.ACTIVE;
  }
  }

  export class PostTicketDto implements Idto {

    status?: number;
    type?: number;
    idoperator?: string;
    idstudent?: string;
    idteacher?: string;
    idsticketcategory?: string[];
    constructor() {
      this.status = Status.ACTIVE;
  }


  }

  export class ResponseTicketAdminDto implements Idto {

    idoperator?: string;
    type?: number;
    status?: number;
    message?: string;

}