import { startOfWeekWithOptions } from "date-fns/fp";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/status";


export class PostNomenclatureDto implements Idto {
    id?: string;
    idlanguage?: string;
    name?: string;
    status?: number;
    ordercriteria?: number;
    idtype?: string;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
    }
}
