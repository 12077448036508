import React, { useState, useEffect } from 'react';

import UserService from "services/user/user.service";
import { logger } from 'hoc/logger';

import UserDto from 'dto/user/user.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import UserSettingsInfo from 'components/user/components/UserSettingsInfo';
import UserRoleInfo from './components/UserRoleInfo';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';
import PhoneInfo from 'components/user/components/PhoneInfo';
import SocialInfo from 'components/user/components/SocialInfo';
import AddressInfo from 'components/address/components/AddressInfo';
import { RouteTools } from 'tools/routetools';

const userService = new UserService();


interface DetailUserProps {
    children: any;
    currentRoute: any;
    _obj: UserDto,
    mainObject: any;
}

const DetailUser: React.FC<DetailUserProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();
    const detailObj = _obj !== undefined ? _obj : undefined;
    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<UserDto | undefined>(detailObj);
    const [id] = useState<string>(currentRoute._paths[1]);
    const editUrl = CommonTools.generateEditUrl(mainObject, id);


    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {
            userService.get(id, loadObject, {});

        }
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        if (obj === undefined) getObject();

    }, []);

    useEffect(() => {
        checkIsLoading();
    }, [obj]);

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Detail" + mainObject) },
    ];
    const [value, setValue] = React.useState(CommonTools.getAnchor(currentRoute, 'generalInfo'));

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue);
        RouteTools.setAnchor(newValue)
    };



    return !loading ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
          maxWidth="md"
        >
          <MyNavBt
            tabIndex={1}
            href={listUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />
          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {" "}
            {LL("DetailBlock")}{" "}
          </Typography>

          {obj ? (
            <MyNavBt
              tabIndex={0}
              href={editUrl}
              children={
                <IconButton className="editBtn">
                  <EditIcon />
                </IconButton>
              }
              sx={{ ml: "auto" }}
              _hstate={CommonTools.prepareObjectForUpdate(obj, mainObject,value)}
              _mainurl={mainUrl}
            />
          ) : (
            <></>
          )}
        </Box>

        {/* <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4, }}
            >

                <Grid spacing={2} >
                    <Grid>
                        <Typography color="text.disabled" variant='caption'>{LL("Email")}</Typography>
                    </Grid>
                    <Grid>
                        {obj ? obj.email : ''}
                    </Grid>

                    <Grid mt={3}>
                        <Typography color="text.disabled" variant='caption'>{LL("Status")}</Typography>
                    </Grid>
                    <Grid>
                        {obj ? obj.status_name : ''}
                    </Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant='caption'>{LL("RegisterDate")}</Typography>
                    </Grid>
                    <Grid>

                        {obj ? obj.registerdate_name : ''}
                    </Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant='caption'>{LL("StatusEmail")}</Typography>
                    </Grid>
                    <Grid>
                        {obj ? obj.statusemail_name : ''}
                    </Grid>
                    <Grid mt={3}>
                        <Typography color="text.disabled" variant='caption'>{LL("StatusPassword")}</Typography>
                    </Grid>
                    <Grid>

                        {obj ? obj.statuspassword_name : ''}
                    </Grid>

                </Grid>
            </Box> */}
        {/* <UserSettingsInfo currentRoute={currentRoute}/>
            <UserRoleInfo currentRoute={currentRoute}/> */}
        <Box>
          <Box maxWidth="md" className="tabs">
            <Tabs variant="scrollable" value={value} onChange={handleChange}>
              {obj ? <Tab label={LL("User_main_Info")} value={'generalInfo'} /> : <></>}
              {obj && obj.idusersettings ? (
                <Tab label={LL("User_Settings_Info")} value={'userSettings'} />
              ) : (
                <></>
              )}
              {obj && obj.userroles ? (
                <Tab label={LL("User_Role")} value={'userRole'} />
              ) : (
                <></>
              )}
              {obj && obj.phones ? (
                <Tab label={LL("User_Phone")} value={'userPhone'} />
              ) : (
                <></>
              )}
              {obj ? <Tab label={LL("User_Social")} value={'userSocial'} /> : <></>}
              {obj ? <Tab label={LL("User_Address")} value={'userAddress'} /> : <></>}
            </Tabs>
          </Box>
          <TabPanel value={value} index={'generalInfo'}>
            <Box
              maxWidth="md"
              sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >
              <Grid spacing={2}>
                <Grid>
                  <Typography color="text.disabled" variant="caption">
                    {LL("Email")}
                  </Typography>
                </Grid>
                <Grid>
                  {ComponentCommonTools.processObjectField(obj, "email")}
                </Grid>

                <Grid mt={3}>
                  <Typography color="text.disabled" variant="caption">
                    {LL("Status")}
                  </Typography>
                </Grid>
                <Grid>
                  {ComponentCommonTools.processObjectField(obj, "status_name")}
                </Grid>
                <Grid mt={3}>
                  <Typography color="text.disabled" variant="caption">
                    {LL("RegisterDate")}
                  </Typography>
                </Grid>
                <Grid>
                  {ComponentCommonTools.processObjectField(
                    obj,
                    "registerdate_name"
                  )}
                </Grid>
                <Grid mt={3}>
                  <Typography color="text.disabled" variant="caption">
                    {LL("StatusEmail")}
                  </Typography>
                </Grid>
                <Grid>
                  {ComponentCommonTools.processObjectField(
                    obj,
                    "statusemail_name"
                  )}
                </Grid>
                <Grid mt={3}>
                  <Typography color="text.disabled" variant="caption">
                    {LL("StatusPassword")}
                  </Typography>
                </Grid>
                <Grid>
                  {ComponentCommonTools.processObjectField(
                    obj,
                    "statuspassword_name"
                  )}
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={'userSettings'}>
            {obj && obj.idusersettings ? (
              <UserSettingsInfo currentRoute={currentRoute} />
            ) : (
              <></>
            )}
          </TabPanel>
          <TabPanel value={value} index={'userRole'}>
            {obj && obj.userroles ? (
              <UserRoleInfo currentRoute={currentRoute} />
            ) : (
              <></>
            )}
          </TabPanel>
          <TabPanel value={value} index={'userPhone'}>
            {obj && obj.phones ? (
              <PhoneInfo currentRoute={currentRoute} />
            ) : (
              <></>
            )}
          </TabPanel>
          <TabPanel value={value} index={'userSocial'}>
            {obj ? <SocialInfo currentRoute={currentRoute} /> : <></>}
          </TabPanel>
          <TabPanel value={value} index={'userAddress'}>
            {obj ? <AddressInfo currentRoute={currentRoute} /> : <></>}
          </TabPanel>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default DetailUser;
