import React, { useState, useEffect } from 'react';

import ScheduleService from "services/course/schedule.service";
import { logger } from 'hoc/logger';

import { ScheduleDto, PostScheduleDto } from 'dto/course/schedule.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import MyDatePicker from 'components/elements/MyDatePicker/MyDatePicker';
import  MyCheckboxField  from 'components/form/mycheckboxfield';
const scheduleService = new ScheduleService();

interface AddScheduleProps {
    children: any;
    currentRoute: any;
    _obj: ScheduleDto,
    mainObject: any;
}

const AddSchedule: React.FC<AddScheduleProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();


    const [loading, setLoading] = useState<boolean>(false);
    var [obj, setObj] = useState<PostScheduleDto>(new PostScheduleDto());


    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        // if (!validators) return;
        // if (vresults) return;

        obj.startdate = obj.startdate !== undefined ? obj.startdate : undefined;
        obj.enddate = obj.enddate !== undefined ? obj.enddate : undefined;
        obj.islifetime = obj.islifetime !== undefined ? obj.islifetime : false;
        setObj(obj);

    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            // name: [],
            // ordercriteria: [],
           
        };

        // v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
        // v.ordercriteria.push(new RequiredValidator(LL('Required field ordercriteria', LabelTypes.LABEL_VALIDATION), ''));
        // setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;
        // if (!validators) return;
        validateAll();
        setLoading(false);
    };

    useEffect(() => {
        validateAll();
        // processValidators();
    }, []);

    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    // useEffect(() => {
    //     checkIsFormDisabled();
    // }, [vresults]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

   

    // const checkIsFormDisabled = () => {
    //     var rez = ValidateTools.vForm(vresults, 'name');
    //     rez = ValidateTools.vForm(vresults, 'ordercriteria', rez);
        

    //     setIsFormDisabled(rez);
    // };


    const setCheckFixed = (value: any) => {
        let t = JSON.parse(JSON.stringify(obj));

        t['islifetime'] = value;
        setObj(t);

    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        // if (isFormDisabled) return false;

        scheduleService.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
    };


    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Add" + mainObject) },
    ];
    return !loading ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <MyNavBt
            tabIndex={8}
            href={listUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />

          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {LL("AddBlock")}
          </Typography>
        </Box>

        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <form onSubmit={handleSubmit}>
            <MyDatePicker
              tabIndex={0}
              field="startdate"
              _defaultValue={obj.startdate}
              LL={LL}
              setObjField={setObjField}
            />

            <Box sx={{ mt: 3 }}>
              <MyDatePicker
                tabIndex={1}
                field="enddate"
                _defaultValue={obj.enddate}
                LL={LL}
                setObjField={setObjField}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyCheckboxField
                tabIndex={1}
                id="islifetime"
                name="islifetime"
                _label={LL("islifetime")}
                setObj={setCheckFixed}
                checked={obj.islifetime}
                color="primary"
                size="small"
                _vresults={undefined}
              />
            </Box>
            <MyButton
              tabIndex={6}
              disabled={isFormDisabled}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
          </form>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default AddSchedule;
