import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class TeacherDto implements Idto {


  id?: string;
  iduser?: string;
  idtypespecialty?: string;
  idtypetargetgroup?: string;
  idtypeinternetconnection?: string;
  idtypehardware?: string;
  idtypelesson?: string;
  idtypediscipline?: string;
  idtypeoccupation?: string;
  idtypestudies?: string;
  idtypeprofessionalismlevel?: string;
  idtypeexperience?: string;
  bio?: string;
  timetoteachperweek?: string;
  hasaudience?: boolean;
  graduatedinstitution?: string;
  whyliveclass?: string;
  idssociallink?: string[];
  status?: number;
  idsterm?: string[];
  idvideolessonexample?: string;
  idvideopresentation?: string;
  idattachmentcv?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  videolessonexample?: any;
  videopresentation?: any;
  attachmentcv?: any;
  constructor() {
    this.status = Status.TEACHER_UNVERIFIED;
  }
}

export class PostTeacherDto implements Idto {

  iduser?: string;
  idtypespecialty?: string;
  idtypetargetgroup?: string;
  idtypeinternetconnection?: string;
  idtypehardware?: string;
  idtypelesson?: string;
  idtypediscipline?: string;
  idtypeoccupation?: string;
  idtypestudies?: string;
  idtypeprofessionalismlevel?: string;
  idtypeexperience?: string;
  bio?: string;
  timetoteachperweek?: string;
  hasaudience?: boolean;
  graduatedinstitution?: string;
  whyliveclass?: string;
  idssociallink?: string[];
  status?: number;
  idsterm?: string[];
  idvideolessonexample?: string;
  idvideopresentation?: string;
  idattachmentcv?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  constructor() {
    this.status = Status.TEACHER_UNVERIFIED;
  }



}

export class TeacherMediaDto implements Idto {
  videolocationlessonexample?: string;
  videolocationpresentation?: string;
  idattachmentcv?: string;
  idteacher?: string;
  files?: any;
  fileInfoObj?: any;
  deleteattachmentcv?: boolean;
  deletevideopresentation?: boolean;
  deletevideolessonexample?: boolean;
}