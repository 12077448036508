import React, { useState, useEffect } from 'react';

import TypeProfessionalismLevelService from "services/nomenclature/typeprofessionalismlevel.service";
import GenAddType from 'components/elements/MyGenComponents/GenAddType';
import { PostNomenclatureDto } from 'dto/nomenclature/postnomenclature.dto';


const typeProfessionalismLevelService = new TypeProfessionalismLevelService();


interface AddTypeProfessionalismLevelProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const AddTypeProfessionalismLevel: React.FC<AddTypeProfessionalismLevelProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenAddType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeProfessionalismLevelService}
            mainObject={mainObject}
            DTO={PostNomenclatureDto}
            {...props}
        />
    )
};

export default AddTypeProfessionalismLevel;
