import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class CourseFilterDto implements Idto {

  id?: string;
  value?: number | string | null;
  idfilter?: string;
  idfilterdictionary?: string;
  idcourse?: string;
  fillter?: any;
  filterdictionary?: any;
}

export class PostCourseFilterDto implements Idto {

  value?:  number | string | null;
  idfilter?: string;
  idfilterdictionary?: string;
  idcourse?: string;
  fillter?: any;
  filterdictionary?: any;
}