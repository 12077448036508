import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import UserSettingsDto from 'dto/user/usersettings.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TeacherService from "services/teacher/teacher.service";
import IconButton from '@mui/material/IconButton';
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { TypeGenderSelect } from "components/elements/Select/GenderTypeSelect";
import { Status } from 'tools/status';
import MyDatePicker from 'components/elements/MyDatePicker/MyDatePicker';
import AddAddressForm from 'components/address/components/AddAddressForm';
import { TeacherDto, TeacherMediaDto } from 'dto/teacher/teacher.dto';
import MyFileField from 'components/form/myfilefield';
import MyCheckboxField from 'components/form/mycheckboxfield';

const teacherService = new TeacherService();

interface AddTeacherMediaProps {

    currentRoute: any;

    mainObject: any;

    objTeacher: TeacherDto;
}

const AddTeacherMedia: React.FC<AddTeacherMediaProps> = ({
    currentRoute,
    mainObject,
    objTeacher,
    ...props
}) => {

    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");
    const [addUrl, setAddUrl] = useState("");

    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const au = CommonTools.generateAddUrl(mainObject);
        setAddUrl(au);
        const lu = _getListUrl(mu);
        setListUrl(lu);
    }, [mainObject, currentRoute]);

    const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<TeacherMediaDto>(new TeacherMediaDto())
    const [files, setFiles] = useState(null);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);



    const validateAll = () => {
        if (!obj) return;
        // if (!validators) return;
        // if (vresults) return;

        obj.idteacher = id ?? id;
        obj.videolocationlessonexample = obj.videolocationlessonexample !== undefined ? obj.videolocationlessonexample : '';
        obj.videolocationpresentation = obj.videolocationpresentation !== undefined ? obj.videolocationpresentation : '';
        obj.deleteattachmentcv = obj.deleteattachmentcv !== undefined ? obj.deleteattachmentcv : false;
        obj.deletevideopresentation = obj.deletevideopresentation !== undefined ? obj.deletevideopresentation : false;
        obj.deletevideolessonexample = obj.deletevideolessonexample !== undefined ? obj.deletevideolessonexample : false;
        setObj(obj);

        // let t = validateField("name", obj.videolocationlessonexample);
        // t = validateField("surname", obj.videolocationpresentation, t);
        // logger("validateAll", t);
    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));

        return v;
    };

    // const processValidators = () => {
    //     let v: any = {
    //         name: [],
    //         surname: [],
    //     };

    //     v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
    //     v.surname.push(new RequiredValidator(LL('Required field surname', LabelTypes.LABEL_VALIDATION), ''));

    //     setValidators(v);
    // };

    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };



    // useEffect(() => {
    //     processValidators();
    // }, []);

    // const checkIsFormDisabled = () => {
    //     var rez = ValidateTools.vForm(vresults, 'name');
    //     rez = ValidateTools.vForm(vresults, 'surname', rez);
    //     setIsFormDisabled(rez);
    // };

    useEffect(() => {
        validateAll();
        // checkLoading();
    }, [obj]);

    // useEffect(() => {
    //     checkIsFormDisabled();
    // }, [vresults]);

    const setCheckDeleteCv = (value: any) => {
        // let t = JSON.parse(JSON.stringify(obj));

        // t['deleteattachmentcv'] = value;
        // setObj(t);
        CommonTools.handleCheckValue(obj,setObj,'deleteattachmentcv',value);
    };

    const setCheckDeleteVideoPresentation = (value: any) => {
        CommonTools.handleCheckValue(obj,setObj,'deletevideopresentation',value);
    };

    const setCheckDeleteVideoLessonExample = (value: any) => {
        CommonTools.handleCheckValue(obj,setObj,'deletevideolessonexample',value);
    };

    // const processCVDelete = () => {

    //     if (!objTeacher) return <></>;
    //     if (!objTeacher.hasOwnProperty("attachmentcv")) return <></>;
    //     if (!objTeacher.attachmentcv) return <></>;
    //     const label = objTeacher.attachmentcv.hasOwnProperty('name') && objTeacher.attachmentcv.name
    //         ? objTeacher.attachmentcv.name : LL("file");
    //     const checkedValue = obj.deleteattachmentcv !== undefined ? obj.deleteattachmentcv : false;
    //     return (<Box sx={{ mt: 3 }}>
    //         <MyCheckboxField
    //             id="deleteattachmentcv"
    //             name="deleteattachmentcv"
    //             _label={label}
    //             setObj={setCheckDeleteCv}
    //             checked={checkedValue}
    //             color="primary"
    //             size="small"
    //             _vresults={undefined}
    //         />
    //     </Box >)

    // }

    const processCVDelete = () => {
        if (!objTeacher) return <></>;
        return (<Box sx={{ mt: 3 }}>
            {ComponentCommonTools.processCheckboxDelete(objTeacher,obj,
                'attachmentcv',
                'deleteattachmentcv',
                'name',
                setCheckDeleteCv,
                LL)}
        </Box>)
    }

    // const processVideoPresentationDelete = () => {
    //     if (!objTeacher) return <></>;
    //     if (!objTeacher.hasOwnProperty("videopresentation")) return <></>;
    //     if (!objTeacher.videopresentation) return <></>;
    //     const label = objTeacher.videopresentation.hasOwnProperty('videolocation') && objTeacher.videopresentation.videolocation
    //         ? objTeacher.videopresentation.videolocation : LL("videopresentation");
    //     const checkedValue = obj.deletevideopresentation !== undefined ? obj.deletevideopresentation : false;
    //     return (<Box sx={{ mt: 3 }}>
    //         <MyCheckboxField

    //             id="deletevideopresentation"
    //             name="deletevideopresentation"
    //             _label={label}
    //             setObj={setCheckDeleteVideoPresentation}
    //             checked={checkedValue}
    //             color="primary"
    //             size="small"
    //             _vresults={undefined}
    //         />
    //     </Box >)
    // }

    const processVideoPresentationDelete = () => {
        if (!objTeacher) return <></>;
        return (<Box sx={{ mt: 3 }}>
            {ComponentCommonTools.processCheckboxDelete(objTeacher,obj,
                'videopresentation',
                'deletevideopresentation',
                'videolocation',
                setCheckDeleteVideoPresentation,
                LL)}
        </Box>)
    }

    // const processVideoLessonExampleDelete = () => {
    //     if (!objTeacher) return <></>;
    //     if (!objTeacher.hasOwnProperty("videolessonexample")) return <></>;
    //     if (!objTeacher.videolessonexample) return <></>;
    //     const label = objTeacher.videolessonexample.hasOwnProperty('videolocation') && objTeacher.videolessonexample.videolocation
    //         ? objTeacher.videolessonexample.videolocation : LL("videolessonexample");
    //     const checkedValue = obj.deletevideolessonexample !== undefined ? obj.deletevideolessonexample : false;
    //     return (<Box sx={{ mt: 3 }}>
    //         <MyCheckboxField
    //             id="deletevideolessonexample"
    //             name="deletevideolessonexample"
    //             _label={label}
    //             setObj={setCheckDeleteVideoLessonExample}
    //             checked={checkedValue}
    //             color="primary"
    //             size="small"
    //             _vresults={undefined}
    //         />
    //     </Box >)
    // }

    const processVideoLessonExampleDelete = () => {
        if (!objTeacher) return <></>;
        return (<Box sx={{ mt: 3 }}>
            {ComponentCommonTools.processCheckboxDelete(objTeacher,obj,
                'videolessonexample',
                'deletevideolessonexample',
                'videolocation',
                setCheckDeleteVideoLessonExample,
                LL)}
        </Box>)
    }


    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (obj !== undefined) {
            obj.idteacher = id;
            if (obj) obj.files = files;
            if (objTeacher &&
                (
                    objTeacher.hasOwnProperty("attachmentcv") ||
                    objTeacher.hasOwnProperty("videopresentation") ||
                    objTeacher.hasOwnProperty("videolessonexample")
                )
                && (
                    objTeacher.attachmentcv ||
                    objTeacher.videopresentation ||
                    objTeacher.videolessonexample
                )
            ) {
                teacherService.updateMedia(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject, idRoute: id }, obj);
            }
            else {
                teacherService.addMedia(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject, idRoute: id }, obj);
            }
        };
    }
    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
        // validateField(field, value);
    };
    const setObjFile = (field: any, event: any) => {
        setFiles(event.target.files[0]);
    };


    return !loading ? (
        <>
            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit}>
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="videolocationlessonexample"
                        name="videolocationlessonexample"
                        variant="outlined"
                        color="primary"
                        _label={LL("videolocationlessonexample")}
                        size="small"
                        value={obj.videolocationlessonexample}
                        setObj={setObjField}
                        _vresults={
                            undefined
                        }
                    />
                    {processVideoLessonExampleDelete()}
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="videolocationpresentation"
                            name="videolocationpresentation"
                            variant="outlined"
                            color="primary"
                            _label={LL("videolocationpresentation")}
                            size="small"
                            value={obj.videolocationpresentation}
                            setObj={setObjField}
                            _vresults={
                                undefined
                            }
                        />
                    </Box>
                    {processVideoPresentationDelete()}
                    <Box sx={{ mt: 3 }}>
                        <MyFileField
                            tabIndex={2}
                            fullWidth
                            id="files"
                            name="files"
                            variant="outlined"
                            color="primary"
                            _label={LL("files")}
                            size="small"
                            setObj={setObjFile}
                            _vresults={undefined}
                        />
                    </Box>
                    {processCVDelete()}
                    <MyButton
                        tabIndex={5}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>

            </Box>

        </>
    ) : (
        <Loading />
    );
};

export default AddTeacherMedia;