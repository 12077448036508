import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';

import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';

import LessonService from "services/course/lesson.service";
import { StudentAutocomplete } from 'components/elements/Autocomplete/StudentAutocomplete';
import MyTable from 'components/elements/TabelTools/MyTable';
import ResultListDTO from 'dto/app/resultlist.dto';
import TbFilterDTO from 'dto/app/tbfilter.dto';
import TbFilterListDTO from 'dto/app/tbfilterlist.dto';
import FilterTools from 'tools/filtertools';
import RequestListDTO from 'dto/app/requestlist.dto';
import RequestFilterDTO from 'dto/app/requestfilter.dto';
import { LessonDto } from 'dto/course/lesson.dto';
import { FilterAutocomplete } from 'components/elements/Autocomplete/FilterAutocomplete';
import { Status } from 'tools/status';
import { FilterDictionaryAutocomplete } from 'components/elements/Autocomplete/FilterDictionaryAutocomplete';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { CheckBox } from '@mui/icons-material';
import { LessonTypeSelect } from 'components/elements/Select/LessonTypeSelect';
import MyCheckboxField from 'components/form/mycheckboxfield';
import MyDateTimePicker from 'components/elements/MyDatePicker/MyDateTimePicker';
import DateTools from 'tools/date.tools';
import {Stack} from "@mui/material";
const lessonService = new LessonService();

interface AddLessonProps {

    currentRoute: any;

    mainObject: any;
}

const AddLesson: React.FC<AddLessonProps> = ({
    currentRoute,
    mainObject,
    ...props
}) => {


    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");
    const [addUrl, setAddUrl] = useState("");

    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const au = CommonTools.generateAddUrl(mainObject);
        setAddUrl(au);
        const lu = _getListUrl(mu);
        setListUrl(lu);
    }, [mainObject, currentRoute]);

    const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<LessonDto | undefined>(new LessonDto())


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.name = obj.name !== undefined ? obj.name : '';
        obj.description = obj.description !== undefined ? obj.description : '';
        // obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 1;
        obj.idtypelesson = obj.idtypelesson !== undefined ? obj.idtypelesson : '';
        obj.lessonduration = obj.lessonduration !== undefined ? obj.lessonduration : 0;
        obj.islive = obj.islive !== undefined ? obj.islive : false;
        obj.startdate = obj.startdate !== undefined ? obj.startdate : undefined;
        obj.enddate = obj.enddate !== undefined ? obj.enddate : undefined;
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;

        setObj(obj);

        // let t = validateField("name", obj.name);
        // t = validateField("description", obj.description, t);
        // t = validateField("lessonduration", obj.lessonduration, t);
        // t = validateField("ordercriteria", obj.ordercriteria, t);
    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };

    const checkIsFormDisabled = () => {
        let rez = false;
        if (obj && obj.startdate !== undefined && obj.enddate !== undefined) {
            if (obj.startdate > obj.enddate) {
                rez = true;
            }
        }

        setIsFormDisabled(rez);
    };
    const setObjDateField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = DateTools.getDateTimeStamp(value);
        setObj(t);
        checkIsFormDisabled();
    };
    // useEffect(() => {
    //     checkIsFormDisabled();
    // }, [vresults]);

    useEffect(() => {
        validateAll();
    }, [obj]);

    useEffect(() => {
        // processValidators();
        validateAll();
    }, []);

    const processValidators = () => {
        let v: any = {
            name: [],
            description: [],
            lessonduration: [],
            // ordercriteria: [],
        };

        v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
        v.description.push(new RequiredValidator(LL('Required field description', LabelTypes.LABEL_VALIDATION), ''));
        // v.lessonduration.push(new RequiredValidator(LL('Required field lessonduration', LabelTypes.LABEL_VALIDATION), ''));
        // v.ordercriteria.push(new RequiredValidator(LL('Required field ordercriteria', LabelTypes.LABEL_VALIDATION), ''));
        setValidators(v);
    };




    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (obj !== undefined && id !== undefined) {
            obj.idcourse = id;
            lessonService.add(getList, {}, obj);
        }
        setObj(new LessonDto());
    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };

    const setCheckFixed = (value: any) => {
        let t = JSON.parse(JSON.stringify(obj));

        t['islive'] = value;
        setObj(t);

    };


//-------------------------------------------------
const [cookies, setCookies] = useCookies();

const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
const [rows, setRows] = useState<Array<LessonDto>>(new Array<LessonDto>());

const [total, setTotal] = useState(0);
const [totalPage, setTotalPage] = useState(0);

const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
);

const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
    new TbFilterListDTO()
);
const deleteObj = (obj: any) => {
    setDeleteOneObject(obj);
};
const columns = [];
    columns.push(ComponentCommonTools.columns_GetCheck());
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "name"));
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "description"));
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"));
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "lessonduration"));
    columns.push(ComponentCommonTools.columns_StandardObjectColumn(LL, "typelesson", 'name'));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL, 'istest'));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL, 'islive'));
    columns.push(
        ComponentCommonTools.columns_ActionsColumn(
            LL,
            mainObject,
            deleteObj,
            ["delete","gallery", "attachment", "video"],
        )
    );

const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
        data,
        setData,
        setRows,
        setTotal,
        setTotalPage,
        setResponseParams
    );
};

const getList = () => {
    setLoading(true);
    if (id !== undefined) lessonService.getList(loadObjects, {}, reqList);
};

const getReqList = () => {
    let reqList = RouteTools.prepareListRequest(currentRoute, cookies);
    reqList = lessonService.getDefaultRequestList(reqList);
    const filters = new Array<RequestFilterDTO>()
    const filter = new RequestFilterDTO();
    filter.field = "idcourse";
    if (id !== undefined) filter.values = [id];
    else filter.values = [];
    filters.push(filter);
    reqList.filters = filters;

    setReqList(reqList);
};



const checkIsLoading = () => {
    validateAll();
    const load: boolean = CommonTools.checkIsLoading(
        reqList,
        responseParams,
        data,
        total,
        totalPage,
        rows
    );
    setLoading(load);
};

const processTbFilterList = () => {
    const _tobj = new TbFilterListDTO();

    var t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    _tobj.addFilter(t);



    setTbFilterList(_tobj);
};

useEffect(() => {
    processTbFilterList();
    // processValidators();
}, []);

useEffect(() => {
    getReqList();
}, [currentRoute]);

useEffect(() => {
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
        getList();
    }
}, [reqList]);

useEffect(() => {
    checkIsLoading();
}, [data, rows, reqList, total, totalPage]);

const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);


const handleDelete = (item: any) => {

    lessonService.delete(item.id, getList, undefined);
};
//-------------------------------------------------

    return !loading && obj ? (
        <>
            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit} >


                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="name"
                        name="name"
                        variant="outlined"
                        color="primary"
                        _label={LL("Name")}
                        size="small"
                        value={obj.name}
                        setObj={setObjField}
                        _vresults={
                            vresults && vresults.name ? vresults.name : false
                        }
                    />
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="description"
                            name="description"
                            variant="outlined"
                            color="primary"
                            _label={LL("Description")}
                            size="small"
                            value={obj.description}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.description ? vresults.description : false
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={2}
                            fullWidth
                            id="ordercriteria"
                            name="ordercriteria"
                            variant="outlined"
                            color="primary"
                            _label={LL("Ordercriteria")}
                            size="small"
                            value={obj.ordercriteria}
                            setObj={setObjField}
                            _vresults={
                               undefined
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <LessonTypeSelect
                            tabIndex={3}
                            id="idtypelesson"
                            name="idtypelesson"
                            variant="outlined"
                            color="primary"
                            value={obj.idtypelesson}
                            field="idtypelesson"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                        />
                    </Box>
                    <Box mt={3}>
                        <StatusSelect
                            tabIndex={2}
                            id="status"
                            name="status"
                            variant="outlined"
                            color="primary"
                            value={obj.status}
                            field="status"
                            size="small"
                            setObj={setObjField}
                        />
                    </Box>
                    {/* <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="lessonduration"
                            name="lessonduration"
                            variant="outlined"
                            color="primary"
                            _label={LL("lessonduration")}
                            size="small"
                            value={obj.lessonduration}
                            setObj={setObjField}
                            _vresults={vresults && vresults.lessonduration ? vresults.lessonduration : false}
                        />
                    </Box> */}
                    <Box sx={{ mt: 3 }}>
                        <MyCheckboxField

                            tabIndex={1}
                            id="islive"
                            name="islive"
                            _label={LL("islive")}
                            setObj={setCheckFixed}
                            checked={obj.islive}
                            color="primary"
                            size="small"
                            _vresults={undefined}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                <Typography variant="h6" mb={1}> {LL('Seteaza data si ora lectiei')}</Typography>
                    <Stack direction='row' spacing={2}>
                    <MyDateTimePicker
                        className="form-picker"
                        field="startdate"
                        _defaultValue={DateTools.convertTimestampToDate(obj.startdate)}
                        LL={LL}
                        setObjField={setObjDateField}

                    />
                
                    <MyDateTimePicker
                        className="form-picker"
                        field="enddate"
                        _defaultValue={DateTools.convertTimestampToDate(obj.enddate)}
                        LL={LL}
                        setObjField={setObjDateField}
                    /></Stack>
                </Box>
                    <MyButton
                        tabIndex={3}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>

            </Box>
            <Box mt={3} >
                <MyTable
                    _columns={columns}
                    _data={rows}
                    _total={total}
                    _totalPage={totalPage}
                    _reqList={reqList}
                    _tbFilterList={tbFilterList}
                    setReqList={setReqList}
                    loading={loading}
                    currentRoute={currentRoute}
                    onDelete={handleDelete}
                    mainObject={mainObject}
                    fieldToShowOnDelete="name"
                    deleteOneObject={deleteOneObject}
                    setDeleteOneObject={setDeleteOneObject}
                    onRowClickCb={() => { }}
                    {...props}
                />
            </Box>
        </>
    ) : (
        <Loading />
    );
};

export default AddLesson;
