import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class TeacherFAQDto implements Idto {

    id?: string;
    iduser?: string;
    idteacher?: string;
    idcourse?: string;
    date?: number;

    status?: number;
    message?: string;
    teacherresponse?: string;
    showoncourse?: boolean;

    date_name?: string;
    status_name?: string;
    user?: any;
    teacher?: any;
    course?: any;
    constructor() {
        this.status = Status.ACTIVE;
        this.showoncourse = false;
    }
}

export class PostTeacherFAQDto implements Idto {


    iduser?: string;
    idteacher?: string;
    idcourse?: string;
    date?: number;

    status?: number;
    message?: string;
    teacherresponse?: string;
    showoncourse?: boolean;

    date_name?: string;
    status_name?: string;
    user?: any;
    teacher?: any;
    course?: any;
    constructor() {
        this.status = Status.ACTIVE;
        this.showoncourse = false;
    }
}