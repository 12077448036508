
import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CircularLoading = () => {
    return (
        <Box sx={{ display: 'flex', alignItems:'center', alignContent:'center', justifyContent:'center' }}>
            <CircularProgress color="primary"/>
        </Box>
    );
};

export { CircularLoading };