import React, { useState, useEffect } from 'react';

import TypeOccupationService from "services/nomenclature/typeoccupation.service";
import GenListType from 'components/elements/MyGenComponents/GenListType';
import { PopulateTypeDto } from 'dto/nomenclature/populatetype.dto';


const typeOccupationService = new TypeOccupationService();


interface TypeOccupationProps {
    children: any;
    currentRoute: any;
    mainObject: any;
}

const TypeOccupation: React.FC<TypeOccupationProps> = ({
    children,
    currentRoute,
    mainObject,
    ...props
}) => {

    return (
        <GenListType
            children={children}
            currentRoute={currentRoute}
            service={typeOccupationService}
            mainObject={mainObject}
            DTO={PopulateTypeDto}
            {...props}
        />
    )
};

export default TypeOccupation;
