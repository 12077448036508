import React, { useState, useEffect } from 'react';

import MyTable from "components/elements/TabelTools/MyTable";
import CourseTeacherRequestService from "services/course/courseteacherrequest.service";
import { logger } from 'hoc/logger';
import RequestListDTO from 'dto/app/requestlist.dto';
import ResultListDTO from 'dto/app/resultlist.dto';
import { CourseTeacherRequestDto } from 'dto/course/courseteacherrequest.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import { CommonTools } from 'tools/commontools';

import { ComponentCommonTools } from 'tools/componentcommontools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TbFilterDTO from 'dto/app/tbfilter.dto';
import TbFilterListDTO from 'dto/app/tbfilterlist.dto';
import FilterTools from 'tools/filtertools';
import { RouteTools } from 'tools/routetools';
import { Status } from 'tools/status';
const courseTeacherRequestService = new CourseTeacherRequestService();


interface CourseTeacherRequestProps {
    children: any;
    currentRoute: any;
    mainObject: any;
}

const CourseTeacherRequest: React.FC<CourseTeacherRequestProps> = ({
    children,
    currentRoute,
    mainObject,
    ...props
}) => {
    const [mainUrl, setMainUrl] = useState("");
    const [listUrl, setListUrl] = useState("");
    const [addUrl, setAddUrl] = useState("");
    useEffect(() => {
        const mu = CommonTools.generateMainUrl(mainObject);
        setMainUrl(mu);
        const lu = CommonTools.generateListUrl(mainObject, currentRoute);
        setListUrl(lu);
        const au = CommonTools.generateAddUrl(mainObject);
        setAddUrl(au);
    }, [mainObject, currentRoute]);

    useEffect(() => {
        _setListUrl(listUrl);
    }, [listUrl]);

    const { LL, _getListUrl, _setListUrl } = useResource();
    const [cookies, setCookies] = useCookies();

    const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
    const [rows, setRows] = useState<Array<CourseTeacherRequestDto>>(new Array<CourseTeacherRequestDto>());

    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const [responseParams, setResponseParams] = useState<RequestListDTO>(
        new RequestListDTO()
    );

    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
    const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
        new TbFilterListDTO()
    );

    const [loading, setLoading] = useState<boolean>(true);


    const columns = [];
    columns.push(ComponentCommonTools.columns_GetCheck());
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, 'teacherfullname'));
    columns.push(ComponentCommonTools.columns_StandardObjectColumn(LL, 'teacher','email'));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL, "type"));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL, "date"));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL, "changedate"));
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, 'managerfullname'));
    columns.push(ComponentCommonTools.columns_StandardObjectColumn(LL, 'manager','email'));
    columns.push(ComponentCommonTools.columns_StandardObjectColumn(LL, 'course','name'));
    columns.push(
        ComponentCommonTools.columns_ActionsColumn(LL, mainObject, undefined,
            ['edit', "detail"],
            ["detail"],
        )
    );

    const loadObjects = (data: ResultListDTO) => {
        CommonTools.processListLoadObjects(
            data,
            setData,
            setRows,
            setTotal,
            setTotalPage,
            setResponseParams
        );
    };

    const getList = () => {
        setLoading(true);
        const urlPart = RouteTools.prepareListLocation(reqList);
        RouteTools.setHistory(mainUrl + urlPart, {});
        setListUrl(mainUrl + urlPart);
        courseTeacherRequestService.getList(loadObjects, {}, reqList);
    };

    const getReqList = () => {
        let reqList = RouteTools.prepareListRequest(currentRoute, cookies);
        reqList = courseTeacherRequestService.getDefaultRequestList(reqList);

        setReqList(reqList);
    };

    const checkIsLoading = () => {
        const load: boolean = CommonTools.checkIsLoading(
            reqList,
            responseParams,
            data,
            total,
            totalPage,
            rows
        );
        setLoading(load);
    };

    const processTbFilterList = () => {
        const _tobj = new TbFilterListDTO();

        var t: TbFilterDTO;

        t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
        t.setValues(Status.GA("clasificator", true));
        _tobj.addFilter(t);


        setTbFilterList(_tobj);
    };

    useEffect(() => {
        processTbFilterList();

    }, []);

    useEffect(() => {
        getReqList();
    }, [currentRoute]);

    useEffect(() => {
        if (CommonTools.atLeastOneFieldDefined(reqList)) {
            getList();
        }
    }, [reqList]);

    useEffect(() => {
        checkIsLoading();
    }, [data, rows, reqList, total, totalPage]);




    const toggleEdit = (obj: any) => {
        
        ComponentCommonTools.goToEdit(obj, mainObject);
    };

    const toggleDetail = (obj: any) => {
        ComponentCommonTools.goToDetails(obj, mainObject);
    };



    // const handleDelete = (item: any) => {
    //     logger("DeletelabelDeletelabelDeletelabel", item);

    //     courseTeacherRequestService.delete(item.id, getList, undefined);
    // };

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            label: LL("crumb_" + mainObject),
        },
    ];
    return !loading || 1 ? (
        <>
            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
            <Box
                sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" sx={{ my: 3 }}>
                    {LL(mainObject + "page")}
                </Typography>
               
            </Box>
            <MyTable
                _columns={columns}
                _data={rows}
                _total={total}
                _totalPage={totalPage}
                _reqList={reqList}
                _tbFilterList={tbFilterList}
                setReqList={setReqList}
                loading={loading}
                currentRoute={currentRoute}
                onRowClickCb={toggleDetail}
                // onDelete={handleDelete}
                mainObject={mainObject}
                // fieldToShowOnDelete="name"
                // deleteOneObject={deleteOneObject}
                // setDeleteOneObject={setDeleteOneObject}
                {...props}
            />

        </>
    ) : (
        <Loading />
    );
};


export default CourseTeacherRequest;
