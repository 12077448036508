import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class CourseEnrollmentDto implements Idto {
    
    id?: string;
    idcourse?: string;
    idstudent?: string;
    status?: number;
    paymentstatus?: number;
    data?: number;
  
    numberoflessons?: number;
    numberoflessonspassed?: number;
    coursepercentage?: number;
    
    constructor() {
      this.status = Status.ENR_NEED_CHECKOUT;
      this.paymentstatus = Status.PAYMENT_UNPAID;
  }
  }

  export class PostCourseEnrollmentDto implements Idto {
    
    idcourse?: string;
    idstudent?: string;
    status?: number;
    paymentstatus?: number;
    data?: number;
    
    constructor() {
      this.status = Status.ENR_NEED_CHECKOUT;
      this.paymentstatus = Status.PAYMENT_UNPAID;
  }

  }