import React, { useState, useEffect } from 'react';

import StudentService from "services/student/student.service";
import { logger } from 'hoc/logger';

import { StudentDto, PostStudentDto } from 'dto/student/student.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { Status } from 'tools/status';
import { LanguageSelect } from 'components/elements/Select/LanguageSelect';
import { UserAutocomplete } from 'components/elements/Autocomplete/UserAutocomplete';
import { StudyTypeSelect } from 'components/elements/Select/StudyTypeSelect';
import MyCKEditor from 'components/elements/ckeditor/MyCKEditor';


const studentService = new StudentService();

interface AddStudentProps {
    children: any;
    currentRoute: any;
    _obj: StudentDto,
    mainObject: any;
}

const AddStudent: React.FC<AddStudentProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();


    const [loading, setLoading] = useState<boolean>(false);
    var [obj, setObj] = useState<PostStudentDto>(new PostStudentDto());


    const [isFormDisabled, setIsFormDisabled] = useState(true);


    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        if (vresults) return;

        obj.iduser = obj.iduser !== undefined ? obj.iduser : '';
        obj.bio = obj.bio !== undefined ? obj.bio : '';
        obj.institution = obj.institution !== undefined ? obj.institution : '';

        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.idtypestudies = obj.idtypestudies !== undefined ? obj.idtypestudies : '';

        setObj(obj);

        let   t = validateField("institution", obj.institution);

    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            
            institution: [],
        };

        
        v.institution.push(new RequiredValidator(LL('Required field institution', LabelTypes.LABEL_VALIDATION), ''));


        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    useEffect(() => {
        validateAll();
        processValidators();
    }, []);

    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };



    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'institution');
        
        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;

        studentService.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
    };


    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Add" + mainObject) },
    ];
    return !loading ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <MyNavBt
            tabIndex={11}
            href={listUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />

          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {LL("AddBlock")}
          </Typography>
        </Box>

        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <form onSubmit={handleSubmit}>
            <MyTextField
              tabIndex={0}
              fullWidth
              id="institution"
              name="institution"
              variant="outlined"
              color="primary"
              _label={LL("institution")}
              size="small"
              value={obj.institution}
              setObj={setObjField}
              _vresults={
                vresults && vresults.institution ? vresults.institution : false
              }
            />

            <Box sx={{ mt: 3 }}>
              <StatusSelect
                tabIndex={1}
                id="status"
                name="status"
                variant="outlined"
                color="primary"
                value={obj.status}
                field="status"
                size="small"
                setObj={setObjField}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyCKEditor
                data={obj.bio ? obj.bio : ""}
                setObj={setObjField}
                name="bio"
              />
            </Box>

            <Box sx={{ mt: 3 }}>
              <UserAutocomplete
                tabIndex={3}
                id="iduser"
                variant="outlined"
                color="primary"
                field="iduser"
                size="small"
                setObj={setObj}
                obj={obj}
                setObjFieldGeneral={setObjField}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <StudyTypeSelect
                tabIndex={3}
                id="idtypestudies"
                name="idtypestudies"
                variant="outlined"
                color="primary"
                value={obj.idtypestudies}
                field="idtypestudies"
                size="small"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <MyButton
              tabIndex={4}
              disabled={isFormDisabled}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
          </form>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default AddStudent;
