import { logger } from "hoc/logger";
import queryString from "query-string";
import MenuDomDto from "dto/app/menudom.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestPopulateDTO from "dto/app/requestpopulate.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

class RouteTools {
  static _LL: any = false;
  static hasAccess: any = false;
  static checkUser: any = false;

  static setLL(_l: any): any {
    RouteTools._LL = _l;
  }

  static setHasAccess(_h: any): any {
    RouteTools.hasAccess = _h;
  }

  static setCheckUser(_h: any): any {
    RouteTools.checkUser = _h;
  }

  // -----------------------------------------

  static getPmHash(m: string): string {
    const isAdmin =
      RouteTools.mAdminController.indexOf(m) !== -1 ? true : false;
    let t = "get-";
    t += isAdmin ? "admin" : "";
    t += m;

    return t;
  }

  static getPmHash_List(m: string): string {
    const h = RouteTools.getPmHash(m);
    return h + "-getlist";
  }

  static getPmHash_Add(m: string): string {
    const h = RouteTools.getPmHash(m);
    return h + "-add";
  }

  static getPmHash_Update(m: string): string {
    const h = RouteTools.getPmHash(m);
    return h + "-update";
  }

  static getPmHash_Gallery(m: string): string {
    const h = RouteTools.getPmHash(m);
    return h + "-gallery";
  }

  static getPmHash_Attachment(m: string): string {
    const h = RouteTools.getPmHash(m);
    return h + "-attachment";
  }

  static getPmHash_Video(m: string): string {
    const h = RouteTools.getPmHash(m);
    return h + "-video";
  }

  static getPmHash_Get(m: string): string {
    const h = RouteTools.getPmHash(m);
    return h + "-get";
  }

  static mAdminController = [
    "user",
    "student",
    "teacher",
    "testimonial",
    "teacherfaq",
    "agreement",
    "gallery",
    "attachment",
    "video",
    "page",
    "menu",
    "faq",
    "advertisement",
    "blogcategory",
    "supportarticlecategory",
    "ticketcategory",
    "ticket",
    "blog",
    "supportarticle",
    "sitesocial",
    "country",
    "typeagreement",
    "typeblogcategory",
    "typecourse",
    "typediscipline",
    "typeexperience",
    "typefilter",
    "typehardware",
    "typeinternetconnection",
    "typelesson",
    "typelocality",
    "typeoccupation",
    "typephone",
    "typeprice",
    "typeprofessionalismlevel",

    "typesocial",
    "typestudies",
    "typespecialty",
    "typetargetgroup",
    "typegender",
    "locality",
    "pricepolicy",
    "currency",
    "currencyrate",
    "transaction",
    "recommendationprice",
    "filter",
    "filterdictionary",
    "coursecategory",
    "course",
    "courseteacherrequest",
    "lesson",
    "question",
    "answer",
    "schedule",
    "journal",
    "test",

    "address",
    "coursefilter",
    "certificate",
    "courseenrollment",
    "coursesettings",
    "lessonsettings",
    "testresult",
    "testresultquestion",
    "zoomsettings",
    "coursepayment",
    "courseprice",
    "usersettings",
    "phone",
    "social",
    "userrole",
    "notification",
    "teacherrequest",
    "pricepolicylinkteacher",
  ];

  static modules = [
    "user",
    "student",
    "teacher",
    "teacherfaq",
    "testimonial",
    "role",
    "permission",
    "notification",
    "teacherrequest",
    "agreement",
    "file",
    "gallery",
    "attachment",
    "video",
    "language",
    "siteconfig",
    "siteconfigfile",
    "label",
    "page",
    "menu",
    "faq",
    "advertisement",
    "blogcategory",
    "supportarticlecategory",
    "ticketcategory",
    "ticket",
    "blog",
    "supportarticle",
    "sitesocial",
    "country",
    "typeagreement",
    "typeblogcategory",
    "typecourse",
    "typediscipline",
    "typeexperience",
    "typefilter",
    "typehardware",
    "typeinternetconnection",
    "typelesson",
    "typelocality",
    "typeoccupation",
    "typephone",
    "typeprice",
    "typeprofessionalismlevel",
    "typesocial",
    "typestudies",
    "typespecialty",
    "typetargetgroup",
    "typegender",
    "locality",
    "pricepolicy",
    "currency",
    "currencyrate",
    "transaction",
    "recommendationprice",
    "filter",
    "filterdictionary",
    "coursecategory",
    "course",
    "courseteacherrequest",
    "lesson",
    "question",
    "answer",
    "schedule",
    "journal",
    "test",

    "address",
    "coursefilter",
    "certificate",
    "courseenrollment",
    "coursesettings",
    "lessonsettings",
    "testresult",
    "testresultquestion",
    "zoomsettings",
    "coursepayment",
    "courseprice",
    "usersettings",
    "phone",
    "social",
    "userrole",
    "pricepolicylinkteacher",
  ];

  static getAdminLeftMenu_Childs(m: string[]): MenuDomDto[] {
    var childs: MenuDomDto[] = [];

    m.map(function (item): any {
      const ph = RouteTools.getPmHash_List(item);
      if (!ph) return false;

      const obj: MenuDomDto = new MenuDomDto();
      obj.identifier = "am_" + item;
      obj.childs = false;
      obj.href = "/" + item;
      obj.label = RouteTools._LL("AM_" + item);
      obj.permissionhash = ph;
      childs.push(obj);
    });

    return childs;
  }

  static getAdminLeftMenu_Group(
    mm: string,
    childsModule: string[]
  ): MenuDomDto | boolean {
    var childs: MenuDomDto[] = RouteTools.getAdminLeftMenu_Childs(childsModule);
    if (!childs.length) return false;

    var rez: MenuDomDto = new MenuDomDto();
    rez.identifier = "amg_" + mm;
    rez.childs = childs;
    rez.href = false;
    rez.label = RouteTools._LL("AMG_" + mm);
    rez.icon = "amg_" + mm;

    return rez;
  }

  static getAdminLeftMenu_UserGroup(): MenuDomDto | boolean {
    var childsModule = [
      "user",
      "student",
      "teacher",
      "role",
      "permission",
      "agreement",
      "testimonial",
      "teacherfaq",
      "notification",
      "teacherrequest",
    ];
    return RouteTools.getAdminLeftMenu_Group("user", childsModule);
  }

  static getAdminLeftMenu_SystemGroup(): any {
    var childsModule = [
      "file",

      "gallery",
      "attachment",
      "video",
      "schedule",
      "journal",
      "test",
    ];
    return RouteTools.getAdminLeftMenu_Group("system", childsModule);
  }

  static getAdminLeftMenu_StaticGroup(): any {
    var childsModule = [
      "language",
      "label",
      "page",
      "menu",
      "faq",
      "blogcategory",
      "blog",
      "supportarticlecategory",
      "supportarticle",
      "sitesocial",
      "advertisement",
      "siteconfig",
      "siteconfigfile",
    ];
    return RouteTools.getAdminLeftMenu_Group("static", childsModule);
  }

  static getAdminLeftMenu_NomenclatureGroup(): any {
    var childsModule = [
      "country",
      "typeagreement",
      "typeblogcategory",
      "typecourse",
      "typediscipline",
      "typeexperience",
      "typefilter",
      "typehardware",
      "typeinternetconnection",
      "typelesson",
      "typelocality",
      "typeoccupation",
      "typephone",
      "typeprice",
      "typeprofessionalismlevel",
      "typesocial",
      "typestudies",
      "typespecialty",
      "typetargetgroup",
      "typegender",
      "locality",
    ];
    return RouteTools.getAdminLeftMenu_Group("nomenclature", childsModule);
  }

  static getAdminLeftMenu_FinanceGroup(): any {
    var childsModule = [
      "pricepolicy",
      "currency",
      "currencyrate",
      "transaction",
      "recommendationprice",
      "pricepolicylinkteacher",
    ];
    return RouteTools.getAdminLeftMenu_Group("finance", childsModule);
  }

  static getAdminLeftMenu_CourseGroup(): any {
    var childsModule = [
      "filter",
      "filterdictionary",
      "coursecategory",
      "course",
      "courseteacherrequest",
    ];
    return RouteTools.getAdminLeftMenu_Group("course", childsModule);
  }

  static getAdminLeftMenu_SupportGroup(): any {
    var childsModule = ["ticketcategory", "ticket"];
    return RouteTools.getAdminLeftMenu_Group("support", childsModule);
  }

  static getAdminLeftMenu(): any {
    let rez = [];
    let t: any = false;

    // t = RouteTools.getAdminLeftMenu_TestGroup();
    // if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_UserGroup();
    if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_StaticGroup();
    if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_NomenclatureGroup();
    if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_SystemGroup();
    if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_FinanceGroup();
    if (t) rez.push(t);
    t = RouteTools.getAdminLeftMenu_CourseGroup();
    if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_SupportGroup();
    if (t) rez.push(t);

    return rez;
  }

  // -----------------------------------------
  static processSpecial(paths: any): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m == "login") {
      return { mobj: "login", permissionhash: "post-auth-signin" };
    }

    if (m == "test2") {
      return { mobj: "Test", permissionhash: "get-admintest1-list" };
    }

    return false;
  }

  static processModuleItem(paths: any, module: string): any {
    if (!paths || !paths.length) {
      return false;
    }

    const m: any = paths[0];

    if (m != module) {
      return false;
    }

    if (paths.length === 1) {
      return {
        mobj: module,
        permissionhash: RouteTools.getPmHash_List(module),
      };
    }

    const mSecondPath: string = paths[1];
    const mThertPath: string = paths[2] != undefined ? paths[2] : "";

    if (mSecondPath === "add") {
      return {
        mobj: module,
        permissionhash: RouteTools.getPmHash_Add(module),
      };
    }

    if (mSecondPath === "edit") {
      return {
        mobj: module,
        permissionhash: RouteTools.getPmHash_Update(module),
      };
    }

    if (mThertPath === "gallery") {
      return {
        mobj: module,
        permissionhash: RouteTools.getPmHash_Gallery(module),
      };
    }

    if (mThertPath === "attachment") {
      return {
        mobj: module,
        permissionhash: RouteTools.getPmHash_Attachment(module),
      };
    }

    if (mThertPath === "video") {
      return {
        mobj: module,
        permissionhash: RouteTools.getPmHash_Video(module),
      };
    }

    return {
      mobj: module,
      permissionhash: RouteTools.getPmHash_Get(module),
    };
  }

  static processModule(paths: any): any {
    if (!paths || !paths.length) {
      return false;
    }

    var rez = false;

    RouteTools.modules.map(function (item): void {
      if (rez) return;
      const t = RouteTools.processModuleItem(paths, item);
      if (t) rez = t;
    });

    return rez;
  }

  //---------------------------------------

  static processRoute(
    paths: any,
    parsed: any,
    fullUrl: any,
    anchor: string
  ): any {
    var mo: any = false;

    mo = RouteTools.processSpecial(paths);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        mo.permissionhash,
        anchor
      );
    mo = RouteTools.processModule(paths);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        mo.permissionhash,
        anchor
      );

    return RouteTools.processRouteVars(
      "notfound",
      paths,
      parsed,
      fullUrl,
      "/",
      anchor
    );
  }

  // -----------------------------------------
  static processRouteVars(
    mainObj: any,
    paths: any,
    parsed: any,
    fullUrl: any,
    permissionhash: any,
    anchor: string
  ): any {
    if (!anchor) anchor = "";
    if (!RouteTools.hasAccess(permissionhash)) {
      mainObj = "forbidden";
    }
    if (!RouteTools.checkUser()) {
      RouteTools.setHistory("/login", {});
      mainObj = "login";
    } else if (mainObj == "login") {
      RouteTools.setHistory("/", {});
      mainObj = "homepage";
    }

    return {
      _mainobject: mainObj,
      _paths: paths,
      _getVars: parsed,
      url: fullUrl,
      anchor: anchor,
    };
  }

  // -----------------------------------------
  // -----------------------------------------
  // -----------------------------------------

  // -----------------------------------------
  static getDefaultIdParent(currentRoute: any, cookies: any): string {
    const _reqList = RouteTools.prepareListRequest(currentRoute, cookies);

    let _idParent = "";

    if (_reqList && _reqList.filters && _reqList.filters.length) {
      _reqList.filters.forEach((item, index) => {
        if (item.field != "idparent") return;
        if (!item.values || !item.values.length) return;
        _idParent = item.values[0];
      });
    }

    return _idParent;
  }

  // -----------------------------------------
  static prepareListRequest(currentRoute: any, cookies: any): RequestListDTO {
    const cOnPage =
      cookies.onPage != undefined
        ? Number(cookies.onPage)
        : Number(process.env.REACT_APP_ONPAGE);

    const obj = new RequestListDTO();
    const _getVars =
      currentRoute && currentRoute._getVars ? currentRoute._getVars : {};

    obj.page = _getVars.page ? Number(_getVars.page) : 1;
    obj.onpage = _getVars.onpage ? Number(_getVars.onpage) : cOnPage;

    obj.filters = [];
    if (_getVars.filters != null && _getVars.filters != undefined) {
      const filters = _getVars.filters.split("|");

      for (let i in filters) {
        const t = filters[i].split(",");

        const _o = new RequestFilterDTO();
        _o.field = t[0].trim();
        t.shift();
        _o.values = t;

        if (!_o.field) continue;
        if (_o.values == undefined || !_o.values.length) continue;

        obj.filters.push(_o);
      }
    }

    if (_getVars.populates != null && _getVars.populates != undefined) {
      const _o = new RequestPopulateDTO();
      _o.populates = _getVars.populates.split(",");
      obj.populate = _o;
    }

    obj.sortcriteria = [];
    logger("getDefaultRequestList", _getVars.order);
    if (_getVars.order != null && _getVars.order != undefined) {
      const order = _getVars.order.split("|");

      for (let i in order) {
        const t = order[i].split(",");

        const _o = new RequestSortCriteriaDTO();
        _o.field = t[0].trim();
        _o.asc = t.length > 1 && t[1] == "asc" ? true : false;

        if (!_o.field) continue;

        obj.sortcriteria.push(_o);
      }
    }

    return obj;
  }

  // -----------------------------------------
  static prepareListLocation(obj: RequestListDTO): string {
    let str = "";

    if (obj.page) {
      str += str ? "&" : "?";
      str += "page=" + obj.page.toString();
    }

    if (obj.onpage) {
      str += str ? "&" : "?";
      str += "onpage=" + obj.onpage.toString();
    }

    if (obj.filters) {
      let fstr = "";

      for (let i in obj.filters) {
        if (!obj.filters[i].values) continue;
        if (!Array.isArray(obj.filters[i].values)) continue;
        if (!obj.filters[i].values?.length) continue;

        fstr += fstr ? "|" : "";
        fstr += obj.filters[i]?.field;
        fstr += ",";
        fstr += obj.filters[i].values?.join(",");
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "filters=" + fstr;
      }
    }
    logger("getDefaultRequestList", obj, obj.sortcriteria);
    if (obj.sortcriteria) {
      let fstr = "";

      for (let i in obj.sortcriteria) {
        fstr += fstr ? "|" : "";
        fstr += obj.sortcriteria[i]?.field;
        fstr += ",";
        fstr += obj.sortcriteria[i]?.asc ? "asc" : "desc";
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "order=" + fstr;
      }
    }

    if (
      obj.populate &&
      obj.populate.populates &&
      obj.populate.populates.length
    ) {
      str += str ? "&" : "?";
      str += "populates=" + obj.populate.populates.join(",");
    }

    return str;
  }
  //--------------------------
  // static transformParamsTo(input:string) {
  //   const result = new RequestListDTO();
  //   if (!input.startsWith('?')) {
  //     return result;
  //   }

  //   const params = new URLSearchParams(input);

  //   const page = parseInt(params.get('page'), 10);
  //   if (!isNaN(page)) {
  //     result.page = page;
  //   }

  //   const onPage = parseInt(params.get('onpage'), 10);
  //   if (!isNaN(onPage)) {
  //     result.onpage = onPage;
  //   }

  //   const order = params.get('order');
  //   if (order) {
  //     const [field, asc] = order.split(',');
  //     if (field && (asc === 'asc' || asc === 'desc')) {
  //       result.sortcriteria = [
  //         {
  //           field: field,
  //           asc: asc === 'asc',
  //         },
  //       ];
  //     }
  //   }

  //   return result;
  // }

  // -----------------------------------------
  static parseLocation(location: any): any {
    const parsed = queryString.parse(location.search);

    const fullUrl = location.pathname + location.search;

    var tpaths = location.pathname.split("/");
    tpaths = tpaths.filter(Boolean);

    let anchor = location.hash.replace("#", "");
    if (!anchor) anchor = "";

    return RouteTools.processRoute(tpaths, parsed, fullUrl, anchor);
  }

  // -----------------------------------------
  static setHistory(location: any, state: any, anchor?: string): any {
    state = state ? state : {};
    if (anchor) location += "#" + anchor;
    window.history.pushState(state, "", location);

    var popStateEvent = new PopStateEvent("popstate", state);
    dispatchEvent(popStateEvent);
  }

  static setAnchor(anchor: string): void {
    window.location.hash = anchor;
  }
  // -----------------------------------------
}

export { RouteTools };
