import React, { useState, useEffect } from 'react';

import CountryService from "services/nomenclature/country.service";
import GenListType from 'components/elements/MyGenComponents/GenListType';
import { PopulateTypeDto } from 'dto/nomenclature/populatetype.dto';


const countryService = new CountryService();


interface CountryProps {
    children: any;
    currentRoute: any;
    mainObject: any;
}

const Country: React.FC<CountryProps> = ({
    children,
    currentRoute,
    mainObject,
    ...props
}) => {

    return (
        <GenListType
            children={children}
            currentRoute={currentRoute}
            service={countryService}
            mainObject={mainObject}
            DTO={PopulateTypeDto}
            {...props}
        />
    )
};

export default Country;
