import React, { useState, useEffect } from 'react';

import AdvertisementService from "services/advertisement/advertisement.service";
import { logger } from 'hoc/logger';

import { AdvertisementDto, PostAdvertisementDto } from 'dto/advertisement/advertisement.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Status } from 'tools/status';
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MyCheckboxField from "components/form/mycheckboxfield";
import IconButton from '@mui/material/IconButton';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { SectionSelect } from 'components/elements/Select/SectionSelect';
import { LanguageSelect } from 'components/elements/Select/LanguageSelect';
import { PageAutocomplete } from 'components/elements/Autocomplete/PageAutocomplete';
import { PageSelect } from 'components/elements/Select/PageSelect';
import { AdvertisementTypeSelect } from 'components/elements/Select/AdvertisementTypeSelect';
import MyDatePicker from 'components/elements/MyDatePicker/MyDatePicker';
import { AdvertisementSectionSelect } from 'components/elements/Select/AdvertisementSectionSelect';
const advertisementService = new AdvertisementService();

interface AddAdvertisementProps {
    children: any;
    currentRoute: any;
    _obj: AdvertisementDto,
    mainObject: any;
}

const AddAdvertisement: React.FC<AddAdvertisementProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();


    const [loading, setLoading] = useState<boolean>(false);
    var [obj, setObj] = useState<PostAdvertisementDto>(new PostAdvertisementDto());


    const [isFormDisabled, setIsFormDisabled] = useState(true);


    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.name = obj.name !== undefined ? obj.name : '';
        obj.description = obj.description !== undefined ? obj.description : '';
        obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.startdate = obj.startdate !== undefined ? obj.startdate : undefined;
        obj.enddate = obj.enddate !== undefined ? obj.enddate : undefined;
        obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : '';
        obj.type = obj.type !== undefined ? obj.type : 0;
        obj.clabt = obj.clabt !== undefined ? obj.clabt : '';
        obj.section = obj.section !== undefined ? obj.section : 0;
        obj.url = obj.url !== undefined ? obj.url : '';
      

        setObj(obj);

        let t = validateField("name", obj.name);
        // t = validateField("ordercriteria", obj.ordercriteria, t);
        // t = validateField("description", obj.description, t);


    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            name: [],
            // ordercriteria: [],
            // description: []
        };

        v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
        // v.description.push(new RequiredValidator(LL('Required field description', LabelTypes.LABEL_VALIDATION), ''));
        // v.ordercriteria.push(new RequiredValidator(LL('Required field ordercriteria', LabelTypes.LABEL_VALIDATION), ''));
        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    useEffect(() => {
        // validateAll();
        processValidators();
    }, []);

    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };



    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'name');
        // rez = ValidateTools.vForm(vresults, 'ordercriteria', rez);
        // rez = ValidateTools.vForm(vresults, 'description', rez);
        // if(obj && obj.startdate && obj.enddate){
        //     if(obj.startdate > obj.enddate){
        //         rez = false;
        //     }
        // }else {
        //     rez = true;
        // }
        

        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;

        advertisementService.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
    };


    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Add" + mainObject) },
    ];
    return !loading ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <MyNavBt
            tabIndex={12}
            href={listUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />

          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {LL("AddBlock")}
          </Typography>
        </Box>

        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <form onSubmit={handleSubmit}>
            <MyTextField
              tabIndex={0}
              fullWidth
              id="name"
              name="name"
              variant="outlined"
              color="primary"
              _label={LL("name")}
              size="small"
              value={obj.name}
              setObj={setObjField}
              _vresults={vresults && vresults.name ? vresults.name : false}
            />
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={1}
                fullWidth
                id="description"
                name="description"
                variant="outlined"
                color="primary"
                _label={LL("description")}
                size="small"
                value={obj.description}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={2}
                fullWidth
                id="clabt"
                name="clabt"
                variant="outlined"
                color="primary"
                _label={LL("clabt")}
                size="small"
                value={obj.clabt}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={3}
                fullWidth
                id="ordercriteria"
                name="ordercriteria"
                variant="outlined"
                color="primary"
                _label={LL("ordercriteria")}
                size="small"
                value={obj.ordercriteria}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={4}
                fullWidth
                id="url"
                name="url"
                variant="outlined"
                color="primary"
                _label={LL("url")}
                size="small"
                value={obj.url}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <StatusSelect
                tabIndex={5}
                id="status"
                name="status"
                variant="outlined"
                color="primary"
                value={obj.status}
                field="status"
                size="small"
                setObj={setObjField}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <AdvertisementTypeSelect
                tabIndex={6}
                id="type"
                name="type"
                variant="outlined"
                color="primary"
                value={obj.type}
                field="type"
                size="small"
                setObj={setObjField}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <AdvertisementSectionSelect
                tabIndex={7}
                id="section"
                name="section"
                variant="outlined"
                color="primary"
                value={obj.section}
                field="section"
                size="small"
                setObj={setObjField}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <LanguageSelect
                tabIndex={8}
                id="idlanguage"
                name="idlanguage"
                variant="outlined"
                color="primary"
                value={obj.idlanguage}
                field="idlanguage"
                size="small"
                setObj={setObjField}
                obj={obj}
                forAddForms={true}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyDatePicker
                tabIndex={9}
                field="startdate"
                _defaultValue={obj.startdate}
                LL={LL}
                setObjField={setObjField}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyDatePicker
                tabIndex={10}
                field="enddate"
                _defaultValue={obj.enddate}
                LL={LL}
                setObjField={setObjField}
              />
            </Box>
            <MyButton
              tabIndex={11}
              disabled={isFormDisabled}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
          </form>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default AddAdvertisement;
