import React, { useState, useEffect } from 'react';

import TeacherService from "services/teacher/teacher.service";
import { logger } from 'hoc/logger';

import { TeacherDto, PostTeacherDto } from 'dto/teacher/teacher.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import MyCheckboxField from "components/form/mycheckboxfield";
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from 'tools/routetools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import { TeacherStatusSelect } from 'components/elements/Select/TeacherStatusSelect';
import { Status } from 'tools/status';
import { LanguageSelect } from 'components/elements/Select/LanguageSelect';
import { UserAutocomplete } from 'components/elements/Autocomplete/UserAutocomplete';
import { StudyTypeSelect } from 'components/elements/Select/StudyTypeSelect';
import { SpecialtyTypeSelect } from 'components/elements/Select/SpecialtyTypeSelect';
import { TargetGroupTypeSelect } from 'components/elements/Select/TargetGroupTypeSelect';
import { InternetConnectionTypeSelect } from 'components/elements/Select/InternetConnectionTypeSelect';
import { HardwareTypeSelect } from 'components/elements/Select/HardwareTypeSelect';
import { LessonTypeSelect } from 'components/elements/Select/LessonTypeSelect';
import { OccupationTypeSelect } from 'components/elements/Select/OccupationTypeSelect';
import { ProfessionalismLevelTypeSelect } from 'components/elements/Select/ProfessionalismLevelTypeSelect';
import { ExperienceTypeSelect } from 'components/elements/Select/ExperienceTypeSelect';
import { DisciplineTypeSelect } from 'components/elements/Select/DisciplineTypeSelect';
import MyCKEditor from 'components/elements/ckeditor/MyCKEditor';


const teacherService = new TeacherService();

interface AddTeacherProps {
    children: any;
    currentRoute: any;
    _obj: TeacherDto,
    mainObject: any;
}

const AddTeacher: React.FC<AddTeacherProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();


    const [loading, setLoading] = useState<boolean>(false);
    var [obj, setObj] = useState<PostTeacherDto>(new PostTeacherDto());


    const [isFormDisabled, setIsFormDisabled] = useState(true);


    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.iduser = obj.iduser !== undefined ? obj.iduser : '';
        obj.bio = obj.bio !== undefined ? obj.bio : '';
        obj.graduatedinstitution = obj.graduatedinstitution !== undefined ? obj.graduatedinstitution : '';
        obj.timetoteachperweek = obj.timetoteachperweek !== undefined ? obj.timetoteachperweek : '';
        obj.whyliveclass = obj.whyliveclass !== undefined ? obj.whyliveclass : '';
        obj.hasaudience = obj.hasaudience !== undefined ? obj.hasaudience : false;

        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.idtypestudies = obj.idtypestudies !== undefined ? obj.idtypestudies : '';
        obj.idtypespecialty = obj.idtypespecialty !== undefined ? obj.idtypespecialty : '';
        obj.idtypetargetgroup = obj.idtypetargetgroup !== undefined ? obj.idtypetargetgroup : '';
        obj.idtypeinternetconnection = obj.idtypeinternetconnection !== undefined ? obj.idtypeinternetconnection : '';
        obj.idtypelesson = obj.idtypelesson !== undefined ? obj.idtypelesson : '';
        obj.idtypediscipline = obj.idtypediscipline !== undefined ? obj.idtypediscipline : '';
        obj.idtypeoccupation = obj.idtypeoccupation !== undefined ? obj.idtypeoccupation : '';
        obj.idtypeprofessionalismlevel = obj.idtypeprofessionalismlevel !== undefined ? obj.idtypeprofessionalismlevel : '';
        obj.idtypeexperience = obj.idtypeexperience !== undefined ? obj.idtypeexperience : '';

        setObj(obj);

        let    t = validateField("timetoteachperweek", obj.timetoteachperweek);
        t = validateField("graduatedinstitution", obj.graduatedinstitution, t);
        t = validateField("whyliveclass", obj.whyliveclass, t);

    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            
            timetoteachperweek: [],
            graduatedinstitution: [],
            whyliveclass: [],
        };

       
        v.timetoteachperweek.push(new RequiredValidator(LL('Required field timetoteachperweek', LabelTypes.LABEL_VALIDATION), ''));
        v.graduatedinstitution.push(new RequiredValidator(LL('Required field graduatedinstitution', LabelTypes.LABEL_VALIDATION), ''));
        v.whyliveclass.push(new RequiredValidator(LL('Required field whyliveclass', LabelTypes.LABEL_VALIDATION), ''));

        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    useEffect(() => {
        validateAll();
        processValidators();
    }, []);

    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };



    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'timetoteachperweek');
        
        rez = ValidateTools.vForm(vresults, 'graduatedinstitution', rez);
        rez = ValidateTools.vForm(vresults, 'whyliveclass', rez);
        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;

        teacherService.add(ComponentCommonTools.handleSuccessAddAndEdit, { mainObject }, obj);
    };
    const setCheckFixed = (value: any) => {
        let t = JSON.parse(JSON.stringify(obj));

        t['hasaudience'] = value;
        setObj(t);

    };

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrl,
            label: LL("crumb_" + mainObject),
        },
        { label: LL("crumb_Add" + mainObject) },
    ];
    return !loading ? (
      <>
        <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <MyNavBt
            tabIndex={18}
            href={listUrl}
            children={
              <IconButton color="primary">
                <ArrowBackIcon />
              </IconButton>
            }
            size="large"
            _hstate={{}}
            _mainurl={mainUrl}
          />

          <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
            {LL("AddBlock")}
          </Typography>
        </Box>

        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          <form onSubmit={handleSubmit}>
            <MyTextField
              tabIndex={0}
              fullWidth
              id="timetoteachperweek"
              name="timetoteachperweek"
              variant="outlined"
              color="primary"
              _label={LL("timetoteachperweek")}
              size="small"
              value={obj.timetoteachperweek}
              setObj={setObjField}
              _vresults={
                vresults && vresults.timetoteachperweek
                  ? vresults.timetoteachperweek
                  : false
              }
            />

            <Box sx={{ mt: 3 }}>
              <TeacherStatusSelect
                tabIndex={1}
                id="status"
                name="status"
                variant="outlined"
                color="primary"
                value={obj.status}
                field="status"
                size="small"
                setObj={setObjField}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              {/* <MyTextField
                            tabIndex={2}
                            fullWidth
                            id="bio"
                            name="bio"
                            variant="outlined"
                            color="primary"
                            _label={LL("bio")}
                            size="small"
                            value={obj.bio}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.bio ? vresults.bio : false
                            }
                        /> */}

              <MyCKEditor
                data={obj.bio ? obj.bio : ""}
                setObj={setObjField}
                name="bio"
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={3}
                fullWidth
                id="whyliveclass"
                name="whyliveclass"
                variant="outlined"
                color="primary"
                _label={LL("whyliveclass")}
                size="small"
                value={obj.whyliveclass}
                setObj={setObjField}
                _vresults={
                  vresults && vresults.whyliveclass
                    ? vresults.whyliveclass
                    : false
                }
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyTextField
                tabIndex={4}
                fullWidth
                id="graduatedinstitution"
                name="graduatedinstitution"
                variant="outlined"
                color="primary"
                _label={LL("graduatedinstitution")}
                size="small"
                value={obj.graduatedinstitution}
                setObj={setObjField}
                _vresults={
                  vresults && vresults.graduatedinstitution
                    ? vresults.graduatedinstitution
                    : false
                }
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <MyCheckboxField
                tabIndex={5}
                id="hasaudience"
                name="hasaudience"
                _label={LL("hasaudience")}
                setObj={setCheckFixed}
                checked={obj.hasaudience}
                color="primary"
                size="small"
                _vresults={undefined}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <UserAutocomplete
                tabIndex={6}
                id="iduser"
                variant="outlined"
                color="primary"
                field="iduser"
                size="small"
                setObj={setObj}
                obj={obj}
                setObjFieldGeneral={setObjField}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <StudyTypeSelect
                tabIndex={7}
                id="idtypestudies"
                name="idtypestudies"
                variant="outlined"
                color="primary"
                value={obj.idtypestudies}
                field="idtypestudies"
                size="small"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <SpecialtyTypeSelect
                tabIndex={8}
                id="idtypespecialty"
                name="idtypespecialty"
                variant="outlined"
                color="primary"
                value={obj.idtypespecialty}
                field="idtypespecialty"
                size="small"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <TargetGroupTypeSelect
                tabIndex={9}
                id="idtypetargetgroup"
                name="idtypetargetgroup"
                variant="outlined"
                color="primary"
                value={obj.idtypetargetgroup}
                field="idtypetargetgroup"
                size="small"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <InternetConnectionTypeSelect
                tabIndex={10}
                id="idtypeinternetconnection"
                name="idtypeinternetconnection"
                variant="outlined"
                color="primary"
                value={obj.idtypeinternetconnection}
                field="idtypeinternetconnection"
                size="small"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <HardwareTypeSelect
                tabIndex={11}
                id="idtypehardware"
                name="idtypehardware"
                variant="outlined"
                color="primary"
                value={obj.idtypehardware}
                field="idtypehardware"
                size="small"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <LessonTypeSelect
                tabIndex={12}
                id="idtypelesson"
                name="idtypelesson"
                variant="outlined"
                color="primary"
                value={obj.idtypelesson}
                field="idtypelesson"
                size="small"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <OccupationTypeSelect
                tabIndex={13}
                id="idtypeoccupation"
                name="idtypeoccupation"
                variant="outlined"
                color="primary"
                value={obj.idtypeoccupation}
                field="idtypeoccupation"
                size="small"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <ProfessionalismLevelTypeSelect
                tabIndex={14}
                id="idtypeprofessionalismlevel"
                name="idtypeprofessionalismlevel"
                variant="outlined"
                color="primary"
                value={obj.idtypeprofessionalismlevel}
                field="idtypeprofessionalismlevel"
                size="small"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <ExperienceTypeSelect
                tabIndex={15}
                id="idtypeexperience"
                name="idtypeexperience"
                variant="outlined"
                color="primary"
                value={obj.idtypeexperience}
                field="idtypeexperience"
                size="small"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <DisciplineTypeSelect
                tabIndex={16}
                id="idtypediscipline"
                name="idtypediscipline"
                variant="outlined"
                color="primary"
                value={obj.idtypediscipline}
                field="idtypediscipline"
                size="small"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <MyButton
              tabIndex={17}
              disabled={isFormDisabled}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
          </form>
        </Box>
      </>
    ) : (
      <Loading />
    );
};

export default AddTeacher;
