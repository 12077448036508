import React, { useState, useEffect } from "react";

import CourseService from "services/course/course.service";
import { logger } from "hoc/logger";
import useMyFormValidation from "hook/useMyFormValidation";
import { CourseDto } from "dto/course/course.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";

import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Status } from "tools/status";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "components/elements/Tabs/TabPanel";
import { StatusSelect } from "components/elements/Select/StatusSelect";
import { TeacherAutocomplete } from "components/elements/Autocomplete/TeacherAutocomplete";
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import AddCourseFilter from "components/course/course/components/coursefilter/AddCourseFilter";
import AddCourseSettings from "components/course/course/components/coursesettings/AddCourseSettings";
import EditCourseSettingsInfo from "components/course/course/components/coursesettings/EditCourseSettings";
import AddCourseEnrollment from "components/course/course/components/courseenrollment/AddCourseEnrollment";
import AddLesson from "components/course/course/components/lesson/AddLesson";
import AddCoursePrice from "components/course/course/components/courseprice/AddCoursePrice";
import EditCoursePrice from "components/course/course/components/courseprice/EditCoursePrice";
import AddCertificate from "components/course/course/components/certificate/AddCertificate";
import EditCertificate from "components/course/course/components/certificate/EditCertificate";
import AddLinkCourseCategory from "components/course/course/components/linkcoursecategory/AddLinkCourseCategory";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
const courseService = new CourseService();

interface EditCourseProps {
  children: any;
  currentRoute: any;
  _obj: CourseDto;
  mainObject: any;
}

const EditCourse: React.FC<EditCourseProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [id, setId] = useState<string | undefined>(_id);

  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<CourseDto | undefined>();
  const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const [validators, setValidators] = useState<any>();
  const [vresults, setVResults] = useState<any>(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    // if (vresults) return;

    obj.name = obj.name !== undefined ? obj.name : "";
    obj.description = obj.description !== undefined ? obj.description : "";
    obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
    obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : "";
    obj.idteacher = obj.idteacher !== undefined ? obj.idteacher : "";
    obj.teacher = obj.teacher !== undefined ? obj.teacher : null;
    obj.popularidentifier =
      obj.popularidentifier !== undefined ? obj.popularidentifier : 0;
    obj.requirementstext =
      obj.requirementstext !== undefined ? obj.requirementstext : "";
    obj.whatwillyoulearntext =
      obj.whatwillyoulearntext !== undefined ? obj.whatwillyoulearntext : "";
    obj.targetaudiencetext =
      obj.targetaudiencetext !== undefined ? obj.targetaudiencetext : "";
    obj.shortdescription =
      obj.shortdescription !== undefined ? obj.shortdescription : "";
    setObj(obj);

    let t = validateField("name", obj.name);
    // t = validateField("description", obj.description, t);
    logger("validateAll", t);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);

    setIsFormDisabled(ValidateTools.vForm(v, field));
    setVResults(v);
    return v;
  };

  const processValidators = () => {
    let v: any = {
      name: [],
      // description: [],
    };

    v.name.push(
      new RequiredValidator(
        LL("Required field name", LabelTypes.LABEL_VALIDATION),
        ""
      )
    );
    // v.description.push(new RequiredValidator(LL('Required field description', LabelTypes.LABEL_VALIDATION), ''));
    setValidators(v);
  };

  const checkLoading = () => {
    if (!loading) return;
    if (obj === undefined) return;
    if (!validators) return;
    validateAll();
    setLoading(false);
  };

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined) {
      courseService.get(id, loadObject, {});
    }
  };

  useEffect(() => {
    processValidators();
    if (id === "") {
      if (obj !== undefined) {
        setId(obj.id);
      }
    }
  }, []);
  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, "name");
    if (obj && !obj?.idlanguage) rez = true;
    setIsFormDisabled(rez);
  };

  useEffect(() => {
    validateAll();
    checkLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults, obj?.idlanguage]);

  useEffect(() => {
    if (obj === undefined) getObject();
  }, [id]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (id !== undefined) {
      courseService.update(
        id,
        ComponentCommonTools.handleSuccessAddAndEdit,
        { mainObject, anchor: "generalInfo" },
        obj
      );
    }
  };
  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    {
      url: detailUrl,
      label: LL("crumb_Detail" + mainObject),
    },
    { label: LL("crumb_Edit" + mainObject) },
  ];

  const [value, setValue] = React.useState(
    CommonTools.getAnchor(currentRoute, "generalInfo")
  );

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
    RouteTools.setAnchor(newValue);
  };

  return !loading && obj ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={2}
          href={detailUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{ anchor: value }}
          _mainurl={mainUrl}
        />
        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {LL("EditBlock")}
        </Typography>
      </Box>

      <Box>
        <Box className="tabs">
          <Tabs
            scrollButtons
            allowScrollButtonsMobile
            variant="scrollable"
            value={value}
            onChange={handleChange}
          >
            {obj ? (
              <Tab label={LL("Edit_Course_main_Info")} value={"generalInfo"} />
            ) : (
              <></>
            )}
            {obj ? (
              <Tab label={LL("Edit_Course_Filter")} value={"courseFilter"} />
            ) : (
              <></>
            )}
            {obj ? (
              <Tab
                label={LL("Course_Settings_Info")}
                value={"courseSettings"}
              />
            ) : (
              <></>
            )}
            {obj ? (
              <Tab label={LL("Course_Enrollment")} value={"courseEnrollment"} />
            ) : (
              <></>
            )}
            {obj ? (
              <Tab label={LL("Course_Lesson")} value={"courseLesson"} />
            ) : (
              <></>
            )}
            {obj ? (
              <Tab label={LL("Course_Price")} value={"coursePrice"} />
            ) : (
              <></>
            )}
            {/* {obj ? <Tab label={LL("Course_Certificate")} value={7} /> : <></>} */}
            {obj ? (
              <Tab
                label={LL("Course_Link_Course_Category")}
                value={"courseCategory"}
              />
            ) : (
              <></>
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={"generalInfo"}>
          <Box
            maxWidth="md"
            sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
          >
            <form onSubmit={handleSubmit}>
              <MyTextField
                tabIndex={0}
                fullWidth
                id="name"
                name="name"
                variant="outlined"
                color="primary"
                _label={LL("Name")}
                value={obj.name}
                setObj={setObjField}
                _vresults={vresults && vresults.name ? vresults.name : false}
              />
              {/* <Box sx={{ mt: 3 }}>
                                <MyTextField
                                    tabIndex={1}
                                    fullWidth
                                    id="description"
                                    name="description"
                                    variant="outlined"
                                    color="primary"
                                    _label={LL("Description")}
                                    
                                    value={obj.description}
                                    setObj={setObjField}
                                    _vresults={
                                        vresults && vresults.description ? vresults.description : false
                                    }
                                />
                            </Box> */}
              <Box mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("description")}
                </Typography>
                <MyCKEditor
                  data={obj.description ? obj.description : ""}
                  setObj={setObjField}
                  name="description"
                />
              </Box>
              <Box mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("requirementstext")}
                </Typography>
                <MyCKEditor
                  data={obj.requirementstext ? obj.requirementstext : ""}
                  setObj={setObjField}
                  name="requirementstext"
                />
              </Box>
              <Box mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("whatwillyoulearntext")}
                </Typography>
                <MyCKEditor
                  data={
                    obj.whatwillyoulearntext ? obj.whatwillyoulearntext : ""
                  }
                  setObj={setObjField}
                  name="whatwillyoulearntext"
                />
              </Box>
              <Box mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("targetaudiencetext")}
                </Typography>
                <MyCKEditor
                  data={obj.targetaudiencetext ? obj.targetaudiencetext : ""}
                  setObj={setObjField}
                  name="targetaudiencetext"
                />
              </Box>
              <Box mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("shortdescription")}
                </Typography>
                <MyCKEditor
                  data={obj.shortdescription ? obj.shortdescription : ""}
                  setObj={setObjField}
                  name="shortdescription"
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <StatusSelect
                  tabIndex={2}
                  id="status"
                  name="status"
                  variant="outlined"
                  color="primary"
                  value={obj.status}
                  field="status"
                  statusType="course"
                  setObj={setObjField}
                  _defaultValue={obj.status}
                />
              </Box>

              <Box sx={{ mt: 3 }}>
                <LanguageSelect
                  tabIndex={4}
                  id="idlanguage"
                  name="idlanguage"
                  variant="outlined"
                  color="primary"
                  value={obj.idlanguage}
                  field="idlanguage"
                  setObj={setObjField}
                  obj={obj}
                  _defaultValue={obj.idlanguage}
                />
              </Box>
              <TeacherAutocomplete
                tabIndex={3}
                id="idteacher"
                field="idteacher"
                setObj={setObj}
                obj={obj}
                setObjFieldGeneral={setObjField}
              />
              {obj.teacher ? (
                <Box sx={{ mt: 3 }}>
                  {ComponentCommonTools.processObjectFieldOfObject(
                    obj,
                    "teacher",
                    "email"
                  )}
                </Box>
              ) : (
                <></>
              )}
              <Box sx={{ mt: 3 }}>
                <MyTextField
                  tabIndex={5}
                  fullWidth
                  id="popularidentifier"
                  name="popularidentifier"
                  variant="outlined"
                  color="primary"
                  _label={LL("PopularIdentifier")}
                  size="small"
                  value={obj.popularidentifier}
                  setObj={setObjField}
                  _vresults={undefined}
                />
              </Box>
              <MyButton
                tabIndex={3}
                disabled={isFormDisabled}
                color="primary"
                fullWidth
                variant="contained"
                size="large"
                type="submit"
                sx={{ mt: 4 }}
                children={LL("Bt_Submit")}
              />
            </form>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={"courseFilter"}>
          {obj ? (
            <AddCourseFilter
              mainObject={mainObject}
              currentRoute={currentRoute}
            />
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel value={value} index={"courseSettings"}>
          {obj && obj.idcoursesettings ? (
            <EditCourseSettingsInfo
              mainObject={mainObject}
              currentRoute={currentRoute}
            />
          ) : (
            <AddCourseSettings
              mainObject={mainObject}
              currentRoute={currentRoute}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={"courseEnrollment"}>
          {obj ? (
            <AddCourseEnrollment
              mainObject={mainObject}
              currentRoute={currentRoute}
            />
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel value={value} index={"courseLesson"}>
          {obj ? (
            <AddLesson mainObject={mainObject} currentRoute={currentRoute} />
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel value={value} index={"coursePrice"}>
          {obj && obj.hasOwnProperty("courseprice") ? (
            <EditCoursePrice
              mainObject={mainObject}
              currentRoute={currentRoute}
              course={obj}
            />
          ) : (
            <AddCoursePrice
              mainObject={mainObject}
              currentRoute={currentRoute}
              course={obj}
            />
          )}
        </TabPanel>
        {/* <TabPanel value={value} index={7}>
                    {obj && obj.hasOwnProperty('certificate') ? <EditCertificate mainObject={mainObject} currentRoute={currentRoute} /> : <AddCertificate mainObject={mainObject} currentRoute={currentRoute} />}
                </TabPanel> */}
        <TabPanel value={value} index={"courseCategory"}>
          {obj ? (
            <AddLinkCourseCategory
              mainObject={mainObject}
              currentRoute={currentRoute}
            />
          ) : (
            <></>
          )}
        </TabPanel>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default EditCourse;
