
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export default class PhoneDto implements Idto {
    id?: string;
    phonenumber?: string;
    countrycode?: string;
    status?: number;
    activationcode?: string;
    idtypephone?: string;
    typephone?: any;
    iduser?: string;
    constructor() {
        this.status = Status.ACTIVE;
    }
}
