import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import PhoneDto from 'dto/user/phone.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneService from "services/user/phone.service";
import IconButton from '@mui/material/IconButton';
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { TypeGenderSelect } from "components/elements/Select/GenderTypeSelect";

import MyDatePicker from 'components/elements/MyDatePicker/MyDatePicker';
import { RoleSelect } from 'components/elements/Select/RoleSelect';
import MyTable from 'components/elements/TabelTools/MyTable';
import ResultListDTO from 'dto/app/resultlist.dto';
import TbFilterDTO from 'dto/app/tbfilter.dto';
import TbFilterListDTO from 'dto/app/tbfilterlist.dto';

import FilterTools from 'tools/filtertools';
import { Status } from 'tools/status';
import RequestListDTO from 'dto/app/requestlist.dto';
import RequestFilterDTO from 'dto/app/requestfilter.dto';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { PhoneTypeSelect } from 'components/elements/Select/PhoneTypeSelect';

const phoneService = new PhoneService();

interface AddPhoneProps {

    currentRoute: any;

    mainObject: any;
}

const AddPhone: React.FC<AddPhoneProps> = ({
    currentRoute,
    mainObject,
    ...props
}) => {


  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

    const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [id, setId] = useState<string | undefined>(_id);

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<PhoneDto | undefined>(new PhoneDto())


    const [cookies, setCookies] = useCookies();

    const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
    const [rows, setRows] = useState<Array<PhoneDto>>(new Array<PhoneDto>());

    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const [responseParams, setResponseParams] = useState<RequestListDTO>(
        new RequestListDTO()
    );

    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
    const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
        new TbFilterListDTO()
    );
    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.countrycode = obj.countrycode !== undefined ? obj.countrycode : '';
        obj.phonenumber = obj.phonenumber !== undefined ? obj.phonenumber : '';
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.idtypephone = obj.idtypephone !== undefined ? obj.idtypephone : '';
        
        setObj(obj);

        let t = validateField("countrycode", obj.countrycode);
        t = validateField("phonenumber", obj.phonenumber, t);
    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'countrycode');
        rez = ValidateTools.vForm(vresults, 'phonenumber', rez);

        setIsFormDisabled(rez);
    };
    
    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);
    
    useEffect(() => {
        validateAll();
    }, [obj, validators]);

    const processValidators = () => {
        let v: any = {
            countrycode: [],
            phonenumber: [],
        };

        v.countrycode.push(new RequiredValidator(LL('Required field countrycode', LabelTypes.LABEL_VALIDATION), ''));
        v.phonenumber.push(new RequiredValidator(LL('Required field phonenumber', LabelTypes.LABEL_VALIDATION), ''));

        setValidators(v);
    };


    // ------------------------------------------------
    const deleteObj = (obj: any) => {
        setDeleteOneObject(obj);
    };

    const columns = [];
    columns.push(ComponentCommonTools.columns_GetCheck());
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "countrycode"));
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "phonenumber"));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL));
    columns.push(ComponentCommonTools.columns_StandardObjectColumn(LL, "typephone", "name"));
    columns.push(
        ComponentCommonTools.columns_ActionsColumn(
            LL,
            mainObject,
            deleteObj,
            ["delete"],
        )
    );



    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (obj !== undefined && id !== undefined && obj.phonenumber !== undefined) {
            obj.iduser = id;
            phoneService.add(getList, {}, obj);
        }

        setObj(new PhoneDto());
    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    };

    const loadObjects = (data: ResultListDTO) => {
        CommonTools.processListLoadObjects(
            data,
            setData,
            setRows,
            setTotal,
            setTotalPage,
            setResponseParams
        );
    };

    const getList = () => {
        setLoading(true);
        if (id !== undefined) phoneService.getList(loadObjects, {}, reqList);
    };

    const getReqList = () => {
        let reqList = RouteTools.prepareListRequest(currentRoute, cookies);
        reqList = phoneService.getDefaultRequestList(reqList);
        const filters = new Array<RequestFilterDTO>()
        const filter = new RequestFilterDTO();
        filter.field = "iduser";
        if (id !== undefined) filter.values = [id];
        else filter.values = [];
        filters.push(filter);
        reqList.filters = filters;

        setReqList(reqList);
    };


    const checkIsLoading = () => {
        validateAll();
        const load: boolean = CommonTools.checkIsLoading(
            reqList,
            responseParams,
            data,
            total,
            totalPage,
            rows
        );
        setLoading(load);
    };

    const processTbFilterList = () => {
        const _tobj = new TbFilterListDTO();

        var t: TbFilterDTO;

        t = new TbFilterDTO("search", FilterTools.fiterTypeText);
        _tobj.addFilter(t);



        setTbFilterList(_tobj);
    };

    useEffect(() => {
        processTbFilterList();
        processValidators();
    }, []);

    useEffect(() => {
        getReqList();
    }, [currentRoute]);

    useEffect(() => {
        if (CommonTools.atLeastOneFieldDefined(reqList)) {
            getList();
        }
    }, [reqList]);

    useEffect(() => {
        checkIsLoading();
    }, [data, rows, reqList, total, totalPage]);

    const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);


    const handleDelete = (item: any) => {

        phoneService.delete(item.id, getList, undefined);
    };

    return !loading && obj ? (
        <>
            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
            >

                <form onSubmit={handleSubmit} >
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="countrycode"
                        name="countrycode"
                        variant="outlined"
                        color="primary"
                        _label={LL("countrycode")}
                        size="small"
                        value={obj.countrycode}
                        setObj={setObjField}
                        _vresults={
                            vresults && vresults.countrycode ? vresults.countrycode : false
                        }
                    />
                    <Box sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="phonenumber"
                            name="phonenumber"
                            variant="outlined"
                            color="primary"
                            _label={LL("phonenumber")}
                            size="small"
                            value={obj.phonenumber}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.phonenumber ? vresults.phonenumber : false
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <StatusSelect
                            tabIndex={2}
                            id="status"
                            name="status"
                            variant="outlined"
                            color="primary"
                            value={obj.status}
                            field="status"
                            size="small"
                            setObj={setObjField}
                            _defaultValue={obj.status}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <PhoneTypeSelect
                            tabIndex={3}
                            id="idtypephone"
                            name="idtypephone"
                            variant="outlined"
                            color="primary"
                            value={obj.idtypephone}
                            field="idtypephone"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                        />
                    </Box>
                    <MyButton
                        tabIndex={4}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>

            </Box>
            <Box mt={3} >
                <MyTable
                    _columns={columns}
                    _data={rows}
                    _total={total}
                    _totalPage={totalPage}
                    _reqList={reqList}
                    _tbFilterList={tbFilterList}
                    setReqList={setReqList}
                    loading={loading}
                    currentRoute={currentRoute}
                    onDelete={handleDelete}
                    mainObject={mainObject}
                    fieldToShowOnDelete="phonenumber"
                    deleteOneObject={deleteOneObject}
                    setDeleteOneObject={setDeleteOneObject}
                    onRowClickCb={() => { }}
                    {...props}
                />
            </Box>
        </>
    ) : (
        <Loading />
    );
};

export default AddPhone;
