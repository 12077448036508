import React, { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { logger } from "hoc/logger";
import { useResource } from "hook/useResource";

import { MySelect } from "components/elements/Select/MySelect";
import { Types } from "tools/types"
import SelectOptions from "dto/app/selectoptions.dto";
import { Skeleton } from "@mui/material";
type TypeSelectProps = {
    setObj: ((field: any, value: any) => void);
    field: any;
    _defaultValue?: number;
    [key: string]: any; // for the rest of the props which we do not have types for
}
const QuestionTypeSelect: React.FC<TypeSelectProps> = ({
    setObj,
    field,
    _defaultValue,
    ...props
}) => {
    const { LL } = useResource();
    const label = "QuestionType";
    const defaultValue = _defaultValue ? _defaultValue : '';
    const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
    const [loading, setLoading] = useState<boolean>(true);
    const [value, setValue] = useState<number | any>(defaultValue);
    const processOptions = () => {
        const options: Array<SelectOptions> = Types.GA('questiontype');
        setOptions(options);
    }

    useEffect(() => {
        processOptions()
    }, []);

    useEffect(() => {
        checkIsLoading();
    }, [options]);

    useEffect(() => {
        setObj(field, value);
    }, [value]);


    const checkIsLoading = () => {
        let load: boolean = false;
        if (options === undefined) load = true;
        setLoading(load);
    };

    return !loading ? (
        <MySelect
            options={options}
            _label={label}
            setValue={setValue}
            value={value}
            {...props}
        />
    ) : (<Skeleton variant="rectangular" height={50} />);
};

export { QuestionTypeSelect };
