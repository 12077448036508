import React, { useState, useEffect } from "react";

import VideoService from "services/video/video.service";
import { logger } from "hoc/logger";

import { VideoDto } from "dto/video/video.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from "@mui/material/Grid";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { FileTools } from "tools/filetools";
import SpecialTopInfo from "./SpecialTopInfo";
import VideoPlayer from "components/video/VideoPlayer";

const videoService = new VideoService();

interface SpecialVideoDetailProps {
  children: any;
  currentRoute: any;
  _obj: VideoDto;
  mainObject: any;
}

const SpecialVideoDetail: React.FC<SpecialVideoDetailProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  logger("SpecialVideoDetailSpecialVideoDetail", currentRoute);
//   const mainUrl = CommonTools.generateMainUrl(mainObject);
  const mainUrl = CommonTools.generateMainUrlSpecial(currentRoute);

  const { LL } = useResource();
  const detailObj = _obj !== undefined ? _obj : undefined;
  const [loading, setLoading] = useState<boolean>(true);
  const [obj, setObj] = useState<VideoDto | undefined>(detailObj);
  const [id] = useState<string>(currentRoute._paths[3]);

  const [parentType, setParentType] = useState<string>(currentRoute._paths[0]);
  const [parentId, setParentId] = useState<string>(currentRoute._paths[1]);

  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");

  const editUrl = CommonTools.generateEditUrlSpecial(
    id,
    parentType,
    parentId,
    "video"
  );
  const _idLanguage =
    currentRoute.historystate.idLanguage !== undefined
      ? currentRoute.historystate.idLanguage
      : "";
  const [idLanguage, setIdLanguage] = useState<string>(_idLanguage);

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined) {
      if (idLanguage === "") videoService.get(id, loadObject, {});
      else videoService.get(id, loadObject, {}, idLanguage);
    }
  };

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  const setFileUrls = () => {
    if (obj === undefined) return;

    if (obj.fileInfoObj == undefined) {
      setImgUrl("");
      setFullUrl("");
      return;
    }

    const imgUrl = FileTools.getFileImage(
      obj.fileInfoObj._id,
      obj.fileInfoObj.name,
      200,
      200
    );
    const aUrl = FileTools.getFileUrl(
      obj.fileInfoObj._id,
      obj.fileInfoObj.name
    );

    setImgUrl(imgUrl);
    setFullUrl(aUrl);
  };

  useEffect(() => {
    if (obj === undefined) getObject();
    if (idLanguage !== "") getObject();
  }, [idLanguage]);

  useEffect(() => {
    checkIsLoading();
    setFileUrls();
  }, [obj]);

  const setObjFieldLanguage = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;

    if (value !== undefined) setIdLanguage(value);
  };

  const prepareObjectForUpdate = (obj: any, mainObj: any) => {
    if (!obj) return;
    if (!mainObj) return;

    // const mainUrl = CommonTools.generateMainUrl(mainObj);
  const mainUrl = CommonTools.generateMainUrlSpecial(currentRoute);
    const object: any = {
      id: id,
      obj: obj,
      idLanguage: idLanguage,
      _mainurl: mainUrl,
    };
    return object;
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: "/" + parentType,
      label: LL("crumb_" + parentType),
    },
    {
      url: "/" + parentType + "/" + parentId + "/video",
      label: LL("crumb_video"),
    },
    { label: LL("crumb_Detailvideo") },
  ];

  const getFileBlock = () => {
    return <VideoPlayer videoId={obj?.videoid} platform={obj?.videoserver} />;
  };

  return !loading ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
        maxWidth="md"
      >
        <MyNavBt
          tabIndex={1}
          href={mainUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />

        <SpecialTopInfo
          parentType={parentType}
          parentId={parentId}
          specialtype={"video"}
          suplimentar={"detail"}
          variant="h4"
          sx={{ my: 3, ml: 3 }}
        />

        {obj ? (
          <MyNavBt
            tabIndex={0}
            href={editUrl}
            children={
              <IconButton className="editBtn">
                <EditIcon />
              </IconButton>
            }
            sx={{ ml: "auto" }}
            _hstate={prepareObjectForUpdate(
              videoService.prepareObjToUpdate(obj),
              mainObject
            )}
            _mainurl={mainUrl}
          />
        ) : (
          <></>
        )}
      </Box>

      {obj ? (
        <Box
          maxWidth="md"
          sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
        >
          {getFileBlock()}

          <Box>
            <LanguageSelect
              tabIdex={0}
              id="idlanguage"
              name="idlanguage"
              variant="outlined"
              color="primary"
              value={obj.idlanguage ? obj.idlanguage : idLanguage}
              field="idlanguage"
              size="small"
              setObj={setObjFieldLanguage}
              obj={obj}
            />
          </Box>

          <Grid spacing={2}>
            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("Name")}
              </Typography>
            </Grid>
            <Grid>{obj ? obj.name : ""}</Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("Status")}
              </Typography>
            </Grid>
            <Grid>{obj ? obj.status_name : ""}</Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("language")}
              </Typography>
            </Grid>
            <Grid>{obj.language ? obj.language.name : ""}</Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("ordercriteria")}
              </Typography>
            </Grid>
            <Grid>{obj ? obj.ordercriteria : ""}</Grid>

            <Grid mt={3}>
              <Typography color="text.disabled" variant="caption">
                {LL("isdefault")}
              </Typography>
            </Grid>
            <Grid>
              {ComponentCommonTools.processBooleanField(
                obj,
                "isdefault",
                false,
                LL
              )}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <></>
      )}
    </>
  ) : (
    <Loading />
  );
};

export default SpecialVideoDetail;
