import React, { useState, useEffect } from "react";

import PermissionService from "services/auth/permission.service";
import { logger } from "hoc/logger";
import useMyFormValidation from "hook/useMyFormValidation";
import { PermissionDto } from "dto/permission/permission.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import Permission from "./Permission";
import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MyCheckboxField from "components/form/mycheckboxfield";
import IconButton from "@mui/material/IconButton";
import RoleDto from "dto/user/role.dto";
import { RoleSelector } from "components/role/components/roleselector";

const permissionService = new PermissionService();

interface EditPermissionProps {
  children: any;
  currentRoute: any;
  _obj: any;
  mainObject: any;
}

const EditPermission: React.FC<EditPermissionProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const _id =
    currentRoute.historystate !== undefined ? currentRoute._paths[2] : "";
  const [id, setId] = useState<string | undefined>(_id);
  const [loading, setLoading] = useState<boolean>(false);

  var [obj, setObj] = useState<any | undefined>();
  // const _roles = obj.roles ?? obj.roles;
  const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
  const [roles, setRoles] = useState<Array<RoleDto> | undefined>();

  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const [acceptedRoles, setAcceptedRoles] = useState<Array<string>>([]);

  const loadObject = (obj: any) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      const permission =
        object.permission !== undefined ? object.permission : {};
      const roles = object.roles !== undefined ? object.roles : [{}];
      setObj(object);
      setRoles(roles);
    }
  };

  const getObject = () => {
    if (id !== undefined) permissionService.get(id, loadObject, {});
  };

  const validateAll = () => {
    if (!obj) return;
    obj.name = obj.name !== undefined ? obj.name : "";
    obj.id = obj.id !== undefined ? obj.id : "";
    obj.description = obj.description !== undefined ? obj.description : "";

    obj.acceptedroles =
      obj.acceptedroles !== undefined
        ? obj.acceptedroles !== null
          ? obj.acceptedroles
          : []
        : [];

    setAcceptedRoles(obj.acceptedroles);
    setObj(obj);
  };

  const checkLoading = () => {
    if (!loading) return;
    if (!obj) return;
    setLoading(false);
  };

  useEffect(() => {
    validateAll();
    if (id === "") {
      if (obj !== undefined) setId(obj.id);
    }
    if (obj === undefined) getObject();
  }, [obj]);

  useEffect(() => {
    checkLoading();
  }, [obj]);

  const handleRolesChange = (value: string) => {
    if (acceptedRoles.includes(value)) {
      return acceptedRoles.filter((role) => role !== value);
    } else {
      return [...acceptedRoles, value];
    }
  };

  const setCheckRole = (event: any) => {
    const value = event.target.value;
    const rez = handleRolesChange(value);
    setAcceptedRoles(rez);
  };
  const checkDefaultRoles = (value: string | undefined) => {
    if (value === undefined) return false;
    if (obj === undefined) return false;
    if (obj.acceptedroles === undefined) return false;
    if (obj.acceptedroles === null) return false;
    if (obj.acceptedroles.length === 0) return false;

    return obj.acceptedroles.includes(value);
  };
  // const processRoles = () => {
  //     if(roles === undefined) return (<></>);
  //     return roles.map((item: RoleDto) =>
  //     (
  //         <Box>
  //             <MyCheckboxField
  //                 defaultChecked={checkDefaultRoles(item.id)}
  //                 id={item.id}
  //                 name={item.name}
  //                 _label={item.name}
  //                 setObj={undefined}
  //                 onChange={setCheckRole}
  //                 checked={undefined}
  //                 value={item.id}
  //                 color="primary"
  //                 size="small"
  //                 _vresults={undefined}
  //             />
  //         </Box>
  //     )
  //     )
  // }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (id !== undefined) {
      permissionService.update(
        id,
        ComponentCommonTools.goToMainPage,
        { mainObject },
        { acceptedRoles }
      );
    }
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    { label: LL("crumb_Edit" + mainObject) },
  ];
  return !loading && obj ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={2}
          href={mainUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />
        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {LL("EditBlock")}
        </Typography>
      </Box>
      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >

            <Typography color="text.disabled" variant="caption">
              {LL("Name")}:
            </Typography>
            <Typography sx={{ pb: 3 }}>{obj.name ? obj.name : ""}</Typography>

            <Typography color="text.disabled"  variant="caption">
              {LL("Description")}:
            </Typography>
            <Typography>{obj.description ? obj.description : ""}</Typography>

          <Divider sx={{ my: 2 }} />
          <form onSubmit={handleSubmit}>
            <RoleSelector
              setCheckRole={setCheckRole}
              checkDefaultRoles={checkDefaultRoles}
            />
            <MyButton
              tabIndex={1}
              disabled={isFormDisabled}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
          </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default EditPermission;
