import React, { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { logger } from "hoc/logger";
import { useResource } from "hook/useResource";
import LangService from "services/lang/lang.service";
import { MySelect } from "components/elements/Select/MySelect";
import RequestListDTO from 'dto/app/requestlist.dto';
import SelectOptions from "dto/app/selectoptions.dto";
import ResultListDTO from 'dto/app/resultlist.dto';
import { LanguageDto } from 'dto/language/language.dto';
import { CommonTools } from 'tools/commontools';
import { Skeleton } from "@mui/material";
const langService = new LangService();

type LanguageSelectProps = {
    setObj: ((field: any, value: any) => void);
    field: any;
    obj: any;
    _defaultValue?: any;
    forAddForms?: boolean;
    [key: string]: any; // for the rest of the props which we do not have types for
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({
    setObj,
    field,
    obj,
    _defaultValue,
    forAddForms,
    ...props
}) => {
    const { LL, langs } = useResource();

    const label = "Language";
    forAddForms = forAddForms ?? false;
    const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE_ID ? process.env.REACT_APP_DEFAULT_LANGUAGE_ID : '';
    const defaultValue = _defaultValue ? _defaultValue : defaultLanguage;
    const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
    const [loading, setLoading] = useState<boolean>(true);
    const [value, setValue] = useState<string>();
    const [data, setData] = useState<Array<LanguageDto> | undefined>();
    const onPage = 20;

    // const [reqList, setReqList] = useState<RequestListDTO>(langService.getDefaultRequestList(onPage));

    // const loadObjects = (data: ResultListDTO) => {
    //     setData(data.objects);
    // };



    const getList = () => {
        setLoading(true);
        // langService.getList(loadObjects, {}, reqList);
        setData(langs);
    };

    const processOptions = () => {

        if (data !== undefined) {
            const options: Array<SelectOptions> = data.map((item) => {
                const obj = {
                    label: item.name,
                    value: item.id
                }
                return obj;
            })
            setOptions(options);
        }

    }


    useEffect(() => {
        setLoading(true);
        getList();
    }, []);


    useEffect(() => {
        if (data !== undefined) processOptions()
        checkIsLoading();
    }, [options, data]);

    useEffect(() => {
        if (value) setObj(field, value);
        logger("LanguageSelectuseEffectvalue", value, options);
    }, [value]);

    useEffect(() => {
        if (forAddForms) {
            if (_defaultValue) setValue(_defaultValue);
            if (!_defaultValue && !value) setValue(defaultLanguage);
        }
    }, [_defaultValue]);

    const checkIsLoading = () => {
        let load: boolean = false;
        if (data === undefined) load = true;
        if (options === undefined) load = true;
        // if (value === undefined) load = true;
        setLoading(load);
    };

    return !loading ? (
        <MySelect
            options={options}
            _label={label}
            setValue={setValue}
            value={value}
            {...props}
        />
    ) : (<Skeleton variant="rectangular" height={50} />);
};

export { LanguageSelect };
