import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
// import {CourseCategoryDto} from "dto/course/coursecategory.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import CourseCategoryRepository from "repositories/course/coursecategory.repository";
import { Status } from "tools/status";
import GeneralService from "services/general.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import { CommonTools } from "tools/commontools";

export default class CourseCategoryService extends GeneralService implements IService {
    courseCategoryRepository: CourseCategoryRepository = new CourseCategoryRepository();
    constructor() {
        super();
        this.handleGetList = this.handleGetList.bind(this);
        this.handleGet = this.handleGet.bind(this);
    }
    handleUpdate(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;

        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }

    prepareObjectUpdate(obj: any): CourseCategoryDto {
        const rez = new CourseCategoryDto();
        if (obj.hasOwnProperty('id')) rez.id = obj.id;
        if (obj.hasOwnProperty('status')) rez.status = obj.status;
        if (obj.hasOwnProperty('ordercriteria')) rez.ordercriteria = obj.ordercriteria;
        if (obj.hasOwnProperty('idicon') && obj.idicon) rez.idicon = obj.idicon;
        if (obj.hasOwnProperty('istop')) rez.istop = obj.istop;
        if (obj.hasOwnProperty('popularidentifier')) rez.popularidentifier = obj.popularidentifier;
        if (obj.hasOwnProperty('idparent')) rez.idparent = obj.idparent;
        if (obj.hasOwnProperty('idlanguage')) rez.idlanguage = obj.idlanguage;
        if (obj.hasOwnProperty('name')) rez.name = obj.name;
        if (obj.hasOwnProperty('keymeta')) rez.keymeta = obj.keymeta;
        if (obj.hasOwnProperty('descriptionmeta')) rez.descriptionmeta = obj.descriptionmeta;
        if (obj.hasOwnProperty('url')) rez.url = obj.url;
        
        if (obj.hasOwnProperty('deleteicon') && obj.deleteicon) rez.deleteicon = obj.deleteicon;
        if(obj.hasOwnProperty('files')) rez.files = obj.files;
        return rez;
    }
    async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        this.courseCategoryRepository.update(id, this.handleUpdate, this.prepareObjectUpdate(data), cbparameters);
    }

    handleDelete(result?: any, cbparameters?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters);
        }
    }

    async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;

        this.courseCategoryRepository.delete(id, this.handleDelete, cbparameters);
    }



    handleGet(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;
        this.populateObject(rez.obj, result.obj, [
            { key: 'status', type: 'status' },
            { key: 'istop', type: 'booleans' }
        ]);
        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

        return rez;
    }

    // async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
    //     cbparameters = cbparameters ? cbparameters : {};
    //     cbparameters._cb = cb;
    //     const t = await this.courseCategoryRepository.get(id, this.handleGet, cbparameters);
    //     if (cb == undefined)
    //     {
    //         return this.handleGet(t);
    //     }
    // }
    async get(id: string, cb?: any, cbparameters?: any, idLanguage?: string): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.courseCategoryRepository.get(id, this.handleGet, cbparameters, idLanguage);
        if (cb == undefined) {
            return this.handleGet(t);
        }
    }

    handleGetList(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultListDTO();

        rez = result;
        if (result.err) return;

        this.parseResultPopulate(result, rez, [
            { key: 'status', type: 'status' },
            { key: 'istop', type: 'booleans' }

        ]);

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

        return rez;
    }

    async getList(
        cb?: any,
        cbparameters?: any,
        data?: RequestListDTO
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.courseCategoryRepository.getList(this.handleGetList, cbparameters, data);
        if (cb == undefined) {
            return this.handleGetList(t);
        }
    }

    handleAdd(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }



    async add(
        cb?: any,
        cbparameters?: any,
        data?: any
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;

        this.courseCategoryRepository.add(this.handleGetList, cbparameters, data);
    }


    getDefaultRequestListSelect(onPage?: number): RequestListDTO {
        onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
        if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
        var defaultRequestList = new RequestListDTO();
        defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
        defaultRequestList.onpage = onPage;
        var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
        defaultRequestSortCriteria.asc = true;
        defaultRequestSortCriteria.field = 'name';
        defaultRequestList.sortcriteria = []
        defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
        return defaultRequestList;
    }

    getDefaultRequestList(obj: RequestListDTO, idParent: string): RequestListDTO {
        if (
            !obj.sortcriteria ||
            !Array.isArray(obj.sortcriteria) ||
            !obj.sortcriteria.length
        ) {
            var sobj = new RequestSortCriteriaDTO();
            sobj.asc = true;
            sobj.field = "name";

            obj.sortcriteria = [];
            obj.sortcriteria?.push(sobj);
        }
        
        obj = CommonTools.addToRequestFilter(
          obj,
          "idparent",
          idParent
        );

        return obj;
    }
}
