import React from "react";
import { MessageTypes } from "tools/messagetypes";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const MessageItem = ({ _obj, _closeMessage, ...props }) => {
  const [obj, setObj] = React.useState(_obj);
  const [typeClass, setTypeClass] = React.useState("");

  const clMess = () => {
    if (!_closeMessage) return;
    _closeMessage(obj);
  };

  const processClassType = () => {
    var type = "";

    if (obj && obj.mestype == MessageTypes.MESSAGE_SUCCESS) type = "success";
    if (obj && obj.mestype == MessageTypes.MESSAGE_WARNING) type = "warning";
    if (obj && obj.mestype == MessageTypes.MESSAGE_ERROR) type = "error";

    setTypeClass(type);
  };

  const processAutomatedClosed = () => {
    if (!obj) return;

    let exp = 10000;

    if (obj.mestype == MessageTypes.MESSAGE_SUCCESS)
      exp = process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_SUCCESS;
    if (obj.mestype == MessageTypes.MESSAGE_WARNING)
      exp = process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_WARNING;
    if (obj.mestype == MessageTypes.MESSAGE_ERROR)
      exp = process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_ERROR;

    exp = parseInt(exp);
    if (isNaN(exp)) exp = 10000;

    const timer = setTimeout(() => {
      clMess();
    }, exp);
    return () => clearTimeout(timer);
  };

  // ----------------------------------------
  React.useEffect(() => {
    processClassType();
    processAutomatedClosed();
  }, [obj]);

  return obj ? (
    <Alert
      sx={{
        position: "absolute",
        fontSize: "12px",
        right: "10px",
        bottom: "10px",
        width: "350px",
      }}
      severity={typeClass}
      onClick={clMess}
      onClose={() => {}}
    >
      <AlertTitle>{obj.code}</AlertTitle>
      {obj.message}
    </Alert>
  ) : (
    <></>
  );
};

export { MessageItem };
