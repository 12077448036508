import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class CourseSettingsDto implements Idto {

  id?: string;
  idtypecourse?: string;
  idschedule?: string;
  password?: string;
  hastest?: boolean;
  idtest?: string;
  idcourse?: string;
  test?: any;
  typecourse?: any;
  schedule?: any;
  maxnumberofstudents?: number
}

export class PostCourseSettingsDto implements Idto {

  idtypecourse?: string;
  idschedule?: string;
  password?: string;
  hastest?: boolean;
  idtest?: string;
  idcourse?: string;
  test?: any;
  typecourse?: any;
  schedule?: any;
  maxnumberofstudents?: number

}