import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import MyTable from "components/elements/TabelTools/MyTable";
import AgreementService from "services/legal/agreement.service";
import { logger } from 'hoc/logger';
import RequestListDTO from 'dto/app/requestlist.dto';
import ResultListDTO from 'dto/app/resultlist.dto';
import { AgreementDto } from 'dto/legal/agreement.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import { CommonTools } from 'tools/commontools';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { ComponentCommonTools } from 'tools/componentcommontools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { MessagesContainerPopUpConfirmDelete } from 'components/message/MessagesContainerPopUpConfirmDelete';
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { GridActionsCellItem } from '@mui/x-data-grid';
import TbFilterDTO from 'dto/app/tbfilter.dto';
import TbFilterListDTO from 'dto/app/tbfilterlist.dto';
import FilterTools from 'tools/filtertools';
import { RouteTools } from 'tools/routetools';
import { Status } from 'tools/status';

const agreementService = new AgreementService();


interface AgreementProps {
  children: any;
  currentRoute: any;
  mainObject: any;
}

const Agreement: React.FC<AgreementProps> = ({
  children,
  currentRoute,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const lu = CommonTools.generateListUrl(mainObject, currentRoute);
    setListUrl(lu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
  }, [mainObject, currentRoute]);

  useEffect(() => {
    logger("DetailPageDetailPageDetailPageDetailPage 1111111111111", listUrl);
    _setListUrl(listUrl);
  }, [listUrl]);

  const { LL, _getListUrl, _setListUrl } = useResource();
  const [cookies, setCookies] = useCookies();

  const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  const [rows, setRows] = useState<Array<AgreementDto>>(new Array<AgreementDto>());

  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
  );

  const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
  const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
    new TbFilterListDTO()
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);

  const deleteUser = (obj: any) => {


    setDeleteOneObject(obj);
  };
  const columns = [];
  columns.push(ComponentCommonTools.columns_GetCheck());
  columns.push(ComponentCommonTools.columns_StandardColumn(LL, "name"));
  columns.push(ComponentCommonTools.columns_ConstantColumn(LL));
  columns.push(ComponentCommonTools.columns_ConstantColumn(LL, "date"));
  columns.push(
    ComponentCommonTools.columns_ActionsColumn(LL, mainObject, deleteUser,
      ["edit", "detail", "delete", "gallery", "attachment", "video"],
      ["detail"],
    )
  );

  // const columns = [
  // {
  //   field: "__check__",
  //   hideable: false,
  //   sortable: false,

  //   width: 1,
  //   align: "center",
  //   headerAlign: "center",

  // },
  //     { field: 'name', headerName: LL('headerName_Name'), flex: 1 },

  //     {
  //         field: "status",
  //         headerName: LL("headerName_Status"),
  //         renderCell: (params: GridRenderCellParams<any>) => {

  //           if (params.row !== undefined) {
  //             if (params.row.hasOwnProperty("status_name")) {
  //               return params.row.status_name;
  //             }
  //           } else {
  //             return "";
  //           }
  //         },
  //         sortable: true,
  //         flex: 1,
  //       },
  //       {
  //         field: "date",
  //         headerName: LL("headerName_Date"),
  //         renderCell: (params: GridRenderCellParams<any>) => {

  //           if (params.row !== undefined) {
  //             if (params.row.hasOwnProperty("date_name")) {
  //               return params.row.date_name;
  //             }
  //           } else {
  //             return "";
  //           }
  //         },
  //         sortable: true,
  //         flex: 1,
  //       },

  //       {
  //         field: "actions",
  //         type: "actions",
  //         flex:1,
  //         getActions: (params: any) => [
  //           <GridActionsCellItem
  //             icon={<SecurityIcon />}
  //             label="Edit"
  //             onClick={() => {
  //               toggleEdit(params.row);
  //             }}
  //             // showInMenu
  //           />,
  //           <GridActionsCellItem
  //             icon={<FileCopyIcon />}
  //             label="Detail"
  //             onClick={() => {
  //               toggleDetail(params.row);
  //             }}
  //             // showInMenu
  //           />,
  //           <GridActionsCellItem
  //             icon={<DeleteIcon />}
  //             label="Delete"
  //             onClick={() => {
  //               deleteUser(params.row);
  //             }}
  //           />,
  //         ],
  //       },
  // ];

  const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
      data,
      setData,
      setRows,
      setTotal,
      setTotalPage,
      setResponseParams
    );
  };

  const getList = () => {
    setLoading(true);
    const urlPart = RouteTools.prepareListLocation(reqList);
    RouteTools.setHistory(mainUrl + urlPart, {});
    setListUrl(mainUrl + urlPart);
    agreementService.getList(loadObjects, {}, reqList);
  };

  const getReqList = () => {
    let reqList = RouteTools.prepareListRequest(currentRoute, cookies);
    reqList = agreementService.getDefaultRequestList(reqList);

    setReqList(reqList);
  };

  const checkIsLoading = () => {
    const load: boolean = CommonTools.checkIsLoading(
      reqList,
      responseParams,
      data,
      total,
      totalPage,
      rows
    );
    setLoading(load);
  };

  const processTbFilterList = () => {
    const _tobj = new TbFilterListDTO();

    var t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    _tobj.addFilter(t);



    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    _tobj.addFilter(t);


    t = new TbFilterDTO("date", FilterTools.fiterTypeDateTSRange);
    _tobj.addFilter(t);

    setTbFilterList(_tobj);
  };

  useEffect(() => {
    processTbFilterList();

  }, []);

  useEffect(() => {
    getReqList();
  }, [currentRoute]);

  useEffect(() => {
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
      getList();
    }
  }, [reqList]);

  useEffect(() => {
    checkIsLoading();
  }, [data, rows, reqList, total, totalPage]);




  const toggleEdit = (obj: any) => {
    logger("toggleAdmin", obj);
    ComponentCommonTools.goToEdit(obj, mainObject);
  };

  const toggleDetail = (obj: any) => {
    ComponentCommonTools.goToDetails(obj, mainObject);
  };



  const handleDelete = (item: any) => {
    logger("DeletelabelDeletelabelDeletelabel", item);

    agreementService.delete(item.id, getList, undefined);
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      label: LL("crumb_" + mainObject),
    },
  ];
  return !loading || 1 ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 3 }}>
          {LL(mainObject + "page")}
        </Typography>
        <MyNavBt
          href={addUrl}
          children={LL("bt_Add")}
          className="addButton"
          size="small"
          _hstate={{}}
          _mainurl={mainUrl}
        />
      </Box>
      <MyTable
        _columns={columns}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        _tbFilterList={tbFilterList}
        setReqList={setReqList}
        loading={loading}
        currentRoute={currentRoute}
        onDelete={handleDelete}
        mainObject={mainObject}
        fieldToShowOnDelete="name"
        deleteOneObject={deleteOneObject}
        setDeleteOneObject={setDeleteOneObject}
        {...props}
      />

    </>
  ) : (
    <Loading />
  );
};


export default Agreement;
