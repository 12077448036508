import React, { useState, useEffect } from "react";

import PricePolicyLinkTeacherService from "services/finance/pricepolicylinkteacher.service";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";

import ResultObjectDTO from "dto/app/resultobject.dto";

import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";

import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import IconButton from "@mui/material/IconButton";

import { useForm } from "hook/useForm";
import { PricePolicyLinkTeacherDto } from "dto/finance/pricepolicylinkteacher.dto";
import { PricePolicySelect } from "components/elements/Select/PricePolicySelect";
const service = new PricePolicyLinkTeacherService();

interface PricePolicyLinkTeacherProps {
  children: any;
  currentRoute: any;
  _obj: PricePolicyLinkTeacherDto;
  mainObject: any;
}

const PricePolicyLinkTeacher: React.FC<PricePolicyLinkTeacherProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,
  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [id, setId] = useState<string | undefined>(_id);

  const [loading, setLoading] = useState<boolean>(false);

  const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
  const [obj, isFormDisabled, setObjField, setObj] =
    useForm<PricePolicyLinkTeacherDto>(
      new PricePolicyLinkTeacherDto(),
      RequiredValidator.getValidators(["idpricepolicy"])
    );

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined) {
      service.get(id, loadObject, {});
    }
  };

  useEffect(() => {
    if (id === "") {
      if (obj !== undefined) {
        setId(obj.id);
      }
    }
  }, []);

  const checkIsLoading = () => {
    let load: boolean = false;
    if (!obj) load = true;
    setLoading(load);
  };

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  useEffect(() => {
    getObject();
  }, [id]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (id !== undefined) {
      service.update(
        id,
        ComponentCommonTools.handleSuccessAddAndEdit,
        { mainObject },
        obj
      );
    }
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    {
      url: detailUrl,
      label: LL("crumb_Detail" + mainObject),
    },
    { label: LL("crumb_Edit" + mainObject) },
  ];

  return !loading && obj ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={2}
          href={detailUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />
        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {LL("EditBlock")}
        </Typography>
      </Box>

      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <Box sx={{ mt: 3 }}>
          <Typography>
            {LL("Teacher_email")}
            {": "}
            {CommonTools.processObjectField(obj, ["teacher", "email"])}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box sx={{ mt: 3 }}>
            <PricePolicySelect
              tabIndex={8}
              id="idpricepolicy"
              name="idpricepolicy"
              variant="outlined"
              color="primary"
              value={obj.idpricepolicy}
              idTypeCourse=""
              field="idpricepolicy"
              size="small"
              setObj={setObjField}
              obj={obj}
            />
          </Box>
          <MyButton
            tabIndex={3}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Submit")}
          />
        </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default PricePolicyLinkTeacher;
