import React, { useEffect, useState, useRef } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import {
    IconButton,
    Stack,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Box

} from "@mui/material";
import MyTextField from 'components/form/mytextfield';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from "hook/useAuth";
import { TypesSelect } from "components/elements/Select/TypesSelect";
// import { RegisterTicketDto } from "dto/support/ticket.dto";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";

import { MyButton } from "components/general/MyButton";

import { Types } from "tools/types";
import TicketService from "services/support/ticket.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { ResponseTicketAdminDto, TicketDto } from "dto/support/ticket.dto";
import { Status } from "tools/status";
import { CommonTools } from "tools/commontools";
import { StatusSelect } from "components/elements/Select/StatusSelect";

type TicketAdminFormProps = {
    ticket: TicketDto;
    setObjWasChanged: any;
    objWasChanged: string;


}
const ticketService = new TicketService();
const TicketAdminForm: React.FC<TicketAdminFormProps> = ({
    ticket,
    setObjWasChanged,
    objWasChanged,

    ...props
}) => {

    const { LL } = useResource();
    const { user } = useAuth();

    const [obj, setObj] = useState<ResponseTicketAdminDto>(new ResponseTicketAdminDto());
    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);
    const [isFormDisabled, setIsFormDisabled] = useState(true);
    const [loading, setLoading] = useState<boolean>(true);

    const validateAll = () => {
        if (!obj) return;
        if (!ticket) return;


        obj.message = obj.message !== undefined ? obj.message : '';
        obj.status = obj.status ?? Status.OPEN_TICKET;
        obj.type = ticket.type;


        setObj(obj);
        validateField('message', obj.message);
    };


    const processValidators = () => {
        setLoading(true);
        let v: any = {
            message: [],
        };

        v.message.push(new RequiredValidator('', ''));
        setValidators(v);
    };

    useEffect(() => {
        processValidators();
        validateAll();
    }, [ticket, objWasChanged]);

    const checkLoading = () => {

        if (!validators) return;
        if (!ticket) return;

        setLoading(false);
    };
    useEffect(() => {
        checkLoading();
    }, [validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [
        vresults,
        obj.status,
        obj.type
    ]);



    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };
    const checkIsFormDisabled = () => {
        let rez = ValidateTools.vForm(vresults, 'message');

        if (!obj.type) rez = true;
        if (!obj.status) rez = true;

        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;

        if (!obj) return;
        if (!user) return;
        if (!ticket) return;
        if (!ticket.hasOwnProperty('id')) return;
        if (!ticket.id) return;
        obj.idoperator = user.id;


        setLoading(true);
        ticketService.responseByAdmin(ticket.id, handleResult, {}, obj);
    };

    const handleResult = (result: ResultObjectDTO, cbparams: any) => {
        if (!result) return;
        if (!result.err) {

            setObjWasChanged(CommonTools.generateRandomString(5))
            setLoading(false);
            setObj(new ResponseTicketAdminDto());
        }
    }

    const processComponent = () => {

        if (!obj) return <></>;
        if (loading) return <></>;

        return (
            <Box>
            <form onSubmit={handleSubmit}>
                
                <StatusSelect
                                tabIndex={0}
                                id="status"
                                name="status"
                                variant="outlined"
                                color="primary"
                                value={obj.status}
                                field="status"
                                size="small"
                                setObj={setObjField}
                                _defaultValue={obj.status}
                                statusType="ticket"
                                    
                            />
                <Box mt={3}>
                    
                    <MyTextField
                        tabIndex={2}
                        fullWidth
                        id="message"
                        name="message"
                        minRows={4}
                        color="primary"
                        _label={LL("message")}
                        size="small"
                        value={obj.message}
                        setObj={setObjField}
                        multiline
                        _vresults={vresults && vresults.message ? vresults.message : false}
                    />
                </Box>


                <Box mt={3}>
                    <MyButton
                        tabIndex={1}
                        disabled={isFormDisabled}
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                        className="btn"
                        children={LL("Bt_Submit")}
                    />
                </Box>
            </form>
            </Box>
        )
    }


     return processComponent();
    
}

export { TicketAdminForm };