
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export default class RoleDto implements Idto {
  id?: string;
  name?: string;
  fixed?: boolean;
  
}
