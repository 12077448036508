import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
import { PostNomenclatureDto } from "dto/nomenclature/postnomenclature.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import TypeBlogCategoryRepository from "repositories/nomenclature/typeblogcategory.repository";
import { Status } from "tools/status";

import GeneralService from "services/general.service";

import GeneralNomenclatureService from "./generalnomenclature.service";

export default class TypeBlogCategoryService extends GeneralNomenclatureService  implements IService {
  constructor() {
    super(new TypeBlogCategoryRepository());
  }
}
// export default class TypeBlogCategoryService extends GeneralService implements IService {
//   typeBlogCategoryRepository: TypeBlogCategoryRepository = new TypeBlogCategoryRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   fromTypePopulateToPostType(obj: PopulateTypeDto): PostNomenclatureDto {
//     const object = new PostNomenclatureDto();
    
//     object.idlanguage = obj.language?.id;
//     object.status = obj.typeobject?.status;
//     object.ordercriteria = obj.typeobject?.ordercriteria;
//     object.name = obj.name;
//     object.idtype = obj.id;
//     object.id = obj.typeobject?.id;

//     return object;
//   }


//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {
    
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.typeBlogCategoryRepository.update(id, this.handleUpdate, data, cbparameters);
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.typeBlogCategoryRepository.delete(id, this.handleDelete, cbparameters);
//   }



//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

    // return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.typeBlogCategoryRepository.get(id, this.handleGet, cbparameters);
        // if (cb == undefined)
        // {
        //     return this.handleGet(t);
        // }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

        // return rez;
//   }
//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.typeBlogCategoryRepository.getList(this.handleGetList, cbparameters, data);
        // if (cb == undefined)
        // {
        //     return this.handleGetList(t);
        // }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.typeBlogCategoryRepository.add(this.handleGetList, cbparameters, data);
//   }


//   getDefaultRequestList(onPage?: number): RequestListDTO {
//     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     var defaultRequestList = new RequestListDTO();
//     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     defaultRequestList.onpage = onPage;
//     var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//     defaultRequestSortCriteria.asc = true;
//     defaultRequestSortCriteria.field = 'name';
//     defaultRequestList.sortcriteria = []
//     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     return defaultRequestList;
//   }
// }
