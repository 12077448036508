import React, { useState, useEffect } from 'react';

import QuestionService from "services/course/question.service";
import { logger } from 'hoc/logger';

import { QuestionDto } from 'dto/course/question.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';
import Answer from 'components/course/test/components/answer/Answer';
import { RouteTools } from 'tools/routetools';

const questionService = new QuestionService();


interface DetailQuestionProps {
    children: any;
    currentRoute: any;
    _obj: QuestionDto,
    mainObject: any;
}

const DetailQuestion: React.FC<DetailQuestionProps> = ({
    children,
    currentRoute,
    _obj,
    mainObject,
    ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();
    const detailObj = _obj !== undefined ? _obj : undefined;
    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<QuestionDto | undefined>(detailObj);
    const [id] = useState<string>(currentRoute._paths[1]);
    const editUrl = CommonTools.generateEditUrl(mainObject, id);


    const _testUrl = obj ? CommonTools.generateDetailUrl('Test', obj.idtest) : '';
    const mainUrlTest = CommonTools.generateMainUrl('Test');
    const [testDetailUrl, setTestDetailUrl] = useState<string>(_testUrl);


    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {
            questionService.get(id, loadObject, {});

        }
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        if (obj === undefined) getObject();
    }, []);

    useEffect(() => {
        checkIsLoading();
        if (testDetailUrl === '') {
            if (obj !== undefined) {
                setTestDetailUrl(CommonTools.generateDetailUrl('Test', obj.idtest));
            }
        }

    }, [obj]);



    const prepareObjectForUpdate = (obj: any, mainObj: any,value?:string) => {
        if (!obj) return;
        if (!mainObj) return;
        if(!value) value = ''
        const mainUrl = CommonTools.generateMainUrl(mainObj);
        const object: any = {
            id: id,
            obj: obj,
            _mainurl: mainUrl,
            anchor:value
        }
        return object;
    }

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: mainUrlTest,
            label: LL("crumb_Test"),
        },
        {
            url: testDetailUrl,
            label: LL("crumb_DetailTest"),
        },
        { label: LL("crumb_Detail" + mainObject) },
    ];


    const [value, setValue] = React.useState(CommonTools.getAnchor(currentRoute, 'generalInfo'));

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue);
        RouteTools.setAnchor(newValue)
    };

    return !loading ? (
        <>
            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
            <Box sx={{ flexDirection: 'row', display: 'flex', alignContent: 'center', alignItems: 'center' }} maxWidth="md">
                <MyNavBt
                    tabIndex={1}
                    href={testDetailUrl}
                    children={<IconButton color="primary" ><ArrowBackIcon /></IconButton>}
                    size="large"
                    _hstate={{}}
                    _mainurl={mainUrl}
                />
                <Typography variant="h4" sx={{ my: 3, ml: 3 }}>  {LL("DetailBlock")} </Typography>

                {obj ? (
                    <MyNavBt
                        tabIndex={0}
                        href={editUrl}
                        children={<IconButton className='editBtn'><EditIcon /></IconButton>}
                        sx={{ ml: 'auto' }}
                        _hstate={prepareObjectForUpdate(obj, mainObject,value)}
                        _mainurl={mainUrl}
                    />) : (<></>)
                }

            </Box>
            <Box maxWidth="md" className="tabs">
            <Tabs
                
                variant="scrollable"
                value={value}
                onChange={handleChange}
            >
                {obj ? <Tab label={LL("Question_main_Info")} value={'generalInfo'} /> : <></>}
                {obj ? <Tab label={LL("Answer_Info")} value={'answer'} /> : <></>}
            </Tabs>
            </Box>
            <TabPanel value={value} index={'generalInfo'}>
                <Box
                    maxWidth="md"
                    sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4, }}
                >
                    <Grid spacing={2} >

                        <Grid>
                            <Typography color="text.disabled" variant='caption'>{LL("name")}</Typography>
                        </Grid>
                        <Grid>
                            {ComponentCommonTools.processObjectField(obj, 'name')}
                        </Grid>
                        <Grid mt={3}>
                            <Typography color="text.disabled" variant='caption'>{LL("ordercriteria")}</Typography>
                        </Grid>
                        <Grid>
                            {ComponentCommonTools.processObjectField(obj, 'ordercriteria')}
                        </Grid>
                        <Grid mt={3}>
                            <Typography color="text.disabled" variant='caption'>{LL("status")}</Typography>
                        </Grid>
                        <Grid>
                            {ComponentCommonTools.processObjectField(obj, 'status_name')}
                        </Grid>
                        <Grid mt={3}>
                            <Typography color="text.disabled" variant='caption'>{LL("type")}</Typography>
                        </Grid>
                        <Grid>
                            {ComponentCommonTools.processObjectField(obj, 'type_name')}
                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={'answer'}>
                {obj ? <Answer mainObject={mainObject} currentRoute={currentRoute} /> : <></>}
            </TabPanel>
        </>
    ) : (
        <Loading />
    );
};

export default DetailQuestion;
