
import { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { useAuth } from "hook/useAuth";
import AppBar from '@mui/material/AppBar';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import SignInDto from "dto/auth/signin.dto";

import Button from "@mui/material/Button";
import { Loading } from "components/general/Loading";
import MyTextField from "components/form/mytextfield";
import MyCheckboxField from "components/form/mycheckboxfield";
import RequiredValidator from "validators/required.validator";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import Container from "@mui/material/Container";
import InputAdornment from '@mui/material/InputAdornment';

import MailOutlineIcon from '@mui/icons-material/MailOutline';

import Box from '@mui/material/Box';
import { logger } from "hoc/logger";

const Login = () => {

  const { signIn } = useAuth();

  const { LL } = useResource(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const [obj, setObj] = useState(false);

  const [validators, setValidators] = useState(false);
  const [vresults, setVResults] = useState(false);
  const [remember, setRemember] = useState(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    if (vresults) return;

    obj.identifier = obj.identifier != undefined ? obj.identifier : '';
    obj.password = obj.password != undefined ? obj.password : "";
    setObj(obj);

    let t = validateField("identifier", obj.identifier);
    t = validateField("password", obj.password, t);
  };

  const validateField = (field, value, v) => {
    logger("validateField_vresults", field, value, v);
    v = (v) ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);
    logger("validateField_vresults cccc", field, value, v);
    setVResults(v);
    return v;
  };

  const processValidators = () => {
    let v = {};
    v.identifier = [];
    v.password = [];

    v.identifier.push(new RequiredValidator(LL('Required field identifier', LabelTypes.LABEL_VALIDATION), ''));
    v.password.push(new RequiredValidator(LL('Required field password', LabelTypes.LABEL_VALIDATION), ''));

    setValidators(v);
  };

  const checkIsLoading = () => {
    if (!isLoading) return;
    if (!obj) return;
    if (!validators) return;
    validateAll();
    setIsLoading(false);
  };

  useEffect(() => {
    const _obj = new SignInDto();
    setObj(_obj);
    processValidators();
  }, []);

  useEffect(() => {
    checkIsLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);


  const setObjField = (field, value) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);

    validateField(field, value);
  };

  const setObjRemember = (value) => {
    setRemember(value);
  };

  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, 'identifier');
    rez = ValidateTools.vForm(vresults, "password", rez);
    
    setIsFormDisabled(rez);
  };



  const handleSubmit = (event) => {
    event.preventDefault();

    if (isFormDisabled) return false;

    signIn(obj, remember);
  };

  return isLoading ? (
    <Loading />
  ) : (



    <Container maxWidth="sm" className="loginForm" sx={{display:"flex", alignContent:"center", alignItems:"center", height:"100vh"}}>
    <AppBar component="nav" sx={{bgcolor:'white', p:2}}>
      <Box className="logo"></Box>
    </AppBar>
    <Box sx={{
        alignItems: "center",
        alignContent:"center",
        width:"100%",
        flexDirection:"column",
        justifyContent:"center",
      }}>

          

          <h1 align="center">{LL("Autentificare în cont")}</h1>

          <form onSubmit={handleSubmit} >
            <Box  pb={3}>
              <MyTextField
                fullWidth
                id="identifier"
                name="identifier"
                variant="outlined"
                color="primary"
                
                
                InputProps={{
                startAdornment:<InputAdornment position="start"><MailOutlineIcon /></InputAdornment>,}}
                _label={LL("Identifier Login")}
                value={obj.identifier}
                setObj={setObjField}
                _vresults={
                  vresults && vresults.identifier ? vresults.identifier : false
                }
              />
            </Box>
            <Box pb={1}>
            <MyTextField
              fullWidth
              type="password"
              id="password"
              name="password"
              variant="outlined"
              color="primary"

              InputProps={{
              startAdornment:<InputAdornment position="start"><LockOpenIcon /></InputAdornment>,}}
              _label={LL("Password")}
              value={obj.password}
              setObj={setObjField}
              _vresults={
                vresults && vresults.password ? vresults.password : false
              }
            />
            </Box>
          <Box>
            <MyCheckboxField
              id="remember"
              name="remember"
              _label={LL("remember")}
              setObj={setObjRemember}
              checked={remember}
              color="primary"
              size="small"
            />
          </Box>
            <Box mt={2}>
              <Button
                disabled={isFormDisabled}
                color="primary"
                fullWidth
                variant="contained"
                size="large"
                type="submit"                
              >
                {LL("Bt Login")}
              </Button>
            </Box>
          </form>

    </Box>
    </Container>
  );
};

export { Login };
