import React, { useState, useEffect } from 'react';

import TypeExperienceService from "services/nomenclature/typeexperience.service";
import GenEditType from 'components/elements/MyGenComponents/GenEditType';
import { PostNomenclatureDto } from 'dto/nomenclature/postnomenclature.dto';


const typeExperienceService = new TypeExperienceService();


interface EditTypeExperienceProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const EditTypeExperience: React.FC<EditTypeExperienceProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenEditType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeExperienceService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default EditTypeExperience;
