import React, { useState, useEffect } from "react";

import { logger } from "hoc/logger";

import { PostLanguageDto } from "dto/language/postlanguage.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import { StatusSelect } from "components/elements/Select/StatusSelect";
import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { RouteTools } from "tools/routetools";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import IconButton from "@mui/material/IconButton";
import { TypesSelect } from "components/elements/Select/TypesSelect";
import PageService from "services/page/page.service";
import { PageDto } from "dto/page/page.dto";
import MyCheckboxField from "components/form/mycheckboxfield";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { Status } from "tools/status";
const pageService = new PageService();
interface EditPageProps {
  children: any;
  currentRoute: any;
  _obj: PageDto;
  mainObject: any;
}

const EditPage: React.FC<EditPageProps> = ({
  children,
  currentRoute,
  _obj,
  mainObject,

  ...props
}) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  useEffect(() => {
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  const { LL, _getListUrl, _setListUrl } = useResource();

  const _id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";
  const _idLanguage =
    currentRoute.historystate.idLanguage !== undefined
      ? currentRoute.historystate.idLanguage
      : "";
  const [id, setId] = useState<string | undefined>(_id);

  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<PageDto | undefined>();
  const detailUrl = CommonTools.generateDetailUrl(mainObject, id);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const [idLanguage, setIdLanguage] = useState<string>(_idLanguage);
  const [validators, setValidators] = useState<any>();
  const [vresults, setVResults] = useState<any>(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    // if (vresults) return;
    obj.idpage = obj.idpage !== undefined ? obj.idpage : "";
    obj.name = obj.name !== undefined ? obj.name : "";
    obj.type = obj.type !== undefined ? obj.type : 0;
    obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
    obj.url = obj.url !== undefined ? obj.url : "";
    obj.title = obj.title !== undefined ? obj.title : "";
    obj.keymeta = obj.keymeta !== undefined ? obj.keymeta : "";
    obj.descriptionmeta =
      obj.descriptionmeta !== undefined ? obj.descriptionmeta : "";
    obj.content = obj.content !== undefined ? obj.content : "";

    setObj(obj);

    let t = validateField("name", obj.name);
    t = validateField("type", obj.type, t);
    // t = validateField("url", obj.url, t);
    // t = validateField("keymeta", obj.keymeta, t);
    // t = validateField("descriptionmeta", obj.descriptionmeta, t);
    // t = validateField("content", obj.content, t);
    t = validateField("status", obj.status, t);
    // t = validateField("title", obj.title, t);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);
    setIsFormDisabled(ValidateTools.vForm(v, field));
    setVResults(v);
    return v;
  };

  const processValidators = () => {
    let v: any = {
      name: [],
      type: [],
      // url: [],
      // keymeta: [],
      // descriptionmeta: [],
      // content: [],
      // title: [],
    };

    v.name.push(
      new RequiredValidator(
        LL("Required field name", LabelTypes.LABEL_VALIDATION),
        ""
      )
    );
    v.type.push(
      new RequiredValidator(
        LL("Required field type", LabelTypes.LABEL_VALIDATION),
        ""
      )
    );
    // v.url.push(new RequiredValidator(LL('Required field url', LabelTypes.LABEL_VALIDATION), ''));
    // v.keymeta.push(new RequiredValidator(LL('Required field keymeta', LabelTypes.LABEL_VALIDATION), ''));
    // v.descriptionmeta.push(new RequiredValidator(LL('Required field descriptionmeta', LabelTypes.LABEL_VALIDATION), ''));
    // v.content.push(new RequiredValidator(LL('Required field content', LabelTypes.LABEL_VALIDATION), ''));
    // v.title.push(new RequiredValidator(LL('Required field title', LabelTypes.LABEL_VALIDATION), ''));

    setValidators(v);
  };

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);

  const checkLoading = () => {
    if (!loading) return;
    if (obj === undefined) return;
    if (!validators) return;
    validateAll();
    setLoading(false);
  };

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined) {
      if (idLanguage === "") pageService.get(id, loadObject, {});
      else pageService.get(id, loadObject, {}, idLanguage);
    }
  };

  useEffect(() => {
    processValidators();
    if (id === "") {
      if (obj !== undefined) {
        setId(obj.id);
        if (obj.idlanguage !== undefined) {
          setIdLanguage(obj.idlanguage);
        }
      }
    }
    if (idLanguage !== "") getObject();
  }, [idLanguage]);

  useEffect(() => {
    validateAll();
    checkLoading();
    if (idLanguage === "") {
      if (obj !== undefined) {
        if (obj.idlanguage !== undefined) setIdLanguage(obj.idlanguage);
      }
    }
  }, [obj, validators]);

  useEffect(() => {
    if (currentRoute.historystate.obj !== undefined) {
      setObj(currentRoute.historystate.obj);
      if (idLanguage === "")
        setIdLanguage(currentRoute.historystate.obj.idlanguage);
    }
    if (obj === undefined) getObject();
  }, []);

  const setObjFieldLanguage = (field: any, value: any) => {
    if (value !== undefined) setIdLanguage(value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (isFormDisabled) return false;
    if (obj !== undefined) {
      if (obj.id !== undefined) {
        obj.idlanguage = idLanguage;
        setLoading(true);
        pageService.update(
          obj.id,
          ComponentCommonTools.handleSuccessAddAndEdit,
          { mainObject, idLanguage },
          obj
        );
      }
    }
  };

  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, "name");
    rez = ValidateTools.vForm(vresults, "type", rez);
    // rez = ValidateTools.vForm(vresults, 'url', rez);
    // rez = ValidateTools.vForm(vresults, 'title', rez);
    // rez = ValidateTools.vForm(vresults, 'keymeta', rez);
    // rez = ValidateTools.vForm(vresults, 'descriptionmeta', rez);
    // rez = ValidateTools.vForm(vresults, 'content', rez);
    setIsFormDisabled(rez);
  };

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };

  // const handleSubmit = (event: any) => {
  //     event.preventDefault();
  //     if (isFormDisabled) return false;
  //     if (obj !== undefined) {
  //         setLoading(true);
  //         if (id !== undefined) {
  //             pageService.update(
  //                 id,
  //                 ComponentCommonTools.handleSuccessAddAndEdit,
  //                 { mainObject },
  //                 obj
  //             );
  //         }
  //     }
  // };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      url: mainUrl,
      label: LL("crumb_" + mainObject),
    },
    {
      url: detailUrl,
      label: LL("crumb_Detail" + mainObject),
    },
    { label: LL("crumb_Edit" + mainObject) },
  ];
  return !loading && obj ? (
    <>
      <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={10}
          href={detailUrl}
          children={
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
          }
          size="large"
          _hstate={{}}
          _mainurl={mainUrl}
        />
        <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
          {LL("EditBlock")}
        </Typography>
      </Box>
      <Box
        maxWidth="md"
        sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
      >
        <form onSubmit={handleSubmit}>
          <LanguageSelect
            tabIdex={0}
            id="idlanguage"
            name="idlanguage"
            variant="outlined"
            color="primary"
            value={idLanguage}
            field="idlanguage"
            size="small"
            setObj={setObjFieldLanguage}
            obj={obj}
          />
          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={0}
              fullWidth
              id="name"
              name="name"
              variant="outlined"
              color="primary"
              _label={LL("Name")}
              size="small"
              value={obj.name}
              setObj={setObjField}
              _vresults={vresults && vresults.name ? vresults.name : false}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <StatusSelect
              tabIndex={1}
              id="status"
              name="status"
              variant="outlined"
              color="primary"
              value={obj.status}
              field="status"
              size="small"
              setObj={setObjField}
              _defaultValue={obj.status}
            />
          </Box>
          {/* <Box sx={{ mt: 3 }}>
                        <LanguageSelect
                            tabIndex={2}
                            id="idlanguage"
                            name="idlanguage"
                            variant="outlined"
                            color="primary"
                            value={obj.idlanguage}
                            field="idlanguage"
                            size="small"
                            setObj={setObjField}
                            obj={obj} forAddForms={true}
                        />
                    </Box> */}
          <Box sx={{ mt: 3 }}>
            <TypesSelect
              tabIndex={3}
              id="type"
              name="type"
              variant="outlined"
              color="primary"
              value={obj.type}
              field="type"
              size="small"
              setObj={setObjField}
              _defaultValue={obj.type}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={4}
              fullWidth
              id="url"
              name="url"
              variant="outlined"
              color="primary"
              _label={LL("url")}
              size="small"
              value={obj.url}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={5}
              fullWidth
              id="title"
              name="title"
              variant="outlined"
              color="primary"
              _label={LL("title")}
              size="small"
              value={obj.title}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={6}
              fullWidth
              id="keymeta"
              name="keymeta"
              variant="outlined"
              color="primary"
              _label={LL("keymeta")}
              size="small"
              value={obj.keymeta}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <MyTextField
              tabIndex={7}
              fullWidth
              id="descriptionmeta"
              name="descriptionmeta"
              variant="outlined"
              color="primary"
              _label={LL("descriptionmeta")}
              size="small"
              value={obj.descriptionmeta}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            {/* <MyTextField
                            tabIndex={8}
                            fullWidth
                            id="content"
                            name="content"
                            variant="outlined"
                            color="primary"
                            _label={LL("content")}
                            size="small"
                            value={obj.content}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.content ? vresults.content : false
                            }
                        /> */}
            <MyCKEditor
              data={obj.content ? obj.content : ""}
              setObj={setObjField}
              name="content"
            />
          </Box>

          <MyButton
            tabIndex={9}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Submit")}
          />
        </form>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default EditPage;
