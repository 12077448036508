import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';

import CheckIcon from '@mui/icons-material/Check';
import { logger } from "hoc/logger";

const MyFileField = ({ _label, setObj, _vresults, ...props }) => {
  const [label, setLabel] = useState("");
  const [field, setField] = useState("");
  const [vresults, setVResults] = useState(false);


  useEffect(() => {
    setLabel(_label);
  }, [_label]);

  useEffect(() => {
    setVResults(_vresults);
  }, [_vresults]);

  useEffect(() => {
    const f = props.field ? props.field : props.name;
    setField(f);

  }, [props]);

  const handleChange = (event) => {
    if (!setObj) return;
    setObj(field, event);
  };

  const infoButton = () => {
    if (!vresults) return <></>;
    if (vresults.isOk == '-1') return <></>;

    if (vresults.isOk) {
      return <><Box className="iconInput" sx={{}}><CheckIcon sx={{ color: 'green' }} /></Box></>;
    }

    return <><Box className="iconInput"><ReportGmailerrorredIcon sx={{ color: 'red' }} /></Box></>;
  };

  const infoMessages = () => {
    if (!vresults) return <></>;
    if (!vresults.objects) return <></>;
    if (!vresults.objects.length) return <></>;
    if (vresults.isOk == "-1") return <></>;

    const cl = vresults.isOk ? 'active' : 'disabled';

    return vresults.objects.map((_obj, i) => {
      if (!_obj.message) return;

      return <FormHelperText className={cl}>{_obj.message}</FormHelperText>;
    });
  };

  return (
    <>
      <Box sx={{ position: 'relative', right: '0px' }}>
        <TextField type="file" size="small" variant="outlined" label={label} onFocus={handleChange} onChange={handleChange} {...props} />
        {infoButton()}
        {infoMessages()}
      </Box>
    </>
  );
};

export default MyFileField;
