import React, { useState, useEffect } from 'react';

import UserSettingsService from "services/user/usersettings.service";
import { logger } from 'hoc/logger';

import UserSettingsDto from 'dto/user/usersettings.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

const userSettingsService = new UserSettingsService();


interface UserSettingsProps {
    currentRoute: any;

}

const UserSettingsInfo: React.FC<UserSettingsProps> = ({
    currentRoute,
    ...props
}) => {

    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<UserSettingsDto | undefined>();
    const [id] = useState<string>(currentRoute._paths[1]);


    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {
        if (id !== undefined) userSettingsService.getByUserId(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [currentRoute]);

    useEffect(() => {
        checkIsLoading();
        
    }, [obj]);

    const processAvatar = () => {
        if (!obj) return <></>;
        if(!obj.hasOwnProperty("avatar")) return <></>;
        if (!obj.avatar) return <></>;
        return <>{ComponentCommonTools.getFileBlockImage(obj.avatar,200,200,'',true)}</>
    
      }

    return !loading && obj ? (
        <>

            <Box
                maxWidth="md"
                sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4,marginTop:'1%' }}
            >

                <Grid spacing={2} >
                    <Grid>
                        <Typography color="text.disabled" variant='caption'>{LL("Name")}</Typography>
                    </Grid>
                    <Grid>
                    {ComponentCommonTools.processObjectField(obj, 'name')}
                    </Grid>

                    <Grid mt={3}>
                        <Typography color="text.disabled" variant='caption'>{LL("Surname")}</Typography>
                    </Grid>
                    <Grid>
                        
                        {ComponentCommonTools.processObjectField(obj, 'surname')}
                    </Grid>

                    <Grid mt={3}>
                        <Typography color="text.disabled" variant='caption'>{LL("Birthday")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectField(obj, 'birthday_name')}
                    </Grid>
                    <Grid sx={{ mt: 3 }}>
                        <Typography color="text.disabled" variant='caption'>{LL("typegender")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'typegender','name')}
                    </Grid>
                    <Grid sx={{ mt: 3 }}>
                        <Typography color="text.disabled" variant='caption'>{LL("language")}</Typography>
                    </Grid>
                    <Grid>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'language','name')}
                    </Grid>
                    <Grid mt={3}>
                <Typography color="text.disabled" variant="caption">
                  {LL("Avatar")}
                </Typography>
              </Grid>
              <Grid>
               {processAvatar()}
              </Grid>
                </Grid>
            </Box>
        </>
    ) : (
        <></>
    );
};

export default UserSettingsInfo;
