import React, { useState, useEffect } from 'react';

import TypeExperienceService from "services/nomenclature/typeexperience.service";
import GenDetailType from 'components/elements/MyGenComponents/GenDetailType';


const typeExperienceService = new TypeExperienceService();


interface DetailTypeExperienceProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const DetailTypeExperience: React.FC<DetailTypeExperienceProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenDetailType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeExperienceService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default DetailTypeExperience;
