
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class PostSiteSocialDto implements Idto {
    
    link?: string;
    idtypesocial?: string;
    typesocial?: any;
    status?: number;
    constructor() {
        this.status = Status.ACTIVE;
    }
}


export class SiteSocialDto implements Idto {
    
    id?: string;
    link?: string;
    idtypesocial?: string;
    typesocial?: any;
    status?: number;
    constructor() {
        this.status = Status.ACTIVE;
    }
}