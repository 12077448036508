import React, { useState, useEffect } from 'react';

import TypeLessonService from "services/nomenclature/typelesson.service";
import GenEditType from 'components/elements/MyGenComponents/GenEditType';
import { PostNomenclatureDto } from 'dto/nomenclature/postnomenclature.dto';


const typeLessonService = new TypeLessonService();


interface EditTypeLessonProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const EditTypeLesson: React.FC<EditTypeLessonProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenEditType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeLessonService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default EditTypeLesson;
