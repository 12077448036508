import React, { useState, useEffect } from 'react';

import TypeTargetGroupService from "services/nomenclature/typetargetgroup.service";
import GenListType from 'components/elements/MyGenComponents/GenListType';
import { PopulateTypeDto } from 'dto/nomenclature/populatetype.dto';


const typeTargetGroupService = new TypeTargetGroupService();


interface TypeTargetGroupProps {
    children: any;
    currentRoute: any;
    mainObject: any;
}

const TypeTargetGroup: React.FC<TypeTargetGroupProps> = ({
    children,
    currentRoute,
    mainObject,
    ...props
}) => {

    return (
        <GenListType
            children={children}
            currentRoute={currentRoute}
            service={typeTargetGroupService}
            mainObject={mainObject}
            DTO={PopulateTypeDto}
            {...props}
        />
    )
};

export default TypeTargetGroup;
