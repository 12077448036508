import React, { useState, useEffect } from 'react';

import TypeTargetGroupService from "services/nomenclature/typetargetgroup.service";
import GenEditType from 'components/elements/MyGenComponents/GenEditType';
import { PostNomenclatureDto } from 'dto/nomenclature/postnomenclature.dto';


const typeTargetGroupService = new TypeTargetGroupService();


interface EditTypeTargetGroupProps {
    children: any;
    currentRoute: any;
    mainObject: any;
    _obj?: any,
}

const EditTypeTargetGroup: React.FC<EditTypeTargetGroupProps> = ({
    children,
    currentRoute,
    mainObject,
    _obj,
    ...props
}) => {

    return (
        <GenEditType
            children={children}
            _obj={_obj}
            currentRoute={currentRoute}
            service={typeTargetGroupService}
            mainObject={mainObject}
            {...props}
        />
    )
};

export default EditTypeTargetGroup;
