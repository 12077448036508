
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export default class ChangePasswordByAdminDto implements Idto {
  
  
  password?: string;
  
}
